import {Calculation2Types} from '~src/utils/calculations';
import testJpg from '~src/assets/images/calculation2/test.jpg';
import TimberButtonImage from '~src/assets/images/calculation2/calcButton2.png';
import TimberButtonImageSelected from '~src/assets/images/calculation2/calcButton2Selected.png';
import BrickButtonImage from '~src/assets/images/calculation2/calcButton1.png';
import BrickButtonImageSelected from '~src/assets/images/calculation2/calcButton1Selected.png';

export const calculation2 = {
  meta: {
    title: 'Бесплатный калькулятор расчета строительных материалов',
    description: 'Калькулятор расчета кирпича, блоков, бруса, бревна и прочих материалов',
    keywords: 'строительный калькулятор, расчет кирпича, калькулятор расчета кирпича, калькулятор кирпича, расчет количества кирпича, калькулятор строительных материалов, калькулятор шлакоблока, расчет шлакоблока, расчет кирпича онлайн калькулятор, калькулятор газобетонных блоков, расчет бруса, калькулятор расчета бруса, расчет бревна, калькулятор бревна, калькулятор блоков, расчет блоков, калькулятор расчета блоков, расчет количества блоков, расчет блоков онлайн, расчет блоков для строительства, расчет газобетонных блоков, расчет блоков калькулятор онлайн, калькулятор расчета газобетонных блоков, калькулятор строительных материалов'
  },
  header1: 'Расчет количества материалов для строительства дома — калькулятор',
  breadcrumbs: ['Главная'],
  breadcrumbsSource: ['/'],
  descriptionButton: {
    showMore: 'Показать больше',
    showLess: 'Свернуть',
  },
  description: [
    'Возведение любого здания или его капитальный ремонт требуют предварительной оценки количества необходимых для этого стройматериалов. Сделать это «на глазок» практически нереально, и сегодня огромную помощь в решении подобных задач оказывают специальные программы. Современный онлайн калькулятор подсчета строительных материалов для ремонта квартиры или постройки дома выполняет все расчеты...',
    'Возведение любого здания или его капитальный ремонт требуют предварительной оценки количества необходимых для этого стройматериалов. Сделать это «на глазок» практически нереально, и сегодня огромную помощь в решении подобных задач оказывают специальные программы. Современный онлайн калькулятор подсчета строительных материалов для ремонта квартиры или постройки дома выполняет все расчеты быстро, точно и абсолютно бесплатно. Это экономит будущему владельцу время и деньги, а также освобождает от множества других проблем – например, поиска недостающего стройматериала в разгар сезона строек.',
    'На что способна строительная программа для расчета материала',
    'Простой и дружелюбный интерфейс предлагает пользователю ввести в соответствующие поля ряд данных, касающихся региона строительства, особенностей здания или конструкции и т.д. В результате их обработки строительный калькулятор по подсчету, сколько надо материала на дом или квартиру, выдаст следующую информацию:',
    [
      'объем и тип требующихся стройматериалов;',
      'их геометрические параметры и технические характеристики;',
      'примерную стоимость и прочее.',
    ],
    'Любой строительный онлайн калькулятор расхода материалов является многофункциональным программным средством. Количество рассчитываемых им данных огромно, причем каждая итоговая цифра вычисляется с учетом требований соответствующих ГОСТов и СнИПов. Важным достоинством такого калькулятора является способность определить не только количество материала, но и примерный бюджет.',
    'Скачать расчет или использовать бесплатный сайт-сервис расчета стройматериалов онлайн не достаточно просто. Отличие нашего программного решения расчета материалов для строительства в многофакторности, что позволяет максимально эффективно и точно определить их количество. Но все же, полученные результаты лучше использовать как предварительные, ведь их точность зависит от полноты и правильности введенных данных.',
    'Работа с калькулятором – бесплатный расчет выделения строительных материалов',
    'Работа с программой строится следующим образом. В главном окне калькулятора поочередно выбираются пункты основного меню, каждый из которых соответствует определенной категории данных. В их числе:',
    [
      'геометрические размеры постройки и отдельных ее частей;',
      'размерные параметры предполагаемых к использованию стройматериалов (Д Ш В);',
      'материал стен, перекрытий, варианты кладки, толщина швов;',
      'характеристика дверей, окон, перегородок и т.д.',
    ],
    'Помимо основных данных, методика расчета выделения строительных материалов предусматривает обработку и множества дополнительных. Для их ввода используются отдельные подменю калькулятора, после чего вся полученная информация обобщается и собирается воедино. Также для более удобного пользования на сайте предусмотрен вывод на экран различных справочных сведений.',
    'Процесс ввода возможен двумя способами:',
    [
      'Из выпадающего подменю выбирается нужный пункт кликом мыши.',
      'Цифровые данные вносятся вручную с помощью клавиатуры.',
    ],
    'Проверив правильность ввода, останется лишь нажать кнопку «Рассчитать». После этого на экран будет выведена таблица расчета количества и стоимости строительных материалов для ремонта крыши, лестницы или дома в целом – в зависимости от поставленной задачи.',
    'Преимущества строительного калькулятора',
    'Несомненным достоинством таких бесплатных калькуляторов является способность быстро и точно произвести предварительный расчет количества и стоимости стройматериалов. Однако никакая формула расчета расхода строительных материалов – в т.ч. сыпучих – для строительства дома или ремонта квартиры не может учесть абсолютно все. По этой причине согласование полученных цифр рекомендуется доверить профильному специалисту.',
  ],
  section0: {
    title: 'Выберите основной материал для строительства',
    types: [
      {
        title: 'Кирпич и блоки',
        name: Calculation2Types.brickAndBlocks,
        image: {
          defaultImage: BrickButtonImage,
          imageSelected: BrickButtonImageSelected,
        },
        tooltipText: 'Расчет количества несущих и отделочных материалов для дома и цоколя',
      },
      {
        title: 'Брус',
        name: Calculation2Types.timber,
        image: {
          defaultImage: TimberButtonImage,
          imageSelected: TimberButtonImageSelected,
        },
        tooltipText: 'Расчет количества и объема пиломатериала для строительства домов, бань и т.д.',
      },
    ],
  },
  brickAndBlocksSections: {
    section1: {
      title: '1. Параметры несущих стен',
      image: testJpg,
      brickType: {
        label: 'Материал',
        placeholder: 'Выбрать',
        values: [
          {
            value: 0,
            label: 'Керамический',
          },
          {
            value: 1,
            label: 'Силикатный',
          },
          {
            value: 2,
            label: 'Пенобетонные блоки',
          },
          {
            value: 3,
            label: 'Газосиликатные блоки',
          },
          {
            value: 4,
            label: 'Газобетонные блоки',
          },
          {
            value: 5,
            label: 'Керамоблоки',
          },
        ],
      },
      bearingWallSize: {
        label: 'Размер материала (Д×Ш×В)',
        placeholder: 'Выберите размер',
        values: [
          {
            value: 0,
            label: 'Одинарный — 250×120×65 мм',
          },
          {
            value: 1,
            label: 'Полуторный — 250×120×88 мм',
          },
          {
            value: 2,
            label: 'Двойной — 250×120×140 мм',
          },
        ],
        userSize: {
          label: 'Ввести свои размеры',
          brickLength: {
            label: 'Длина, мм',
            placeholder: 'Введите значение',
            name: 'brickLength',
          },
          brickHeight: {
            label: 'Высота, мм',
            placeholder: 'Введите значение',
            name: 'brickHeight',
          },
          brickWidth: {
            label: 'Ширина, мм',
            placeholder: 'Введите значение',
            name: 'brickWidth',
          },
          brickWeight: {
            label: 'Вес одного изделия, кг',
            placeholder: 'Введите значение',
            name: 'brickWeight',
          },
        },
      },
      executionType: {
        label: 'Исполнение',
        values: [
          {
            label: 'Полнотелый',
            value: 0,
          },
          {
            label: 'Пустотелый',
            value: 1,
          },
        ],
      },
      trimMargin: {
        label: 'Запас на бой и обрезки %',
        placeholder: 'Выберите значение',
        manualInputPlaceholder: 'Введите значение',
        name: 'trimMargin',
        values: [
          {
            value: 5,
            label: '5',
          },
          {
            value: 10,
            label: '10',
          },
          {
            value: 15,
            label: '15',
          },
          {
            value: 20,
            label: '20',
          },
          {
            value: 25,
            label: '25',
          },
          {
            value: 30,
            label: '30',
          },
          {
            value: 35,
            label: '35',
          },
          {
            value: 40,
            label: '40',
          },
          {
            value: 45,
            label: '45',
          },
          {
            value: 50,
            label: '50',
          },
        ],
      },
      masonryNet: {
        label: 'Кладочная сетка',
        placeholder: 'Выбрать',
        values: [
          {
            value: 1,
            label: 'каждый ряд',
          },
          {
            value: 2,
            label: 'через 2 ряда',
          },
          {
            value: 3,
            label: 'через 3 ряда',
          },
          {
            value: 4,
            label: 'через 4 ряда',
          },
        ],
      },
      masonryOption: {
        label: 'Варианты кладки',
        placeholder: 'Выберите вариант',
        values: [
          {
            value: 0.5,
            label: '0.5 блока',
          },
          {
            value: 1,
            label: '1 блок',
          },
          {
            value: 1.5,
            label: '1.5 блока',
          },
          {
            value: 2,
            label: '2 блока',
          },
        ],
      },
      seamThickness: {
        label: 'Толщина швов',
        placeholder: 'Выберите вариант',
        glueValues: [
          {
            value: 2,
            label: '2 мм',
          },
        ],
        plasterValues: [
          {
            value: 5,
            label: '5 мм',
          },
          {
            value: 10,
            label: '10 мм',
          },
          {
            value: 15,
            label: '15 мм',
          },
          {
            value: 20,
            label: '20 мм',
          },
        ],
      },
    },
    section2: {
      title: '2. Параметры фасада',
      claddingType: {
        label: 'Тип фасада',
        placeholder: 'Выберите значение',
        name: 'l_kr',
        values: [
          {
            label: 'Фасадный лицевой кирпич',
            value: 0,
          },
          {
            label: 'Фасадная штукатурка',
            value: 1,
          },
          {
            label: 'Вентилируемый фасад',
            value: 2,
          },
          {
            label: 'Фасадные панели',
            value: 3,
          },
          {
            label: 'Сайдинг',
            value: 4,
          },
        ],
      },
      facadeSize: {
        defaultSize: {
          label: 'Выбрать размеры материала',
          facadeSize: {
            label: 'Размер материала (Д×Ш×В)',
            placeholder: 'Выберите значение',
            values: [
              {
                value: 0,
                label: '250x120x65 мм',
              },
            ],
          },
        },
        userSize: {
          label: 'Ввести свои размеры',
          facadeLength: {
            label: 'Длина, мм',
            placeholder: 'Введите значение',
            name: 'facadeBrickLength',
          },
          facadeHeight: {
            label: 'Высота, мм',
            placeholder: 'Введите значение',
            name: 'facadeBrickHeight',
          },
          facadeWidth: {
            label: 'Ширина, мм',
            placeholder: 'Введите значение',
            name: 'facadeBrickWidth',
          },
          facadeWeight: {
            label: 'Вес одного изделия, кг',
            placeholder: 'Введите значение',
            name: 'facadeBrickWeight',
          },
        },
      },
      trimMargin: {
        label: 'Запас на бой и обрезки %',
        placeholder: 'Выберите значение',
        manualInputPlaceholder: 'Введите значение',
        name: 'facadeTrimMargin',
        values: [
          {
            value: 5,
            label: '5',
          },
          {
            value: 10,
            label: '10',
          },
          {
            value: 15,
            label: '15',
          },
          {
            value: 20,
            label: '20',
          },
          {
            value: 25,
            label: '25',
          },
          {
            value: 30,
            label: '30',
          },
          {
            value: 35,
            label: '35',
          },
          {
            value: 40,
            label: '40',
          },
          {
            value: 45,
            label: '45',
          },
          {
            value: 50,
            label: '50',
          },
        ],
      },
      seamThickness: {
        label: 'Толщина швов',
        placeholder: 'Выберите вариант',
        values: [
          {
            value: 5,
            label: '5 мм',
          },
          {
            value: 10,
            label: '10 мм',
          },
          {
            value: 15,
            label: '15 мм',
          },
          {
            value: 20,
            label: '20 мм',
          },
        ],
      },
      insulation: {
        title: 'Утеплитель',
        insulationType: {
          label: 'Тип утеплителя',
          placeholder: 'Выбрать',
          values: [
            {
              value: 0,
              label: 'Пенополистерол (ППС)',
            },
            {
              value: 1,
              label: 'Экструдированный ППС',
            },
            {
              value: 2,
              label: 'Минеральная вата',
            },
            {
              value: 3,
              label: 'Базальтовая вата',
            },
          ],
        },
        insulationThickness: {
          label: 'Толщина, мм',
          placeholder: 'Введите значение',
          name: 'insulationThickness',
        },
        insulationDensity: {
          label: 'Плотность, кг/м³',
          placeholder: 'Введите значение',
          name: 'insulationDensity',
        },
      },
      facadePanelLength: {
        title: 'Длина панели, мм',
        placeholder: 'Введите значение',
        name: 'facadePanelLength',
      },
      facadePanelWidth: {
        title: 'Ширина панели, мм',
        placeholder: 'Введите значение',
        name: 'facadePanelWidth',
      },
      facadePanelSquare: {
        title: 'Ширина панели, мм',
        placeholder: 'Введите значение',
        name: 'facadePanelSquare',
      },
      facadeWeight: {
        title: 'Вес фасада, кг/м²',
        placeholder: 'Введите значение',
        name: 'facadeWeight',
      },
    },
    section3: {
      title: '3. Параметры стен',
      wallsLength: {
        label: 'Общая длина всех стен, м',
        placeholder: 'Введите значение',
        name: 'wallsLength',
      },
      wallsHeight: {
        label: 'Высота стен по углам, м',
        placeholder: 'Введите значение',
        name: 'wallsHeight',
      },
      outerAngles: {
        label: 'Кол-во внешних углов, шт',
        placeholder: 'Введите значение',
        name: 'wallsOuterAngleCount',
      },
      innerAngles: {
        label: 'Кол-во внутренних углов, шт',
        placeholder: 'Введите значение',
        name: 'wallsInnerAngleCount',
      },
    },
    section5: {
      title: '4. Дополнительно',
      gables: {
        gableType: {
          label: 'Тип фронтона',
          placeholder: 'Выбрать',
          values: [
            {
              label: 'Треугольный',
              value: 0,
            },
            {
              label: 'Трапецевидный',
              value: 1,
            },
            {
              label: 'Пятиугольный',
              value: 2,
            },
          ],
        },
        gableHeight: {
          label: 'Высота фронтона, м',
          placeholder: 'Введите значение',
          name: 'gableHeight',
        },
        gableWidth: {
          label: 'Ширина фронтона, м',
          placeholder: 'Введите значение',
          name: 'gableWidth',
        },
        gableCount: {
          label: 'Количество фронтонов, шт',
          placeholder: 'Введите значение',
          name: 'gableCount',
        },
        gableHeightBetween: {
          label: 'Высота между основаниями, м',
          placeholder: 'Введите значение',
          name: 'gablePentaHeightBetween',
        },
        gableSmallerWidth: {
          label: 'Ширина меньшего основания, м',
          placeholder: 'Введите значение',
          name: 'gableSmallerWidth',
        },
        gableBiggerWidth: {
          label: 'Ширина большего основания, м',
          placeholder: 'Введите значение',
          name: 'gableBiggerWidth',
        },
        gableTriangleHeight: {
          label: 'Высота треугольной части, м',
          placeholder: 'Введите значение',
          name: 'gablePentaTriangleHeight',
        },
      },
      windows: {
        windowHeight: {
          label: 'Высота окна, см',
          placeholder: 'Введите значение',
          name: 'windowHeight',
        },
        windowWidth: {
          label: 'Ширина окна, см',
          placeholder: 'Введите значение',
          name: 'windowWidth',
        },
        windowCount: {
          label: 'Количество окон, шт',
          placeholder: 'Введите значение',
          name: 'windowCount',
        },
      },
      armobelt: {
        armobeltThickness: {
          label: 'Толщина, мм',
          placeholder: 'Введите значение',
          name: 'armobeltThickness',
        },
        armobeltCount: {
          label: 'Количество, шт',
          placeholder: 'Введите значение',
          name: 'armobeltCount',
        },
      },
      doors: {
        doorHeight: {
          label: 'Высота двери, см',
          placeholder: 'Введите значение',
          name: 'doorHeight',
        },
        doorWidth: {
          label: 'Ширина двери, см',
          placeholder: 'Введите значение',
          name: 'doorWidth',
        },
        doorCount: {
          label: 'Количество дверей, шт',
          placeholder: 'Введите значение',
          name: 'doorCount',
        },
      },
      trim: {
        trimType: {
          label: 'Тип отделки',
          placeholder: 'Выбрать',
          values: [
            {
              label: 'Штукатурка',
              value: 0,
            },
            {
              label: 'ГКЛ',
              value: 1,
            },
          ],
        },
        trimThickness: {
          label: 'Толщина слоя, мм',
          placeholder: 'Введите значение',
          name: 'trimThickness',
        },
        trimSize: {
          label: 'Размер панели, мм',
          placeholder: 'Выбрать',
          values: [
            {
              label: '1500x600x12,5',
              value: 0,
            },
            {
              label: '2500x1200x12,5',
              value: 1,
            },
          ],
        },
      },
      jumpers: {
        jumpersLength: {
          label: 'Длина, мм',
          placeholder: 'Введите значение',
          name: 'jumperLength',
        },
        jumpersWidth: {
          label: 'Ширина, мм',
          placeholder: 'Введите значение',
          name: 'jumperWidth',
        },
        jumpersHeight: {
          label: 'Высота, мм',
          placeholder: 'Введите значение',
          name: 'jumperHeight',
        },
        jumpersCount: {
          label: 'Количество, шт',
          placeholder: 'Введите значение',
          name: 'jumperCount',
        },
      },
      partitions: {
        brickType: {
          label: 'Тип материала',
          placeholder: 'Выбрать',
          values: [
            {
              value: 0,
              label: 'Керамический',
            },
            {
              value: 1,
              label: 'Силикатный',
            },
            {
              value: 2,
              label: 'Пенобетонные блоки',
            },
            {
              value: 3,
              label: 'Газосиликатные блоки',
            },
            {
              value: 4,
              label: 'Газобетонные блоки',
            },
          ],
        },
        userSize: {
          label: 'Ввести свои размеры',
          facadeLength: {
            label: 'Длина, мм',
            placeholder: 'Введите значение',
            name: 'partitionBrickLength',
          },
          facadeHeight: {
            label: 'Высота, мм',
            placeholder: 'Введите значение',
            name: 'partitionBrickHeight',
          },
          facadeWidth: {
            label: 'Ширина, мм',
            placeholder: 'Введите значение',
            name: 'partitionBrickWidth',
          },
          facadeWeight: {
            label: 'Вес одного изделия, кг',
            placeholder: 'Введите значение',
            name: 'partitionBrickWeight',
          },
        },
        bearingWallSize: {
          label: 'Размер (Д×Ш×В)',
          placeholder: 'Выберите размер',
          values: [
            {
              value: 0,
              label: 'Одинарный — 250×120×65 мм',
            },
            {
              value: 1,
              label: 'Полуторный — 250×120×88 мм',
            },
            {
              value: 2,
              label: 'Двойной — 250×120×140 мм',
            },
          ],
        },
        executionType: {
          label: 'Исполнение',
          values: [
            {
              label: 'Полнотелый',
              value: 0,
            },
            {
              label: 'Пустотелый',
              value: 1,
            },
          ],
        },
        trimMargin: {
          label: 'Запас на бой и обрезки %',
          placeholder: 'Выберите значение',
          manualInputPlaceholder: 'Введите значение',
          name: 'partitionTrimMargin',
          values: [
            {
              value: 5,
              label: '5',
            },
            {
              value: 10,
              label: '10',
            },
            {
              value: 15,
              label: '15',
            },
            {
              value: 20,
              label: '20',
            },
            {
              value: 25,
              label: '25',
            },
            {
              value: 30,
              label: '30',
            },
            {
              value: 35,
              label: '35',
            },
            {
              value: 40,
              label: '40',
            },
            {
              value: 45,
              label: '45',
            },
            {
              value: 50,
              label: '50',
            },
          ],
        },
        masonryNet: {
          label: 'Кладочная сетка',
          placeholder: 'Выбрать',
          values: [
            {
              value: 1,
              label: 'каждый ряд',
            },
            {
              value: 2,
              label: 'через 2 ряда',
            },
            {
              value: 3,
              label: 'через 3 ряда',
            },
            {
              value: 4,
              label: 'через 4 ряда',
            },
          ],
        },
        masonryOption: {
          label: 'Варианты кладки',
          placeholder: 'Выберите вариант',
          values: [
            {
              value: 0.5,
              label: '0.5 блока',
            },
            {
              value: 1,
              label: '1 блок',
            },
            {
              value: 1.5,
              label: '1.5 блока',
            },
            {
              value: 2,
              label: '2 блока',
            },
          ],
        },
        seamThickness: {
          label: 'Толщина швов',
          placeholder: 'Выберите вариант',
          values: [
            {
              value: 5,
              label: '5 мм',
            },
            {
              value: 10,
              label: '10 мм',
            },
            {
              value: 15,
              label: '15 мм',
            },
            {
              value: 20,
              label: '20 мм',
            },
          ],
        },
        partitionWallsLength: {
          label: 'Длина перегородок, м',
          placeholder: 'Введите значение',
          name: 'partitionWallsLength',
        },
        partitionWallsHeight: {
          label: 'Высота перегородок, м',
          placeholder: 'Введите значение',
          name: 'partitionWallsHeight',
        },
        doors: {
          label: 'Двери',
          doorHeight: {
            label: 'Высота двери, см',
            placeholder: 'Введите значение',
            name: 'partitionDoorHeight',
          },
          doorWidth: {
            label: 'Ширина двери, см',
            placeholder: 'Введите значение',
            name: 'partitionDoorWidth',
          },
          doorCount: {
            label: 'Количество дверей, шт',
            placeholder: 'Введите значение',
            name: 'partitionDoorCount',
          },
        },
        jumpers: {
          label: 'Перемычки',
          jumpersThickness: {
            label: 'Толщина, мм',
            placeholder: 'Введите значение',
            name: 'partitionJumperThickness',
          },
          jumpersLength: {
            label: 'Длина, мм',
            placeholder: 'Введите значение',
            name: 'partitionJumperLength',
          },
          jumpersCount: {
            label: 'Количество, шт',
            placeholder: 'Введите значение',
            name: 'partitionJumperCount',
          },
        },
      },
    },
  },
  timberSections: {
    section1: {
      title: 'Основные параметры',
      timberMaterial: {
        label: 'Вид материала',
        placeholder: 'Выберите вид',
        values: [
          {
            value: 0,
            label: 'Оцилиндрованное бревно',
          },
          {
            value: 1,
            label: 'Пиленый брус прямоугольного сечения',
          },
          {
            value: 2,
            label: 'Профилированный брус естественной влажности',
          },
          {
            value: 3,
            label: 'Профилированный брус камерной сушки',
          },
          {
            value: 4,
            label: 'Клееный брус',
          },
        ],
      },
      timberWidth: {
        label: 'Ширина бруса, мм',
        placeholder: 'Введите значение',
        name: 'balkWidth',
      },
      timberDiameter: {
        label: 'Диаметр бруса, мм',
        placeholder: 'Введите значение',
        name: '',
      },
      timberWallsLength: {
        label: 'Общая длина всех стен, м',
        placeholder: 'Введите значение',
        name: 'wallsOverallLength',
      },
      timberLength: {
        label: 'Высота бруса, мм',
        placeholder: 'Введите значение',
        name: 'balkHeight',
      },
      timberHeight: {
        label: 'Высота стен по углам, м',
        placeholder: 'Введите значение',
        name: 'wallsCornerHeight',
      },
      timberDensity: {
        label: 'Плотность древесины',
        placeholder: 'Выберите значение',
        name: 'balkDensity',
        values: [
          {
            value: 480,
            label: 'Сосна — 480 кг/м³',
          },
          {
            value: 420,
            label: 'Ель — 420 кг/м3',
          },
          {
            value: 350,
            label: 'Пихта — 350 кг/м3',
          },
          {
            value: 405,
            label: 'Кедр — 405 кг/м3',
          },
          {
            value: 635,
            label: 'Лиственница — 635 кг/м3',
          },
          {
            value: 655,
            label: 'Дуб — 655 кг/м3',
          },
        ],
      },
    },
    section2: {
      title: 'Дополнительно',
    },
  },
  resultData: {
    image: '',
    tables: [
      {
        title: 'Несущие конструкции',
        values: [
          {
            key: 'V_st',
            title: 'Объем стен',
            value: 0,
            unit: 'м³',
          },
          {
            key: 'S_st',
            title: 'Площадь стен',
            value: 0,
            unit: 'м²',
          },
          {
            key: 'N_bl_m3',
            title: 'Количество блоков в кубическом метре',
            value: 0,
            unit: 'шт.',
          },
          {
            key: 'N_bl',
            title: 'Общее количество блоков',
            value: 0,
            unit: 'шт.',
          },
          {
            key: 'V_bl',
            title: 'Объем блоков',
            value: 0,
            unit: 'м³',
          },
          {
            key: 'N_kl_set',
            title: 'Кол-во кладочной сетки',
            value: 0,
            unit: 'м²',
          },
          {
            key: 'N_bl_zapas',
            title: 'Блоки, заложенные на бой',
            value: 0,
            unit: 'шт.',
          },
          {
            key: 'V_p_p',
            title: 'Объем раствора для кладки',
            value: 0,
            unit: 'м³',
          },
          {
            key: 'M_p_p',
            title: 'Вес раствора',
            value: 0,
            unit: 'тн',
          },
          {
            key: 'N_p_p',
            title: 'Количество мешков',
            value: 0,
            unit: 'шт.',
          },
        ],
      },
      {
        title: 'Фасадные материалы',
        values: [
          {
            key: 'V_sht',
            title: 'Объем раствора',
            value: 0,
            unit: 'м³',
          },
          {
            key: 'M_sht',
            title: 'Вес раствора',
            value: 0,
            unit: 'тн',
          },
          {
            key: 'N_sht',
            title: 'Количество мешков',
            value: 0,
            unit: 'шт.',
          },
        ],
      },
      {
        title: 'Фасадные материалы2',
        values: [
          {
            key: 'V_st_facade',
            title: 'Объем стен',
            value: 0,
            unit: 'м³',
          },
          {
            key: 'S_st_facade',
            title: 'Площадь стен',
            value: 0,
            unit: 'м²',
          },
          {
            key: 'N_bl_m3_facade',
            title: 'Количество блоков в кубическом метре',
            value: 0,
            unit: 'шт.',
          },
          {
            key: 'N_bl_facade',
            title: 'Общее количество блоков',
            value: 0,
            unit: 'шт.',
          },
          {
            key: 'V_bl_facade',
            title: 'Объем блоков',
            value: 0,
            unit: 'м³',
          },
          {
            key: 'N_kl_set_facade',
            title: 'Кол-во кладочной сетки',
            value: 0,
            unit: 'м²',
          },
          {
            key: 'N_bl_zapas_facade',
            title: 'Блоки, заложенные на бой',
            value: 0,
            unit: 'шт.',
          },
          {
            key: 'V_p_p_facade',
            title: 'Объем раствора для кладки',
            value: 0,
            unit: 'м³',
          },
          {
            key: 'M_p_p_facade',
            title: 'Вес раствора',
            value: 0,
            unit: 'тн',
          },
          {
            key: 'N_p_p_facade',
            title: 'Количество мешков',
            value: 0,
            unit: 'шт.',
          },
        ],
      },
      {
        title: 'Фасадные материалы3',
        values: [
          {
            key: 'N_fp',
            title: 'Количество панелей',
            value: 0,
            unit: 'шт.',
          },
          {
            key: 'N_vf',
            title: 'Количество панелей',
            value: 0,
            unit: 'шт.',
          },
        ],
      },
      {
        title: 'Внутренняя отделка',
        values: [
          {
            key: 'V_sht_trim',
            title: 'Объем раствора',
            value: 0,
            unit: 'м³',
          },
          {
            key: 'M_sht_trim',
            title: 'Вес раствора',
            value: 0,
            unit: 'тн',
          },
          {
            key: 'N_sht_trim',
            title: 'Количество мешков',
            value: 0,
            unit: 'шт.',
          },
          {
            key: 'N_gkl',
            title: 'Количество панелей',
            value: 0,
            unit: 'шт.',
          },
        ],
      },
      {
        title: 'Теплоизоляция',
        values: [
          {
            key: 'V_ut',
            title: 'Объем утеплителя',
            value: 0,
            unit: 'м³',
          },
          {
            key: 'S_ut',
            title: 'Площадь утеплителя',
            value: 0,
            unit: 'м²',
          },
        ],
      },
      {
        title: 'Перегородки',
        values: [
          {
            key: 'V_st_per',
            title: 'Объем стен',
            value: 0,
            unit: 'м³',
          },
          {
            key: 'S_st_per',
            title: 'Площадь стен',
            value: 0,
            unit: 'м²',
          },
          {
            key: 'N_bl_m3_per',
            title: 'Количество блоков в кубическом метре',
            value: 0,
            unit: 'шт.',
          },
          {
            key: 'N_bl_per',
            title: 'Общее количество блоков',
            value: 0,
            unit: 'шт.',
          },
          {
            key: 'V_bl_per',
            title: 'Объем блоков',
            value: 0,
            unit: 'м³',
          },
          {
            key: 'N_kl_set_per',
            title: 'Кол-во кладочной сетки',
            value: 0,
            unit: 'м²',
          },
          {
            key: 'N_bl_zapas_per',
            title: 'Блоки, заложенные на бой',
            value: 0,
            unit: 'шт.',
          },
          {
            key: 'V_p_p_per',
            title: 'Объем раствора для кладки',
            value: 0,
            unit: 'м³',
          },
          {
            key: 'M_p_p_per',
            title: 'Вес раствора',
            value: 0,
            unit: 'тн',
          },
          {
            key: 'N_p_p_per',
            title: 'Количество мешков',
            value: 0,
            unit: 'шт.',
          },
        ],
      },
      {
        title: 'Дополнительно',
        values: [
          {
            key: 'L_ok_slopes',
            title: 'Длина оконных откосов',
            value: 0,
            unit: 'п.м.',
          },
          {
            key: 'S_ok_slopes',
            title: 'Площадь оконных откосов',
            value: 0,
            unit: 'м²',
          },
          {
            key: 'L_dv_slopes',
            title: 'Длина дверных откосов в несущих стенах',
            value: 0,
            unit: 'п.м.',
          },
          {
            key: 'S_dv_slopes',
            title: 'Площадь дверных откосов в несущих стенах',
            value: 0,
            unit: 'м²',
          },
          {
            key: 'L_dv_per_slopes',
            title: 'Длина дверных откосов в перегородках',
            value: 0,
            unit: 'п.м.',
          },
          {
            key: 'S_dv_per_slopes',
            title: 'Площадь дверных откосов в перегородках',
            value: 0,
            unit: 'м²',
          },
        ],
      },
      {
        title: 'Нагрузка на фундамент',
        values: [
          {
            key: 'N_fund',
            title: 'Нагрузка на фундамент от несущих стен',
            value: 0,
            unit: 'кг/м²',
          },
          {
            key: 'N_fund_per',
            title: 'Нагрузка на фундамент от перегородок',
            value: 0,
            unit: 'кг/м²',
          },
        ],
      },
    ],
  },
  balkResultData: {
    title: 'Результаты расчета',
    values: [
      {
        key: 'V_br',
        title: 'Объём бруса',
        value: 0,
        unit: 'м³',
      },
      {
        key: 'S_st',
        title: 'Общая площадь стен с учетом фрононов',
        value: 0,
        unit: 'м²',
      },
      {
        key: 'M_br',
        title: 'Общий вес',
        value: 0,
        unit: 'т',
      },
      {
        key: 'N_venc',
        title: 'Кол-во венцов с учетом фронтов (рядов)',
        value: 0,
        unit: 'шт.',
      },
      {
        key: 'L_ut_rul',
        title: 'Кол-во рулонного утеплителя на все венцы',
        value: 0,
        unit: 'м',
      },
      {
        key: 'D_nag',
        title: 'Диаметр нагелей',
        value: 0,
        unit: 'мм',
      },
      {
        key: 'L_nag',
        title: 'Длина нагелей',
        value: 0,
        unit: 'мм',
      },
      {
        key: 'N_nag',
        title: 'Кол-во нагелей с шагом 1.5м',
        value: 0,
        unit: 'шт.',
      },
      {
        key: 'V_prop',
        title: 'Кол-во пропитки с двух сторон',
        value: 0,
        unit: 'л',
      },
      {
        key: 'Delta_usad',
        title: 'Примерная усадка бруса/бревна',
        value: 0,
        unit: 'мм',
      },
      {
        key: 'N_fund',
        title: 'Нагрузка на фундамент от стен',
        value: 0,
        unit: 'кг/м²',
      },
    ],
  },
  result: {
    title: 'Расчет кирпича',
    sectionsTitle: 'Результаты расчета',
    section1: {
      title: 'Несущие конструкции - ',
      rows: [
        {
          title: 'Объем стен',
          name: 'V_st' as 'V_st',
          unit: 'м³',
        },
        {
          title: 'Площадь стен',
          name: 'S_st' as 'S_st',
          unit: 'м²',
        },
        {
          title: 'Количество блоков в кубическом метре',
          name: 'N_bl_m3' as 'N_bl_m3',
          unit: 'шт.',
        },
        {
          title: 'Общее количество блоков',
          name: 'N_bl' as 'N_bl',
          unit: 'шт.',
        },
        {
          title: 'Объем блоков',
          name: 'V_bl' as 'V_bl',
          unit: 'м³',
        },
        {
          title: 'Кол-во кладочной сетки',
          name: 'N_kl_set' as 'N_kl_set',
          unit: 'м²',
        },
        {
          title: 'Блоки, заложенные на бой',
          name: 'N_bl_zapas' as 'N_bl_zapas',
          unit: 'шт.',
        },
        {
          title: 'Объем раствора для кладки',
          name: 'V_p_p' as 'V_p_p',
          unit: 'м³',
        },
        {
          title: 'Вес раствора',
          name: 'M_p_p' as 'M_p_p',
          unit: 'тн',
        },
        {
          title: 'Количество мешков',
          name: 'N_p_p' as 'N_p_p',
          unit: 'шт.',
        },
      ],
      columns: [
        {
          dataIndex: 'title',
        },
        {
          dataIndex: 'name',
        },
        {
          dataIndex: 'unit',
        },
      ],
    },
    section2Plaster: {
      title: 'Фасадные материалы - ',
      rows: [
        {
          title: 'Объем раствора',
          name: 'V_sht' as 'V_sht',
          unit: 'м³',
        },
        {
          title: 'Вес раствора',
          name: 'M_sht' as 'M_sht',
          unit: 'тн',
        },
        {
          title: 'Количество мешков',
          name: 'N_sht' as 'N_sht',
          unit: 'шт.',
        },
      ],
      columns: [
        {
          dataIndex: 'title',
        },
        {
          dataIndex: 'name',
        },
        {
          dataIndex: 'unit',
        },
      ],
    },
    section2: {
      title: 'Фасадные материалы - ',
      rows: [
        {
          title: 'Объем стен',
          name: 'V_st_facade' as 'V_st_facade',
          unit: 'м³',
        },
        {
          title: 'Площадь стен',
          name: 'S_st_facade' as 'S_st_facade',
          unit: 'м²',
        },
        {
          title: 'Количество блоков в кубическом метре',
          name: 'N_bl_m3_facade' as 'N_bl_m3_facade',
          unit: 'шт.',
        },
        {
          title: 'Общее количество блоков',
          name: 'N_bl_facade' as 'N_bl_facade',
          unit: 'шт.',
        },
        {
          title: 'Объем блоков',
          name: 'V_bl_facade' as 'V_bl_facade',
          unit: 'м³',
        },
        {
          title: 'Блоки, заложенные на бой',
          name: 'N_bl_zapas_facade' as 'N_bl_zapas_facade',
          unit: 'шт.',
        },
        {
          title: 'Объем раствора для кладки',
          name: 'V_p_p_facade' as 'V_p_p_facade',
          unit: 'м³',
        },
        {
          title: 'Вес раствора',
          name: 'M_p_p_facade' as 'M_p_p_facade',
          unit: 'тн',
        },
        {
          title: 'Количество мешков',
          name: 'N_p_p_facade' as 'N_p_p_facade',
          unit: 'шт.',
        },
      ],
      columns: [
        {
          dataIndex: 'title',
        },
        {
          dataIndex: 'name',
        },
        {
          dataIndex: 'unit',
        },
      ],
    },
    section2Panel: {
      title: 'Фасадные материалы - ',
      rows: [
        {
          title: 'Количество панелей',
          name: 'N_fp' as 'N_fp',
          unit: 'шт.',
        },
      ],
      columns: [
        {
          dataIndex: 'title',
        },
        {
          dataIndex: 'name',
        },
        {
          dataIndex: 'unit',
        },
      ],
    },
    section3Plaster: {
      title: 'Внутренняя отделка - Штукатурка для внутренних работ',
      rows: [
        {
          title: 'Объем раствора',
          name: 'V_sht_trim' as 'V_sht_trim',
          unit: 'м³',
        },
        {
          title: 'Вес раствора',
          name: 'M_sht_trim' as 'M_sht_trim',
          unit: 'тн',
        },
        {
          title: 'Количество мешков',
          name: 'N_sht_trim' as 'N_sht_trim',
          unit: 'шт.',
        },
      ],
      columns: [
        {
          dataIndex: 'title',
        },
        {
          dataIndex: 'name',
        },
        {
          dataIndex: 'unit',
        },
      ],
    },
    section3Gkl: {
      title: 'Внутренняя отделка - Гипсокартонный лист',
      rows: [
        {
          title: 'Количество панелей',
          name: 'N_gkl' as 'N_gkl',
          unit: 'шт.',
        },
      ],
      columns: [
        {
          dataIndex: 'title',
        },
        {
          dataIndex: 'name',
        },
        {
          dataIndex: 'unit',
        },
      ],
    },
    section4: {
      title: 'Перегородки - ',
      rows: [
        {
          title: 'Объем стен',
          name: 'V_st_per' as 'V_st_per',
          unit: 'м³',
        },
        {
          title: 'Площадь стен',
          name: 'S_st_per' as 'S_st_per',
          unit: 'м²',
        },
        {
          title: 'Количество блоков в кубическом метре',
          name: 'N_bl_m3_per' as 'N_bl_m3_per',
          unit: 'шт.',
        },
        {
          title: 'Общее количество блоков',
          name: 'N_bl_per' as 'N_bl_per',
          unit: 'шт.',
        },
        {
          title: 'Объем блоков',
          name: 'V_bl_per' as 'V_bl_per',
          unit: 'м³',
        },
        {
          title: 'Кол-во кладочной сетки',
          name: 'N_kl_set_per' as 'N_kl_set_per',
          unit: 'м²',
        },
        {
          title: 'Блоки, заложенные на бой',
          name: 'N_bl_zapas_per' as 'N_bl_zapas_per',
          unit: 'шт.',
        },
        {
          title: 'Объем раствора для кладки',
          name: 'V_p_p_per' as 'V_p_p_per',
          unit: 'м³',
        },
        {
          title: 'Вес раствора',
          name: 'M_p_p_per' as 'M_p_p_per',
          unit: 'тн',
        },
        {
          title: 'Количество мешков',
          name: 'N_p_p_per' as 'N_p_p_per',
          unit: 'шт.',
        },
      ],
      columns: [
        {
          dataIndex: 'title',
        },
        {
          dataIndex: 'name',
        },
        {
          dataIndex: 'unit',
        },
      ],
    },
    section5: {
      title: 'Дополнительно',
      rows: [
        {
          title: 'Длина оконных откосов',
          name: 'L_ok_slopes' as 'L_ok_slopes',
          unit: 'п.м.',
        },
        {
          title: 'Площадь оконных откосов',
          name: 'S_ok_slopes' as 'S_ok_slopes',
          unit: 'м²',
        },
        {
          title: 'Длина дверных откосов в несущих стенах',
          name: 'L_dv_slopes' as 'L_dv_slopes',
          unit: 'п.м.',
        },
        {
          title: 'Площадь дверных откосов в несущих стенах',
          name: 'S_dv_slopes' as 'S_dv_slopes',
          unit: 'м²',
        },
        {
          title: 'Длина дверных откосов в перегородках',
          name: 'L_dv_per_slopes' as 'L_dv_per_slopes',
          unit: 'п.м.',
        },
        {
          title: 'Площадь дверных откосов в перегородках',
          name: 'S_dv_per_slopes' as 'S_dv_per_slopes',
          unit: 'м²',
        },
      ],
      columns: [
        {
          dataIndex: 'title',
        },
        {
          dataIndex: 'name',
        },
        {
          dataIndex: 'unit',
        },
      ],
    },
    section6: {
      title: 'Нагрузка на фундамент',
      rows: [
        {
          title: 'Нагрузка на фундамент от несущих стен',
          name: 'N_fund' as 'N_fund',
          unit: 'кг/м²',
        },
        {
          title: 'Нагрузка на фундамент от перегородок',
          name: 'N_fund_per' as 'N_fund_per',
          unit: 'кг/м²',
        },
      ],
      columns: [
        {
          dataIndex: 'title',
        },
        {
          dataIndex: 'name',
        },
        {
          dataIndex: 'unit',
        },
      ],
    },
    sectionInsulation: {
      title: 'Теплоизоляция - ',
      rows: [
        {
          title: 'Объем утеплителя',
          name: 'V_ut' as 'V_ut',
          unit: 'м³',
        },
        {
          title: 'Площадь утеплителя',
          name: 'S_ut' as 'S_ut',
          unit: 'м²',
        },
      ],
      columns: [
        {
          dataIndex: 'title',
        },
        {
          dataIndex: 'name',
        },
        {
          dataIndex: 'unit',
        },
      ],
    },
  },
  balkResult: {
    title: 'Расчет бруса',
    sectionsTitle: 'Результаты расчета',
    section1: {
      rows: [
        {
          title: 'Объём бруса',
          name: 'V_br' as 'V_br',
          unit: 'м³',
        },
        {
          title: 'Общая площадь стен с учетом фрононов',
          name: 'S_st' as 'S_st',
          unit: 'м²',
        },
        {
          title: 'Общий вес',
          name: 'M_br' as 'M_br',
          unit: 'т',
        },
        {
          title: 'Кол-во венцов с учетом фронтов (рядов)',
          name: 'N_venc' as 'N_venc',
          unit: 'шт.',
        },
        {
          title: 'Кол-во рулонного утеплителя на все венцы',
          name: 'L_ut_rul' as 'L_ut_rul',
          unit: 'м',
        },
        {
          title: 'Диаметр нагелей',
          name: 'D_nag' as 'D_nag',
          unit: 'мм',
        },
        {
          title: 'Длина нагелей',
          name: 'L_nag' as 'L_nag',
          unit: 'шт.',
        },
        {
          title: 'Кол-во нагелей с шагом 1.5м',
          name: 'N_nag' as 'N_nag',
          unit: 'шт.',
        },
        {
          title: 'Кол-во пропитки с двух сторон',
          name: 'V_prop' as 'V_prop',
          unit: 'л',
        },
        {
          title: 'Примерная усадка бруса/бревна',
          name: 'Delta_usad' as 'Delta_usad',
          unit: 'мм',
        },
        {
          title: 'Нагрузка на фундамент от стен',
          name: 'N_fund' as 'N_fund',
          unit: 'кг/м²',
        },
      ],
      columns: [
        {
          dataIndex: 'title',
        },
        {
          dataIndex: 'name',
        },
        {
          dataIndex: 'unit',
        },
      ],
    },
  },
};
