import {customColors} from '~src/theme/colors';

const t_bValues = [
  {
    value: 16,
    label: '16',
  },
  {
    value: 17,
    label: '17',
  },
  {
    value: 18,
    label: '18',
  },
  {
    value: 19,
    label: '19',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 21,
    label: '21',
  },
  {
    value: 22,
    label: '22',
  },
  {
    value: 23,
    label: '23',
  },
  {
    value: 24,
    label: '24',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 26,
    label: '26',
  },
];

export const calculation1 = {
  meta: {
    title: 'Бесплатный теплотехнический расчет онлайн',
    description: 'Теплотехнический калькулятор. Тепловая защита зданий. Расчет точки росы',
    keywords:
      'Точка росы калькулятор, точка росы калькулятор онлайн, расчет точки росы калькулятор, теплотехнический расчет, теплотехнический расчет онлайн, теплотехнический расчет стены, калькулятор теплотехнического расчета, теплотехнический калькулятор, калькулятор теплотехнического расчета, расчет теплопотерь, расчет теплопотерь дома, калькулятор расчета теплопотерь, расчет утепления, калькулятор утепления',
  },
  header1: 'Тепловая защита зданий – теплотехнический калькулятор',
  breadcrumbs: ['Главная'],
  breadcrumbsSource: ['/'],
  descriptionButton: {
    showMore: 'Показать больше',
    showLess: 'Свернуть',
  },
  description: [
    'Определение теплотехнических характеристик зданий, сооружений, а также отдельных элементов их конструкций всегда являлось сложной инженерной задачей. До появления современного программного обеспечения ее решение требовало многодневной работы нескольких профильных специалистов.',
    'Сегодня теплотехнический расчет окна, пола, стены, перекрытий, сэндвич панелей, а также зданий и конструкций в целом можно сделать с помощью онлайн калькулятора. Причем итоговый результат, включая наглядную инфографику, формируется компьютером практически мгновенно.',
    'На чем основана работа смарт калькулятора теплотехнического расчета',
    'В своей работе все разновидности подобных программ опираются на строительную нормативную базу законодательства РФ. Основными документами для обработки вносимых пользователем данных являются:',
    [
      'СП 50.13330.2012 Тепловая защита зданий. Актуализированная редакция СНиП 23-02-2003 (с Изменением N 1)',
      'СТО 00044807-001-2006 "Теплозащитные свойства ограждающих конструкций зданий"',
      'СП 23-101-2004 "Проектирование тепловой защиты зданий"',
      'СП 230.1325800.2015 Конструкции ограждающие зданий. Характеристики теплотехнических неоднородностей (с изменением N 1)',
      'СП 345.1325800.2017 Здания жилые и общественные. Правила проектирования тепловой защиты',
    ],
    'Методика теплотехнического расчета для пола, стен, перекрытий и других ограждающих конструкций спомощью онлайн калькулятора позволяет быстро определить важнейшие параметры тепловой защиты. Если она окажется недостаточной, владельцам следует подумать о дополнительном утеплении помещений. В противном случае их ожидает две проблемы:',
    [
      'неэффективное использование отопительных систем;',
      'разрушение поверхностного покрытия стен из-за возможного образования конденсата.',
    ],
    'Как работать с калькулятором',
    'Теплотехнический смарт расчет наружной панельной или каркасной стены, перекрытия и других элементов конструкций здания онлайн калькулятор производит на основании введенной информации.',
    'Для удобства пользователя она сгруппирована в несколько разделов.',
    '1. Место расположения объекта',
    'Для каждого города и региона РФ характерны собственные климатические условия. В нашем онлайн калькуляторе реализована возможность выбора места строительства на карте или с помощью выпадающего списка.',
    '2. Параметры помещений',
    'Теплотехнический онлайн расчет отопления базируется на особенностях тех или иных помещений. Так, ванные комнаты отличаются повышенной влажностью, кухни – температурой, а детским требуется более комфортные условия. Все желательные параметры вводятся отдельно.',
    '3. Слои конструкций',
    'Для каждой стены, пола или перекрытия понадобится поочередно указать все имеющиеся слои. Например, наружную штукатурку, кирпичную кладку, внутренний утеплитель, декоративное поверхностное покрытие.',
    '4. Тепловая защита',
    'Теплотехнический расчет помещения – например, с помощью нашей онлайн программы калькулятора – предоставляет возможность вводить конкретные значения влажности с целью наглядного построения графиков. Для этого предусмотрены соответствующие вкладки. Это позволяет варьировать режимы влажности в разных помещениях в сторону повышения или понижения. Аналогичным образом можно указать и температуру в помещении.',
    'Результаты расчетов',
    'После окончания ввода данных калькулятор выдаст теплозащитные характеристики помещений и сравнит их с нормативными показателями, учитывая коэффициент теплопроводности. Если теплоотдача и вероятность образования конденсата окажутся высокими, Вам понадобиться внести изменения в планы подбора материалов.',
    'При этом следует помнить, что вместо излишнего утолщения стен целесообразнее использовать энергоэффективные окна и двери, а также качественный утеплитель. Теплотехнический расчет утеплителя для ограждающих конструкций или трубопровода также удобно произвести с помощью калькулятора. А каким будет наиболее комфортный уровень теплозащиты – решать Вам.',
  ],
  section1: {
    title: '1. Выбор региона строительства',
    regionSelect: {
      placeholder: 'Выберите регион',
      values: [],
    },
    geoParams: [
      {
        title: 'Основные климатические параметры',
        description: '',
      },
    ],
    collapse: [
      {
        header: 'Основные климатические параметры',
        key: '0',
        items: [
          {
            title: 'Температура холодной пятидневки с обеспеченностью 0.92',
            key: 't_h',
            unit: '°С',
          },
          {
            title: 'Продолжительность отопительного периода',
            key: 'z_ot',
            unit: 'сут.',
          },
          {
            title: 'Средняя температура воздуха отопительного периода',
            key: 't_ot',
            unit: '°С',
          },
          {
            title: 'Относительная влажность воздуха наиболее холодного месяца',
            key: 'f',
            unit: '%',
          },
          {
            title: 'Зона влажности',
            key: 'zona',
            unit: '',
          },
        ],
      },
      {
        header: 'Средние месячные и годовые значения температуры и парциального давления водяного пара',
        key: '1',
        items: [
          {
            title: 'Январь',
            key: 'jan',
            unit: '',
          },
          {
            title: 'Февраль',
            key: 'feb',
            unit: '',
          },
          {
            title: 'Март',
            key: 'mar',
            unit: '',
          },
          {
            title: 'Апрель',
            key: 'apr',
            unit: '',
          },
          {
            title: 'Май',
            key: 'may',
            unit: '',
          },
          {
            title: 'Июнь',
            key: 'jun',
            unit: '',
          },
          {
            title: 'Июль',
            key: 'jul',
            unit: '',
          },
          {
            title: 'Август',
            key: 'aug',
            unit: '',
          },
          {
            title: 'Сентябрь',
            key: 'sep',
            unit: '',
          },
          {
            title: 'Октябрь',
            key: 'oct',
            unit: '',
          },
          {
            title: 'Ноябрь',
            key: 'nov',
            unit: '',
          },
          {
            title: 'Декабрь',
            key: 'dec',
            unit: '',
          },
          {
            title: 'Среднее годовое',
            key: 'year',
            unit: '',
          },
        ],
        headers: ['Месяц', 'T, °С', 'E, гПа'],
      },
    ],
    defaultValueCollapse: '0',
    zonaLabels: {
      1: 'Влажная',
      2: 'Нормальная',
      3: 'Сухая',
    },
  },
  section2: {
    title: '2. Ввод данных о помещении',
    region: {
      label: '',
      placeholder: 'Выберите регион',
      name: 'regionCity' as 'regionCity',
    },
    typeRoom: {
      label: 'Тип помещения',
      placeholder: 'Выбрать значение',
      name: 'typeRoom' as 'typeRoom',
      values: [
        {
          value: 0,
          label: 'Жилое помещение',
        },
        {
          value: 1,
          label: 'Кухня',
        },
        {
          value: 2,
          label: 'Ванная',
        },
        {
          value: 3,
          label: 'Техническое помещение',
        },
        {
          value: 4,
          label: 'Общественные',
        },
      ],
    },
    categoryPublicRoom: {
      label: 'Категория общественного помещения',
      placeholder: 'Выбрать значение',
      name: 'categoryPublicRoom' as 'categoryPublicRoom',
      values: [
        {
          value: 0,
          label: 'Помещения, в которых люди в положении лежа или сидя находятся в состоянии покоя и отдыха',
        },
        {
          value: 1,
          label: 'Помещения, в которых люди заняты умственным трудом, учебой',
        },
        {
          value: 2,
          label:
            'Помещения с массовым пребыванием людей, в которых люди находятся преимущественно в положении сидя без уличной одежды',
        },
        {
          value: 3,
          label:
            'Помещения с массовым пребыванием людей, в которых люди находятся преимущественно в положении сидя в уличной одежде',
        },
        {
          value: 4,
          label:
            'Помещения с массовым пребыванием людей, в которых люди находятся преимущественно в положении стоя без уличной одежды',
        },
        {
          value: 5,
          label: 'Помещения для занятий подвижными видами спорта',
        },
        {
          value: 6,
          label:
            'Помещения, в которых люди находятся в полураздетом виде (раздевалки, процедурные кабинеты, кабинеты врачей и т. п.)',
        },
        {
          value: 7,
          label:
            'Помещения с временным пребыванием людей (вестибюли, гардеробные, коридоры, лестницы, санузлы, курительные, кладовые)',
        },
        {
          value: 8,
          label: 'Ванные,душевые',
        },
      ],
    },
    typeConstruction: {
      label: 'Тип конструкции',
      placeholder: 'Выбрать значение',
      name: 'typeConstruction' as 'typeConstruction',
      values: [
        {
          value: 0,
          label: 'Наружные стены',
        },
        {
          value: 1,
          label: 'Чердачное перекрытие/утепленная кровля',
        },
        {
          value: 2,
          label: 'Перекрытие над холодным подвалом, сообщающимся с холодным воздухом ',
        },
        {
          value: 3,
          label: 'Перекрытие над неотапливаемым подвалом со световыми проемами в стенах ',
        },
        {
          value: 4,
          label: 'Перекрытие над неотапливаемым подвалом без световых проемов в стенах ',
        },
        {
          value: 5,
          label: 'Перекрытие над проездом',
        },
      ],
    },
    temperatureInRoomsByGost: {
      label: 'Температура внутри помещения по ГОСТ',
      placeholder: 'Выбрать значение',
      name: 't_b' as 't_b',
      getValues: (typeRoom: number) => {
        if (typeRoom === 0) return t_bValues.slice(4, 8);
        else if (typeRoom === 1) return t_bValues.slice(3, 5);
        else if (typeRoom === 2) return t_bValues.slice(9, 11);
        else if (typeRoom === 3) return t_bValues.slice(0, 4);
        else if (typeRoom === 4) return t_bValues.slice(6, 7);
        return t_bValues;
      },
      getPublicRoomValues: (categoryPublicRoom: number) => {
        if (categoryPublicRoom === 0) return t_bValues.slice(6, 7);
        else if (categoryPublicRoom === 1) return t_bValues.slice(5, 6);
        else if (categoryPublicRoom === 2) return t_bValues.slice(5, 6);
        else if (categoryPublicRoom === 3) return t_bValues.slice(0, 1);
        else if (categoryPublicRoom === 4) return t_bValues.slice(4, 5);
        else if (categoryPublicRoom === 5) return t_bValues.slice(3, 4);
        else if (categoryPublicRoom === 6) return t_bValues.slice(6, 7);
        else if (categoryPublicRoom === 7) return t_bValues.slice(2, 3);
        else if (categoryPublicRoom === 8) return t_bValues.slice(10, 11);

        return t_bValues;
      },
      values: t_bValues,
    },
    wetInRoom: {
      label: 'Влажность внутри помещения, %',
      placeholder: 'От 0 до 100',
      name: 'wetInRoom' as 'wetInRoom',
      getDefaultValue: (typeRoom: number) => {
        if (typeRoom === 0) return 55;
        else if (typeRoom === 1) return 60;
        else if (typeRoom === 2) return 65;
        else if (typeRoom === 3) return 50;
        else if (typeRoom === 4) return 45;
        return 0;
      },
      getPublicRoomDefaultValue: (categoryPublicRoom: number) => {
        if (categoryPublicRoom === 0) return 45;
        else if (categoryPublicRoom === 1) return 45;
        else if (categoryPublicRoom === 2) return 45;
        else if (categoryPublicRoom === 3) return 45;
        else if (categoryPublicRoom === 4) return 45;
        else if (categoryPublicRoom === 5) return 45;
        else if (categoryPublicRoom === 6) return 45;
        else if (categoryPublicRoom === 7) return 60;
        else if (categoryPublicRoom === 8) return 60;
        return 0;
      },
    },
    roomParams: {
      columns: [
        {
          title: 'Характеристики выбранного помещения',
          dataIndex: 'name',
          key: 'name',
          width: '40%',
        },
        {
          title: '',
          dataIndex: 'variableName',
          key: 'variableName',
          width: '10%',
        },
        {
          title: '',
          dataIndex: 'value',
          key: 'value',
          width: '50%',
        },
      ],
      rows: [
        {
          name: 'Влажность в помещении*',
          variableName: 'ϕ',
          variable: 'wetInRoom',
          unit: '%',
        },
        {
          name: 'Коэффициент зависимости положения наружной поверхности по отношению к наружному воздуху',
          variableName: 'n',
          variable: 'n',
          unit: '',
        },
        {
          name: 'Коэффициент теплоотдачи внутренней поверхности',
          variableName: 'ɑ в',
          variable: 'alfa_b',
          unit: '',
        },
        {
          name: 'Коэффициент теплоотдачи наружной поверхности',
          variableName: 'ɑ н',
          variable: 'alfa_h',
          unit: '',
        },
        {
          name: 'Нормируемый температурный перепад',
          variableName: 'Δt н',
          variable: 'delta_t_h',
          unit: '°C',
        },
        {
          name: 'Условия эксплуатации ограждающей конструкций',
          variableName: '—',
          variable: 'operatingConditions',
          unit: '',
        },
      ],
    },
  },
  section3: {
    layersParams: {
      columns: [
        {
          title: '№',
          dataIndex: 'layerId',
          key: 'layerId',
        },
        {
          title: 'Тип слоя',
          dataIndex: 'layerName',
          key: 'layerName',
        },
        {
          title: 'Материал',
          dataIndex: 'materialName',
          key: 'materialName',
        },
        {
          title: 'δ, мм',
          dataIndex: 't',
          key: 't',
        },
        {
          title: 'λ,\nВт/(м°С)',
          dataIndex: 'materialTranscalencyA',
          key: 'materialTranscalencyA',
        },
        {
          title: 'λ,\nВт/(м°С)',
          dataIndex: 'materialTranscalencyB',
          key: 'materialTranscalencyB',
        },
        {
          title: 'μ,\nмг/(м·ч·Па)',
          dataIndex: 'materialVaporRermeability',
          key: 'materialVaporRermeability',
        },
        {
          title: 'Управление',
          dataIndex: 'actions',
          key: 'actions',
        },
      ],
    },
  },
  result: {
    title: 'Результат теплотехнического расчёта',
    userValuesTitle: 'Характеристики объекта',
    sectionsTitle: 'Результат расчёта',
    chartsLegend: [
      [
        {type: 'line', color: customColors.blue1000, label: 'Температура'},
        {type: 'line', color: customColors.blue700, label: 'Температура точки росы'},
        {type: 'zone', color: customColors.blue700, label: 'Зона конденсации'},
      ],
      [
        {type: 'line', color: customColors.green500, label: 'Безразмерное сопротивление паропроницанию'},
        {type: 'line', color: customColors.blue700, label: 'То же насыщенного паром воздуха'},
        {type: 'line', color: customColors.red600, label: 'Плоскость максимального увлажнения'},
      ],
      [
        {
          type: 'line',
          color: customColors.blue1000,
          label: 'Зависимость потерь тепла Q от сопротивления теплопередаче R',
        },
        {type: 'line', color: customColors.red600, label: 'Санитарно-гигиенические требования Rс', name: 'r_c'},
        {
          type: 'line',
          color: customColors.green500,
          label: 'Нормируемое значение поэлементных требований Rэ',
          name: 'r_e',
        },
        {
          type: 'line',
          color: customColors.green300,
          label: 'Базовое значение поэлементных требований Rт',
          name: 'r_t',
        },
        {
          type: 'line',
          color: customColors.blue700,
          label: 'Сопротивление теплопередаче ограждающей конструкции R',
          name: 'R',
        },
        {type: 'line', color: customColors.blue400, label: 'R +10%', name: 'r10%'},
        {type: 'line', color: customColors.blue400, label: 'R +25%', name: 'r25%'},
        {type: 'line', color: customColors.blue400, label: 'R +50%', name: 'r50%'},
        {type: 'line', color: customColors.blue400, label: 'R +100%', name: 'r100%'},
      ],
    ],
    legendAxis: [
      {x: 'δ, мм', y: 'T, °C'},
      {x: 'δ, мм', y: 'E, Па'},
      {x: 'R, (м²•°С)/Вт', y: 'Q, Вт•ч'},
    ],
    userValues: {
      dataSource: [
        {
          title: 'Регион',
          name: 'regionCity',
          value: '',
        },
        {
          title: 'Тип помещения',
          name: 'typeRoom',
          value: '',
        },
        {
          title: 'Категория общественного помещения',
          name: 'categoryPublicRoom',
          value: '',
        },
        {
          title: 'Температура внутреннего воздуха',
          name: 't_b',
          value: '',
          unit: '°С',
        },
        {
          title: 'Тип конструкции',
          value: '',
          name: 'typeConstruction',
        },
        {
          title: 'Влажность внутри помещения',
          value: '',
          name: 'wetInRoom',
          unit: '%',
        },
      ],
    },
    section1: {
      title: 'Градосутки отопительного периода:',
      rows: [
        {
          name: 'gsop' as 'gsop',
        },
      ],
    },
    section2: {
      title: 'Требуемые сопротивления теплопередаче ограждающей конструкции:',
      rows: [
        {
          title: 'Базовое значение поэлементных требований',
          name: 'r_t' as 'r_t',
        },
        {
          title: 'Санитарно-гигиенические требования',
          name: 'r_c' as 'r_c',
        },
        {
          title: 'Нормируемое значение поэлементных требований',
          name: 'r_e' as 'r_e',
        },
      ],
    },
    section3: {
      title: 'Приведенное сопротивление теплопередаче ограждающей конструкции:',
      rows: [
        {
          title: 'Условное сопротивление теплопередаче',
          name: 'r_usl' as 'r_usl',
        },
        {
          title: 'Приведенное сопротивление теплопередаче',
          name: 'R' as 'R',
        },
        {
          title: 'Нормируемое значение поэлементных требований',
          name: 'r_e' as 'r_e',
        },
      ],
    },
    literature: [
      'СП 50.13330.2012 Тепловая защита зданий. Актуализированная редакция СНиП 23-02-2003 (с Изменением N 1)',
      'СП 131.13330.2018 Строительная климатология',
      'СТО 00044807-001-2006 "Теплозащитные свойства ограждающих конструкций зданий"',
      'СП 23-101-2004 "Проектирование тепловой защиты зданий"',
      'СП 230.1325800.2015 Конструкции ограждающие зданий. Характеристики теплотехнических неоднородностей (с изменением N 1)',
      'СП 345.1325800.2017 Здания жилые и общественные. Правила проектирования тепловой защиты',
      'ГОСТ 30494 – 2011 “Здания жилые и общественные. Параметры микроклимата в помещении”',
    ],
    result3TableGeneration: {
      heatLossParams: {
        title: 'Результат - Тепловые потери',
        columns: [
          {
            columnTitle: 'Сопротивление теплопередаче',
            title: 'Сопротивление теплопередаче',
            dataIndex: 'name',
            key: 'name',
            columnWidth: {
              width: '50%',
            },
          },
          {
            columnTitle: 'R',
            title: 'R',
            dataIndex: 'r',
            key: 'r',
            columnWidth: {
              width: '12.5%',
            },
          },
          {
            columnTitle: '±R, %',
            title: '±R, %',
            dataIndex: 'r+-',
            key: 'r+-',
            columnWidth: {
              width: '12.5%',
            },
          },
          {
            columnTitle: 'Q',
            title: 'Q',
            dataIndex: 'q',
            key: 'q',
            columnWidth: {
              width: '12.5%',
            },
          },
          {
            columnTitle: '±Q, Вт•ч',
            title: '±Q, Вт•ч',
            dataIndex: 'q+-',
            key: 'q+-',
            columnWidth: {
              width: '12.5%',
            },
          },
        ],
      },
      propNames: [
        ['Санитарно-гигиенические требования [Rс]', 'r_c', 'r+-_c', 'q1', 'q+-1'],
        ['Нормируемое значение поэлементных требований [Rэ]', 'r_e', 'r+-_e', 'q2', 'q+-2'],
        ['Базовое значение поэлементных требований [Rт]', 'r_t', 'r+-_t', 'q3', 'q+-3'],
        ['Сопротивление теплопередаче ограждающей конструкции [R]', 'R', 'r+-', 'q', 'q+-'],
        ['R +10%', 'r10%', 'r+-10%', 'q4', 'q+-4'],
        ['R +25%', 'r25%', 'r+-25%', 'q5', 'q+-5'],
        ['R +50%', 'r50%', 'r+-50%', 'q6', 'q+-6'],
        ['R +100%', 'r100%', 'r+-100%', 'q7', 'q+-7'],
      ],
      conclusionParams: {
        title: 'Выводы',
        columns: [
          {
            title: '',
            dataIndex: 'name',
            key: 'name',
            width: '80%',
          },
          {
            title: 'R',
            dataIndex: 'value',
            key: 'value',
            width: '20%',
          },
        ],
        dataSource: [
          {
            name: 'Потери тепла через 1 м² за отопительный сезон',
            value: 0,
            unit: ' кВт•ч',
            propName: 'conclusion3' as 'conclusion3',
          },
          {
            name: 'Потери тепла через 1 м² за 1 час при температуре самой холодной пятидневки',
            value: 0,
            unit: ' Вт•ч',
            propName: 'conclusion4' as 'conclusion4',
          },
        ],
      },
    },

    result4TableGeneration: {
      humidityRegime: {
        title: 'Исходные данные для расчета влажностного режима стены в годовом цикле в табличной форме',
        columns: [
          {
            columnTitle: 'Наименование материала',
            title: 'Наименование материала',
            dataIndex: 'name',
            key: 'name',
            columnWidth: {
              width: '16%',
            },
          },
          {
            columnTitle: 'Номер слоя',
            title: 'Номер слоя',
            dataIndex: 'layerId',
            key: 'layerId',
            columnWidth: {
              width: '12%',
            },
          },
          {
            columnTitle: 'Плотность материала, кг/м³',
            title: 'Плотность материала, кг/м³',
            dataIndex: 'materialDensity',
            key: 'materialDensity',
            columnWidth: {
              width: '12%',
            },
          },
          {
            columnTitle: 'Толщина слоя, м',
            title: 'Толщина слоя, м',
            dataIndex: 't',
            key: 't',
            columnWidth: {
              width: '12%',
            },
          },
          {
            columnTitle: 'Коэффициент материала',
            title: 'Коэффициент материала',
            children: [
              {
                title: 'Теплопроводности λ Вт/м·°С',
                dataIndex: 'materialTranscalency',
                key: 'materialTranscalency',
                width: '12.5%',
              },
              {
                title: 'Паропроницаемости μ мг/м·ч·Па',
                dataIndex: 'materialVaporRermeability',
                key: 'materialVaporRermeability',
                width: '12%',
              },
            ],
          },
          {
            columnTitle: 'Сопротивление слоя',
            title: 'Сопротивление слоя',
            children: [
              {
                title: 'Термическое Ri м²·°С/Вт',
                dataIndex: 'R_i',
                key: 'R_i',
                width: '12%',
              },
              {
                title: 'Паропроницанию Rni м²·ч·Па/мг',
                dataIndex: 'R_ni',
                key: 'R_ni',
                width: '12%',
              },
            ],
          },
        ],
      },
    },

    result5TableGeneration: {
      humidityAnnualCycle: {
        title: 'Количественный расчёт распределения влаги в стене в годовом цикле проводится для каждого месяца',
        columns: [
          {
            columnTitle: 'Сопротивления паропроницанию',
            title: 'Со­про­тив­ле­ния па­роп­ро­ни­ца­нию',
            dataIndex: 'resistanceVaporRermeability',
            key: 'resistanceVaporRermeability',
            width: '1%',
            columnWidth: {
              width: '5%',
            },
          },
          {
            columnTitle: 'Индекс',
            title: 'Ин­декс',
            dataIndex: 'index',
            key: 'index',
            width: '1%',
            columnWidth: {
              width: '5%',
            },
          },
          {
            columnTitle: 'Ед. изм.',
            title: 'Ед. изм.',
            dataIndex: 'unit',
            key: 'unit',
            width: '1%',
            columnWidth: {
              width: '5%',
            },
          },
          {
            columnTitle: 'МЕСЯЦЫ',
            title: 'МЕСЯЦЫ',
            children: [
              {
                title: 'Ⅰ',
                dataIndex: 'jan',
                key: 'jan',
                width: '2%',
              },
              {
                title: 'Ⅱ',
                dataIndex: 'feb',
                key: 'feb',
                width: '2%',
              },
              {
                title: 'Ⅲ',
                dataIndex: 'mar',
                key: 'mar',
                width: '2%',
              },
              {
                title: 'Ⅳ',
                dataIndex: 'apr',
                key: 'apr',
                width: '2%',
              },
              {
                title: 'Ⅴ',
                dataIndex: 'may',
                key: 'may',
                width: '2%',
              },
              {
                title: 'Ⅵ',
                dataIndex: 'jun',
                key: 'jun',
                width: '2%',
              },
              {
                title: 'Ⅶ',
                dataIndex: 'jul',
                key: 'jul',
                width: '2%',
              },
              {
                title: 'Ⅷ',
                dataIndex: 'aug',
                key: 'aug',
                width: '2%',
              },
              {
                title: 'Ⅸ',
                dataIndex: 'sep',
                key: 'sep',
                width: '2%',
              },
              {
                title: 'Ⅹ',
                dataIndex: 'oct',
                key: 'oct',
                width: '2%',
              },
              {
                title: 'Ⅺ',
                dataIndex: 'nov',
                key: 'nov',
                width: '2%',
              },
              {
                title: 'Ⅻ',
                dataIndex: 'dec',
                key: 'dec',
                width: '2%',
              },
            ],
          },
        ],
      },
      propNames: [
        ['', 't_n', '°С', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
        ['', 't_v', '°С', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
        ['', 'Δt', '°С', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
        ['', 'τ_п', '°С', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
        ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
        ['', 'e_н', 'Па', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
        ['', 'e_в', 'Па', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
        ['', 'Δe', 'Па', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
        ['', 'e_τ', 'Па', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
        ['', 'E_τ', 'Па', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
        ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
        ['', 'Пе­ри­од', 'ча­сов', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
        [
          '',
          'Q_в.сл',
          'г/м² · мес',
          'jan',
          'feb',
          'mar',
          'apr',
          'may',
          'jun',
          'jul',
          'aug',
          'sep',
          'oct',
          'nov',
          'dec',
        ],
        [
          '',
          'Q_н.сл',
          'г/м² · мес',
          'jan',
          'feb',
          'mar',
          'apr',
          'may',
          'jun',
          'jul',
          'aug',
          'sep',
          'oct',
          'nov',
          'dec',
        ],
        ['', 'ΔQ', 'г/м² · мес', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
        ['', '∑ΔQ', 'г/м² · мес', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
        ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
        [
          'Ре­зуль­тат',
          'Кон­ден­сат',
          '+/-',
          'jan',
          'feb',
          'mar',
          'apr',
          'may',
          'jun',
          'jul',
          'aug',
          'sep',
          'oct',
          'nov',
          'dec',
        ],
      ],
    },

    result2TableGeneration: {
      heatLossParams: {
        columns: [
          {
            title: '№',
            columnTitle: '№',
            dataIndex: 'layerId',
            key: 'name',
            columnWidth: {
              width: '20%',
            },
          },
          {
            title: 'Материал',
            columnTitle: 'Материал',
            dataIndex: 'materialName',
            key: 'materialName',
          },
          {
            title: 'f i (t м.у.)',
            columnTitle: 'f i (t м.у.)',
            dataIndex: 'f_i',
            key: 'fi',
          },
          {
            title: 't м.у.',
            columnTitle: 't м.у.',
            dataIndex: 't_mu',
            key: 't_mu',
          },
          {
            title: 't н',
            columnTitle: 't н',
            dataIndex: 't_n',
            key: 't_n',
          },
          {
            title: 't к',
            columnTitle: 't к',
            dataIndex: 't_k',
            key: 't_k',
          },
        ],
      },
    },
    result2: {
      emptyZLabel: ' - отсутствует',
    },
  },
};
