import {StoreonModule} from 'storeon';
import pick from 'ramda/es/pick';
import {IEvents, IState} from '~src/store/index';
import {commonCityProps, getCityDataByCode} from '~src/utils/calculations';
import calculation1Service, {IResultValues, IValues as IValuesAtCalculation1} from '~src/services/Calculation1Service';
import calculation2Service, {IValues as IValuesAtCalculation2} from '~src/services/Calculation2Service';
import calculation3Service, {IValues as IValuesAtCalculation3} from '~src/services/Calculation3Service';
import {api} from '~src/api';
import {delay} from '~src/utils';

export interface IUserValuesAtCalculation1 extends IValuesAtCalculation1 {}
export interface IUserValuesAtCalculation2 extends IValuesAtCalculation2 {}
export interface IUserValuesAtCalculation3 extends IValuesAtCalculation3 {}
export interface IResultValuesAtCalculation1 extends Partial<IResultValues> {}
export interface IResultValuesAtCalculation2 {
  // Несущие конструкции
  V_st: number;
  S_st: number;
  N_bl_m3: number;
  N_bl: number;
  V_bl: number;
  N_kl_set: number;
  N_bl_zapas: number;
  V_p_p: number;
  M_p_p: number;
  N_p_p: number;
  // Фасадные материалы
  V_sht: number;
  M_sht: number;
  N_sht: number;
  // Теплоизоляция
  V_ut: number;
  S_ut: number;
  // Перегородки
  V_st_per: number;
  S_st_per: number;
  N_bl_m3_per: number;
  N_bl_per: number;
  V_bl_per: number;
  N_kl_set_per: number;
  N_bl_zapas_per: number;
  V_p_p_per: number;
  M_p_p_per: number;
  N_p_p_per: number;
  // Дополнительно
  L_ok: number;
  S_ok: number;
  L_dv: number;
  S_dv: number;
  L_dv_per: number;
  S_dv_per: number;
  // Откосы
  L_ok_slopes: number;
  S_ok_slopes: number;
  L_dv_slopes: number;
  S_dv_slopes: number;
  L_dv_per_slopes: number;
  S_dv_per_slopes: number;
  // Нагрузка на фундамент
  N_fund: number;
  N_fund_per: number;
}

export interface IResultValuesAtCalculation3 {
  V_br: number;
  S_st: number;
  M_br: number;
  N_venc: number;
  L_ut_rul: number;
  D_nag: number;
  L_nag: number;
  N_nag: number;
  V_prop: number;
  Delta_usad: number;
  N_fund: number;
}

export interface ICalculationsStore {
  1: {
    userValues: IUserValuesAtCalculation1;
    resultValues: IResultValuesAtCalculation1;
    id: number;
  };
  2: {
    userValues: IUserValuesAtCalculation2;
    resultValues: IResultValuesAtCalculation2;
  };
  3: {
    userValues: IUserValuesAtCalculation3;
    resultValues: IResultValuesAtCalculation3;
  };
}

export const initialState: ICalculationsStore = {
  1: {
    userValues: {
      ...pick(commonCityProps, getCityDataByCode('mc')),
      regionCode: 'mc',
      regionCity: 'Москва',
      typeRoom: -1,
      typeConstruction: -1,
      t_b: -1,
      wetInRoom: -1,
      n: 1,
      alfa_b: 8.7,
      alfa_h: 23,
      delta_t_h: 4,
      layers: [],
      containsAirGap: false,
      r: 0.92,
      operatingConditions: '',
      // Вентилируемая воздушная прослойка
      sum_xi_sh: 4,
      sum_xi: 8,
      h_y: 600,
      b_y: 600,
      h_gap: 15,
      h_all: 10,
    },
    resultValues: {},
    id: 0,
  },
  2: {
    userValues: {
      isInvalid: false,
      enabledFlags: [],
      enabledSubFlags: [],
      bearingWallImage: '',
      facadeImage: '',
      insulationImage: '',
      bearingWalls: [
        {
          brickType: '',
          executionType: 0,
          brickDefaultSize: 0,
          brickWidth: 0,
          brickLength: 0,
          brickHeight: 0,
          brickWeight: 0,
          trimMargin: 0,
          masonryOption: 0,
          seamThickness: 0,
          masonryNet: -1,
          voidness: false,
        },
      ],
      facades: [
        {
          facadeType: -1,
          facadeDefaultSize: false,
          facadeBrickWidth: null,
          facadeBrickLength: null,
          facadeBrickHeight: null,
          facadeBrickWeight: null,
          facadeTrimMargin: null,
          facadeSeamThickness: null,
          facadePanelWidth: null,
          facadePanelLength: null,
          facadePanelSquare: null,
          facadeWeight: null,
        },
      ],
      walls: [
        {
          wallsLength: null,
          wallsHeight: null,
          wallsOuterAngleCount: -1,
          wallsInnerAngleCount: -1,
        },
      ],
      insulation: [
        {
          insulationType: '',
          insulationThickness: 0,
          insulationDensity: 0,
        },
      ],
      armobelts: [
        {
          armobeltHeight: 0,
          armobeltCount: 0,
        },
      ],
      doors: [
        {
          doorWidth: 0,
          doorHeight: 0,
          doorCount: 0,
        },
      ],
      jumpers: [
        {
          jumperLength: 0,
          jumperWidth: 0,
          jumperHeight: 0,
          jumperCount: 0,
        },
      ],
      gables: [
        {
          gableType: 0,
          gableHeight: 0,
          gableWidth: 0,
          gableCount: 0,
          gableBiggerWidth: 0,
          gableSmallerWidth: 0,
          gablePentaHeightBetween: 0,
          gablePentaTriangleHeight: 0,
          enabledGableInput: '',
        },
      ],
      windows: [
        {
          windowHeight: 0,
          windowWidth: 0,
          windowCount: 0,
        },
      ],
      trims: [
        {
          trimType: '',
          trimThickness: null,
          trimLength: 0,
          trimWidth: 0,
          trimHeight: 0,
          trimWeight: 0,
        },
      ],
      partitions: [
        {
          partitionBrickType: -1,
          partitionExecutionType: 0,
          partitionBrickDefaultSize: 0,
          partitionBrickWidth: 0,
          partitionBrickLength: 0,
          partitionBrickHeight: 0,
          partitionBrickWeight: 0,
          partitionTrimMargin: 0,
          partitionMasonryOption: 0,
          partitionSeamThickness: 0,
          partitionMasonryNet: -1,
          partitionWallsLength: 0,
          partitionWallsHeight: 0,
        },
      ],
      partitionDoors: [
        {
          partitionDoorHeight: null,
          partitionDoorWidth: null,
          partitionDoorCount: null,
        },
      ],
      partitionJumpers: [
        {
          partitionJumperLength: null,
          partitionJumperThickness: null,
          partitionJumperCount: null,
        },
      ],
    },
    resultValues: {
      // Несущие конструкции
      V_st: 0,
      S_st: 0,
      N_bl_m3: 0,
      N_bl: 0,
      V_bl: 0,
      N_kl_set: 0,
      N_bl_zapas: 0,
      V_p_p: 0,
      M_p_p: 0,
      N_p_p: 0,
      // Фасадные материалы
      V_sht: 0,
      M_sht: 0,
      N_sht: 0,
      // Теплоизоляция
      V_ut: 0,
      S_ut: 0,
      // Перегородки
      V_st_per: 0,
      S_st_per: 0,
      N_bl_m3_per: 0,
      N_bl_per: 0,
      V_bl_per: 0,
      N_kl_set_per: 0,
      N_bl_zapas_per: 0,
      V_p_p_per: 0,
      M_p_p_per: 0,
      N_p_p_per: 0,
      // Дополнительно
      L_ok: 0,
      S_ok: 0,
      L_dv: 0,
      S_dv: 0,
      L_dv_per: 0,
      S_dv_per: 0,
      // Откосы
      L_ok_slopes: 0,
      S_ok_slopes: 0,
      L_dv_slopes: 0,
      S_dv_slopes: 0,
      L_dv_per_slopes: 0,
      S_dv_per_slopes: 0,
      // Нагрузка на фундамент
      N_fund: 0,
      N_fund_per: 0,
    },
  },
  3: {
    userValues: {
      enabledFlags: [],
      bearingWallImage: '',
      isInvalid: false,
      bearingWalls: [
        {
          balkType: '',
          balkWidth: 0,
          balkHeight: 0,
          wallsOverallLength: 0,
          wallsCornerHeight: 0,
          balkDensity: 0,
        },
      ],
      doors: [
        {
          doorWidth: 0,
          doorHeight: 0,
          doorCount: 0,
        },
      ],
      gables: [
        {
          gableType: 0,
          gableHeight: 0,
          gableWidth: 0,
          gableCount: 0,
          gableBiggerWidth: 0,
          gableSmallerWidth: 0,
          gablePentaHeightBetween: 0,
          gablePentaTriangleHeight: 0,
          enabledGableInput: '',
        },
      ],
      windows: [
        {
          windowHeight: 0,
          windowWidth: 0,
          windowCount: 0,
        },
      ],
    },
    resultValues: {
      V_br: 0,
      S_st: 0,
      M_br: 0,
      N_venc: 0,
      L_ut_rul: 0,
      D_nag: 0,
      L_nag: 0,
      N_nag: 0,
      V_prop: 0,
      Delta_usad: 0,
      N_fund: 0,
    },
  },
};

export const calculationModule: StoreonModule<IState, IEvents> = store => {
  store.on('calculations/1/set-values', ({calculations}, payload) => ({
    calculations: {
      ...calculations,
      1: {
        ...calculations[1],
        userValues: {
          ...calculations[1].userValues,
          ...payload.reduce((acc, propAndValue) => ({...acc, [propAndValue.name]: propAndValue.value}), []),
        },
      },
    },
  }));
  store.on('calculations/1/set-result-values', ({calculations}, payload) => ({
    calculations: {
      ...calculations,
      1: {
        ...calculations[1],
        resultValues: {
          ...calculations[1].resultValues,
          ...payload.reduce((acc, propAndValue) => ({...acc, [propAndValue.name]: propAndValue.value}), []),
        },
      },
    },
  }));
  store.on('calculations/1/calculate', ({calculations}) => ({
    calculations: {
      ...calculations,
      1: {
        ...calculations[1],
        resultValues: calculation1Service.calculate(calculations[1].userValues),
        id: Date.now(),
      },
    },
  }));
  store.on('calculations/1/pdf-report', async (state, payload) => {
    try {
      const response = await api.post<{url: string}>('reports/pdf', payload);
      await delay(500);
    } catch (error) {
      store.dispatch('calculations/1/pdf-report-error', error);
    } finally {
      await store.dispatch('calculations/1/pdf-report-loading', true);
    }
  });
  store.on('calculations/2/calculate', ({calculations}) => ({
    calculations: {
      ...calculations,
      2: {
        ...calculations[2],
        resultValues: calculation2Service.calculate(calculations[2].userValues),
      },
    },
  }));
  store.on('calculations/2/set-values', ({calculations}, payload) => ({
    calculations: {
      ...calculations,
      2: {
        ...calculations[2],
        userValues: {
          ...calculations[2].userValues,
          ...payload.reduce((acc, propAndValue) => ({...acc, [propAndValue.name]: propAndValue.value}), []),
        },
      },
    },
  }));
  store.on('calculations/2/set-group-values', ({calculations}, payload) => {
    const updatedValues = [...calculations[2].userValues[payload.groupName]].map((item, outerIndex) => {
      const updatingValue = payload.values.find(valueItem => {
        return valueItem.index === outerIndex;
      });
      // TODO: разобраться с типом item
      return updatingValue ? {...item, [updatingValue.name]: updatingValue.value} : item;
    });
    return {
      calculations: {
        ...calculations,
        2: {
          ...calculations[2],
          userValues: {
            ...calculations[2].userValues,
            [payload.groupName]: updatedValues,
          },
        },
      },
    };
  });
  store.on('calculations/2/add-item', ({calculations}, payload) => {
    const updatedItems = [
      ...calculations[2].userValues[payload.itemName],
      initialState[2].userValues[payload.itemName][0],
    ];
    return {
      calculations: {
        ...calculations,
        2: {
          ...calculations[2],
          userValues: {
            ...calculations[2].userValues,
            [payload.itemName]: updatedItems,
          },
        },
      },
    };
  });

  store.on('calculations/2/remove-item', ({calculations}, payload) => {
    const updatedItems = [...calculations[2].userValues[payload.itemName]];
    let removed = updatedItems.splice(payload.index, 1);
    return {
      calculations: {
        ...calculations,
        2: {
          ...calculations[2],
          userValues: {
            ...calculations[2].userValues,
            [payload.itemName]: updatedItems,
          },
        },
      },
    };
  });

  store.on('calculations/3/calculate', ({calculations}) => ({
    calculations: {
      ...calculations,
      3: {
        ...calculations[3],
        resultValues: calculation3Service.calculate(calculations[3].userValues),
      },
    },
  }));

  store.on('calculations/3/set-values', ({calculations}, payload) => ({
    calculations: {
      ...calculations,
      3: {
        ...calculations[3],
        userValues: {
          ...calculations[3].userValues,
          ...payload.reduce((acc, propAndValue) => ({...acc, [propAndValue.name]: propAndValue.value}), []),
        },
      },
    },
  }));

  store.on('calculations/3/set-group-values', ({calculations}, payload) => {
    const updatedValues = [...calculations[3].userValues[payload.groupName]].map((item, outerIndex) => {
      const updatingValue = payload.values.find(valueItem => {
        return valueItem.index === outerIndex;
      });
      // TODO: разобраться с типом item
      return updatingValue ? {...item, [updatingValue.name]: updatingValue.value} : item;
    });
    return {
      calculations: {
        ...calculations,
        3: {
          ...calculations[3],
          userValues: {
            ...calculations[3].userValues,
            [payload.groupName]: updatedValues,
          },
        },
      },
    };
  });
  store.on('calculations/3/add-item', ({calculations}, payload) => {
    const updatedItems = [
      ...calculations[3].userValues[payload.itemName],
      initialState[3].userValues[payload.itemName][0],
    ];
    return {
      calculations: {
        ...calculations,
        3: {
          ...calculations[3],
          userValues: {
            ...calculations[3].userValues,
            [payload.itemName]: updatedItems,
          },
        },
      },
    };
  });

  store.on('calculations/3/remove-item', ({calculations}, payload) => {
    const updatedItems = [...calculations[3].userValues[payload.itemName]];
    updatedItems.splice(payload.index, 1);
    return {
      calculations: {
        ...calculations,
        3: {
          ...calculations[3],
          userValues: {
            ...calculations[3].userValues,
            [payload.itemName]: updatedItems,
          },
        },
      },
    };
  });
};
