export const humidityMode = [
  {
    name: 'dry',
    values: [
      {
        t: [0, 12],
        wet: [0, 60],
      },
      {
        t: [12, 24],
        wet: [0, 50],
      },
      {
        t: [24, 100],
        wet: [0, 40],
      },
    ],
  },
  {
    name: 'normal',
    values: [
      {
        t: [0, 12],
        wet: [60, 75],
      },
      {
        t: [12, 24],
        wet: [50, 60],
      },
      {
        t: [24, 100],
        wet: [40, 50],
      },
    ],
  },
  {
    name: 'wet',
    values: [
      {
        t: [0, 12],
        wet: [75, 100],
      },
      {
        t: [12, 24],
        wet: [60, 75],
      },
      {
        t: [24, 100],
        wet: [50, 60],
      },
    ],
  },
  {
    name: 'superwet',
    values: [
      {
        t: [0, 12],
        wet: [],
      },
      {
        t: [12, 24],
        wet: [75, 100],
      },
      {
        t: [24, 100],
        wet: [60, 75],
      },
    ],
  },
];
