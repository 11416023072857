export enum Routes {
  index = '/',
  calculation1 = '/teplotehnicheskij-raschet/',
  calculation2 = '/raschet-stroitelnyh-materialov/',
  comments = '/comments/',
  contacts = '/contacts/',
  articles = '/articles/',
  article = '/articles/article/',
  faq = '/faq/',
  signIn = '/admin/auth/',
  RecoveryStart = '/admin/auth/recovery-start/',
  RecoveryEnd = '/admin/auth/recovery-end/',
  stats = '/admin/stats/',
  materials = '/admin/materials/',
  users = '/admin/users/',
  commentsModeration = '/admin/comments/',
  articlesModeration = '/admin/articles/',
  articleModeration = '/admin/articles/article/',
  account = '/admin/account/',
}
