export const operatingConditions: any = {
  dry: {
    1: 'Б',
    2: 'А',
    3: 'А',
  },
  normal: {
    1: 'Б',
    2: 'Б',
    3: 'А',
  },
  wet: {
    1: 'Б',
    2: 'Б',
    3: 'Б',
  },
  superwet: {
    1: 'Б',
    2: 'Б',
    3: 'Б',
  },
};
