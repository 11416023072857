export const dependencyComplex = [
  {t_mu: -40, f_imu: 2539},
  {t_mu: -39, f_imu: 2322},
  {t_mu: -38, f_imu: 2126},
  {t_mu: -37, f_imu: 1947},
  {t_mu: -36, f_imu: 1785},
  {t_mu: -35, f_imu: 1638},
  {t_mu: -34, f_imu: 1504},
  {t_mu: -33, f_imu: 1382},
  {t_mu: -32, f_imu: 1271},
  {t_mu: -31, f_imu: 1170},
  {t_mu: -30, f_imu: 1077},
  {t_mu: -29, f_imu: 992.7},
  {t_mu: -28, f_imu: 915.5},
  {t_mu: -27, f_imu: 844.8},
  {t_mu: -26, f_imu: 780.2},
  {t_mu: -25, f_imu: 721},
  {t_mu: -24, f_imu: 666.7},
  {t_mu: -23, f_imu: 616.9},
  {t_mu: -22, f_imu: 571.2},
  {t_mu: -21, f_imu: 529.2},
  {t_mu: -20, f_imu: 490.7},
  {t_mu: -19, f_imu: 455.2},
  {t_mu: -18, f_imu: 422.5},
  {t_mu: -17, f_imu: 392.5},
  {t_mu: -16, f_imu: 364.8},
  {t_mu: -15, f_imu: 339.2},
  {t_mu: -14, f_imu: 315.6},
  {t_mu: -13, f_imu: 293.9},
  {t_mu: -12, f_imu: 273.8},
  {t_mu: -11, f_imu: 255.2},
  {t_mu: -10, f_imu: 238},
  {t_mu: -9, f_imu: 222.1},
  {t_mu: -8, f_imu: 207.4},
  {t_mu: -7, f_imu: 193.7},
  {t_mu: -6, f_imu: 181.1},
  {t_mu: -5, f_imu: 169.3},
  {t_mu: -4, f_imu: 158.4},
  {t_mu: -3, f_imu: 148.3},
  {t_mu: -2, f_imu: 138.9},
  {t_mu: -1, f_imu: 130.2},
  {t_mu: 0, f_imu: 122.1},
  {t_mu: 1, f_imu: 114.5},
  {t_mu: 2, f_imu: 107.5},
  {t_mu: 3, f_imu: 100.9},
  {t_mu: 4, f_imu: 94.8},
  {t_mu: 5, f_imu: 89.1},
  {t_mu: 6, f_imu: 83.8},
  {t_mu: 7, f_imu: 78.8},
  {t_mu: 8, f_imu: 74.2},
  {t_mu: 9, f_imu: 69.9},
  {t_mu: 10, f_imu: 65.8},
  {t_mu: 11, f_imu: 62},
  {t_mu: 12, f_imu: 58.5},
  {t_mu: 13, f_imu: 55.2},
  {t_mu: 14, f_imu: 52.1},
  {t_mu: 15, f_imu: 49.1},
  {t_mu: 16, f_imu: 46.4},
  {t_mu: 17, f_imu: 43.9},
  {t_mu: 18, f_imu: 41.5},
  {t_mu: 19, f_imu: 39.2},
  {t_mu: 20, f_imu: 37.1},
  {t_mu: 21, f_imu: 35.1},
  {t_mu: 22, f_imu: 33.2},
  {t_mu: 23, f_imu: 31.5},
  {t_mu: 24, f_imu: 29.8},
  {t_mu: 25, f_imu: 28.3},
  {t_mu: 26, f_imu: 26.8},
  {t_mu: 27, f_imu: 25.4},
];
