export const paramsByCity = [
  {
    index: 'ad',
    subject: 'Республика Адыгея',
    city: 'Майкоп',
    zona: 2,
    t_ot: 2.3,
    z_ot: 146,
    t_h: -17,
    f: 77,
    t_jan: -0.1,
    t_feb: 0.9,
    t_mar: 5.4,
    t_apr: 11.9,
    t_may: 16.4,
    t_jun: 20.1,
    t_jul: 22.9,
    t_aug: 22.5,
    t_sep: 17.7,
    t_oct: 11.8,
    t_nov: 6.4,
    t_dec: 1.9,
    t_year: 11.5,
    p_jan: 4.6,
    p_feb: 5.1,
    p_mar: 5.9,
    p_apr: 8.7,
    p_may: 12.5,
    p_jun: 15.4,
    p_jul: 17.4,
    p_aug: 16.8,
    p_sep: 13.5,
    p_oct: 9.9,
    p_nov: 7.6,
    p_dec: 5.7,
    p_year: 10.3,
    z0: 32,
    t0: -0.1,
  },
  {
    index: 'al',
    subject: 'Алтайский край',
    city: 'Барнаул',
    zona: 3,
    t_ot: -7.5,
    z_ot: 214,
    t_h: -36,
    f: 77,
    t_jan: -16.4,
    t_feb: -14.7,
    t_mar: -6.9,
    t_apr: 4,
    t_may: 12.2,
    t_jun: 17.9,
    t_jul: 19.8,
    t_aug: 17,
    t_sep: 10.9,
    t_oct: 3.4,
    t_nov: -6.5,
    t_dec: -13.5,
    t_year: 2.3,
    p_jan: 1.7,
    p_feb: 1.8,
    p_mar: 3,
    p_apr: 5.2,
    p_may: 7.6,
    p_jun: 12.6,
    p_jul: 15.6,
    p_aug: 13.3,
    p_sep: 8.8,
    p_oct: 5.7,
    p_nov: 3.4,
    p_dec: 2.1,
    p_year: 6.7,
    z0: 163,
    t0: -11.2,
  },
  {
    index: 'am',
    subject: 'Амурская область',
    city: 'Благовещенск',
    zona: 2,
    t_ot: -10.7,
    z_ot: 210,
    t_h: -33,
    f: 72,
    t_jan: -22.3,
    t_feb: -17.2,
    t_mar: -7.2,
    t_apr: 4.3,
    t_may: 12.7,
    t_jun: 19.2,
    t_jul: 21.8,
    t_aug: 19.5,
    t_sep: 12.5,
    t_oct: 3,
    t_nov: -10.1,
    t_dec: -20.3,
    t_year: 1.3,
    p_jan: 0.8,
    p_feb: 1.2,
    p_mar: 2.3,
    p_apr: 4.3,
    p_may: 7.7,
    p_jun: 14.5,
    p_jul: 19.4,
    p_aug: 17.1,
    p_sep: 10.1,
    p_oct: 4.6,
    p_nov: 2,
    p_dec: 1,
    p_year: 7.1,
    z0: 165,
    t0: -14.8,
  },
  {
    index: 'ar',
    subject: 'Архангельская область',
    city: 'Архангельск',
    zona: 1,
    t_ot: -4.5,
    z_ot: 250,
    t_h: -33,
    f: 85,
    t_jan: -13.6,
    t_feb: -12.1,
    t_mar: -5.7,
    t_apr: 0.1,
    t_may: 6.6,
    t_jun: 12.7,
    t_jul: 16,
    t_aug: 13.2,
    t_sep: 8,
    t_oct: 1.8,
    t_nov: -4.8,
    t_dec: -9.9,
    t_year: 1,
    p_jan: 4.6,
    p_feb: 5.1,
    p_mar: 5.9,
    p_apr: 8.7,
    p_may: 12.5,
    p_jun: 15.4,
    p_jul: 17.4,
    p_aug: 16.8,
    p_sep: 13.5,
    p_oct: 9.9,
    p_nov: 7.6,
    p_dec: 5.7,
    p_year: 10.3,
    z0: 176,
    t0: -8.2,
  },
  {
    index: 'as',
    subject: 'Астраханская область',
    city: 'Астрахань',
    zona: 3,
    t_ot: -0.8,
    z_ot: 164,
    t_h: -21,
    f: 83,
    t_jan: -4.8,
    t_feb: -4.3,
    t_mar: 2,
    t_apr: 11.3,
    t_may: 18,
    t_jun: 22.9,
    t_jul: 25.4,
    t_aug: 23.8,
    t_sep: 17.6,
    t_oct: 10,
    t_nov: 3.4,
    t_dec: -2,
    t_year: 10.3,
    p_jan: 3.6,
    p_feb: 3.7,
    p_mar: 5,
    p_apr: 7.5,
    p_may: 11.3,
    p_jun: 15,
    p_jul: 17.1,
    p_aug: 16.2,
    p_sep: 12.3,
    p_oct: 8.6,
    p_nov: 6.3,
    p_dec: 4.6,
    p_year: 9.3,
    z0: 103,
    t0: -3.5,
  },
  {
    index: 'bl',
    subject: 'Белгородская область',
    city: 'Белгород',
    zona: 3,
    t_ot: -1.8,
    z_ot: 189,
    t_h: -24,
    f: 86,
    t_jan: -6.6,
    t_feb: -6,
    t_mar: -0.7,
    t_apr: 8.4,
    t_may: 15.1,
    t_jun: 18.4,
    t_jul: 20.1,
    t_aug: 19.4,
    t_sep: 13.4,
    t_oct: 6.9,
    t_nov: 0.5,
    t_dec: -4.3,
    t_year: 7,
    p_jan: 3.2,
    p_feb: 3.6,
    p_mar: 4.5,
    p_apr: 7.2,
    p_may: 9.9,
    p_jun: 12.8,
    p_jul: 14.9,
    p_aug: 13.7,
    p_sep: 10.2,
    p_oct: 7.7,
    p_nov: 5.7,
    p_dec: 4.4,
    p_year: 8.2,
    z0: 126,
    t0: -5,
  },
  {
    index: 'bn',
    subject: 'Брянская область',
    city: 'Брянск',
    zona: 2,
    t_ot: -2.0,
    z_ot: 199,
    t_h: -24,
    f: 84,
    t_jan: -7.4,
    t_feb: -6.6,
    t_mar: -1.2,
    t_apr: 7,
    t_may: 13.6,
    t_jun: 16.9,
    t_jul: 18.4,
    t_aug: 17.2,
    t_sep: 11.7,
    t_oct: 5.6,
    t_nov: -0.4,
    t_dec: -5,
    t_year: 5.8,
    p_jan: 3.1,
    p_feb: 3.2,
    p_mar: 4.1,
    p_apr: 6.8,
    p_may: 9.6,
    p_jun: 12.9,
    p_jul: 15,
    p_aug: 14.3,
    p_sep: 10.7,
    p_oct: 7.5,
    p_nov: 5.4,
    p_dec: 4,
    p_year: 8.1,
    z0: 131,
    t0: -5.2,
  },
  {
    index: 'br',
    subject: 'Республика Бурятия',
    city: 'Улан-Удэ',
    zona: 3,
    t_ot: -10.3,
    z_ot: 231,
    t_h: -35,
    f: 76,
    t_jan: -23.7,
    t_feb: -19,
    t_mar: -7.9,
    t_apr: 2.3,
    t_may: 10.2,
    t_jun: 17.1,
    t_jul: 19.7,
    t_aug: 17,
    t_sep: 9.5,
    t_oct: 0.4,
    t_nov: -10.6,
    t_dec: -19.7,
    t_year: -0.4,
    p_jan: 0.8,
    p_feb: 1.1,
    p_mar: 2.3,
    p_apr: 3.7,
    p_may: 5.8,
    p_jun: 10.2,
    p_jul: 14,
    p_aug: 12.8,
    p_sep: 7.9,
    p_oct: 4.3,
    p_nov: 2.2,
    p_dec: 1.1,
    p_year: 5.5,
    z0: 175,
    t0: -14.8,
  },
  {
    index: 'bs',
    subject: 'Республика Башкортостан',
    city: 'Уфа',
    zona: 3,
    t_ot: -6.0,
    z_ot: 210,
    t_h: -33,
    f: 78,
    t_jan: -13.8,
    t_feb: -12.8,
    t_mar: -5.3,
    t_apr: 5.3,
    t_may: 13.4,
    t_jun: 17.8,
    t_jul: 19.4,
    t_aug: 17.1,
    t_sep: 11.3,
    t_oct: 3.9,
    t_nov: -3.9,
    t_dec: -10.8,
    t_year: 3.5,
    p_jan: 2.2,
    p_feb: 2.2,
    p_mar: 3.5,
    p_apr: 6.2,
    p_may: 8.8,
    p_jun: 13.3,
    p_jul: 15.8,
    p_aug: 13.9,
    p_sep: 9.9,
    p_oct: 6.5,
    p_nov: 4.1,
    p_dec: 2.7,
    p_year: 7.4,
    z0: 154,
    t0: -9.5,
  },
  {
    index: 'cc',
    subject: 'Чеченская Республика',
    city: 'Грозный',
    zona: 3,
    t_ot: 0.9,
    z_ot: 159,
    t_h: -17,
    f: 87,
    t_jan: -2.2,
    t_feb: -1.9,
    t_mar: 3.5,
    t_apr: 10.9,
    t_may: 16.5,
    t_jun: 21,
    t_jul: 24,
    t_aug: 22.9,
    t_sep: 18,
    t_oct: 10.8,
    t_nov: 4.7,
    t_dec: -0.3,
    t_year: 10.7,
    p_jan: 4.5,
    p_feb: 4.9,
    p_mar: 6.2,
    p_apr: 9.1,
    p_may: 13.3,
    p_jun: 16.5,
    p_jul: 18.7,
    p_aug: 18.5,
    p_sep: 14.8,
    p_oct: 10.5,
    p_nov: 7.7,
    p_dec: 5.5,
    p_year: 7.2,
    z0: 83,
    t0: -1.8,
  },
  {
    index: 'ch',
    subject: 'Чукотский автономный округ',
    city: 'Анадырь',
    zona: 2,
    t_ot: -11.2,
    z_ot: 299,
    t_h: -39,
    f: 80,
    t_jan: -21.1,
    t_feb: -22.2,
    t_mar: -19.7,
    t_apr: -13.3,
    t_may: -1.9,
    t_jun: 6.1,
    t_jul: 11.4,
    t_aug: 10.1,
    t_sep: 4.6,
    t_oct: -5,
    t_nov: -13.8,
    t_dec: -19.8,
    t_year: -7,
    p_jan: 1.3,
    p_feb: 1.2,
    p_mar: 1.4,
    p_apr: 2.1,
    p_may: 4.6,
    p_jun: 7.4,
    p_jul: 10.6,
    p_aug: 10,
    p_sep: 7,
    p_oct: 3.8,
    p_nov: 2.2,
    p_dec: 1.5,
    p_year: 4.9,
    z0: 235,
    t0: -15.5,
  },
  {
    index: 'cl',
    subject: 'Челябинская область',
    city: 'Верхнеуральск',
    zona: 3,
    t_ot: -7.6,
    z_ot: 221,
    t_h: -35,
    f: 77,
    t_jan: -16.7,
    t_feb: -15.8,
    t_mar: -8.2,
    t_apr: 3.7,
    t_may: 11.7,
    t_jun: 16.4,
    t_jul: 17.7,
    t_aug: 15.5,
    t_sep: 9.8,
    t_oct: 2.3,
    t_nov: -6.2,
    t_dec: -13.7,
    t_year: 1.4,
    p_jan: 1.7,
    p_feb: 1.7,
    p_mar: 2.9,
    p_apr: 5.3,
    p_may: 7.5,
    p_jun: 11.7,
    p_jul: 14,
    p_aug: 12.2,
    p_sep: 8.4,
    p_oct: 5.4,
    p_nov: 3.3,
    p_dec: 2.1,
    p_year: 10.9,
    z0: 170,
    t0: -11.1,
  },
  {
    index: 'cl',
    subject: 'Челябинская область',
    city: 'Челябинск',
    zona: 3,
    t_ot: -6.6,
    z_ot: 212,
    t_h: -32,
    f: 78,
    t_jan: -15,
    t_feb: -13.5,
    t_mar: -5.8,
    t_apr: 4.7,
    t_may: 12.4,
    t_jun: 17.6,
    t_jul: 19.2,
    t_aug: 16.7,
    t_sep: 11,
    t_oct: 3.5,
    t_nov: -5.3,
    t_dec: -12.2,
    t_year: 2.8,
    p_jan: 1.9,
    p_feb: 1.9,
    p_mar: 3.2,
    p_apr: 5.6,
    p_may: 8.3,
    p_jun: 12.7,
    p_jul: 15.4,
    p_aug: 13.5,
    p_sep: 9.4,
    p_oct: 6,
    p_nov: 3.6,
    p_dec: 2.3,
    p_year: 6.4,
    z0: 162,
    t0: -10.1,
  },
  {
    index: 'cr',
    subject: 'Республика Крым',
    city: 'Севастополь',
    zona: 1,
    t_ot: 4.7,
    z_ot: 136,
    t_h: -11,
    f: 78,
    t_jan: 3.1,
    t_feb: 3.6,
    t_mar: 5.7,
    t_apr: 10.5,
    t_may: 15.2,
    t_jun: 19.7,
    t_jul: 22,
    t_aug: 21.8,
    t_sep: 18,
    t_oct: 12.8,
    t_nov: 9,
    t_dec: 5.5,
    t_year: 12.2,
    p_jan: 6.2,
    p_feb: 6.3,
    p_mar: 6.7,
    p_apr: 8.9,
    p_may: 12.7,
    p_jun: 16.4,
    p_jul: 18.9,
    p_aug: 18.2,
    p_sep: 14.4,
    p_oct: 11.3,
    p_nov: 9.4,
    p_dec: 7.4,
    p_year: 11.4,
    z0: 0,
    t0: 0,
  },
  {
    index: 'cr',
    subject: 'Республика Крым',
    city: 'Симферополь',
    zona: 1,
    t_ot: 2.9,
    z_ot: 153,
    t_h: -14,
    f: 85,
    t_jan: -0.3,
    t_feb: 0.3,
    t_mar: 3.7,
    t_apr: 9.9,
    t_may: 15.1,
    t_jun: 19.2,
    t_jul: 21.9,
    t_aug: 21.5,
    t_sep: 16.6,
    t_oct: 10.9,
    t_nov: 6.1,
    t_dec: 1.9,
    t_year: 10.6,
    p_jan: 4.6,
    p_feb: 5.1,
    p_mar: 6,
    p_apr: 8.6,
    p_may: 11.7,
    p_jun: 14.9,
    p_jul: 16.4,
    p_aug: 16.2,
    p_sep: 13.1,
    p_oct: 9.8,
    p_nov: 7.7,
    p_dec: 6,
    p_year: 9.5,
    z0: 37,
    t0: -0.5,
  },
  {
    index: 'cu',
    subject: 'Чувашская Республика',
    city: 'Чебоксары',
    zona: 2,
    t_ot: -4.9,
    z_ot: 217,
    t_h: -32,
    f: 84,
    t_jan: -13,
    t_feb: -12.4,
    t_mar: -6,
    t_apr: 3.6,
    t_may: 12,
    t_jun: 16.5,
    t_jul: 18.6,
    t_aug: 16.9,
    t_sep: 10.8,
    t_oct: 3.3,
    t_nov: -3.7,
    t_dec: -10,
    t_year: 3,
    p_jan: 2.3,
    p_feb: 2.3,
    p_mar: 3.4,
    p_apr: 6,
    p_may: 8.8,
    p_jun: 12.1,
    p_jul: 14.8,
    p_aug: 13.6,
    p_sep: 10,
    p_oct: 6.5,
    p_nov: 4.4,
    p_dec: 3,
    p_year: 4.4,
    z0: 156,
    t0: -8.9,
  },
  {
    index: 'da',
    subject: 'Республика Дагестан',
    city: 'Махачкала',
    zona: 3,
    t_ot: 2.7,
    z_ot: 144,
    t_h: -17,
    f: 83,
    t_jan: 0.6,
    t_feb: 0.8,
    t_mar: 4.4,
    t_apr: 10.3,
    t_may: 16.2,
    t_jun: 21.6,
    t_jul: 24.6,
    t_aug: 24.3,
    t_sep: 19.9,
    t_oct: 13.7,
    t_nov: 7.8,
    t_dec: 2.9,
    t_year: 12.3,
    p_jan: 5.3,
    p_feb: 5.5,
    p_mar: 6.6,
    p_apr: 9.2,
    p_may: 13.2,
    p_jun: 16.6,
    p_jul: 19.8,
    p_aug: 20,
    p_sep: 16.3,
    p_oct: 11.9,
    p_nov: 8.8,
    p_dec: 6.5,
    p_year: 11.6,
    z0: 0,
    t0: 0,
  },
  {
    index: 'eu',
    subject: 'Еврейская автономная область',
    city: 'Биробиджан',
    zona: 2,
    t_ot: -10.1,
    z_ot: 210,
    t_h: -31,
    f: 75,
    t_jan: -22.3,
    t_feb: -16.6,
    t_mar: -6.7,
    t_apr: 4.4,
    t_may: 12.1,
    t_jun: 18.1,
    t_jul: 21,
    t_aug: 19.5,
    t_sep: 12.8,
    t_oct: 3.9,
    t_nov: -8.6,
    t_dec: -20,
    t_year: 1.5,
    p_jan: 0.9,
    p_feb: 1.3,
    p_mar: 2.6,
    p_apr: 5.1,
    p_may: 8.8,
    p_jun: 15.3,
    p_jul: 20.1,
    p_aug: 18.7,
    p_sep: 11.6,
    p_oct: 5.4,
    p_nov: 2.4,
    p_dec: 1.1,
    p_year: 7.8,
    z0: 161,
    t0: -14.5,
  },
  {
    index: 'ha',
    subject: 'Хабаровский край',
    city: 'Хабаровск',
    zona: 2,
    t_ot: -9.5,
    z_ot: 205,
    t_h: -29,
    f: 72,
    t_jan: -20.3,
    t_feb: -16,
    t_mar: -6.7,
    t_apr: 4.5,
    t_may: 12.3,
    t_jun: 18.1,
    t_jul: 21.3,
    t_aug: 19.7,
    t_sep: 13.6,
    t_oct: 4.9,
    t_nov: -7.2,
    t_dec: -17.7,
    t_year: 2.2,
    p_jan: 1,
    p_feb: 1.3,
    p_mar: 2.4,
    p_apr: 4.8,
    p_may: 8.4,
    p_jun: 14.5,
    p_jul: 19.3,
    p_aug: 18.2,
    p_sep: 11.5,
    p_oct: 5.5,
    p_nov: 2.5,
    p_dec: 1.2,
    p_year: 5.7,
    z0: 158,
    t0: -13.6,
  },
  {
    index: 'hk',
    subject: 'Республика Хакасия',
    city: 'Абакан',
    zona: 3,
    t_ot: -7.9,
    z_ot: 224,
    t_h: -37,
    f: 79,
    t_jan: -18.6,
    t_feb: -16.4,
    t_mar: -6.1,
    t_apr: 3.9,
    t_may: 11.3,
    t_jun: 17.7,
    t_jul: 19.9,
    t_aug: 16.8,
    t_sep: 9.9,
    t_oct: 2,
    t_nov: -7.6,
    t_dec: -15.5,
    t_year: 1.5,
    p_jan: 1.4,
    p_feb: 1.6,
    p_mar: 2.9,
    p_apr: 4.4,
    p_may: 6.9,
    p_jun: 11.8,
    p_jul: 15,
    p_aug: 13.2,
    p_sep: 8.7,
    p_oct: 5.2,
    p_nov: 2.8,
    p_dec: 1.7,
    p_year: 6.3,
    z0: 164,
    t0: -12.9,
  },
  {
    index: 'ht',
    subject: 'Ханты-Мансийский автономный округ',
    city: 'Сургут',
    zona: 2,
    t_ot: -9.9,
    z_ot: 257,
    t_h: -43,
    f: 79,
    t_jan: -22,
    t_feb: -19.6,
    t_mar: -13.3,
    t_apr: -3.5,
    t_may: 4.1,
    t_jun: 13,
    t_jul: 16.9,
    t_aug: 14,
    t_sep: 7.8,
    t_oct: -1.4,
    t_nov: -13.2,
    t_dec: -20.3,
    t_year: -3.1,
    p_jan: 1.2,
    p_feb: 1.3,
    p_mar: 1.9,
    p_apr: 3.9,
    p_may: 6,
    p_jun: 10.5,
    p_jul: 13.6,
    p_aug: 12.7,
    p_sep: 8.9,
    p_oct: 5,
    p_nov: 2.4,
    p_dec: 1.4,
    p_year: 7,
    z0: 200,
    t0: -13.8,
  },
  {
    index: 'ht',
    subject: 'Ханты-Мансийский автономный округ',
    city: 'Ханты-Мансийск',
    zona: 3,
    t_ot: -8.7,
    z_ot: 248,
    t_h: -41,
    f: 79,
    t_jan: -20.1,
    t_feb: -18.1,
    t_mar: -8.5,
    t_apr: -0.8,
    t_may: 7.1,
    t_jun: 14.7,
    t_jul: 18,
    t_aug: 14.1,
    t_sep: 7.9,
    t_oct: -0.4,
    t_nov: -10.3,
    t_dec: -16.7,
    t_year: -1.1,
    p_jan: 1.4,
    p_feb: 1.5,
    p_mar: 2.8,
    p_apr: 4.3,
    p_may: 6.6,
    p_jun: 11.3,
    p_jul: 14.8,
    p_aug: 12.7,
    p_sep: 8.7,
    p_oct: 5.3,
    p_nov: 2.9,
    p_dec: 1.8,
    p_year: 8.4,
    z0: 191,
    t0: -12.6,
  },
  {
    index: 'ig',
    subject: 'Республика Ингушетия',
    city: 'Назрань',
    zona: 3,
    t_ot: 0.8,
    z_ot: 159,
    t_h: -15,
    f: 84,
    t_jan: -2.5,
    t_feb: -1.9,
    t_mar: 3,
    t_apr: 10.1,
    t_may: 15.4,
    t_jun: 19,
    t_jul: 22,
    t_aug: 21.5,
    t_sep: 16.5,
    t_oct: 10.1,
    t_nov: 4.1,
    t_dec: -0.6,
    t_year: 10.3,
    p_jan: 4.1,
    p_feb: 4.5,
    p_mar: 6,
    p_apr: 8.8,
    p_may: 12.8,
    p_jun: 15.9,
    p_jul: 17.7,
    p_aug: 17.5,
    p_sep: 13.8,
    p_oct: 10.1,
    p_nov: 7.2,
    p_dec: 5,
    p_year: 7.2,
    z0: 87,
    t0: -2.0,
  },
  {
    index: 'ir',
    subject: 'Иркутская область',
    city: 'Иркутск',
    zona: 3,
    t_ot: -7.6,
    z_ot: 233,
    t_h: -33,
    f: 79,
    t_jan: -18.4,
    t_feb: -15.5,
    t_mar: -6.9,
    t_apr: 2.4,
    t_may: 9.8,
    t_jun: 15.6,
    t_jul: 18.1,
    t_aug: 15.7,
    t_sep: 9.1,
    t_oct: 1.6,
    t_nov: -7.9,
    t_dec: -15.7,
    t_year: 0.7,
    p_jan: 1.3,
    p_feb: 1.5,
    p_mar: 2.5,
    p_apr: 4,
    p_may: 6.3,
    p_jun: 11.2,
    p_jul: 15.1,
    p_aug: 13.6,
    p_sep: 8.8,
    p_oct: 5.1,
    p_nov: 2.9,
    p_dec: 1.7,
    p_year: 6.2,
    z0: 170,
    t0: -12,
  },
  {
    index: 'iv',
    subject: 'Ивановская область',
    city: 'Иваново',
    zona: 2,
    t_ot: -3.7,
    z_ot: 214,
    t_h: -29,
    f: 85,
    t_jan: -10.4,
    t_feb: -9.6,
    t_mar: -3.4,
    t_apr: 5.1,
    t_may: 12.2,
    t_jun: 16.3,
    t_jul: 18.5,
    t_aug: 16.2,
    t_sep: 10.4,
    t_oct: 3.9,
    t_nov: -2.5,
    t_dec: -7.5,
    t_year: 4.1,
    p_jan: 2.6,
    p_feb: 2.6,
    p_mar: 3.6,
    p_apr: 6.1,
    p_may: 9,
    p_jun: 12.4,
    p_jul: 14.8,
    p_aug: 13.7,
    p_sep: 10.2,
    p_oct: 6.8,
    p_nov: 4.6,
    p_dec: 3.4,
    p_year: 7.5,
    z0: 152,
    t0: -7.6,
  },
  {
    index: 'ka',
    subject: 'Камчатский Край',
    city: 'Петропавловск-Камчатский',
    zona: 1,
    t_ot: -1.6,
    z_ot: 251,
    t_h: -18,
    f: 68,
    t_jan: -6.9,
    t_feb: -6.6,
    t_mar: -4.1,
    t_apr: 0,
    t_may: 4.4,
    t_jun: 9.2,
    t_jul: 12.5,
    t_aug: 13.4,
    t_sep: 10.3,
    t_oct: 5.2,
    t_nov: -1,
    t_dec: -5.1,
    t_year: 2.6,
    p_jan: 2.8,
    p_feb: 2.7,
    p_mar: 3.2,
    p_apr: 4.4,
    p_may: 6.2,
    p_jun: 9.1,
    p_jul: 12.2,
    p_aug: 12.6,
    p_sep: 9.8,
    p_oct: 6.5,
    p_nov: 4.1,
    p_dec: 3.1,
    p_year: 9.3,
    z0: 160,
    t0: -4.8,
  },
  {
    index: 'kb',
    subject: 'Кабардино-Балкарская Республика',
    city: 'Нальчик',
    zona: 2,
    t_ot: 0.6,
    z_ot: 164,
    t_h: -18,
    f: 85,
    t_jan: -2.8,
    t_feb: -2.2,
    t_mar: 2.9,
    t_apr: 10.3,
    t_may: 15.5,
    t_jun: 19.5,
    t_jul: 22.2,
    t_aug: 21.6,
    t_sep: 16.8,
    t_oct: 10.3,
    t_nov: 4.1,
    t_dec: -0.6,
    t_year: 9.8,
    p_jan: 4.1,
    p_feb: 4.4,
    p_mar: 5.7,
    p_apr: 8.5,
    p_may: 12.4,
    p_jun: 15.2,
    p_jul: 17.3,
    p_aug: 16.9,
    p_sep: 13.6,
    p_oct: 9.5,
    p_nov: 6.9,
    p_dec: 5,
    p_year: 10,
    z0: 86,
    t0: -2.5,
  },
  {
    index: 'kc',
    subject: 'Карачаево-Черкесия',
    city: 'Черкесск',
    zona: 2,
    t_ot: 0.6,
    z_ot: 168,
    t_h: -18,
    f: 80,
    t_jan: -2.9,
    t_feb: -2.2,
    t_mar: 2.5,
    t_apr: 9.8,
    t_may: 14.8,
    t_jun: 18.4,
    t_jul: 21.1,
    t_aug: 20.6,
    t_sep: 15.9,
    t_oct: 9.8,
    t_nov: 3.9,
    t_dec: -0.7,
    t_year: 9.2,
    p_jan: 4.2,
    p_feb: 4.4,
    p_mar: 5.4,
    p_apr: 7.7,
    p_may: 11.3,
    p_jun: 14.2,
    p_jul: 16.2,
    p_aug: 15.5,
    p_sep: 12.3,
    p_oct: 8.9,
    p_nov: 6.6,
    p_dec: 4.9,
    p_year: 6.6,
    z0: 85,
    t0: -2.5,
  },
  {
    index: 'ki',
    subject: 'Кировская область',
    city: 'Киров',
    zona: 2,
    t_ot: -5.0,
    z_ot: 223,
    t_h: -32,
    f: 84,
    t_jan: -13,
    t_feb: -11.5,
    t_mar: -4.3,
    t_apr: 3.9,
    t_may: 11.3,
    t_jun: 16.2,
    t_jul: 18.5,
    t_aug: 15.7,
    t_sep: 9.7,
    t_oct: 2.3,
    t_nov: -4.5,
    t_dec: -10.1,
    t_year: 2.8,
    p_jan: 2.1,
    p_feb: 2.2,
    p_mar: 3.2,
    p_apr: 5.3,
    p_may: 7.7,
    p_jun: 11.2,
    p_jul: 14.1,
    p_aug: 12.8,
    p_sep: 9.6,
    p_oct: 6.1,
    p_nov: 4,
    p_dec: 2.7,
    p_year: 7.4,
    z0: 161,
    t0: -8.5,
  },
  {
    index: 'kj',
    subject: 'Калужская область',
    city: 'Калуга',
    zona: 2,
    t_ot: -2.6,
    z_ot: 208,
    t_h: -27,
    f: 84,
    t_jan: -8.4,
    t_feb: -7.9,
    t_mar: -2.2,
    t_apr: 6,
    t_may: 12.8,
    t_jun: 16.1,
    t_jul: 18,
    t_aug: 16.3,
    t_sep: 10.8,
    t_oct: 4.9,
    t_nov: -1.1,
    t_dec: -5.7,
    t_year: 5,
    p_jan: 2.8,
    p_feb: 2.8,
    p_mar: 3.9,
    p_apr: 6.4,
    p_may: 9.6,
    p_jun: 12.8,
    p_jul: 14.9,
    p_aug: 14,
    p_sep: 10.3,
    p_oct: 7.2,
    p_nov: 5,
    p_dec: 3.8,
    p_year: 7.8,
    z0: 142,
    t0: -6.2,
  },
  {
    index: 'kk',
    subject: 'Республика Калмыкия',
    city: 'Элиста',
    zona: 3,
    t_ot: -1,
    z_ot: 169,
    t_h: -23,
    f: 88,
    t_jan: -5,
    t_feb: -4.6,
    t_mar: 1.3,
    t_apr: 10.3,
    t_may: 16.8,
    t_jun: 21.6,
    t_jul: 24.6,
    t_aug: 23.4,
    t_sep: 17.2,
    t_oct: 9.6,
    t_nov: 2.6,
    t_dec: -2.5,
    t_year: 9.6,
    p_jan: 3.8,
    p_feb: 4,
    p_mar: 5.2,
    p_apr: 7.7,
    p_may: 10.4,
    p_jun: 12.7,
    p_jul: 13.7,
    p_aug: 13.3,
    p_sep: 10.7,
    p_oct: 8.3,
    p_nov: 6.6,
    p_dec: 4.9,
    p_year: 6.8,
    z0: 108,
    t0: -3.7,
  },
  {
    index: 'kl',
    subject: 'Республика Карелия',
    city: 'Петрозаводск',
    zona: 2,
    t_ot: -3.2,
    z_ot: 235,
    t_h: -28,
    f: 86,
    t_jan: -10.3,
    t_feb: -9.5,
    t_mar: -3.8,
    t_apr: 1.8,
    t_may: 8.4,
    t_jun: 13.7,
    t_jul: 16.5,
    t_aug: 14.3,
    t_sep: 9.1,
    t_oct: 3.3,
    t_nov: -2.5,
    t_dec: -7,
    t_year: 2.8,
    p_jan: 2.7,
    p_feb: 2.7,
    p_mar: 3.4,
    p_apr: 4.8,
    p_may: 6.8,
    p_jun: 10.4,
    p_jul: 13.2,
    p_aug: 12.8,
    p_sep: 9.6,
    p_oct: 6.7,
    p_nov: 4.7,
    p_dec: 3.5,
    p_year: 6.3,
    z0: 158,
    t0: -6.6,
  },
  {
    index: 'km',
    subject: 'Кемеровская область',
    city: 'Кемерово',
    zona: 3,
    t_ot: -7.9,
    z_ot: 228,
    t_h: -39,
    f: 77,
    t_jan: -18.1,
    t_feb: -16,
    t_mar: -7.8,
    t_apr: 2.1,
    t_may: 10.5,
    t_jun: 16.5,
    t_jul: 19,
    t_aug: 15.8,
    t_sep: 9.5,
    t_oct: 2,
    t_nov: -7.7,
    t_dec: -15.1,
    t_year: 0.9,
    p_jan: 1.6,
    p_feb: 1.7,
    p_mar: 2.8,
    p_apr: 4.8,
    p_may: 7.3,
    p_jun: 12.4,
    p_jul: 15.5,
    p_aug: 13.4,
    p_sep: 8.9,
    p_oct: 5.6,
    p_nov: 3.2,
    p_dec: 1.9,
    p_year: 6.5,
    z0: 172,
    t0: -12,
  },
  {
    index: 'km',
    subject: 'Кемеровская область',
    city: 'Киселевск',
    zona: 3,
    t_ot: -6.6,
    z_ot: 223,
    t_h: -35,
    f: 77,
    t_jan: -15.7,
    t_feb: -13.6,
    t_mar: -5.8,
    t_apr: 3.4,
    t_may: 11.1,
    t_jun: 17,
    t_jul: 19.4,
    t_aug: 16.4,
    t_sep: 10.1,
    t_oct: 2.9,
    t_nov: -6.5,
    t_dec: -13.3,
    t_year: 2.1,
    p_jan: 1.7,
    p_feb: 1.9,
    p_mar: 3.1,
    p_apr: 4.9,
    p_may: 7.2,
    p_jun: 12,
    p_jul: 15,
    p_aug: 13,
    p_sep: 8.7,
    p_oct: 5.6,
    p_nov: 3.3,
    p_dec: 2.1,
    p_year: 6.8,
    z0: 169,
    t0: -11.2,
  },
  {
    index: 'kn',
    subject: 'Калининградская область',
    city: 'Калининград',
    zona: 2,
    t_ot: 1.2,
    z_ot: 188,
    t_h: -19,
    f: 86,
    t_jan: -2.2,
    t_feb: -1.7,
    t_mar: 1.7,
    t_apr: 6.7,
    t_may: 12.2,
    t_jun: 15.6,
    t_jul: 17.7,
    t_aug: 17.3,
    t_sep: 12.9,
    t_oct: 8.3,
    t_nov: 3.4,
    t_dec: -0.4,
    t_year: 7.6,
    p_jan: 4.4,
    p_feb: 4.5,
    p_mar: 5.2,
    p_apr: 7.1,
    p_may: 9.7,
    p_jun: 12.9,
    p_jul: 15,
    p_aug: 14.9,
    p_sep: 12.3,
    p_oct: 9.3,
    p_nov: 7,
    p_dec: 5.5,
    p_year: 9,
    z0: 87,
    t0: -1.6,
  },
  {
    index: 'ko',
    subject: 'Республика Коми',
    city: 'Воркута',
    zona: 2,
    t_ot: -9.1,
    z_ot: 306,
    t_h: -41,
    f: 81,
    t_jan: -20.3,
    t_feb: -20.6,
    t_mar: -16.5,
    t_apr: -9,
    t_may: -2.8,
    t_jun: 5.8,
    t_jul: 12.4,
    t_aug: 9.5,
    t_sep: 3.8,
    t_oct: -5.1,
    t_nov: -13.6,
    t_dec: -15.7,
    t_year: -6,
    p_jan: 1.3,
    p_feb: 1.2,
    p_mar: 1.8,
    p_apr: 2.9,
    p_may: 4.3,
    p_jun: 7,
    p_jul: 10.4,
    p_aug: 9.6,
    p_sep: 7,
    p_oct: 4,
    p_nov: 2.3,
    p_dec: 1.8,
    p_year: 7.4,
    z0: 237,
    t0: -12.8,
  },
  {
    index: 'ko',
    subject: 'Республика Коми',
    city: 'Сыктывкар',
    zona: 2,
    t_ot: -5.6,
    z_ot: 235,
    t_h: -36,
    f: 83,
    t_jan: -15.2,
    t_feb: -13.2,
    t_mar: -5.3,
    t_apr: 1.5,
    t_may: 8.2,
    t_jun: 14.3,
    t_jul: 17.2,
    t_aug: 13.6,
    t_sep: 7.9,
    t_oct: 1,
    t_nov: -6.5,
    t_dec: -11.9,
    t_year: 1,
    p_jan: 1.9,
    p_feb: 1.9,
    p_mar: 3,
    p_apr: 4.8,
    p_may: 6.9,
    p_jun: 10.4,
    p_jul: 13.4,
    p_aug: 12.3,
    p_sep: 9,
    p_oct: 5.7,
    p_nov: 3.7,
    p_dec: 2.6,
    p_year: 4.5,
    z0: 173,
    t0: -9.6,
  },
  {
    index: 'kr',
    subject: 'Красноярский край',
    city: 'Красноярск',
    zona: 3,
    t_ot: -6.5,
    z_ot: 235,
    t_h: -37,
    f: 72,
    t_jan: -16,
    t_feb: -14,
    t_mar: -6.3,
    t_apr: 1.9,
    t_may: 9.7,
    t_jun: 16,
    t_jul: 18.7,
    t_aug: 15.4,
    t_sep: 8.9,
    t_oct: 1.5,
    t_nov: -7.5,
    t_dec: -13.7,
    t_year: 1.2,
    p_jan: 1.5,
    p_feb: 1.6,
    p_mar: 2.6,
    p_apr: 4.1,
    p_may: 6.4,
    p_jun: 11.4,
    p_jul: 14.9,
    p_aug: 13.1,
    p_sep: 8.5,
    p_oct: 5,
    p_nov: 2.8,
    p_dec: 1.8,
    p_year: 6.1,
    z0: 171,
    t0: -10.7,
  },
  {
    index: 'kr',
    subject: 'Красноярский край',
    city: 'Тура',
    zona: 3,
    t_ot: -17.1,
    z_ot: 270,
    t_h: -53,
    f: 76,
    t_jan: -35.9,
    t_feb: -31.6,
    t_mar: -18.1,
    t_apr: -6.4,
    t_may: 3.6,
    t_jun: 13,
    t_jul: 17,
    t_aug: 12.6,
    t_sep: 4.8,
    t_oct: -6.9,
    t_nov: -24.2,
    t_dec: -32.3,
    t_year: -8.7,
    p_jan: 0.4,
    p_feb: 0.5,
    p_mar: 1.3,
    p_apr: 2.6,
    p_may: 4.6,
    p_jun: 8.5,
    p_jul: 12,
    p_aug: 10.5,
    p_sep: 6.5,
    p_oct: 3.2,
    p_nov: 1,
    p_dec: 0.5,
    p_year: 6.4,
    z0: 218,
    t0: -22.2,
  },
  {
    index: 'ks',
    subject: 'Краснодарский край',
    city: 'Краснодар',
    zona: 3,
    t_ot: 2.5,
    z_ot: 145,
    t_h: -14,
    f: 81,
    t_jan: -0.2,
    t_feb: 1,
    t_mar: 5.4,
    t_apr: 12.2,
    t_may: 17.3,
    t_jun: 21,
    t_jul: 23.8,
    t_aug: 23.2,
    t_sep: 18.1,
    t_oct: 11.9,
    t_nov: 6.3,
    t_dec: 2,
    t_year: 11.8,
    p_jan: 4.9,
    p_feb: 5.3,
    p_mar: 6.2,
    p_apr: 9,
    p_may: 12.9,
    p_jun: 16.1,
    p_jul: 17.9,
    p_aug: 17.2,
    p_sep: 13.4,
    p_oct: 10.1,
    p_nov: 8,
    p_dec: 6.1,
    p_year: 12.9,
    z0: 41,
    t0: -0.2,
  },
  {
    index: 'ks',
    subject: 'Краснодарский край',
    city: 'Сочи',
    zona: 1,
    t_ot: 6.6,
    z_ot: 94,
    t_h: -2,
    f: 72,
    t_jan: 6,
    t_feb: 6.2,
    t_mar: 8.3,
    t_apr: 12.2,
    t_may: 16.1,
    t_jun: 20,
    t_jul: 23,
    t_aug: 23.3,
    t_sep: 19.8,
    t_oct: 15.6,
    t_nov: 11.3,
    t_dec: 7.9,
    t_year: 14.1,
    p_jan: 6.8,
    p_feb: 6.9,
    p_mar: 7.6,
    p_apr: 10.2,
    p_may: 14.3,
    p_jun: 18.3,
    p_jul: 21.8,
    p_aug: 21.4,
    p_sep: 17.2,
    p_oct: 12.8,
    p_nov: 9.9,
    p_dec: 7.7,
    p_year: 4.3,
    z0: 0,
    t0: 0,
  },
  {
    index: 'kt',
    subject: 'Костромская область',
    city: 'Кострома',
    zona: 2,
    t_ot: -3.7,
    z_ot: 216,
    t_h: -31,
    f: 85,
    t_jan: -10.6,
    t_feb: -9.4,
    t_mar: -3.3,
    t_apr: 4.6,
    t_may: 12,
    t_jun: 16.1,
    t_jul: 18.4,
    t_aug: 16.1,
    t_sep: 10.3,
    t_oct: 3.8,
    t_nov: -2.6,
    t_dec: -7.7,
    t_year: 4,
    p_jan: 2.5,
    p_feb: 2.6,
    p_mar: 3.6,
    p_apr: 5.8,
    p_may: 8.6,
    p_jun: 12.3,
    p_jul: 14.9,
    p_aug: 13.8,
    p_sep: 10.2,
    p_oct: 6.7,
    p_nov: 4.6,
    p_dec: 3.3,
    p_year: 10.6,
    z0: 154,
    t0: -7.4,
  },
  {
    index: 'ku',
    subject: 'Курганская область',
    city: 'Курган',
    zona: 3,
    t_ot: -7.5,
    z_ot: 212,
    t_h: -36,
    f: 77,
    t_jan: -16.6,
    t_feb: -15,
    t_mar: -6.7,
    t_apr: 4.8,
    t_may: 12.6,
    t_jun: 18,
    t_jul: 19.5,
    t_aug: 16.8,
    t_sep: 10.9,
    t_oct: 3.2,
    t_nov: -6.2,
    t_dec: -13.3,
    t_year: 2.3,
    p_jan: 1.7,
    p_feb: 1.8,
    p_mar: 3.2,
    p_apr: 5.7,
    p_may: 8.2,
    p_jun: 12.7,
    p_jul: 15.4,
    p_aug: 13.4,
    p_sep: 9.3,
    p_oct: 6,
    p_nov: 3.5,
    p_dec: 2.2,
    p_year: 6.9,
    z0: 161,
    t0: -11.3,
  },
  {
    index: 'ky',
    subject: 'Курская область',
    city: 'Курск',
    zona: 2,
    t_ot: -2.3,
    z_ot: 194,
    t_h: -24,
    f: 85,
    t_jan: -7.3,
    t_feb: -6.9,
    t_mar: -1.4,
    t_apr: 7.5,
    t_may: 14.2,
    t_jun: 17.4,
    t_jul: 19,
    t_aug: 18.1,
    t_sep: 12.5,
    t_oct: 6.2,
    t_nov: -0.5,
    t_dec: -5.2,
    t_year: 6.1,
    p_jan: 3.1,
    p_feb: 3.3,
    p_mar: 4.4,
    p_apr: 7,
    p_may: 9.6,
    p_jun: 12.8,
    p_jul: 15,
    p_aug: 14,
    p_sep: 10.5,
    p_oct: 7.4,
    p_nov: 5.5,
    p_dec: 4,
    p_year: 8.1,
    z0: 132,
    t0: -5.3,
  },
  {
    index: 'le',
    subject: 'Ленинградская область',
    city: 'Санкт-Петербург',
    zona: 1,
    t_ot: -1.3,
    z_ot: 213,
    t_h: -24,
    f: 86,
    t_jan: -6.6,
    t_feb: -6.3,
    t_mar: -1.5,
    t_apr: 4.5,
    t_may: 10.9,
    t_jun: 15.7,
    t_jul: 18.3,
    t_aug: 16.7,
    t_sep: 11.4,
    t_oct: 5.7,
    t_nov: 0.2,
    t_dec: -3.9,
    t_year: 5.4,
    p_jan: 3.3,
    p_feb: 3.2,
    p_mar: 3.9,
    p_apr: 5.7,
    p_may: 8,
    p_jun: 11.8,
    p_jul: 14.6,
    p_aug: 14.3,
    p_sep: 10.9,
    p_oct: 7.6,
    p_nov: 5.5,
    p_dec: 4.2,
    p_year: 7.8,
    z0: 131,
    t0: -4.6,
  },
  {
    index: 'lp',
    subject: 'Липецкая область',
    city: 'Липецк',
    zona: 3,
    t_ot: -3.1,
    z_ot: 197,
    t_h: -27,
    f: 85,
    t_jan: -8.5,
    t_feb: -8.2,
    t_mar: -2.6,
    t_apr: 7.3,
    t_may: 14.3,
    t_jun: 17.9,
    t_jul: 19.6,
    t_aug: 18.3,
    t_sep: 12.5,
    t_oct: 5.7,
    t_nov: -0.9,
    t_dec: -5.9,
    t_year: 5.8,
    p_jan: 2.8,
    p_feb: 2.9,
    p_mar: 3.8,
    p_apr: 6.7,
    p_may: 9.4,
    p_jun: 12.8,
    p_jul: 15.1,
    p_aug: 14.4,
    p_sep: 10.4,
    p_oct: 7,
    p_nov: 4.8,
    p_dec: 3.6,
    p_year: 0,
    z0: 141,
    t0: -6.6,
  },
  {
    index: 'lt',
    subject: 'Приморский край',
    city: 'Красный Яр',
    zona: 1,
    t_ot: -32,
    z_ot: 215,
    t_h: -10.1,
    f: 74,
    t_jan: -22.7,
    t_feb: -17.8,
    t_mar: -7.6,
    t_apr: 3.7,
    t_may: 11.2,
    t_jun: 17,
    t_jul: 20.6,
    t_aug: 19.2,
    t_sep: 12.3,
    t_oct: 3.4,
    t_nov: -8.2,
    t_dec: -19.6,
    t_year: 1,
    p_jan: 0.9,
    p_feb: 1.2,
    p_mar: 2.5,
    p_apr: 4.9,
    p_may: 8.6,
    p_jun: 14.6,
    p_jul: 19.4,
    p_aug: 18.8,
    p_sep: 11.9,
    p_oct: 6,
    p_nov: 2.7,
    p_dec: 1.2,
    p_year: 8,
    z0: 164,
    t0: -14.7,
  },
  {
    index: 'lt',
    subject: 'Республика Алтай',
    city: 'Родино',
    zona: 3,
    t_ot: -8.0,
    z_ot: 207,
    t_h: -37,
    f: 78,
    t_jan: -16.3,
    t_feb: -15.3,
    t_mar: -8,
    t_apr: 4.9,
    t_may: 13.2,
    t_jun: 19,
    t_jul: 20.7,
    t_aug: 18,
    t_sep: 11.8,
    t_oct: 3.8,
    t_nov: -6.1,
    t_dec: -13.1,
    t_year: 2.7,
    p_jan: 1.7,
    p_feb: 1.8,
    p_mar: 3.1,
    p_apr: 5.5,
    p_may: 7.4,
    p_jun: 11.5,
    p_jul: 14.6,
    p_aug: 12.2,
    p_sep: 8.2,
    p_oct: 5.7,
    p_nov: 3.5,
    p_dec: 2.2,
    p_year: 6.5,
    z0: 165,
    t0: -11.8,
  },
  {
    index: 'ma',
    subject: 'Магаданская область',
    city: 'Магадан',
    zona: 2,
    t_ot: -7.4,
    z_ot: 279,
    t_h: -28,
    f: 62,
    t_jan: -16.4,
    t_feb: -15.6,
    t_mar: -11.5,
    t_apr: -4.9,
    t_may: 1.6,
    t_jun: 7.5,
    t_jul: 11.6,
    t_aug: 12,
    t_sep: 7.4,
    t_oct: -1.2,
    t_nov: -10.3,
    t_dec: -14.8,
    t_year: -2.9,
    p_jan: 1.2,
    p_feb: 1.3,
    p_mar: 1.8,
    p_apr: 3.1,
    p_may: 5.3,
    p_jun: 8.4,
    p_jul: 11.6,
    p_aug: 11.5,
    p_sep: 8,
    p_oct: 4.1,
    p_nov: 2.1,
    p_dec: 1.4,
    p_year: 5,
    z0: 210,
    t0: -11.3,
  },
  {
    index: 'mc',
    subject: 'Московская область',
    city: 'Москва',
    zona: 2,
    t_ot: -2.2,
    z_ot: 205,
    t_h: -25,
    f: 83,
    t_jan: -7.8,
    t_feb: -7.1,
    t_mar: -1.3,
    t_apr: 6.4,
    t_may: 13,
    t_jun: 16.9,
    t_jul: 18.7,
    t_aug: 16.8,
    t_sep: 11.1,
    t_oct: 5.2,
    t_nov: -1.1,
    t_dec: -5.6,
    t_year: 5.4,
    p_jan: 2.8,
    p_feb: 2.9,
    p_mar: 3.9,
    p_apr: 6.2,
    p_may: 9.1,
    p_jun: 12.4,
    p_jul: 14.7,
    p_aug: 14,
    p_sep: 10.4,
    p_oct: 7,
    p_nov: 5,
    p_dec: 3.6,
    p_year: 7.7,
    z0: 135,
    t0: -5.5,
  },
  {
    index: 'ml',
    subject: 'Республика Марий Эл',
    city: 'Йошкар-Ола',
    zona: 2,
    t_ot: -5.6,
    z_ot: 215,
    t_h: -33,
    f: 83,
    t_jan: -12.1,
    t_feb: -11.4,
    t_mar: -4.6,
    t_apr: 4.7,
    t_may: 12,
    t_jun: 16.5,
    t_jul: 18.6,
    t_aug: 16.1,
    t_sep: 10.3,
    t_oct: 3.4,
    t_nov: -3.7,
    t_dec: -9.4,
    t_year: 3.4,
    p_jan: 2.2,
    p_feb: 2.2,
    p_mar: 3.3,
    p_apr: 6,
    p_may: 9,
    p_jun: 12.5,
    p_jul: 15,
    p_aug: 13.8,
    p_sep: 10.1,
    p_oct: 6.4,
    p_nov: 4.2,
    p_dec: 3,
    p_year: 7.3,
    z0: 154,
    t0: -8.4,
  },
  {
    index: 'mr',
    subject: 'Республика Мордовия',
    city: 'Саранск',
    zona: 2,
    t_ot: -4.5,
    z_ot: 205,
    t_h: -30,
    f: 83,
    t_jan: -10.4,
    t_feb: -10.1,
    t_mar: -4.3,
    t_apr: 5.9,
    t_may: 13.7,
    t_jun: 17.3,
    t_jul: 19.3,
    t_aug: 17.5,
    t_sep: 11.7,
    t_oct: 4.4,
    t_nov: -2.4,
    t_dec: -7.8,
    t_year: 4.6,
    p_jan: 2.5,
    p_feb: 2.5,
    p_mar: 3.7,
    p_apr: 6.6,
    p_may: 9,
    p_jun: 12.5,
    p_jul: 15.1,
    p_aug: 13.8,
    p_sep: 10.1,
    p_oct: 6.8,
    p_nov: 4.7,
    p_dec: 3.3,
    p_year: 7.6,
    z0: 150,
    t0: -7.9,
  },
  {
    index: 'mu',
    subject: 'Мурманская область',
    city: 'Мурманск',
    zona: 1,
    t_ot: -3.4,
    z_ot: 275,
    t_h: -30,
    f: 84,
    t_jan: -10.5,
    t_feb: -10.4,
    t_mar: -5.8,
    t_apr: -1.3,
    t_may: 3.7,
    t_jun: 9.2,
    t_jul: 12.8,
    t_aug: 11.1,
    t_sep: 6.8,
    t_oct: 0.9,
    t_nov: -4.9,
    t_dec: -8.2,
    t_year: 0.3,
    p_jan: 2.6,
    p_feb: 2.5,
    p_mar: 3.1,
    p_apr: 3.9,
    p_may: 5.4,
    p_jun: 7.9,
    p_jul: 10.1,
    p_aug: 10.2,
    p_sep: 7.8,
    p_oct: 5.4,
    p_nov: 4,
    p_dec: 3.1,
    p_year: 5.6,
    z0: 189,
    t0: -6.9,
  },
  {
    index: 'ne',
    subject: 'Ненецкий автономный округ',
    city: 'Варандей',
    zona: 2,
    t_ot: -7.3,
    z_ot: 323,
    t_h: -36,
    f: 86,
    t_jan: -17.8,
    t_feb: -19.2,
    t_mar: -16.6,
    t_apr: -9.5,
    t_may: -3.4,
    t_jun: 2.8,
    t_jul: 8.9,
    t_aug: 8.8,
    t_sep: 4.9,
    t_oct: -2.2,
    t_nov: -9.5,
    t_dec: -13.9,
    t_year: -5.6,
    p_jan: 1.7,
    p_feb: 1.5,
    p_mar: 1.9,
    p_apr: 3,
    p_may: 4.4,
    p_jun: 6.8,
    p_jul: 10.1,
    p_aug: 10,
    p_sep: 7.7,
    p_oct: 4.8,
    p_nov: 3,
    p_dec: 2.3,
    p_year: 4.8,
    z0: 238,
    t0: -11.5,
  },
  {
    index: 'nn',
    subject: 'Нижегородская область',
    city: 'Нижний Новгород',
    zona: 2,
    t_ot: -3.7,
    z_ot: 209,
    t_h: -30,
    f: 85,
    t_jan: -10.1,
    t_feb: -9.1,
    t_mar: -2.9,
    t_apr: 5.8,
    t_may: 13.1,
    t_jun: 17,
    t_jul: 19.1,
    t_aug: 17,
    t_sep: 11.1,
    t_oct: 4.2,
    t_nov: -2.5,
    t_dec: -7.6,
    t_year: 4.6,
    p_jan: 2.5,
    p_feb: 2.5,
    p_mar: 3.6,
    p_apr: 6,
    p_may: 8.7,
    p_jun: 12,
    p_jul: 14.8,
    p_aug: 13.7,
    p_sep: 10.1,
    p_oct: 6.6,
    p_nov: 4.5,
    p_dec: 3.2,
    p_year: 7.3,
    z0: 151,
    t0: -7.5,
  },
  {
    index: 'no',
    subject: 'Новгородская область',
    city: 'Великий Новгород',
    zona: 2,
    t_ot: -2.1,
    z_ot: 213,
    t_h: -28,
    f: 85,
    t_jan: -7.7,
    t_feb: -7.4,
    t_mar: -2.3,
    t_apr: 4.7,
    t_may: 11.9,
    t_jun: 15.9,
    t_jul: 18.2,
    t_aug: 16.2,
    t_sep: 10.8,
    t_oct: 5,
    t_nov: -0.5,
    t_dec: -4.9,
    t_year: 5,
    p_jan: 3.1,
    p_feb: 3.1,
    p_mar: 3.9,
    p_apr: 6.1,
    p_may: 8.8,
    p_jun: 12.5,
    p_jul: 15.1,
    p_aug: 14.4,
    p_sep: 10.8,
    p_oct: 7.5,
    p_nov: 5.5,
    p_dec: 4,
    p_year: 7.9,
    z0: 143,
    t0: -5.7,
  },
  {
    index: 'nv',
    subject: 'Новосибирская область',
    city: 'Новосибирск',
    zona: 3,
    t_ot: -8.1,
    z_ot: 222,
    t_h: -37,
    f: 77,
    t_jan: -17.7,
    t_feb: -16.2,
    t_mar: -8.2,
    t_apr: 2.6,
    t_may: 11.1,
    t_jun: 17.2,
    t_jul: 19.3,
    t_aug: 16.3,
    t_sep: 10.2,
    t_oct: 2.8,
    t_nov: -7.3,
    t_dec: -14.7,
    t_year: 1.3,
    p_jan: 1.6,
    p_feb: 1.7,
    p_mar: 2.8,
    p_apr: 5,
    p_may: 7.3,
    p_jun: 12.4,
    p_jul: 15.5,
    p_aug: 13.3,
    p_sep: 8.9,
    p_oct: 5.8,
    p_nov: 3.3,
    p_dec: 2,
    p_year: 6.6,
    z0: 169,
    t0: -11.8,
  },
  {
    index: 'ob',
    subject: 'Оренбургская область',
    city: 'Оренбург',
    zona: 3,
    t_ot: -6.1,
    z_ot: 195,
    t_h: -32,
    f: 79,
    t_jan: -12.9,
    t_feb: -12.4,
    t_mar: -5.4,
    t_apr: 7.1,
    t_may: 15.4,
    t_jun: 20.1,
    t_jul: 22,
    t_aug: 20.1,
    t_sep: 13.9,
    t_oct: 5.3,
    t_nov: -3.1,
    t_dec: -9.9,
    t_year: 5,
    p_jan: 1.9,
    p_feb: 2,
    p_mar: 3.4,
    p_apr: 6.2,
    p_may: 8.7,
    p_jun: 12.1,
    p_jul: 14.2,
    p_aug: 12.2,
    p_sep: 8.9,
    p_oct: 6.1,
    p_nov: 4.2,
    p_dec: 2.7,
    p_year: 6.9,
    z0: 149,
    t0: -9.2,
  },
  {
    index: 'om',
    subject: 'Омская область',
    city: 'Омск',
    zona: 3,
    t_ot: -8.1,
    z_ot: 216,
    t_h: -36,
    f: 77,
    t_jan: -17.5,
    t_feb: -16,
    t_mar: -7.7,
    t_apr: 4,
    t_may: 12.1,
    t_jun: 17.9,
    t_jul: 19.4,
    t_aug: 16.4,
    t_sep: 10.5,
    t_oct: 2.9,
    t_nov: -7.2,
    t_dec: -14.3,
    t_year: 1.7,
    p_jan: 1.6,
    p_feb: 1.7,
    p_mar: 3,
    p_apr: 5.4,
    p_may: 7.4,
    p_jun: 11.9,
    p_jul: 14.9,
    p_aug: 12.8,
    p_sep: 8.7,
    p_oct: 5.7,
    p_nov: 3.3,
    p_dec: 2,
    p_year: 6.5,
    z0: 165,
    t0: -11.9,
  },
  {
    index: 'or',
    subject: 'Орловская область',
    city: 'Орел',
    zona: 2,
    t_ot: -2.4,
    z_ot: 199,
    t_h: -25,
    f: 84,
    t_jan: -7.8,
    t_feb: -7.3,
    t_mar: -1.9,
    t_apr: 6.9,
    t_may: 13.9,
    t_jun: 17.2,
    t_jul: 18.7,
    t_aug: 17.6,
    t_sep: 11.9,
    t_oct: 5.7,
    t_nov: -0.6,
    t_dec: -5.4,
    t_year: 5.7,
    p_jan: 3,
    p_feb: 3.1,
    p_mar: 4.2,
    p_apr: 7,
    p_may: 9.6,
    p_jun: 13,
    p_jul: 14.9,
    p_aug: 14,
    p_sep: 10.5,
    p_oct: 7.4,
    p_nov: 5.3,
    p_dec: 3.9,
    p_year: 7.4,
    z0: 135,
    t0: -5.5,
  },
  {
    index: 'pe',
    subject: 'Пермская область',
    city: 'Пермь',
    zona: 2,
    t_ot: -5.5,
    z_ot: 225,
    t_h: -35,
    f: 78,
    t_jan: -14,
    t_feb: -12.3,
    t_mar: -4.5,
    t_apr: 3.6,
    t_may: 10.9,
    t_jun: 16,
    t_jul: 18.2,
    t_aug: 15.2,
    t_sep: 9.6,
    t_oct: 2.3,
    t_nov: -5.3,
    t_dec: -11.2,
    t_year: 2.4,
    p_jan: 2.1,
    p_feb: 2.1,
    p_mar: 3.4,
    p_apr: 5.3,
    p_may: 7.8,
    p_jun: 12.1,
    p_jul: 14.8,
    p_aug: 13,
    p_sep: 9.5,
    p_oct: 6.1,
    p_nov: 3.8,
    p_dec: 2.6,
    p_year: 7.7,
    z0: 161,
    t0: -9.3,
  },
  {
    index: 'pr',
    subject: 'Приморский край',
    city: 'Владивосток',
    zona: 1,
    t_ot: -4.3,
    z_ot: 199,
    t_h: -22,
    f: 58,
    t_jan: -12.6,
    t_feb: -9.1,
    t_mar: -2.1,
    t_apr: 4.8,
    t_may: 9.7,
    t_jun: 13.4,
    t_jul: 17.7,
    t_aug: 19.8,
    t_sep: 15.8,
    t_oct: 8.8,
    t_nov: -0.9,
    t_dec: -9.5,
    t_year: 4.6,
    p_jan: 1.5,
    p_feb: 2,
    p_mar: 3.2,
    p_apr: 5.6,
    p_may: 8.8,
    p_jun: 13.4,
    p_jul: 18.4,
    p_aug: 19.9,
    p_sep: 14,
    p_oct: 7.9,
    p_nov: 3.9,
    p_dec: 2,
    p_year: 8.4,
    z0: 136,
    t0: -8.2,
  },
  {
    index: 'ps',
    subject: 'Псковская область',
    city: 'Псков',
    zona: 2,
    t_ot: -1.3,
    z_ot: 208,
    t_h: -26,
    f: 83,
    t_jan: -6.3,
    t_feb: -6.2,
    t_mar: -1.3,
    t_apr: 5.5,
    t_may: 12,
    t_jun: 15.9,
    t_jul: 17.8,
    t_aug: 16.2,
    t_sep: 10.9,
    t_oct: 5.6,
    t_nov: 0.1,
    t_dec: -4.1,
    t_year: 5.5,
    p_jan: 3.4,
    p_feb: 3.3,
    p_mar: 4.1,
    p_apr: 6.2,
    p_may: 8.8,
    p_jun: 12.3,
    p_jul: 14.6,
    p_aug: 14.1,
    p_sep: 10.9,
    p_oct: 7.8,
    p_nov: 5.8,
    p_dec: 4.3,
    p_year: 8,
    z0: 130,
    t0: -4.6,
  },
  {
    index: 'pz',
    subject: 'Пензенская область',
    city: 'Пенза',
    zona: 3,
    t_ot: -4.1,
    z_ot: 200,
    t_h: -27,
    f: 83,
    t_jan: -9.8,
    t_feb: -9.7,
    t_mar: -3.7,
    t_apr: 6.8,
    t_may: 14.2,
    t_jun: 18,
    t_jul: 19.8,
    t_aug: 18,
    t_sep: 12.2,
    t_oct: 5.1,
    t_nov: -2,
    t_dec: -7.8,
    t_year: 5.1,
    p_jan: 2.4,
    p_feb: 2.5,
    p_mar: 3.7,
    p_apr: 6.3,
    p_may: 8.9,
    p_jun: 12.4,
    p_jul: 14.8,
    p_aug: 13.5,
    p_sep: 9.8,
    p_oct: 6.6,
    p_nov: 4.6,
    p_dec: 3.2,
    p_year: 6.9,
    z0: 143,
    t0: -7.3,
  },
  {
    index: 'ro',
    subject: 'Ростовская область',
    city: 'Ростов-на-Дону',
    zona: 3,
    t_ot: -0.1,
    z_ot: 166,
    t_h: -19,
    f: 82,
    t_jan: -3.8,
    t_feb: -2.9,
    t_mar: 2.2,
    t_apr: 10.8,
    t_may: 16.8,
    t_jun: 20.8,
    t_jul: 23.2,
    t_aug: 22.3,
    t_sep: 16.6,
    t_oct: 9.6,
    t_nov: 3.3,
    t_dec: -1.5,
    t_year: 9.8,
    p_jan: 4,
    p_feb: 4.3,
    p_mar: 5.4,
    p_apr: 8.1,
    p_may: 11.1,
    p_jun: 14.5,
    p_jul: 16,
    p_aug: 14.9,
    p_sep: 11.6,
    p_oct: 8.7,
    p_nov: 6.9,
    p_dec: 5.2,
    p_year: 9.2,
    z0: 97,
    t0: -2.8,
  },
  {
    index: 'ro',
    subject: 'Ростовская область',
    city: 'Таганрог',
    zona: 3,
    t_ot: 0,
    z_ot: 165,
    t_h: -18,
    f: 85,
    t_jan: -3.5,
    t_feb: -3,
    t_mar: 2.1,
    t_apr: 10.7,
    t_may: 17.1,
    t_jun: 21.2,
    t_jul: 23.6,
    t_aug: 22.8,
    t_sep: 17.2,
    t_oct: 10.2,
    t_nov: 3.5,
    t_dec: -1.2,
    t_year: 10.1,
    p_jan: 4.1,
    p_feb: 4.3,
    p_mar: 5.6,
    p_apr: 8.8,
    p_may: 12.6,
    p_jun: 16.1,
    p_jul: 17.6,
    p_aug: 16.8,
    p_sep: 13,
    p_oct: 9.3,
    p_nov: 7,
    p_dec: 5.3,
    p_year: 10,
    z0: 97,
    t0: -2.6,
  },
  {
    index: 'rz',
    subject: 'Рязанская область',
    city: 'Рязань',
    zona: 2,
    t_ot: -3.1,
    z_ot: 202,
    t_h: -26,
    f: 84,
    t_jan: -9,
    t_feb: -8.6,
    t_mar: -2.6,
    t_apr: 6.5,
    t_may: 13.8,
    t_jun: 17.2,
    t_jul: 19.1,
    t_aug: 17.3,
    t_sep: 11.6,
    t_oct: 5.2,
    t_nov: -1.3,
    t_dec: -6.3,
    t_year: 5.2,
    p_jan: 2.5,
    p_feb: 2.7,
    p_mar: 3.8,
    p_apr: 6.6,
    p_may: 9.4,
    p_jun: 12.6,
    p_jul: 14.9,
    p_aug: 14.1,
    p_sep: 10.3,
    p_oct: 7.1,
    p_nov: 4.8,
    p_dec: 3.6,
    p_year: 7.7,
    z0: 145,
    t0: -6.8,
  },
  {
    index: 'sa',
    subject: 'Республика Саха (Якутия)',
    city: 'Якутск',
    zona: 3,
    t_ot: -20.9,
    z_ot: 252,
    t_h: -52,
    f: 76,
    t_jan: -39.2,
    t_feb: -34.7,
    t_mar: -20.5,
    t_apr: -4.8,
    t_may: 7.5,
    t_jun: 16.2,
    t_jul: 19.3,
    t_aug: 15.2,
    t_sep: 5.9,
    t_oct: -7.9,
    t_nov: -27.8,
    t_dec: -37.8,
    t_year: -9.1,
    p_jan: 0.2,
    p_feb: 0.3,
    p_mar: 1,
    p_apr: 2.7,
    p_may: 5.3,
    p_jun: 10.1,
    p_jul: 13.2,
    p_aug: 11.3,
    p_sep: 6.6,
    p_oct: 2.9,
    p_nov: 0.7,
    p_dec: 0.2,
    p_year: 4.5,
    z0: 206,
    t0: -26,
  },
  {
    index: 'sh',
    subject: 'Сахалинская область',
    city: 'Южно-Сахалинск',
    zona: 1,
    t_ot: -4.3,
    z_ot: 228,
    t_h: -21,
    f: 80,
    t_jan: -12.8,
    t_feb: -12.1,
    t_mar: -5.8,
    t_apr: 1.7,
    t_may: 7,
    t_jun: 11.7,
    t_jul: 15.7,
    t_aug: 17.1,
    t_sep: 13.1,
    t_oct: 6.4,
    t_nov: -1.4,
    t_dec: -8.7,
    t_year: 2.7,
    p_jan: 2.2,
    p_feb: 2.2,
    p_mar: 3.2,
    p_apr: 5.2,
    p_may: 7.5,
    p_jun: 11.3,
    p_jul: 15.3,
    p_aug: 16.9,
    p_sep: 12.7,
    p_oct: 7.8,
    p_nov: 4.6,
    p_dec: 2.9,
    p_year: 6.6,
    z0: 163,
    t0: -8.5,
  },
  {
    index: 'sm',
    subject: 'Смоленская область',
    city: 'Смоленск',
    zona: 2,
    t_ot: -2,
    z_ot: 209,
    t_h: -25,
    f: 86,
    t_jan: -7.5,
    t_feb: -6.9,
    t_mar: -1.8,
    t_apr: 5.9,
    t_may: 12.4,
    t_jun: 15.8,
    t_jul: 17.4,
    t_aug: 16,
    t_sep: 10.7,
    t_oct: 5,
    t_nov: -0.8,
    t_dec: -5.2,
    t_year: 5.1,
    p_jan: 3,
    p_feb: 3.1,
    p_mar: 4.1,
    p_apr: 6.5,
    p_may: 9.7,
    p_jun: 12.9,
    p_jul: 14.9,
    p_aug: 14.2,
    p_sep: 10.7,
    p_oct: 7.5,
    p_nov: 5.5,
    p_dec: 4,
    p_year: 8.9,
    z0: 136,
    t0: -5.3,
  },
  {
    index: 'so',
    subject: 'Республика Северная Осетия',
    city: 'Владикавказ',
    zona: 2,
    t_ot: 0.7,
    z_ot: 169,
    t_h: -13,
    f: 81,
    t_jan: -2.9,
    t_feb: -2,
    t_mar: 2.7,
    t_apr: 9.4,
    t_may: 14.1,
    t_jun: 17.7,
    t_jul: 20.3,
    t_aug: 19.8,
    t_sep: 15.2,
    t_oct: 9.5,
    t_nov: 3.7,
    t_dec: -0.9,
    t_year: 8.9,
    p_jan: 3.8,
    p_feb: 4.3,
    p_mar: 5.6,
    p_apr: 8.3,
    p_may: 12.3,
    p_jun: 15,
    p_jul: 17.3,
    p_aug: 16.9,
    p_sep: 13.2,
    p_oct: 9.1,
    p_nov: 6.4,
    p_dec: 4.6,
    p_year: 9.7,
    z0: 90,
    t0: -2.1,
  },
  {
    index: 'sr',
    subject: 'Саратовская область',
    city: 'Балашов',
    zona: 3,
    t_ot: -4.2,
    z_ot: 199,
    t_h: -29,
    f: 84,
    t_jan: -10.6,
    t_feb: -10.4,
    t_mar: -4.6,
    t_apr: 6.3,
    t_may: 14.5,
    t_jun: 18.6,
    t_jul: 20.6,
    t_aug: 19.2,
    t_sep: 12.9,
    t_oct: 5.2,
    t_nov: -1.5,
    t_dec: -7.6,
    t_year: 5.2,
    p_jan: 2.7,
    p_feb: 2.8,
    p_mar: 4,
    p_apr: 6.8,
    p_may: 9.2,
    p_jun: 12.5,
    p_jul: 14.6,
    p_aug: 13.3,
    p_sep: 9.8,
    p_oct: 6.9,
    p_nov: 5.1,
    p_dec: 3.6,
    p_year: 7.7,
    z0: 142,
    t0: -7.4,
  },
  {
    index: 'sr',
    subject: 'Саратовская область',
    city: 'Саратов',
    zona: 3,
    t_ot: -3.5,
    z_ot: 188,
    t_h: -25,
    f: 80,
    t_jan: -8.7,
    t_feb: -8.4,
    t_mar: -2.5,
    t_apr: 8.4,
    t_may: 15.9,
    t_jun: 20.2,
    t_jul: 22.3,
    t_aug: 20.6,
    t_sep: 14.3,
    t_oct: 6.7,
    t_nov: -0.6,
    t_dec: -6.4,
    t_year: 6.8,
    p_jan: 2.6,
    p_feb: 2.7,
    p_mar: 4,
    p_apr: 6.6,
    p_may: 9.1,
    p_jun: 12.2,
    p_jul: 13.9,
    p_aug: 13.2,
    p_sep: 9.7,
    p_oct: 6.7,
    p_nov: 4.7,
    p_dec: 3.6,
    p_year: 7.6,
    z0: 134,
    t0: -6.5,
  },
  {
    index: 'ss',
    subject: 'Самарская область',
    city: 'Самара',
    zona: 3,
    t_ot: -4.7,
    z_ot: 197,
    t_h: -30,
    f: 83,
    t_jan: -11.2,
    t_feb: -10.6,
    t_mar: -3.7,
    t_apr: 7.1,
    t_may: 15.2,
    t_jun: 19.3,
    t_jul: 21.2,
    t_aug: 19.3,
    t_sep: 13.3,
    t_oct: 5.4,
    t_nov: -2.1,
    t_dec: -8.3,
    t_year: 5.4,
    p_jan: 2.2,
    p_feb: 2.2,
    p_mar: 3.6,
    p_apr: 6.2,
    p_may: 8.5,
    p_jun: 12.2,
    p_jul: 14.7,
    p_aug: 13.1,
    p_sep: 9.5,
    p_oct: 6.3,
    p_nov: 4.5,
    p_dec: 3,
    p_year: 7.2,
    z0: 149,
    t0: -8.5,
  },
  {
    index: 'st',
    subject: 'Ставропольский край',
    city: 'Кисловодск',
    zona: 2,
    t_ot: 0.4,
    z_ot: 179,
    t_h: -16,
    f: 70,
    t_jan: -3.3,
    t_feb: -2.4,
    t_mar: 1.5,
    t_apr: 8,
    t_may: 13,
    t_jun: 16.2,
    t_jul: 18.6,
    t_aug: 18.2,
    t_sep: 13.9,
    t_oct: 8.3,
    t_nov: 3.2,
    t_dec: -1.1,
    t_year: 7.7,
    p_jan: 4,
    p_feb: 4.3,
    p_mar: 5.4,
    p_apr: 7.9,
    p_may: 11.4,
    p_jun: 14.3,
    p_jul: 16,
    p_aug: 15.5,
    p_sep: 12.5,
    p_oct: 9,
    p_nov: 6.7,
    p_dec: 4.8,
    p_year: 7.7,
    z0: 91,
    t0: -2.4,
  },
  {
    index: 'st',
    subject: 'Ставропольский край',
    city: 'Пятигорск',
    zona: 2,
    t_ot: 0.2,
    z_ot: 175,
    t_h: -20,
    f: 83,
    t_jan: -4.2,
    t_feb: -3,
    t_mar: 1.1,
    t_apr: 8.9,
    t_may: 14.6,
    t_jun: 18.3,
    t_jul: 21.1,
    t_aug: 20.5,
    t_sep: 15.5,
    t_oct: 8.9,
    t_nov: 3.2,
    t_dec: -1.4,
    t_year: 8.6,
    p_jan: 4,
    p_feb: 4.3,
    p_mar: 5.4,
    p_apr: 7.9,
    p_may: 11.4,
    p_jun: 14.3,
    p_jul: 16,
    p_aug: 15.5,
    p_sep: 12.5,
    p_oct: 9,
    p_nov: 6.7,
    p_dec: 4.8,
    p_year: 0,
    z0: 97,
    t0: -2.7,
  },
  {
    index: 'st',
    subject: 'Ставропольский край',
    city: 'Ставрополь',
    zona: 2,
    t_ot: 0.5,
    z_ot: 168,
    t_h: -18,
    f: 84,
    t_jan: -2.9,
    t_feb: -2.4,
    t_mar: 2.2,
    t_apr: 9.8,
    t_may: 15,
    t_jun: 19,
    t_jul: 22.1,
    t_aug: 21.4,
    t_sep: 16.2,
    t_oct: 9.8,
    t_nov: 3.7,
    t_dec: -0.7,
    t_year: 9.4,
    p_jan: 4.2,
    p_feb: 4.3,
    p_mar: 5.3,
    p_apr: 7.6,
    p_may: 11.2,
    p_jun: 13.5,
    p_jul: 14.8,
    p_aug: 14.1,
    p_sep: 11.5,
    p_oct: 8.7,
    p_nov: 6.6,
    p_dec: 5,
    p_year: 9.3,
    z0: 91,
    t0: -2.2,
  },
  {
    index: 'sv',
    subject: 'Свердловская область',
    city: 'Екатеринбург',
    zona: 3,
    t_ot: -5.4,
    z_ot: 221,
    t_h: -32,
    f: 76,
    t_jan: -13.7,
    t_feb: -11.9,
    t_mar: -4.1,
    t_apr: 4.5,
    t_may: 11.4,
    t_jun: 16.6,
    t_jul: 18.6,
    t_aug: 15.6,
    t_sep: 9.9,
    t_oct: 2.5,
    t_nov: -5.5,
    t_dec: -11.2,
    t_year: 2.7,
    p_jan: 2,
    p_feb: 2,
    p_mar: 3.2,
    p_apr: 5.1,
    p_may: 7.4,
    p_jun: 11.7,
    p_jul: 14.4,
    p_aug: 12.7,
    p_sep: 9,
    p_oct: 5.7,
    p_nov: 3.5,
    p_dec: 2.3,
    p_year: 8,
    z0: 158,
    t0: -9.2,
  },
  {
    index: 'ta',
    subject: 'Республика Татарстан',
    city: 'Елабуга',
    zona: 2,
    t_ot: -5.2,
    z_ot: 209,
    t_h: -32,
    f: 82,
    t_jan: -12.4,
    t_feb: -11.7,
    t_mar: -4.2,
    t_apr: 5.2,
    t_may: 13.2,
    t_jun: 17.5,
    t_jul: 19.8,
    t_aug: 17.2,
    t_sep: 11.4,
    t_oct: 3.9,
    t_nov: -3.6,
    t_dec: -10,
    t_year: 3.9,
    p_jan: 2.1,
    p_feb: 2.1,
    p_mar: 3.3,
    p_apr: 6.1,
    p_may: 8.7,
    p_jun: 12.2,
    p_jul: 14.8,
    p_aug: 13.4,
    p_sep: 9.7,
    p_oct: 6.3,
    p_nov: 4.1,
    p_dec: 2.7,
    p_year: 7.1,
    z0: 152,
    t0: -8.7,
  },
  {
    index: 'ta',
    subject: 'Республика Татарстан',
    city: 'Казань',
    zona: 2,
    t_ot: -4.8,
    z_ot: 208,
    t_h: -31,
    f: 83,
    t_jan: -11.6,
    t_feb: -10.9,
    t_mar: -4.3,
    t_apr: 5.3,
    t_may: 13.2,
    t_jun: 17.6,
    t_jul: 19.7,
    t_aug: 17.4,
    t_sep: 11.5,
    t_oct: 4.2,
    t_nov: -3.2,
    t_dec: -8.9,
    t_year: 4.2,
    p_jan: 2.1,
    p_feb: 2.2,
    p_mar: 3.4,
    p_apr: 6.1,
    p_may: 8.8,
    p_jun: 12.3,
    p_jul: 15,
    p_aug: 13.7,
    p_sep: 9.9,
    p_oct: 6.4,
    p_nov: 4.4,
    p_dec: 2.8,
    p_year: 7.3,
    z0: 151,
    t0: -8.1,
  },
  {
    index: 'tb',
    subject: 'Тамбовская область',
    city: 'Тамбов',
    zona: 3,
    t_ot: -3.2,
    z_ot: 197,
    t_h: -27,
    f: 84,
    t_jan: -8.8,
    t_feb: -8.4,
    t_mar: -2.7,
    t_apr: 7.5,
    t_may: 14.9,
    t_jun: 18.3,
    t_jul: 20.1,
    t_aug: 18.6,
    t_sep: 12.7,
    t_oct: 5.6,
    t_nov: -1,
    t_dec: -6.2,
    t_year: 5.9,
    p_jan: 2.7,
    p_feb: 2.8,
    p_mar: 3.9,
    p_apr: 6.7,
    p_may: 9.3,
    p_jun: 12.6,
    p_jul: 15,
    p_aug: 13.9,
    p_sep: 10.2,
    p_oct: 7,
    p_nov: 5,
    p_dec: 3.6,
    p_year: 7.6,
    z0: 140,
    t0: -7,
  },
  {
    index: 'tl',
    subject: 'Тульская область',
    city: ' Тула',
    zona: 2,
    t_ot: -2.6,
    z_ot: 203,
    t_h: -26,
    f: 83,
    t_jan: -8.2,
    t_feb: -7.9,
    t_mar: -2.1,
    t_apr: 6.6,
    t_may: 13.7,
    t_jun: 17.1,
    t_jul: 18.9,
    t_aug: 17.2,
    t_sep: 11.4,
    t_oct: 5.3,
    t_nov: -0.9,
    t_dec: -5.6,
    t_year: 5.5,
    p_jan: 2.8,
    p_feb: 2.9,
    p_mar: 4,
    p_apr: 6.7,
    p_may: 9.3,
    p_jun: 12.5,
    p_jul: 14.9,
    p_aug: 14,
    p_sep: 10.3,
    p_oct: 7.1,
    p_nov: 5.1,
    p_dec: 3.7,
    p_year: 6.7,
    z0: 140,
    t0: -6.4,
  },
  {
    index: 'tm',
    subject: 'Томская область',
    city: 'Томск',
    zona: 2,
    t_ot: -7.8,
    z_ot: 234,
    t_h: -39,
    f: 78,
    t_jan: -18.1,
    t_feb: -16,
    t_mar: -7.4,
    t_apr: 1.6,
    t_may: 9.6,
    t_jun: 16,
    t_jul: 18.7,
    t_aug: 15.4,
    t_sep: 9,
    t_oct: 1.4,
    t_nov: -8.4,
    t_dec: -15.4,
    t_year: 0.5,
    p_jan: 1.5,
    p_feb: 1.7,
    p_mar: 2.8,
    p_apr: 4.5,
    p_may: 7.2,
    p_jun: 12.4,
    p_jul: 15.8,
    p_aug: 13.5,
    p_sep: 9,
    p_oct: 5.5,
    p_nov: 3.1,
    p_dec: 1.9,
    p_year: 7.8,
    z0: 176,
    t0: -11.8,
  },
  {
    index: 'tr',
    subject: 'Тверская область',
    city: 'Тверь',
    zona: 2,
    t_ot: -2.6,
    z_ot: 212,
    t_h: -29,
    f: 86,
    t_jan: -8.8,
    t_feb: -7.8,
    t_mar: -2.2,
    t_apr: 5.4,
    t_may: 12.3,
    t_jun: 16.3,
    t_jul: 18.3,
    t_aug: 16.3,
    t_sep: 10.5,
    t_oct: 4.5,
    t_nov: -1.4,
    t_dec: -6.1,
    t_year: 4.8,
    p_jan: 2.8,
    p_feb: 2.9,
    p_mar: 3.8,
    p_apr: 6.1,
    p_may: 9,
    p_jun: 12.4,
    p_jul: 14.7,
    p_aug: 13.9,
    p_sep: 10.3,
    p_oct: 7.1,
    p_nov: 5,
    p_dec: 3.7,
    p_year: 6.6,
    z0: 146,
    t0: -6.4,
  },
  {
    index: 'tu',
    subject: 'Тюменская область',
    city: 'Тюмень',
    zona: 3,
    t_ot: -6.8,
    z_ot: 223,
    t_h: -35,
    f: 78,
    t_jan: -16.1,
    t_feb: -14.1,
    t_mar: -5.7,
    t_apr: 3.8,
    t_may: 11.1,
    t_jun: 16.6,
    t_jul: 18.5,
    t_aug: 15.5,
    t_sep: 9.7,
    t_oct: 2.3,
    t_nov: -6.8,
    t_dec: -13.3,
    t_year: 1.8,
    p_jan: 1.8,
    p_feb: 1.8,
    p_mar: 3.1,
    p_apr: 5.1,
    p_may: 7.5,
    p_jun: 12.1,
    p_jul: 15.3,
    p_aug: 13.4,
    p_sep: 9.2,
    p_oct: 5.8,
    p_nov: 3.3,
    p_dec: 2.1,
    p_year: 0,
    z0: 163,
    t0: -10.9,
  },
  {
    index: 'tv',
    subject: 'Республика Тыва',
    city: 'Кызыл',
    zona: 3,
    t_ot: -15,
    z_ot: 225,
    t_h: -47,
    f: 73,
    t_jan: -29.3,
    t_feb: -24.4,
    t_mar: -11,
    t_apr: 4.7,
    t_may: 12.2,
    t_jun: 18.4,
    t_jul: 20.3,
    t_aug: 17.4,
    t_sep: 10.3,
    t_oct: 1.1,
    t_nov: -13.4,
    t_dec: -25.8,
    t_year: -1.6,
    p_jan: 0.5,
    p_feb: 0.8,
    p_mar: 2.2,
    p_apr: 3.9,
    p_may: 5.8,
    p_jun: 9.9,
    p_jul: 13,
    p_aug: 11.5,
    p_sep: 7.5,
    p_oct: 4.3,
    p_nov: 2,
    p_dec: 0.7,
    p_year: 5.2,
    z0: 178,
    t0: -20.1,
  },
  {
    index: 'ud',
    subject: 'Удмуртская Республика',
    city: 'Ижевск',
    zona: 2,
    t_ot: -5.6,
    z_ot: 219,
    t_h: -33,
    f: 83,
    t_jan: -13.4,
    t_feb: -12.3,
    t_mar: -5.1,
    t_apr: 3.8,
    t_may: 11.7,
    t_jun: 16.5,
    t_jul: 18.6,
    t_aug: 15.9,
    t_sep: 10.1,
    t_oct: 2.7,
    t_nov: -4.9,
    t_dec: -10.9,
    t_year: 2.7,
    p_jan: 2.1,
    p_feb: 2.1,
    p_mar: 3.2,
    p_apr: 5.7,
    p_may: 8.2,
    p_jun: 11.6,
    p_jul: 14.5,
    p_aug: 13,
    p_sep: 9.5,
    p_oct: 6,
    p_nov: 4,
    p_dec: 2.6,
    p_year: 7.6,
    z0: 160,
    t0: -9.1,
  },
  {
    index: 'ul',
    subject: 'Ульяновская область',
    city: 'Ульяновск',
    zona: 3,
    t_ot: -4.5,
    z_ot: 205,
    t_h: -33,
    f: 84,
    t_jan: -10.5,
    t_feb: -11,
    t_mar: -4.5,
    t_apr: 5.9,
    t_may: 13.9,
    t_jun: 18.2,
    t_jul: 19.9,
    t_aug: 17.8,
    t_sep: 12.2,
    t_oct: 4.6,
    t_nov: -2.6,
    t_dec: -8.2,
    t_year: 4.6,
    p_jan: 2.3,
    p_feb: 2.4,
    p_mar: 3.2,
    p_apr: 6.2,
    p_may: 8.9,
    p_jun: 12.1,
    p_jul: 14.6,
    p_aug: 13.7,
    p_sep: 9.6,
    p_oct: 6.5,
    p_nov: 4.2,
    p_dec: 3,
    p_year: 6.2,
    z0: 155,
    t0: -8.9,
  },
  {
    index: 'vl',
    subject: 'Волгоградская область',
    city: 'Волгоград',
    zona: 3,
    t_ot: -2.3,
    z_ot: 176,
    t_h: -22,
    f: 85,
    t_jan: -6.9,
    t_feb: -6.5,
    t_mar: -0.3,
    t_apr: 10,
    t_may: 16.8,
    t_jun: 21.4,
    t_jul: 23.9,
    t_aug: 22.7,
    t_sep: 16.3,
    t_oct: 8.3,
    t_nov: 1.1,
    t_dec: -4.4,
    t_year: 8.5,
    p_jan: 3,
    p_feb: 3.3,
    p_mar: 4.8,
    p_apr: 7.1,
    p_may: 9.9,
    p_jun: 12.8,
    p_jul: 14,
    p_aug: 12.8,
    p_sep: 10.2,
    p_oct: 7.4,
    p_nov: 6,
    p_dec: 4.4,
    p_year: 8,
    z0: 122,
    t0: -5.1,
  },
  {
    index: 'vm',
    subject: 'Владимирская область',
    city: 'Владимир',
    zona: 2,
    t_ot: -3.4,
    z_ot: 209,
    t_h: -27,
    f: 85,
    t_jan: -9.7,
    t_feb: -8.6,
    t_mar: -2.7,
    t_apr: 5.7,
    t_may: 12.9,
    t_jun: 16.6,
    t_jul: 18.6,
    t_aug: 16.6,
    t_sep: 10.8,
    t_oct: 4.3,
    t_nov: -2.2,
    t_dec: -7,
    t_year: 4.6,
    p_jan: 2.6,
    p_feb: 2.6,
    p_mar: 3.6,
    p_apr: 6.1,
    p_may: 9,
    p_jun: 12.4,
    p_jul: 14.6,
    p_aug: 14.3,
    p_sep: 10.2,
    p_oct: 6.8,
    p_nov: 4.7,
    p_dec: 3.5,
    p_year: 7.5,
    z0: 148,
    t0: -6.9,
  },
  {
    index: 'vn',
    subject: 'Воронежская область',
    city: 'Воронеж',
    zona: 3,
    t_ot: -2.5,
    z_ot: 190,
    t_h: -24,
    f: 82,
    t_jan: -7.5,
    t_feb: -7.2,
    t_mar: -1.4,
    t_apr: 8.2,
    t_may: 14.9,
    t_jun: 18.4,
    t_jul: 20.1,
    t_aug: 18.9,
    t_sep: 13.1,
    t_oct: 6.5,
    t_nov: -0.1,
    t_dec: -5.2,
    t_year: 6.6,
    p_jan: 2.9,
    p_feb: 3.1,
    p_mar: 4.3,
    p_apr: 7,
    p_may: 9.4,
    p_jun: 12.7,
    p_jul: 15,
    p_aug: 13.8,
    p_sep: 10.3,
    p_oct: 7.3,
    p_nov: 5.4,
    p_dec: 4,
    p_year: 7.9,
    z0: 130,
    t0: -5.5,
  },
  {
    index: 'vo',
    subject: 'Вологодская область',
    city: 'Бабаево',
    zona: 2,
    t_ot: -3.8,
    z_ot: 231,
    t_h: -31,
    f: 86,
    t_jan: -11.6,
    t_feb: -10.5,
    t_mar: -5.4,
    t_apr: 2.4,
    t_may: 9.5,
    t_jun: 14.7,
    t_jul: 16.8,
    t_aug: 14.9,
    t_sep: 9.2,
    t_oct: 2.9,
    t_nov: -2.6,
    t_dec: -8,
    t_year: 2.7,
    p_jan: 2.7,
    p_feb: 2.7,
    p_mar: 3.5,
    p_apr: 5.3,
    p_may: 7.7,
    p_jun: 11.1,
    p_jul: 13.9,
    p_aug: 13.2,
    p_sep: 9.8,
    p_oct: 6.7,
    p_nov: 4.8,
    p_dec: 3.5,
    p_year: 7.1,
    z0: 158,
    t0: -7.3,
  },
  {
    index: 'vo',
    subject: 'Вологодская область',
    city: 'Вологда',
    zona: 2,
    t_ot: -4,
    z_ot: 228,
    t_h: -32,
    f: 85,
    t_jan: -11.7,
    t_feb: -10.5,
    t_mar: -4,
    t_apr: 3.3,
    t_may: 10.4,
    t_jun: 15,
    t_jul: 17.3,
    t_aug: 14.7,
    t_sep: 9.2,
    t_oct: 3,
    t_nov: -3.4,
    t_dec: -8.7,
    t_year: 2.9,
    p_jan: 2.4,
    p_feb: 2.5,
    p_mar: 3.4,
    p_apr: 5.6,
    p_may: 8.2,
    p_jun: 12,
    p_jul: 14.6,
    p_aug: 13.8,
    p_sep: 9.8,
    p_oct: 6.6,
    p_nov: 4.5,
    p_dec: 3.2,
    p_year: 7.2,
    z0: 157,
    t0: -7.6,
  },
  {
    index: 'ya',
    subject: 'Ямало-Ненецкий автономный округ',
    city: 'Новый Уренгой',
    zona: 2,
    t_ot: -13.1,
    z_ot: 283,
    t_h: -48,
    f: 75,
    t_jan: -26.5,
    t_feb: -24.9,
    t_mar: -16.7,
    t_apr: -10,
    t_may: -1.6,
    t_jun: 9.7,
    t_jul: 15.5,
    t_aug: 11.7,
    t_sep: 5,
    t_oct: -5.5,
    t_nov: -17.7,
    t_dec: -22.8,
    t_year: -7,
    p_jan: 0.9,
    p_feb: 1,
    p_mar: 1.7,
    p_apr: 2.6,
    p_may: 4.3,
    p_jun: 8.2,
    p_jul: 11.9,
    p_aug: 10.7,
    p_sep: 7.4,
    p_oct: 4.1,
    p_nov: 1.8,
    p_dec: 1.2,
    p_year: 4.7,
    z0: 236,
    t0: -16.8,
  },
  {
    index: 'ya',
    subject: 'Ямало-Ненецкий автономный округ',
    city: 'Салехард',
    zona: 3,
    t_ot: -11.3,
    z_ot: 286,
    t_h: -43,
    f: 80,
    t_jan: -24.2,
    t_feb: -23.2,
    t_mar: -15.2,
    t_apr: -8.8,
    t_may: -0.8,
    t_jun: 9,
    t_jul: 14.4,
    t_aug: 11.1,
    t_sep: 5.3,
    t_oct: -3.8,
    t_nov: -15.1,
    t_dec: -20.4,
    t_year: -6,
    p_jan: 1.1,
    p_feb: 1.1,
    p_mar: 1.9,
    p_apr: 2.9,
    p_may: 4.7,
    p_jun: 8.4,
    p_jul: 12.1,
    p_aug: 10.6,
    p_sep: 7.5,
    p_oct: 4.5,
    p_nov: 2,
    p_dec: 1.4,
    p_year: 6.9,
    z0: 228,
    t0: -15.5,
  },
  {
    index: 'yr',
    subject: 'Ярославская область',
    city: 'Ярославль',
    zona: 2,
    t_ot: -3.6,
    z_ot: 217,
    t_h: -31,
    f: 82,
    t_jan: -10.3,
    t_feb: -9.6,
    t_mar: -3.5,
    t_apr: 4.7,
    t_may: 11.7,
    t_jun: 16,
    t_jul: 18.1,
    t_aug: 15.8,
    t_sep: 10.1,
    t_oct: 3.9,
    t_nov: -2.6,
    t_dec: -7.6,
    t_year: 3.9,
    p_jan: 2.5,
    p_feb: 2.6,
    p_mar: 3.6,
    p_apr: 5.9,
    p_may: 8.8,
    p_jun: 12.3,
    p_jul: 14.8,
    p_aug: 14,
    p_sep: 10.1,
    p_oct: 6.8,
    p_nov: 4.6,
    p_dec: 3.3,
    p_year: 7.4,
    z0: 152,
    t0: -7.8,
  },
  {
    index: 'zb',
    subject: 'Забайкальский край',
    city: 'Чита',
    zona: 3,
    t_ot: -11.3,
    z_ot: 238,
    t_h: -37,
    f: 76,
    t_jan: -25.5,
    t_feb: -20.2,
    t_mar: -9.6,
    t_apr: 1.2,
    t_may: 9.4,
    t_jun: 16.3,
    t_jul: 18.7,
    t_aug: 15.8,
    t_sep: 8.4,
    t_oct: -0.8,
    t_nov: -13,
    t_dec: -22.6,
    t_year: -1.8,
    p_jan: 0.7,
    p_feb: 1,
    p_mar: 1.8,
    p_apr: 3,
    p_may: 5,
    p_jun: 9.9,
    p_jul: 14,
    p_aug: 12.6,
    p_sep: 7.2,
    p_oct: 3.6,
    p_nov: 1.8,
    p_dec: 0.9,
    p_year: 5.1,
    z0: 182,
    t0: -16.1,
  },
];
