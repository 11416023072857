module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TMMJVM6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":68282989},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"fdb24198bcf5fee3367568ab2e52a469"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
