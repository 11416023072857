import {StoreonModule} from 'storeon';
import {IEvents, IState} from '~src/store/index';
import {api} from '~src/api';
import {delay} from '~src/utils';
import moment from 'moment';
import {toCamelCaseList, toSnakeCase} from '~src/utils/query';

export interface IArticle {
  id?: number;
  creationTime: string;
  title: string;
  previewUrl: string;
  description: string;
  text: string;
}

export interface IArticlesStore {
  isLoading: boolean;
  isSending: boolean;
  isSent: boolean;
  values: IArticle[];
  count: number;
  error: string;
}

export const articlesModule: StoreonModule<IState, IEvents> = store => {
  store.on('articles/set-articles', (state, articles) => {
    const sortedArticlesByDate = articles.items.sort((articleA: IArticle, articleB: IArticle) => {
      return moment(articleB.creationTime).diff(articleA.creationTime); // Sort by date from newest to oldest (B - A)
    });

    const updatedValues =
      articles.strategy === 'push'
        ? [...state.articles.values, ...toCamelCaseList(sortedArticlesByDate)]
        : [...sortedArticlesByDate];
    return {
      articles: {...state.articles, values: updatedValues},
    };
  });

  store.on('articles/fetch-articles', async (state, params) => {
    try {
      const response = await api.get<{items: IArticle[]}>('articles', {});
      await delay(500);
      await store.dispatch('articles/set-articles', {
        items: toCamelCaseList(response.data.items),
        strategy: params.strategy,
      });
    } catch (error) {
      store.dispatch('articles/error', error);
    }
  });

  store.on('articles/send-article', async (state, sendArticlesPayload) => {
    try {
      store.dispatch('articles/set-is-sending', true);
      await delay(500);
      await api.post('articles', toSnakeCase(sendArticlesPayload));
      store.dispatch('articles/set-is-sent');
    } catch (error) {
      store.dispatch('articles/error', error);
    } finally {
      store.dispatch('articles/set-is-sending', false);
      await delay(500);
      const response = await api.get<{items: IArticle[]}>('articles', {});
      await delay(500);
      await store.dispatch('articles/set-articles', {
        items: toCamelCaseList(response.data.items),
        strategy: 'overwrite',
      });
    }
  });

  store.on('articles/patch-article', async (state, patchArticlesPayload) => {
    try {
      store.dispatch('articles/set-is-sending', true);
      await delay(500);
      await api.patch('articles', toSnakeCase(patchArticlesPayload));
      store.dispatch('articles/set-is-sent');
    } catch (error) {
      store.dispatch('articles/error', error);
    } finally {
      store.dispatch('articles/set-is-sending', false);
      await delay(500);
      const response = await api.get<{items: IArticle[]}>('articles', {});
      await delay(500);
      await store.dispatch('articles/set-articles', {
        items: toCamelCaseList(response.data.items),
        strategy: 'overwrite',
      });
    }
  });

  store.on('articles/delete-article', async (_, payload) => {
    try {
      await api.delete('articles', {data: toSnakeCase(payload)});
    } catch (error) {
      store.dispatch('comments/error', error);
    } finally {
      await delay(500);
      const response = await api.get<{items: IArticle[]}>('articles', {});
      await delay(500);
      await store.dispatch('articles/set-articles', {
        items: toCamelCaseList(response.data.items),
        strategy: 'overwrite',
      });
    }
  });

  // store.on('articles/upload-image', async (state, uploadImagePayload) => {
  //   try {
  //     store.dispatch('articles/set-is-sending', true);
  //     await delay(500);
  //     await api.post('articles/upload', uploadImagePayload);
  //     store.dispatch('articles/set-is-sent');
  //   } catch (error) {
  //     store.dispatch('articles/error', error);
  //   } finally {
  //     store.dispatch('articles/set-is-sending', false);
  //   }
  // });

  store.on('articles/upload-image', async (state, uploadImagePayload) => {
    try {
      store.dispatch('articles/set-is-sending', true);
      await delay(500);
      await api.post('articles/upload', uploadImagePayload);
      store.dispatch('articles/set-is-sent');
    } catch (error) {
      store.dispatch('articles/error', error);
    } finally {
      store.dispatch('articles/set-is-sending', false);
    }
  });

  store.on('articles/set-is-sending', ({articles}, value) => ({articles: {...articles, isSending: value}}));
  store.on('articles/set-is-sent', ({articles}) => ({articles: {...articles, isSent: true}}));
  store.on('articles/error', ({articles}, error) => ({articles: {...articles, error}}));
};
