export const calculationService = {
  1: {
    conclusion1: {
      success: {
        type: 'success' as 'success',
        symbol: '>',
        description: 'Ограждающая конструкция удовлетворяет санитарно-гигиеническим нормам по тепловой защите.',
      },
      error: {
        type: 'error' as 'error',
        symbol: '<',
        description: 'Ограждающая конструкция не удовлетворяет санитарно-гигиеническим нормам по тепловой защите.',
      },
    },
    conclusion2: {
      success: {
        type: 'success' as 'success',
        symbol: '>',
        description: 'Условие по теплозащите ограждающей конструкции выполняется',
      },
      warning: {
        type: 'warning' as 'warning',
        symbol: '>',
        description:
          'Условие по теплозащите ограждающей конструкции выполняется. Обращаем ваше внимание, что ' +
          'сопротивление теплоизоляции превышает Rт в {value} раза, что экономически может быть не оправдано',
      },
      error: {
        type: 'error' as 'error',
        symbol: '<',
        description: 'Условие по теплозащите ограждающей конструкции не выполняется',
      },
    },
    conclusion5: {
      success: {
        type: 'success' as 'success',
        symbol: '>',
        description: 'Слой ограждающей конструкции удовлетворяет нормам по защите от переувлажнения',
      },
      error: {
        type: 'error' as 'error',
        symbol: '<',
        description: 'Слой ограждающей конструкции не удовлетворяет нормам по защите от переувлажнения',
      },
    },
    conclusion6: {
      success: {
        type: 'success' as 'success',
        symbol: '>',
        description: 'Влажностный режим стены в зоне стыковых швов экранов удовлетворителен',
      },
      error: {
        type: 'error' as 'error',
        symbol: '<',
        description: 'Влажностный режим стены в зоне стыковых швов экранов неудовлетворителен',
      },
    },
    conclusion7: {
      success: {
        type: 'success' as 'success',
        symbol: '<',
        description: 'Конструкция с навесным вентилируемым фасадам отвечает требованиям влажностного режима',
      },
      error: {
        type: 'error' as 'error',
        symbol: '>',
        description: 'Требования не удовлетворены – следует пересмотреть конструкцию ограждения',
      },
    },
    conclusion8: {
      success: {
        type: 'success' as 'success',
        symbol: '<',
        description: 'Конструкция с навесным вентилируемым фасадам отвечает требованиям влажностного режима',
      },
      error: {
        type: 'error' as 'error',
        symbol: '>',
        description: 'Требования не удовлетворены – следует пересмотреть конструкцию ограждения',
      },
    },
  },
};
