import {admin} from './admin';
import {application} from '~src/locales/application';
import {components} from '~src/locales/components';
import {calculationService} from '~src/locales/calculationService';

export default {
  pages: {
    ...application,
    admin,
  },
  components,
  services: {
    calculationService,
  },
  maps: {
    userRoles: {
      manager: 'Менеджер',
      admin: 'Администратор',
    },
    calculationType: {
      1: 'Тепловая защита',
      2: 'Расчёт материалов',
    },
    comments: ['Ждёт модерации', 'Опубликован', 'Удалён'],
    sorter: {
      ascend: 'ASC' as 'ASC',
      descend: 'DESC' as 'DESC',
    },
    logo: {
      logoText: 'расчетстен.рф',
    },
  },
};
