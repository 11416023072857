import {StoreonModule} from 'storeon';
import qs from 'qs';
import {api} from '~src/api';
import {toSnakeCase} from '~src/utils/query';
import omit from 'ramda/es/omit';
import {delay} from '~src/utils';
import {navigate} from 'gatsby';
import {Routes} from '~src/utils/routes';
import {IEvents, IState} from '~src/store/index';
import {windowGlobal} from '~src/utils/dom';

export interface IAuthStore {
  showReset: boolean;
  isSending: boolean;
  isSent: boolean;
  isReset: boolean;
  error: string;
  isLoadingRecoveryEndPassword: boolean;
  isLoadedRecoveryEndPassword: boolean;
  recoveryEndError: string;
}

export const authModule: StoreonModule<IState, IEvents> = store => {
  store.on('auth/set-reset-status', ({auth}) => ({auth: {...auth, isReset: true}}));
  store.on('auth/sign-in-loading', ({auth}, value) => ({auth: {...auth, isSent: value}}));
  store.on('auth/recovery-start-loading', ({auth}, value) => ({auth: {...auth, isSent: value}}));
  store.on('auth/recovery-start-loaded', ({auth}) => ({auth: {...auth, isSent: true}}));
  store.on('auth/recovery-end-loading', ({auth}, value) => ({auth: {...auth, isLoadingRecoveryEndPassword: value}}));
  store.on('auth/recovery-end-loaded', ({auth}) => ({auth: {...auth, isLoadedRecoveryEndPassword: true}}));
  store.on('auth/recovery-end-error', ({auth}, error) => ({auth: {...auth, recoveryEndError: error}}));
  store.on('auth/error', ({auth}, error) => ({auth: {...auth, error}}));
  store.on('auth/sign-in', async (state, payload) => {
    try {
      store.dispatch('auth/error', '');
      store.dispatch('auth/sign-in-loading', true);
      const response = await api.post<{access_token: string}>(
        'auth/sign-in',
        toSnakeCase(omit(['withRemember'], payload)),
      );
      if (payload.withRemember) {
        windowGlobal?.localStorage.setItem('accessToken', response.data.access_token);
      } else {
        windowGlobal?.sessionStorage.setItem('accessToken', response.data.access_token);
      }
      await delay(500);
      api.defaults.headers = {...api.defaults.headers, Authorization: `Bearer ${response.data.access_token}`};
      navigate(Routes.materials);
    } catch (error) {
      store.dispatch('auth/error', error.response.data.message);
    } finally {
      store.dispatch('auth/sign-in-loading', false);
    }
  });
  store.on('auth/recovery-start', async (state, payload) => {
    try {
      store.dispatch('auth/recovery-start-loading', true);
      await api.post('auth/recovery-start', toSnakeCase(payload));
      await delay(500);
      store.dispatch('auth/set-reset-status', true);
    } catch (error) {
      store.dispatch('auth/error', error);
    } finally {
      store.dispatch('auth/recovery-start-loading', false);
    }
  });
  store.on('auth/recovery-end', async (state, payload) => {
    try {
      store.dispatch('auth/recovery-end-loading', true);
      const parsedQuery = qs.parse(windowGlobal?.location.search.slice(1));
      await api.post('auth/recovery-end', toSnakeCase({...payload, recoveryLinkId: parsedQuery.id}));
      await delay(500);
      store.dispatch('auth/recovery-end-loaded', true);
    } catch (error) {
      store.dispatch('auth/recovery-end-error', error.response.data.message);
    } finally {
      store.dispatch('auth/recovery-end-loading', false);
    }
  });
  store.on('auth/sing-out', async () => {
    try {
      windowGlobal?.localStorage.removeItem('accessToken');
      windowGlobal?.sessionStorage.removeItem('accessToken');
      api.defaults.headers = {...api.defaults.headers, Authorization: 'Bearer '};
      navigate(Routes.signIn);
    } catch (error) {
      store.dispatch('auth/error', error);
    }
  });
};
