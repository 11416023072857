import {StoreonModule} from 'storeon';
import omit from 'ramda/es/omit';
import {IEvents, IState} from '~src/store/index';
import {api} from '~src/api';
import {delay} from '~src/utils';
import {toSnakeCase} from '~src/utils/query';

export interface ILeadsStore {
  isLoading: boolean;
  error: string;
}

export const leadsModule: StoreonModule<IState, IEvents> = store => {
  store.on('leads/send-feedback', async (_, payload) => {
    try {
      await store.dispatch('leads/loading', true);
      await api.post<{url: string}>('leads/feedback', toSnakeCase(omit(['onSuccess', 'onFail'], payload)));
      await delay(500);
      payload.onSuccess();
    } catch (error) {
      store.dispatch('leads/error', error);
      payload.onFail();
    } finally {
      await store.dispatch('leads/loading', false);
    }
  });
  store.on('leads/loading', ({stats}, isLoading) => ({stats: {...stats, isLoading}}));
  store.on('leads/error', ({stats}, error) => ({stats: {...stats, error}}));
};
