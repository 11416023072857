import {
  CheckCircleOutlined,
  FileExcelOutlined,
  LockOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons/lib';

export const admin = {
  account: {
    title: 'Персональные данные',
    pageControls: {
      key: 'accountsControls',
      buttons: [
        {
          key: 1,
          buttonIcon: LogoutOutlined,
          buttonText: 'Выйти из панели',
        },
      ],
    },
    userName: {
      label: 'Имя пользователя',
      placeholder: 'Введите имя',
    },
    userEmail: {
      label: 'Email',
      placeholder: 'Введите email',
    },
    userPassword: {
      label: 'Новый пароль',
      placeholder: 'Введите пароль',
    },
    userPasswordCheck: {
      label: 'Подтвердить новый пароль',
      placeholder: 'Введите пароль',
    },
    saveButton: 'Сохранить изменения',
    cancelButton: 'Отмена',
    signOutButton: 'Выйти из панели',
  },
  users: {
    title: 'Пользователи',
    breadcrumbs: ['Главная', 'Пользователи'],
    tablePageSize: 10,
    createButton: 'Добавить пользователя',
    removeButton: 'Удалить выбранных пользователей',
    tableColumnActionButton: 'Изменить',
    columns: [
      {
        title: 'Пользователь',
        dataIndex: 'userName',
        key: 'userName',
        width: '25%',
      },
      {
        title: 'Email',
        dataIndex: 'userEmail',
        key: 'userEmail',
        width: '20%',
      },
      {
        title: 'Дата регистрации',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: '20%',
      },
      {
        title: 'Роль',
        dataIndex: 'role',
        key: 'role',
        colors: {
          admin: 'orange',
          manager: 'green',
        },
        width: '15%',
      },
      {
        title: '',
        dataIndex: 'userId',
        key: 'actions',
        width: '15%',
      },
    ],
  },
  stats: {
    title: 'Управление расчётами',
    pageControls: {
      key: 'statsControls',
      defaultActiveTab: '1.1',
      tabs: [
        {
          key: '1',
          tabName: 'Тепловая защита',
        },
        {
          key: '2',
          tabName: 'Расчет материалов',
        },
      ],
    },
    tablePageSize: 10,
    tableRowKey: 'calculationId',
    tableColumnActionButton: 'Скачать',
    tableColumnCreatedAtFilter: {
      approveButton: 'Применить',
      resetButton: 'Сбросить фильтр',
    },
    columns: [
      {
        title: 'Дата',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sortDirections: ['ascend', 'descend'] as ('ascend' | 'descend')[],
        showSorterTooltip: false,
        width: '25%',
      },
      {
        title: 'Пользователь',
        dataIndex: 'userName',
        key: 'userName',
        width: '25%',
      },
      {
        title: 'Email',
        dataIndex: 'userEmail',
        key: 'userEmail',
        width: '35%',
      },
      {
        title: 'Файл расчёта',
        dataIndex: 'reportUrl',
        key: 'reportUrl',
        width: '15%',
      },
    ],
  },
  materials: {
    title: 'Конфигурация материалов',
    pageControls: {
      key: 'statsControls',
      defaultActiveTab: '1.1',
      tabs: [
        {
          key: '1.1',
          tabName: '1.1 Тепловая защита',
        },
        {
          key: '2.1',
          tabName: '2.1 Кирпичная кладка',
        },
        {
          key: '2.2',
          tabName: '2.2 Теплоизоляция',
        },
        {
          key: '2.3',
          tabName: '2.3 Фасадные материалы',
        },
        {
          key: '2.4',
          tabName: '2.4 Внутр. отделка',
        },
        {
          key: '2.5',
          tabName: '2.5 Брус',
        },
      ],
      buttons: [
        {
          key: 1,
          buttonIcon: FileExcelOutlined,
          buttonText: 'Импорт XLS-файла',
        },
      ],
    },
    tablePageSize: 10,
    calculationTypes: {
      '1.1': {
        title: 'Тепловая защита',
        columns: [
          {
            title: 'Тип слоя',
            dataIndex: 'layerName',
            key: 'layerName',
          },
          {
            title: 'Тип материала',
            dataIndex: 'materialType',
            key: 'materialType',
          },
          {
            title: 'Наименование',
            dataIndex: 'materialName',
            key: 'materialName',
          },
          {
            title: 'ρ, кг/м³',
            dataIndex: 'materialDensity',
            key: 'materialDensity',
          },
          {
            title: 'λ(А), Вт/(м•°С)',
            dataIndex: 'materialTranscalencyA',
            key: 'materialTranscalencyA',
          },
          {
            title: 'λ(Б), Вт/(м•°С)',
            dataIndex: 'materialTranscalencyB',
            key: 'materialTranscalencyB',
          },
          {
            title: 'μ',
            dataIndex: 'materialVaporRermeability',
            key: 'materialVaporRermeability',
          },
          {
            title: 'Δw, %',
            dataIndex: 'materialDeltaW',
            key: 'materialDeltaW',
          },
        ],
      },
      '2.1': {
        title: 'Кирпичная кладка',
        columns: [
          {
            title: 'Категория',
            dataIndex: 'brickType',
            key: 'layerName',
          },
          {
            title: 'Длина, мм',
            dataIndex: 'brickLength',
            key: 'brickLength',
          },
          {
            title: 'Ширина, мм',
            dataIndex: 'brickWidth',
            key: 'brickWidth',
          },
          {
            title: 'Высота, мм',
            dataIndex: 'brickHeight',
            key: 'brickHeight',
          },
          {
            title: 'Вес, кг',
            dataIndex: 'brickWeight',
            key: 'brickWeight',
          },
        ],
      },
      '2.2': {
        title: 'Теплоизоляция',
        columns: [
          {
            title: 'Категория',
            dataIndex: 'category',
            key: 'category',
          },
          {
            title: 'Наименование',
            dataIndex: 'insulationType',
            key: 'insulationType',
          },
          {
            title: 'Толщина, мм',
            dataIndex: 'insulationThickness',
            key: 'insulationThickness',
          },
          {
            title: 'Высота, мм',
            dataIndex: 'insulationDensity',
            key: 'insulationDensity',
          },
        ],
      },
      '2.3': {
        title: 'Фасадные материалы',
        columns: [
          {
            title: 'Категория',
            dataIndex: 'facadeType',
            key: 'trimType',
          },
          {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Длина, мм',
            dataIndex: 'facadeBrickLength',
            key: 'facadeBrickLength',
          },
          {
            title: 'Ширина, мм',
            dataIndex: 'facadeBrickWidth',
            key: 'facadeBrickWidth',
          },
          {
            title: 'Высота, мм',
            dataIndex: 'facadeBrickHeight',
            key: 'facadeBrickHeight',
          },
          {
            title: 'Вес, кг',
            dataIndex: 'facadeBrickWeight',
            key: 'facadeBrickWeight',
          },
          {
            title: 'Плотность, кг',
            dataIndex: 'density',
            key: 'density',
          },
          {
            title: 'Расход на 1кв.м.',
            dataIndex: 'consumption',
            key: 'кг/мм',
          },
        ],
      },
      '2.4': {
        title: 'Внутренняя отделка',
        columns: [
          {
            title: 'Категория',
            dataIndex: 'trimType',
            key: 'trimType',
          },
          {
            title: 'Длина, мм',
            dataIndex: 'trimLength',
            key: 'trimLength',
          },
          {
            title: 'Ширина, мм',
            dataIndex: 'trimWidth',
            key: 'trimWidth',
          },
          {
            title: 'Высота, мм',
            dataIndex: 'trimHeight',
            key: 'trimHeight',
          },
          {
            title: 'Вес, кг',
            dataIndex: 'trimWeight',
            key: 'trimWeight',
          },
          {
            title: 'Расход на 1кв.м.',
            dataIndex: 'consumption',
            key: 'кг/мм',
          },
        ],
      },
      '2.5': {
        title: 'Брус',
        columns: [
          {
            title: 'Категория',
            dataIndex: 'balkType',
            key: 'balkType',
          },
          {
            title: 'Длина, мм',
            dataIndex: 'balkLength',
            key: 'balkLength',
          },
          {
            title: 'Ширина, мм',
            dataIndex: 'balkWidth',
            key: 'balkWidth',
          },
          {
            title: 'Высота, мм',
            dataIndex: 'balkHeight',
            key: 'balkHeight',
          },
        ],
      },
    },
    comments: {
      breadcrumbs: ['Главная', 'Комментарии'],
      title: 'Модерация комментариев',
      listSize: 10,
      approveButton: 'Опубликовать',
      deleteButton: 'Удалить',
      statuses: {},
      pageControls: {
        key: 'commentsControls',
      },
    },
    auth: {
      login: {
        loginTitle: 'Вход в аккаунт',
      },
      errors: {
        uncorrectPayload: 'Проверьте email и пароль',
      },
      passwordReset: {
        resetTitle: 'Восстановление пароля',
        resetSubtitle: 'Мы пришлем вам ссылку для восстановления пароля на указанный при регистрации адрес',
        passwordResetLinkText: 'Забыли пароль?',
      },
      userName: {
        usernamePlaceholder: 'Email',
        usernameMessage: 'Введите email',
      },
      password: {
        passwordPlaceholder: 'Пароль',
        passwordMessage: 'Введите пароль',
      },
      controls: {
        submitButtonText: 'Войти',
        submitResetButtonText: 'Восстановить пароль',
        goBackButtonText: 'Назад',
        rememberCheckboxText: 'Запомнить меня',
      },
      icons: {
        emailIcon: UserOutlined,
        passwordIcon: LockOutlined,
      },
      misc: {
        copyrightText: 'Расчетстен.рф © 2020. Все права защищены.',
      },
      successScreen: {
        Icon: CheckCircleOutlined,
        title: 'Сообщение для сброса пароля отправлено',
        caption: 'Мы выслали на указанную почту письмо с новым паролем, проверьте почтовый ящик.',
        button: 'Готово',
      },
      recoveryEndScreen: {
        title: 'Введите новый пароль',
        successTitle: 'Пароль изменен',
        successButton: 'Авторизоваться',
        passwordInput: {
          placeholder: 'Новый пароль',
          name: 'userPassword',
          type: 'password',
          iconComponent: LockOutlined,
        },
        button: 'Изменить пароль',
      },
    },
  },
  auth: {
    login: {
      loginTitle: 'Вход в аккаунт',
    },
    errors: {
      uncorrectPayload: 'Проверьте email и пароль',
    },
    passwordReset: {
      resetTitle: 'Восстановление пароля',
      resetSubtitle: 'Мы пришлем вам ссылку для восстановления пароля на указанный при регистрации адрес',
      passwordResetLinkText: 'Забыли пароль?',
    },
    userName: {
      usernamePlaceholder: 'Email',
      usernameMessage: 'Введите email',
    },
    password: {
      passwordPlaceholder: 'Пароль',
      passwordMessage: 'Введите пароль',
    },
    controls: {
      submitButtonText: 'Войти',
      submitResetButtonText: 'Восстановить пароль',
      goBackButtonText: 'Назад',
      rememberCheckboxText: 'Запомнить меня',
    },
    icons: {
      emailIcon: UserOutlined,
      passwordIcon: LockOutlined,
    },
    misc: {
      copyrightText: 'Расчетстен.рф © 2020. Все права защищены.',
    },
    successScreen: {
      Icon: CheckCircleOutlined,
      title: 'Сообщение для сброса пароля отправлено',
      caption: 'Мы выслали на указанную почту письмо с новым паролем, проверьте почтовый ящик.',
      button: 'Готово',
    },
    recoveryEndScreen: {
      title: 'Введите новый пароль',
      successTitle: 'Пароль изменен',
      successButton: 'Авторизоваться',
      passwordInput: {
        placeholder: 'Новый пароль',
        name: 'userPassword',
        type: 'password',
        iconComponent: LockOutlined,
      },
      button: 'Изменить пароль',
    },
  },
  comments: {
    breadcrumbs: ['Главная', 'Комментарии'],
    title: 'Модерация комментариев',
    listSize: 10,
    approveButton: 'Опубликовать',
    deleteButton: 'Удалить',
    statuses: {},
    pageControls: {
      key: 'commentsControls',
    },
  },
  articles: {
    breadcrumbs: ['Главная', 'Статьи'],
    title: 'Статьи',
    listSize: 4,
    addButton: 'Добавить статью',
    editButton: 'Редактировать',
    deleteButton: 'Удалить',
    statuses: {},
    pageControls: {
      key: 'articlesControls',
    },
  },
  article: {
    breadcrumbs: ['Главная', 'Статьи', 'Статья'],
    title: 'Редактирование статьи',
    exitButton: 'Отмена',
    saveButton: 'Сохранить',
  },
};
