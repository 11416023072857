import find from 'ramda/es/find';
import propEq from 'ramda/es/propEq';
import compose from 'ramda/es/compose';
import reduce from 'ramda/es/reduce';
import sort from 'ramda/es/sort';
import has from 'ramda/es/has';
import values from 'ramda/es/values';
import {paramsByCity} from '~src/structs/paramsByCity';
import {humidityMode} from '~src/structs/humidityMode';
import {operatingConditions} from '~src/structs/operatingConditions';
import {IUserValuesAtCalculation1} from '~src/store/Calculations';
import {IResultValuesAtCalculation1} from '~src/store/Calculations';
import {IResultValues} from '~src/services/Calculation1Service';

export enum Calculation2Types {
  brickAndBlocks,
  timber,
}

export type IValue = string | number;
export interface IDataItem {
  label: string;
  value: IValue;
  children: IDataItem[];
  [name: string]: any;
}

export interface ICommonPropCity {
  zona: number;
  z_ot: number;
  t_ot: number;
  t_h: number;
  f: number;
  t_jan: number;
  t_feb: number;
  t_mar: number;
  t_apr: number;
  t_may: number;
  t_jun: number;
  t_jul: number;
  t_aug: number;
  t_sep: number;
  t_oct: number;
  t_nov: number;
  t_dec: number;
  t_min?: number;
  t_year: number;
  p_jan: number;
  p_feb: number;
  p_mar: number;
  p_apr: number;
  p_may: number;
  p_jun: number;
  p_jul: number;
  p_aug: number;
  p_sep: number;
  p_oct: number;
  p_nov: number;
  p_dec: number;
  p_year: number;
  z0: number;
  t0: number;
}

export const commonCityProps: (keyof ICommonPropCity)[] = [
  'zona',
  'z_ot',
  't_ot',
  't_h',
  'f',
  't_jan',
  't_feb',
  't_mar',
  't_apr',
  't_may',
  't_jun',
  't_jul',
  't_aug',
  't_sep',
  't_oct',
  't_nov',
  't_dec',
  't_year',
  't_min',
  'p_jan',
  'p_feb',
  'p_mar',
  'p_apr',
  'p_may',
  'p_jun',
  'p_jul',
  'p_aug',
  'p_sep',
  'p_oct',
  'p_nov',
  'p_dec',
  'p_year',
  'z0',
  't0',
];

export interface IModelCityItem extends ICommonPropCity {
  index: string;
  subject: string;
  city: string;
}

export type IModelCityList = IModelCityItem[];

export interface ICityItem extends ICommonPropCity {
  label: string;
  value: string;
}
export type ICityList = ICityItem[];

export const getCityList = (): ICityList => {
  return compose(
    reduce<IModelCityItem, ICityList>((acc, item) => {
      const {city, index, ...restProps} = item;
      return [
        ...acc,
        {
          label: city,
          value: index,
          t_min: Math.min(
            restProps.t_jan,
            restProps.t_feb,
            restProps.t_mar,
            restProps.t_apr,
            restProps.t_may,
            restProps.t_jun,
            restProps.t_jul,
            restProps.t_aug,
            restProps.t_sep,
            restProps.t_oct,
            restProps.t_nov,
            restProps.t_dec,
          ),
          ...restProps,
        },
      ];
    }, []),
    sort<IModelCityItem>((a, b): 1 | -1 => {
      const itemA = a.subject.toLowerCase();
      const itemB = b.subject.toLowerCase();

      return itemA > itemB ? 1 : -1;
    }),
  )(paramsByCity);
};

type INormalizeDataMap = {[name: string]: IDataItem};

export const normalizedDataList = (dataList: any): IDataItem[] => {
  const normalizeDataMap: INormalizeDataMap = dataList.reduce((acc: INormalizeDataMap, item: IDataItem) => {
    const key: string = item.value as string;
    const hasProp = has(key);
    if (hasProp(acc)) {
      const children = acc[key].children || [];
      return {...acc, [key]: {...acc[key], children: [...children, item]}};
    }
    return {...acc, [key]: {index: item.value, label: item.subject, children: [item]}};
  }, {});

  return values(normalizeDataMap);
};

export const cityList = getCityList();
export const groupedCityList = normalizedDataList(cityList);
export const getCityDataByCode = (code: string) => find<ICityItem>(propEq('value', code))(cityList);
export const getHumidityMode = (t_b: number, wetInRoom: number) => {
  let result = '';
  humidityMode.forEach(mode => {
    const {values} = mode;
    values.forEach(value => {
      if (value.t[0] <= t_b && value.t[1] > t_b && value.wet[0] < wetInRoom && value.wet[1] >= wetInRoom) {
        result = mode.name;
      }
    });
  });
  return result;
};
export const getOperatingConditions = (humidityMode: string, zona: number) => {
  return operatingConditions[humidityMode] && operatingConditions[humidityMode][zona];
};

export const getUserValuesByCalculation1 = (userValues: IUserValuesAtCalculation1, locales: any) => {
  return locales.result.userValues.dataSource.map((item: {name: any}) => {
    switch (item.name) {
      case 'typeRoom':
        return {...item, value: locales.section2.typeRoom.values[userValues.typeRoom].label};
      case 'regionCity':
        return {...item, value: userValues.regionCity};
      case 't_b':
        return {...item, value: userValues.t_b};
      case 'wetInRoom':
        return {...item, value: userValues.wetInRoom};
      case 'typeConstruction':
        return {
          ...item,
          value: locales.section2.typeConstruction.values[userValues.typeConstruction].label,
        };
    }
    return item;
  });
};

export const createDataSourceResult2 = (userValue: IUserValuesAtCalculation1, resultValues: IResultValues) => {
  return userValue.layers
    .filter(layer => layer.isEnabled)
    .filter(layer => layer.materialType !== 'Экран' && layer.materialType !== 'Вентилируемая воздушная прослойка')
    .map((layer, index) => ({
      ...layer,
      f_i: resultValues.layersFi[index],
      t_mu: resultValues.layersTmu[index],
      t_n: resultValues.layersTemperature[index],
      t_k: resultValues.layersTemperature[index + 1],
    }));
};

export const createDataSourceResult3 = (resultValues: IResultValuesAtCalculation1, locales: any) => {
  return locales.result3TableGeneration.propNames.map((names: any[]) => {
    return names.reduce((acc: any, name: string, index: number) => {
      if (index === 0) return {...acc, name};
      return {
        ...acc,
        [locales.result3TableGeneration.heatLossParams.columns[index].key]:
          resultValues[name as keyof IResultValuesAtCalculation1],
      };
    }, {});
  });
};

export const createDataSourceResult4 = (userValue: IUserValuesAtCalculation1) => {
  return userValue.layers
    .filter(layer => layer.isEnabled)
    .map(layer => {
      const materialTranscalency =
        userValue.operatingConditions === 'А' ? layer.materialTranscalencyA : layer.materialTranscalencyB;
      const normalizedT = layer.t / 1000;

      return {
        name: layer.materialName,
        layerId: layer.layerId,
        materialDensity: layer.materialDensity,
        t: normalizedT,
        materialTranscalency: materialTranscalency,
        materialVaporRermeability: layer.materialVaporRermeability,
        R_i: Math.round((normalizedT / materialTranscalency) * 1000000) / 1000000,
        R_ni: Math.round((normalizedT / layer.materialVaporRermeability) * 1000000) / 1000000,
      };
    });
};

export const createDataSourceResult5 = (
  userValue: IUserValuesAtCalculation1,
  resultValues: IResultValuesAtCalculation1,
  locales: any,
) => {
  let greatestCondensateMonth: string | undefined;

  const dataSource = locales.result5TableGeneration.propNames.reduce((accArr: any[], names: any[]) => {
    return [
      ...accArr,
      names.reduce((accObj: {}, name: string, index: number) => {
        if (index === 0) return {...accObj, resistanceVaporRermeability: name};
        if (index === 1) return {...accObj, index: name};
        if (index === 2) return {...accObj, unit: name};
        if (index > 2 && index < 15) {
          interface PeriodsObject {
            [index: string]: number;
          }
          const hoursInMohth: PeriodsObject = {
            jan: 744,
            feb: 672,
            mar: 744,
            apr: 720,
            may: 744,
            jun: 720,
            jul: 744,
            aug: 744,
            sep: 720,
            oct: 744,
            nov: 720,
            dec: 744,
          };
          const t_n = userValue[('t_' + name) as keyof IUserValuesAtCalculation1];
          const t_b = userValue.t_b;
          const e_n = (userValue[('p_' + name) as keyof IUserValuesAtCalculation1] as number) * 100;
          const e_b = resultValues.e_b!;
          const e_tau =
            Math.round((e_b - (e_b - (e_n as number)) * (resultValues.sum_R_ni_section! / resultValues.r_op!)) * 10) /
            10;
          const r_usl = resultValues.r_usl!;

          const Q_v_sl = Math.round(((e_b - e_tau) / resultValues.R_ni!) * hoursInMohth[name] * 10) / 10;
          const Q_n_sl =
            Math.round(
              ((e_tau - (e_n as number)) / (resultValues.r_op! - resultValues.R_ni!)) * hoursInMohth[name] * 10,
            ) / 10;

          // console.log(`
          // -- month=${name} -- Q_n_sl=${Q_n_sl} = Math.round(
          //     ((e_tau=${e_tau} - (e_n=${e_n} as number)) / (resultValues.r_op!=${resultValues.r_op!} - resultValues.R_ni!=${resultValues.R_ni!})) * hoursInMohth[name]=${
          //   hoursInMohth[name]
          // } * 10, ) / 10   ==============${'=>'} `);

          const delta_Q = Math.round((Q_v_sl - Q_n_sl) * 10) / 10;

          if (names[1] === 't_n') {
            return {...accObj, [name]: t_n};
          }
          if (names[1] === 't_v') {
            return {...accObj, [name]: t_b};
          }
          if (names[1] === 'Δt') {
            return {...accObj, [name]: Math.round((t_b - (t_n as number)) * 10) / 10};
          }
          if (names[1] === 'τ_п') {
            return {
              ...accObj,
              [name]: Math.round((t_b - (t_b - (t_n as number)) * (resultValues.sum_R_i_section! / r_usl)) * 10) / 10,
            };
          }
          if (names[1] === 'e_н') {
            return {...accObj, [name]: e_n};
          }
          if (names[1] === 'e_в') {
            return {...accObj, [name]: Math.round(e_b * 10) / 10};
          }
          if (names[1] === 'Δe') {
            return {...accObj, [name]: Math.round((e_b - (e_n as number)) * 10) / 10};
          }
          if (names[1] === 'e_τ') {
            return {...accObj, [name]: e_tau};
          }
          if (names[1] === 'E_τ') {
            const tau_p = t_b - (t_b - (t_n as number)) * (resultValues.sum_R_i_section! / r_usl);
            const E_tau = Math.round(1.84 * Math.pow(10, 11) * Math.exp(-5330 / (273 + tau_p)) * 10) / 10;
            return {...accObj, [name]: E_tau};
          }
          if (names[1] === 'Пе­ри­од') {
            return {...accObj, [name]: hoursInMohth[name]};
          }
          if (names[1] === 'Q_в.сл') {
            return {...accObj, [name]: Q_v_sl};
          }
          if (names[1] === 'Q_н.сл') {
            return {...accObj, [name]: Q_n_sl};
          }
          if (names[1] === 'ΔQ') {
            return {...accObj, [name]: delta_Q};
          }
          if (names[1] === '∑ΔQ') {
            const months = ['oct', 'nov', 'dec', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep'];

            if (name === 'dec') {
              const filteredAndSortedMonth = Object.entries(accArr[14])
                .filter(([key]) => {
                  return months.findIndex(month => month === key) != -1;
                })
                .sort((a: [string, any], b: [string, any]) => {
                  return Math.abs(b[1]) - Math.abs(a[1]);
                });

              greatestCondensateMonth = filteredAndSortedMonth[0][0];
            }

            const sum_delta_Q = months.reduce((accMonths: any, month) => {
              switch (month) {
                case 'oct':
                  return {...accMonths, oct: accArr[14].oct};
                case 'nov':
                  return {...accMonths, [month]: Math.round((accMonths.oct + accArr[14].nov) * 1000) / 1000};
                case 'dec':
                  return {...accMonths, [month]: Math.round((accMonths.nov + accArr[14].dec) * 1000) / 1000};
                case 'jan':
                  return {...accMonths, [month]: Math.round((accMonths.dec + accArr[14].jan) * 1000) / 1000};
                case 'feb':
                  return {...accMonths, [month]: Math.round((accMonths.jan + accArr[14].feb) * 1000) / 1000};
                case 'mar':
                  return {...accMonths, [month]: Math.round((accMonths.feb + accArr[14].mar) * 1000) / 1000};
                case 'apr':
                  return {...accMonths, [month]: Math.round((accMonths.mar + accArr[14].apr) * 1000) / 1000};
                case 'may':
                  return {...accMonths, [month]: Math.round((accMonths.apr + accArr[14].may) * 1000) / 1000};
                case 'jun':
                  return {...accMonths, [month]: Math.round((accMonths.may + accArr[14].jun) * 1000) / 1000};
                case 'jul':
                  return {...accMonths, [month]: Math.round((accMonths.jun + accArr[14].jul) * 1000) / 1000};
                case 'aug':
                  return {...accMonths, [month]: Math.round((accMonths.jul + accArr[14].aug) * 1000) / 1000};
                case 'sep':
                  return {...accMonths, [month]: Math.round((accMonths.aug + accArr[14].sep) * 1000) / 1000};
                default:
                  return {...accMonths, [month]: ''};
              }
            }, {});
            return {...accObj, [name]: Math.round(sum_delta_Q[name] * 100) / 100};
          }
          if (names[1] === 'Кон­ден­сат') {
            const months = ['oct', 'nov', 'dec', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep'];

            const condensate = months.reduce((accMonths: any, month) => {
              switch (month) {
                case 'oct':
                  return {...accMonths, [month]: accArr[15].oct > 0 ? '+' : '-'};
                case 'nov':
                  return {...accMonths, [month]: accArr[15].nov > accArr[15].oct ? '+' : '-'};
                case 'dec':
                  return {...accMonths, [month]: accArr[15].dec > accArr[15].nov ? '+' : '-'};
                case 'jan':
                  return {...accMonths, [month]: accArr[15].jan > accArr[15].dec ? '+' : '-'};
                case 'feb':
                  return {...accMonths, [month]: accArr[15].feb > accArr[15].jan ? '+' : '-'};
                case 'mar':
                  return {...accMonths, [month]: accArr[15].mar > accArr[15].feb ? '+' : '-'};
                case 'apr':
                  return {...accMonths, [month]: accArr[15].apr > accArr[15].mar ? '+' : '-'};
                case 'may':
                  return {...accMonths, [month]: accArr[15].may > accArr[15].apr ? '+' : '-'};
                case 'jun':
                  return {...accMonths, [month]: accArr[15].jun > accArr[15].may ? '+' : '-'};
                case 'jul':
                  return {...accMonths, [month]: accArr[15].jul > accArr[15].jun ? '+' : '-'};
                case 'aug':
                  return {...accMonths, [month]: accArr[15].aug > accArr[15].jul ? '+' : '-'};
                case 'sep':
                  return {...accMonths, [month]: accArr[15].sep > accArr[15].aug ? '+' : '-'};
                default:
                  return {...accMonths, [month]: ''};
              }
            }, {});
            return {...accObj, [name]: condensate[name]};
          }
        }
      }, {}),
    ];
  }, []);

  return {
    dataSource: dataSource,
    greatestCondensateMonth: greatestCondensateMonth,
  };
};
