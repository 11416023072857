export const customColors = {
  red100: '#ffe4e8',
  red600: '#F5222D',
  green100: '#ecffe4',
  green300: '#B7EB8F',
  green500: '#52C41A',
  blue400: '#69C0FF',
  blue700: '#096DD9',
  blue1000: '#002766',
};
