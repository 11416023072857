import {StoreonModule} from 'storeon';
import {toCamelCaseList, toSnakeCase} from '~src/utils/query';
import {api} from '~src/api';
import {delay} from '~src/utils';
import {IEvents, IState} from '~src/store/index';
// import commentsMock from '~src/mocks/commentsMock';

export interface IComment {
  message: string;
  userName: string;
  createdAt: string;
  commentId: string;
  approved: boolean;
}

export interface ICommentResponse {
  message: string;
  user_name: string;
  created_at: string;
  comment_id: string;
  approved: boolean;
}

export interface ICommentsStore {
  isLoading: boolean;
  isSending: boolean;
  isSent: boolean;
  values: IComment[];
  count: number;
  error: string;
}

export const commentsModule: StoreonModule<IState, IEvents> = store => {
  store.on('comments/set-comments', (state, comments) => {
    const updatedValues =
      comments.strategy === 'push'
        ? [...state.comments.values, ...toCamelCaseList(comments.items)]
        : [...comments.items];
    return {
      comments: {...state.comments, values: updatedValues, count: comments.count},
    };
  });
  store.on('comments/fetch-comments', async (state, params) => {
    try {
      const response = await api.get<{items: IComment[]; count: number}>('comments', {params: toSnakeCase(params)});
      await delay(500);
      await store.dispatch('comments/set-comments', {
        count: response.data.count,
        items: toCamelCaseList(response.data.items),
        strategy: params.strategy,
      });
    } catch (error) {
      store.dispatch('comments/error', error);
    }
  });
  store.on('comments/send-comment', async (state, sendCommentPayload) => {
    try {
      store.dispatch('comments/set-is-sending', true);
      await delay(500);
      await api.post('comments', toSnakeCase(sendCommentPayload));
      store.dispatch('comments/set-is-sent');
    } catch (error) {
      store.dispatch('comments/error', error);
    } finally {
      store.dispatch('comments/set-is-sending', false);
    }
  });
  store.on('comments/approve-comments', async ({comments}, approveCommentPayload) => {
    try {
      const response = await api.post<ICommentResponse[]>('comments/approve', toSnakeCase(approveCommentPayload));
      store.dispatch('comments/set-comments', {
        strategy: 'overwrite',
        count: comments.count,
        items: toCamelCaseList(
          comments.values.map(comment => {
            let tempCommentResponse: ICommentResponse | null = null;
            return response.data.some(commentResponse => {
              const isMatched = commentResponse.comment_id === comment.commentId;
              if (isMatched) tempCommentResponse = commentResponse;
              return isMatched;
            })
              ? {...comment, approved: tempCommentResponse!.approved}
              : comment;
          }),
        ),
      });
    } catch (error) {
      store.dispatch('comments/error', error);
    }
  });
  store.on('comments/delete-comments', async (_, payload) => {
    try {
      await api.delete('comments', {data: toSnakeCase(payload)});
    } catch (error) {
      store.dispatch('comments/error', error);
    }
  });
  store.on('comments/set-is-sending', ({comments}, value) => ({comments: {...comments, isSending: value}}));
  store.on('comments/set-is-sent', ({comments}) => ({comments: {...comments, isSent: true}}));
  store.on('comments/error', ({comments}, error) => ({comments: {...comments, error}}));
};
