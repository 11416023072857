import {StoreonModule} from 'storeon';
import {IEvents, IState} from '~src/store/index';
import {api} from '~src/api';
import {delay} from '~src/utils';

export interface IReportsStore {
  pdf: {
    isLoading: boolean;
    error: string;
    url: string;
  };
}

export const initialState: IReportsStore = {
  pdf: {
    isLoading: false,
    error: '',
    url: '',
  },
};

export const reportsModule: StoreonModule<IState, IEvents> = store => {
  store.on('reports/generate-pdf', async (state, payload) => {
    try {
      await store.dispatch('reports/generate-pdf-set-loading', true);
      const calculation = state.calculations[payload.calculationType];
      const response = await api.post<{url: string}>('reports/pdf', {
        ...payload,
        userValues: calculation.userValues,
        resultValues: calculation.resultValues,
      });
      await store.dispatch('reports/generate-pdf-set-url', response.data.url);
      await delay(500);
    } catch (error) {
      store.dispatch('reports/generate-pdf-set-error', error);
    } finally {
      await store.dispatch('reports/generate-pdf-set-loading', false);
    }
  });
  store.on('reports/generate-pdf-set-url', ({reports}, value) => ({
    reports: {...reports, url: value},
  }));
  store.on('reports/generate-pdf-set-loading', ({reports}, value) => ({
    reports: {...reports, isLoading: value},
  }));
  store.on('reports/generate-pdf-set-error', ({reports}, value) => ({
    reports: {...reports, error: value},
  }));
};
