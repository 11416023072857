// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-account-index-tsx": () => import("./../../src/pages/admin/account/index.tsx" /* webpackChunkName: "component---src-pages-admin-account-index-tsx" */),
  "component---src-pages-admin-articles-article-index-tsx": () => import("./../../src/pages/admin/articles/article/index.tsx" /* webpackChunkName: "component---src-pages-admin-articles-article-index-tsx" */),
  "component---src-pages-admin-articles-index-tsx": () => import("./../../src/pages/admin/articles/index.tsx" /* webpackChunkName: "component---src-pages-admin-articles-index-tsx" */),
  "component---src-pages-admin-auth-index-tsx": () => import("./../../src/pages/admin/auth/index.tsx" /* webpackChunkName: "component---src-pages-admin-auth-index-tsx" */),
  "component---src-pages-admin-auth-recovery-end-index-tsx": () => import("./../../src/pages/admin/auth/recovery-end/index.tsx" /* webpackChunkName: "component---src-pages-admin-auth-recovery-end-index-tsx" */),
  "component---src-pages-admin-auth-recovery-start-index-tsx": () => import("./../../src/pages/admin/auth/recovery-start/index.tsx" /* webpackChunkName: "component---src-pages-admin-auth-recovery-start-index-tsx" */),
  "component---src-pages-admin-comments-index-tsx": () => import("./../../src/pages/admin/comments/index.tsx" /* webpackChunkName: "component---src-pages-admin-comments-index-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-materials-index-tsx": () => import("./../../src/pages/admin/materials/index.tsx" /* webpackChunkName: "component---src-pages-admin-materials-index-tsx" */),
  "component---src-pages-admin-stats-index-tsx": () => import("./../../src/pages/admin/stats/index.tsx" /* webpackChunkName: "component---src-pages-admin-stats-index-tsx" */),
  "component---src-pages-admin-users-index-tsx": () => import("./../../src/pages/admin/users/index.tsx" /* webpackChunkName: "component---src-pages-admin-users-index-tsx" */),
  "component---src-pages-articles-article-index-tsx": () => import("./../../src/pages/articles/article/index.tsx" /* webpackChunkName: "component---src-pages-articles-article-index-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-comments-index-tsx": () => import("./../../src/pages/comments/index.tsx" /* webpackChunkName: "component---src-pages-comments-index-tsx" */),
  "component---src-pages-contacts-index-tsx": () => import("./../../src/pages/contacts/index.tsx" /* webpackChunkName: "component---src-pages-contacts-index-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-raschet-stroitelnyh-materialov-index-tsx": () => import("./../../src/pages/raschet-stroitelnyh-materialov/index.tsx" /* webpackChunkName: "component---src-pages-raschet-stroitelnyh-materialov-index-tsx" */),
  "component---src-pages-report-tsx": () => import("./../../src/pages/report.tsx" /* webpackChunkName: "component---src-pages-report-tsx" */),
  "component---src-pages-teplotehnicheskij-raschet-index-tsx": () => import("./../../src/pages/teplotehnicheskij-raschet/index.tsx" /* webpackChunkName: "component---src-pages-teplotehnicheskij-raschet-index-tsx" */)
}

