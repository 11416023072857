import mainBannerJpg from '~src/assets/images/main.webp';
import {calculation1} from '~src/locales/calculation1';
import {calculation2} from '~src/locales/calculation2';

export const application = {
  index: {
    meta: {
      title: 'Бесплатный строительный калькулятор онлайн',
      description: 'Расчет количества строительный материалов и тепловой защиты зданий',
      keywords:
        'строительный калькулятор, расчет стен, расчет стен калькулятор, строительный калькулятор онлайн, расчет стен рф, расчетстен рф',
    },
    commentsSection: {
      key: 'comments',
      title: 'Оставьте свой отзыв!',
      sentTitle: 'Спасибо! Ваш отзыв скоро появится &#128640;',
      subtitle: 'Здесь вы можете поделиться впечталениями о нашем сервисе и помочь другим сделать свой выбор',
      listTitle: 'Комментарии пользователей',
      button: 'Показать ещё',
    },
    mainBanner: {
      key: 'mainBanner',
      title: 'Строительные калькуляторы',
      subtitle:
        'Наш сервис – это онлайн-инструмент для автоматизации и упрощения типовых строительных расчетов.\nОн подойдет как для профи, так и для рядовых пользователей.',
      image: mainBannerJpg,
    },
    advantages: {
      key: 'advantages',
      title: 'Преимущества калькуляторов',
      subtitle:
        'Подберите строительное решение под Ваши задачи, рассчитайте количество и стоимость товаров быстро и легко',
    },
    offerBanner: {
      key: 'offerBanner',
      title: 'Индивидуальное\nпроектирование',
      subtitle: 'от 15 000Р.',
    },
    comments: {
      key: 'comments',
      title: 'Оставьте свой отзыв!',
      subtitle: 'Здесь вы можете поделиться впечталениями о нашем сервисе и помочь другим сделать свой выбор',
    },
  },
  calculation1,
  calculation2,
  faq: {
    meta: {
      title:
        'Вопросы и ответы | Расчетстен.рф - онлайн-инструмент для автоматизации и упрощения типовых строительных расчетов',
    },
    header1: 'Вопросы и ответы',
    breadcrumbs: ['Главная'],
    breadcrumbsSource: ['/'],
  },
  comments: {
    meta: {
      title:
        'Комментарии | Расчетстен.рф - онлайн-инструмент для автоматизации и упрощения типовых строительных расчетов',
    },
    header1: 'Комментарии',
    header3: 'Контакты',
    breadcrumbs: ['Главная'],
    breadcrumbsSource: ['/'],
  },
  contacts: {
    meta: {
      title: 'Контакты | Расчетстен.рф - онлайн-инструмент для автоматизации и упрощения типовых строительных расчетов',
    },
    header1: 'Контакты',
    breadcrumbs: ['Главная'],
    breadcrumbsSource: ['/'],
  },
  articlesPage: {
    meta: {
      title: 'Статьи | Расчетстен.рф - онлайн-инструмент для автоматизации и упрощения типовых строительных расчетов',
    },
    header1: 'Статьи',
    breadcrumbs: ['Главная'],
    breadcrumbsSource: ['/'],
  },
  articlePage: {
    meta: {
      title:
        'Название Статьи | Расчетстен.рф - онлайн-инструмент для автоматизации и упрощения типовых строительных расчетов',
    },
    header1: 'Название Статьи',
    breadcrumbs: ['Главная', 'Статьи'],
    breadcrumbsSource: ['/', '/articles/'],
  },

  report: {
    meta: {
      title:
        'Генерация отчёта | Расчетстен.рф - онлайн-инструмент для автоматизации и упрощения типовых строительных расчетов',
    },
    header1: 'Скачивание отчёта',
  },
  notFoundPage: {
    meta: {
      title:
        'Страница не найдена | Расчетстен.рф - онлайн-инструмент для автоматизации и упрощения типовых строительных расчетов',
    },
    title: 'К сожалению страница, которую вы запросили, в данный момент недоступна.',
    buttonText: 'Вернуться на главную',
  },
  mobileWarning: {
    title: 'Таблицы и графики можно прокручивать горизонтально',
  },
};
