export const airGapRMapByName: {[key: string]: number} = {
  'Невентилируемая воздушная прослойка 1-2 см': 0.15,
  'Невентилируемая воздушная прослойка 3 см': 0.16,
  'Невентилируемая воздушная прослойка 5 см': 0.17,
  'Невентилируемая воздушная прослойка до 10-15 см': 0.18,
  'Невентилируемая воздушная прослойка до 15-30 см': 0.19,

  'Вентилируемая воздушная прослойка 1-2 см': 0.085,
  'Вентилируемая воздушная прослойка 3 см': 0.085,
  'Вентилируемая воздушная прослойка 5 см': 0.085,
  'Вентилируемая воздушная прослойка до 10-15 см': 0.085,
  'Вентилируемая воздушная прослойка до 15-30 см': 0.085,
};

export const airGapT: {[key: string]: number} = {
  'Невентилируемая воздушная прослойка 1-2 см': 20,
  'Невентилируемая воздушная прослойка 3 см': 30,
  'Невентилируемая воздушная прослойка 5 см': 50,
  'Невентилируемая воздушная прослойка до 10-15 см': 150,
  'Невентилируемая воздушная прослойка до 15-30 см': 300,

  'Вентилируемая воздушная прослойка 1-2 см': 20,
  'Вентилируемая воздушная прослойка 3 см': 30,
  'Вентилируемая воздушная прослойка 5 см': 50,
  'Вентилируемая воздушная прослойка до 10-15 см': 150,
  'Вентилируемая воздушная прослойка до 15-30 см': 300,
};
