export interface IBalkBearingWall {
  balkType: string;
  balkWidth: number;
  balkHeight: number;
  wallsOverallLength: number;
  wallsCornerHeight: number;
  balkDensity: number | number;
}

export interface IGable {
  gableType: number; // общий для всех типов
  gableHeight: number; // общий для треугольника и трапеции
  gableCount: number; // общий для всех типов
  gableWidth: number;

  gableSmallerWidth: number; // общий для трапеции и пятиугольника
  gableBiggerWidth: number; // общий для трапеции и пятиугольника

  gablePentaHeightBetween: number;
  gablePentaTriangleHeight: number;

  enabledGableInput: string;
}

export interface IWindow {
  windowHeight: number;
  windowWidth: number;
  windowCount: number;
}

export interface IDoor {
  doorHeight: number;
  doorWidth: number;
  doorCount: number;
}

export interface IValues {
  bearingWalls: IBalkBearingWall[];
  gables: IGable[];
  windows: IWindow[];
  doors: IDoor[];
  enabledFlags: string[];
  bearingWallImage: string;
  isInvalid: boolean;
}

export interface IResultValues {
  calculationSubtype: number;

  V_br: number;
  S_st: number;
  M_br: number;
  N_venc: number;
  L_ut_rul: number;
  D_nag: number;
  L_nag: number;
  N_nag: number;
  V_prop: number;
  Delta_usad: number;
  N_fund: number;
}

class Calculation3Service {
  values: IValues | {} = {};
  resultValue: IResultValues = {
    V_br: 0,
    S_st: 0,
    M_br: 0,
    N_venc: 0,
    L_ut_rul: 0,
    D_nag: 0,
    L_nag: 0,
    N_nag: 0,
    V_prop: 0,
    Delta_usad: 0,
    N_fund: 0,
  };
  private S_st() {
    const {bearingWalls, gables, windows, doors, enabledFlags} = this.values as IValues;
    let wallsOverallLength = 0;
    let wallsHeight = 0;
    let gableType = 0;
    let gableHeight = 0;
    let gableCount = 0;
    let gableWidth = 0;
    let gableSmallerWidth = 0;
    let gableBiggerWidth = 0;
    let gablePentaHeightBetween = 0;
    let gablePentaTriangleHeight = 0;
    let windowCount = 0;
    let windowHeight = 0;
    let windowWidth = 0;
    let doorCount = 0;
    let doorHeight = 0;
    let doorWidth = 0;

    bearingWalls.map(item => {
      return (wallsOverallLength = item.wallsOverallLength), (wallsHeight = item.wallsCornerHeight);
    });

    gables.map(item => {
      return (
        (gableType = item.gableType),
        (gableHeight = item.gableHeight),
        (gableCount = item.gableCount),
        (gableWidth = item.gableWidth),
        (gableSmallerWidth = item.gableSmallerWidth),
        (gableBiggerWidth = item.gableBiggerWidth),
        (gablePentaHeightBetween = item.gablePentaHeightBetween),
        (gablePentaTriangleHeight = item.gablePentaTriangleHeight)
      );
    });

    windows.map(item => {
      return (windowCount += item.windowCount), (windowHeight += item.windowHeight), (windowWidth += item.windowWidth);
    });

    doors.map(item => {
      return (doorCount += item.doorCount), (doorHeight += item.doorHeight), (doorWidth += item.doorWidth);
    });

    const calculateGableSquare = () => {
      console.log('calculateGableSquare is called');
      let gableSquare = 0;
      if (!enabledFlags.find(item => item === 'balkGables')) {
        return (gableSquare = 0);
      } else {
        gables.forEach(item => {
          let innerSquare = 0;
          switch (item.gableType) {
            case 0:
              innerSquare += 0.5 * item.gableWidth * item.gableHeight * item.gableCount;
              gableSquare = gableSquare + innerSquare;
              break;
            case 1:
              innerSquare +=
                0.5 * item.gableHeight * (item.gableSmallerWidth + item.gableBiggerWidth) * item.gableCount;
              gableSquare = gableSquare + innerSquare;
              break;
            case 2:
              innerSquare +=
                (0.5 * item.gablePentaHeightBetween * (item.gableSmallerWidth + item.gableBiggerWidth) +
                  0.5 * item.gableSmallerWidth * item.gablePentaTriangleHeight) *
                item.gableCount;
              gableSquare = gableSquare + innerSquare;
              break;
          }
        });
      }
      return gableSquare;
    };

    let S_ok = 0;

    windows.map(window => {
      S_ok += Math.round((window.windowHeight / 100) * (window.windowWidth / 100) * window.windowCount * 100) / 100;
    });
    let S_dv = (((doorHeight / 100) * doorWidth) / 100) * doorCount;
    let S_pr = S_ok + S_dv;

    let s_st = (wallsOverallLength * wallsHeight + calculateGableSquare() - S_pr).toFixed(3);

    this.resultValue = {
      ...this.resultValue,
      S_st: Math.round(Number(s_st) * 100) / 100,
    };
  }
  private V_br() {
    const {bearingWalls} = this.values as IValues;
    const {S_st} = this.resultValue as IResultValues;
    let balkWidth = 0;

    bearingWalls.map(item => {
      return (balkWidth = item.balkWidth / 1000);
    });

    this.resultValue = {...this.resultValue, V_br: Math.round(S_st * balkWidth * 100) / 100};
  }
  private M_br() {
    const {bearingWalls} = this.values as IValues;
    const {V_br} = this.resultValue as IResultValues;
    let balkDensity = 0;

    bearingWalls.map(item => {
      return (balkDensity = item.balkDensity);
    });

    let m_br = (balkDensity * V_br) / 1000;
    this.resultValue = {...this.resultValue, M_br: Math.round(m_br * 100) / 100};
  }
  private N_venc() {
    const {bearingWalls, gables} = this.values as IValues;
    let gableHeight = 0;
    let wallsHeight = 0;
    let balkHeight = 0;

    gables.map(item => {
      if (item.gableType === 0 || item.gableType == 1) {
        return (gableHeight = item.gableHeight);
      } else {
        return (gableHeight = item.gablePentaHeightBetween + item.gablePentaTriangleHeight);
      }
    });

    bearingWalls.map(item => {
      return (wallsHeight = item.wallsCornerHeight), (balkHeight = item.balkHeight / 1000);
    });

    this.resultValue = {...this.resultValue, N_venc: Math.ceil((wallsHeight + gableHeight) / balkHeight)};
  }
  private L_ut_rul() {
    const {bearingWalls} = this.values as IValues;
    const {S_st} = this.resultValue as IResultValues;

    let balkHeight = 0;

    bearingWalls.map(item => {
      return (balkHeight = item.balkHeight / 1000);
    });

    this.resultValue = {...this.resultValue, L_ut_rul: Math.round((S_st / balkHeight) * 100) / 100};
  }
  private D_nag() {
    const {bearingWalls} = this.values as IValues;
    let balkHeight = 0;

    bearingWalls.map(item => {
      return (balkHeight = item.balkHeight / 1000);
    });

    this.resultValue = {...this.resultValue, D_nag: Math.ceil((balkHeight / 3) * 100)};
  }
  private L_nag() {
    const {bearingWalls} = this.values as IValues;
    const {N_venc} = this.resultValue as IResultValues;

    let balkHeight = 0;
    let wallsOverallLength = 0;

    bearingWalls.map(item => {
      return (balkHeight = item.balkHeight), (wallsOverallLength = item.wallsOverallLength);
    });

    let l_nag = ((1 / 3) * balkHeight) / 1000 + balkHeight / 1000 + balkHeight / 1000 - 0.04;

    this.resultValue = {
      ...this.resultValue,
      L_nag: ((wallsOverallLength / 1.5) * N_venc * l_nag).toFixed(1),
    };
  }
  private N_nag() {
    const {bearingWalls} = this.values as IValues;
    const {N_venc} = this.resultValue as IResultValues;

    let wallsOverallLength = 0;

    bearingWalls.map(item => {
      return (wallsOverallLength = item.wallsOverallLength);
    });

    this.resultValue = {...this.resultValue, N_nag: Math.ceil((wallsOverallLength / 1.5) * N_venc)};
  }
  private V_prop() {
    const {bearingWalls} = this.values as IValues;
    const {V_br} = this.resultValue as IResultValues;

    let d = 10;
    let balkWidth = 0;
    let balkHeight = 0;

    bearingWalls.map(item => {
      return (balkHeight = item.balkHeight), (balkWidth = item.balkWidth);
    });

    this.resultValue = {
      ...this.resultValue,
      V_prop: Math.floor(
        ((((balkWidth / 1000) * balkHeight) / 1000 + (6 * balkWidth) / 1000 + (6 * balkHeight) / 1000) /
          ((((3 * balkHeight) / 1000) * balkWidth) / 1000)) *
          V_br *
          d,
      ),
    };
  }
  private Delta_usad() {
    const {bearingWalls, gables} = this.values as IValues;
    let wallsHeight = 0;
    let balkType = '';
    let gableType = 0;
    let gableHeight = 0;
    let gablePentaHeightBetween = 0;
    let gablePentaTriangleHeight = 0;

    bearingWalls.map(item => {
      return (wallsHeight = item.wallsCornerHeight), (balkType = item.balkType);
    });

    gables.map(item => {
      return (
        (gableType = item.gableType),
        (gableHeight += item.gableHeight),
        (gablePentaHeightBetween += item.gablePentaHeightBetween),
        (gablePentaTriangleHeight += item.gablePentaTriangleHeight)
      );
    });

    const calculateGableSquare = () => {
      let gableSquare = 0;
      switch (gableType) {
        case 0:
          return (gableSquare += gableHeight);
        case 1:
          return (gableSquare += gableHeight);
        case 2:
          return (gableSquare += gablePentaHeightBetween + gablePentaTriangleHeight);
      }
      return gableSquare;
    };

    const calculateDeltaUsad = () => {
      let D_usad = 0;
      switch (balkType) {
        case 'Бревно оцилиндрованное':
          return (D_usad = (wallsHeight + calculateGableSquare()) * 0.08).toFixed(3);
        case 'Брус прямоугольного сечения':
          return (D_usad = (wallsHeight + calculateGableSquare()) * 0.07).toFixed(3);
        case 'Брус профилированный':
          return (D_usad = (wallsHeight + calculateGableSquare()) * 0.05).toFixed(3);
        case 'Брус клееный':
          return (D_usad = (wallsHeight + calculateGableSquare()) * 0.01).toFixed(3);
      }
    };
    this.resultValue = {...this.resultValue, Delta_usad: calculateDeltaUsad()};
  }
  private N_fund() {
    const {bearingWalls} = this.values as IValues;
    const {V_br} = this.resultValue as IResultValues;
    let balkDensity = 0;
    let wallsLength = 0;
    let balkWidth = 0;

    bearingWalls.map(item => {
      return (balkDensity = item.balkDensity), (wallsLength = item.wallsOverallLength), (balkWidth = item.balkWidth);
    });

    let n_fund = V_br * balkDensity;
    let S_fund = wallsLength * balkWidth;

    this.resultValue = {...this.resultValue, N_fund: Math.ceil(n_fund / S_fund)};
  }

  calculate(values: IValues) {
    this.values = values;
    this.S_st();
    this.V_br();
    this.M_br();
    this.N_venc();
    this.L_ut_rul();
    this.D_nag();
    this.L_nag();
    this.N_nag();
    this.V_prop();
    this.Delta_usad();
    this.N_fund();
    return this.resultValue;
  }
}

const calculation3Service = new Calculation3Service();
export default calculation3Service;
