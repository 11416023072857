import {StoreonModule} from 'storeon';
import {IEvents, IState} from '~src/store/index';

export interface IModalsStore {
  feedbackModal: boolean;
}

export const modalsModule: StoreonModule<IState, IEvents> = store => {
  store.on('modals/show-modal', ({modals}, payload) => ({modals: {...modals, [payload.key]: payload.value}}));
};
