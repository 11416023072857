export interface IBearingWall {
  brickType: string;
  executionType: number;
  brickDefaultSize: number;
  brickWidth: number;
  brickLength: number;
  brickHeight: number;
  brickWeight: number;
  trimMargin: number;
  masonryOption: number;
  seamThickness: number;
  masonryNet: number;
  voidness: boolean;
}

export interface IFacade {
  facadeType: string | number;
  facadeDefaultSize: boolean;
  facadeBrickWidth: number | null;
  facadeBrickLength: number | null;
  facadeBrickHeight: number | null;
  facadeBrickWeight: number | null;
  facadeSeamThickness: number | null;
  facadeTrimMargin: number | null;
  facadePanelWidth: number | null;
  facadePanelLength: number | null;
  facadePanelSquare: number | null;
  facadeWeight: number | null;
}

export interface IWall {
  wallsLength: number | null;
  wallsHeight: number | null;
  wallsOuterAngleCount: number;
  wallsInnerAngleCount: number;
}

export interface IInsulation {
  insulationType: string;
  insulationThickness: number;
  insulationDensity: number;
}

export interface IGable {
  gableType: number; // общий для всех типов
  gableHeight: number; // общий для треугольника и трапеции
  gableCount: number; // общий для всех типов
  gableWidth: number;

  gableSmallerWidth: number; // общий для трапеции и пятиугольника
  gableBiggerWidth: number; // общий для трапеции и пятиугольника

  gablePentaHeightBetween: number;
  gablePentaTriangleHeight: number;

  enabledGableInput: string;
}

export interface IWindow {
  windowHeight: number;
  windowWidth: number;
  windowCount: number;
}

export interface IArmobelt {
  armobeltHeight: number;
  armobeltCount: number;
}

export interface IDoor {
  doorHeight: number;
  doorWidth: number;
  doorCount: number;
}

export interface IJumper {
  jumperLength: number;
  jumperWidth: number;
  jumperHeight: number;
  jumperCount: number;
}

export interface ITrim {
  trimType: string;
  trimThickness: number | null;
  trimLength: number | null;
  trimWidth: number | null;
  trimHeight: number | null;
  trimWeight: number | null;
}

export interface IPartition {
  partitionBrickType: string;
  partitionExecutionType: number;
  partitionBrickDefaultSize: number;
  partitionBrickWidth: number;
  partitionBrickLength: number;
  partitionBrickHeight: number;
  partitionBrickWeight: number;
  partitionTrimMargin: number;
  partitionMasonryOption: number;
  partitionSeamThickness: number;
  partitionMasonryNet: number;
  partitionWallsLength: number;
  partitionWallsHeight: number;
}

export interface IPartitionDoors {
  partitionDoorHeight: number | null;
  partitionDoorWidth: number | null;
  partitionDoorCount: number | null;
}

export interface IPartitionJumpers {
  partitionJumperLength: number | null;
  partitionJumperThickness: number | null;
  partitionJumperCount: number | null;
}

export interface IValues {
  bearingWalls: IBearingWall[];
  facades: IFacade[];
  walls: IWall[];
  insulation: IInsulation[];
  gables: IGable[];
  windows: IWindow[];
  armobelts: IArmobelt[];
  doors: IDoor[];
  jumpers: IJumper[];
  trims: ITrim[];
  partitions: IPartition[];
  partitionDoors: IPartitionDoors[];
  partitionJumpers: IPartitionJumpers[];
  enabledFlags: string[];
  enabledSubFlags: string[];
  isInvalid: boolean;
  bearingWallImage: string;
  facadeImage: string;
  insulationImage: string;
}

export interface IResultValues {
  calculationSubtype: number;
  // Несущие конструкции
  V_st: number;
  S_st: number;
  N_bl_m3: number;
  N_bl: number;
  V_bl: number;
  N_kl_set: number;
  N_bl_zapas: number;
  V_p_p: number;
  M_p_p: number;
  N_p_p: number;
  // толщина несущей кладки
  T_kl: number;
  // Фасадные материалы
  // Кирпич
  S_st_facade: number;
  V_st_facade: number;
  N_bl_m3_facade: number;
  N_bl_facade: number;
  V_bl_facade: number;
  N_bl_zapas_facade: number;
  V_p_p_facade: number;
  M_p_p_facade: number;
  N_p_p_facade: number;
  // Штукатурка
  V_sht: number;
  M_sht: number;
  N_sht: number;
  // Прочие типы фасадов
  N_fp: number;
  N_vf: number;
  N_sp: number;
  // толщина фасадного слоя
  T_fac: number;
  // Теплоизоляция
  V_ut: number;
  S_ut: number;
  // толщина утеплителя
  T_ut: number;
  // Отделка
  N_gkl: number;
  V_sht_trim: number;
  M_sht_trim: number;
  N_sht_trim: number;
  // Перегородки
  V_st_per: number;
  S_st_per: number;
  N_bl_m3_per: number;
  N_bl_per: number;
  V_bl_per: number;
  N_kl_set_per: number;
  N_bl_zapas_per: number;
  V_p_p_per: number;
  M_p_p_per: number;
  N_p_p_per: number;
  // Дополнительно
  L_ok: number;
  S_ok: number;
  L_dv: number;
  S_dv: number;
  L_dv_per: number;
  S_dv_per: number;
  // Откосы
  L_ok_slopes: number;
  S_ok_slopes: number;
  L_dv_slopes: number;
  S_dv_slopes: number;
  L_dv_per_slopes: number;
  S_dv_per_slopes: number;
  // Нагрузка на фундамент
  N_fund: number;
  N_fund_per: number;
}

class Calculation2Service {
  values: IValues | {} = {};
  resultValue: IResultValues | {} = {};
  private N_kl_set() {
    const {bearingWalls, walls, facades, windows, doors, enabledFlags} = this.values as IValues;
    const {L_ok, L_dv} = this.resultValue as IResultValues;
    let masonryOption = 0;
    let brickWidth = 0;
    let facadeBrickWidth = 0;
    let brickLength = 0;
    let brickHeight = 0;
    let seamThickness = 0;
    let b_kl = 0;
    let wallsHeight = 0;
    let wallsLength = 0;
    let masonryNet = 0;

    bearingWalls.map(item => {
      return (
        (masonryOption = item.masonryOption),
        (brickWidth = item.brickWidth / 1000),
        (brickLength = item.brickLength / 1000),
        (seamThickness = item.seamThickness / 1000),
        (brickHeight = item.brickHeight / 1000),
        (masonryNet = item.masonryNet)
      );
    });

    walls.map(item => {
      return (wallsHeight = item.wallsHeight), (wallsLength = item.wallsLength);
    });

    facades.map(item => {
      return (facadeBrickWidth = item.facadeBrickWidth / 1000);
    });

    // b_кл
    switch (masonryOption) {
      case 0.5:
        b_kl = brickWidth;
        break;
      case 1:
        b_kl = brickLength;
        break;
      case 1.5:
        b_kl = brickLength + brickWidth + seamThickness;
        break;
      case 2:
        b_kl = brickLength * 2 + seamThickness;
        break;
    }

    let N_s = Math.round(wallsHeight / (masonryNet * brickHeight));
    let b_set = Math.round((b_kl + facadeBrickWidth) * 100) / 100;
    let N_kl_set = wallsLength * N_s * b_set;

    // для дверей
    let doorsHeight = 0;
    let doorsWidth = 0;

    doors.map(item => {
      return (doorsHeight += item.doorHeight / 1000), (doorsWidth += item.doorWidth / 1000);
    });

    let N_s_dv = Math.round(doorsHeight / (masonryNet * brickHeight));
    let N_kl_dv = Math.round(L_dv * N_s_dv * b_set);

    if (enabledFlags.find(item => item === 'doors')) {
      N_kl_set -= N_kl_dv;
    }

    // для окон
    let windowsHeight = 0;
    let windowsWidth = 0;

    windows.map(item => {
      return (windowsHeight += item.windowHeight / 1000), (windowsWidth += item.windowWidth / 1000);
    });

    let N_s_ok = Math.round(windowsHeight / (masonryNet * brickHeight));
    let N_kl_ok = Math.round(L_ok * N_s_ok * b_set);

    if (enabledFlags.find(item => item === 'windows')) {
      N_kl_set -= N_kl_ok;
    }

    this.resultValue = {...this.resultValue, N_kl_set: Math.round(N_kl_set * 100) / 100};
  }
  private V_st() {
    const {bearingWalls, walls, gables, armobelts, jumpers, enabledFlags} = this.values as IValues;
    const {S_ok, S_dv} = this.resultValue as IResultValues;
    let wallsLength = 0;
    let wallsHeight = 0;
    let wallsOuterAngleCount = 0;
    let wallsInnerAngleCount = 0;
    let brickWidth = 0;
    let brickLength = 0;
    let gableType = 0;
    let gableHeight = 0;
    let gableCount = 0;
    let gableWidth = 0;
    let gableSmallerWidth = 0;
    let gableBiggerWidth = 0;
    let gablePentaHeightBetween = 0;
    let gablePentaTriangleHeight = 0;
    let armobeltHeight = 0;
    let armobeltLength = 0;
    let armobeltWidth = 0;
    let armobeltCount = 0;
    let masonryOption = 0;
    let b_kl = 0;
    let seamThickness = 0;

    bearingWalls.map(item => {
      return (
        (brickWidth = item.brickWidth / 1000),
        (brickLength = item.brickLength / 1000),
        (masonryOption = item.masonryOption),
        (seamThickness = item.seamThickness / 1000)
      );
    });

    // b_кл
    switch (masonryOption) {
      case 0.5:
        b_kl = brickWidth;
        break;
      case 1:
        b_kl = brickLength;
        break;
      case 1.5:
        b_kl = brickLength + brickWidth + seamThickness;
        break;
      case 2:
        b_kl = brickLength * 2 + seamThickness;
        break;
    }

    walls.map(item => {
      return (
        (wallsHeight = item.wallsHeight),
        (wallsLength = item.wallsLength),
        (wallsOuterAngleCount = item.wallsOuterAngleCount),
        (wallsInnerAngleCount = item.wallsInnerAngleCount)
      );
    });

    gables.map(item => {
      return (
        (gableType = item.gableType),
        (gableHeight = item.gableHeight),
        (gableCount = item.gableCount),
        (gableWidth = item.gableWidth),
        (gableSmallerWidth = item.gableSmallerWidth),
        (gableBiggerWidth = item.gableBiggerWidth),
        (gablePentaHeightBetween = item.gablePentaHeightBetween),
        (gablePentaTriangleHeight = item.gablePentaTriangleHeight)
      );
    });

    armobelts.map(item => {
      return (
        (armobeltHeight += item.armobeltHeight / 100),
        (armobeltLength += wallsLength / 1000),
        (armobeltWidth += brickWidth),
        (armobeltCount += item.armobeltCount)
      );
    });

    let V_per = 0;
    if (enabledFlags.find(item => item === 'jumpers')) {
      jumpers.map(item => {
        V_per +=
          (item.jumperHeight / 100) * (item.jumperLength / 100) * (item.jumperWidth / 100) * (item.jumperCount / 100);
        return V_per;
      });
    }

    let gableSquare = 0;
    const determineGableSquare = () => {
      let gableSquare = 0;
      if (!enabledFlags.find(item => item === 'gables')) {
        return (gableSquare = 0);
      }

      gables.map(item => {
        switch (item.gableType) {
          case 0:
            return (gableSquare += 0.5 * item.gableWidth * item.gableHeight * item.gableCount);
          case 1:
            return (gableSquare +=
              0.5 * item.gableHeight * (item.gableSmallerWidth + item.gableBiggerWidth) * item.gableCount);
          case 2:
            return (gableSquare +=
              (0.5 * item.gablePentaHeightBetween * (item.gableSmallerWidth + item.gableBiggerWidth) +
                0.5 * item.gableSmallerWidth * item.gablePentaTriangleHeight) *
              item.gableCount);
        }
      });

      return gableSquare;
    };

    let V_gr1 =
      wallsHeight * b_kl * wallsLength -
      wallsOuterAngleCount * Math.pow(b_kl, 2) * wallsHeight +
      wallsInnerAngleCount * Math.pow(b_kl, 2) * wallsHeight;

    let V_gr2 = b_kl * determineGableSquare();
    let V_o_pr: number;
    if (!enabledFlags.find(item => item === 'windows')) {
      V_o_pr = 0;
    } else {
      V_o_pr = b_kl * S_ok;
    }

    let V_d_pr: number;
    if (!enabledFlags.find(item => item === 'doors')) {
      V_d_pr = 0;
    } else {
      V_d_pr = b_kl * S_dv;
    }

    let V_pr = V_o_pr + V_d_pr;

    let V_ar = (armobeltHeight * armobeltLength * armobeltWidth * armobeltCount) / 10;
    if (!enabledFlags.find(item => item === 'armobelt')) {
      V_ar = 0;
    }

    let V = V_gr1 + V_gr2 - V_pr - V_per / 10 - V_ar;

    this.resultValue = {...this.resultValue, V_st: Math.round(V * 100) / 100};
  }
  private S_st() {
    const {gables, walls, enabledFlags, jumpers} = this.values as IValues;
    const {S_dv, S_ok} = this.resultValue as IResultValues;
    let gableType = 0;
    let gableHeight = 0;
    let gableCount = 0;
    let gableWidth = 0;
    let gableSmallerWidth = 0;
    let gableBiggerWidth = 0;
    let gablePentaHeightBetween = 0;
    let gablePentaTriangleHeight = 0;
    let gableSquare = 0;
    let jumperSquare = 0;

    let wallsLength = 0;
    let wallsHeight = 0;

    walls.map(item => {
      return (wallsLength = item.wallsLength), (wallsHeight = item.wallsHeight);
    });

    gables.map(item => {
      return (
        (gableType = item.gableType),
        (gableHeight = item.gableHeight),
        (gableCount = item.gableCount),
        (gableWidth = item.gableWidth),
        (gableSmallerWidth = item.gableSmallerWidth),
        (gableBiggerWidth = item.gableBiggerWidth),
        (gablePentaHeightBetween = item.gablePentaHeightBetween),
        (gablePentaTriangleHeight = item.gablePentaTriangleHeight)
      );
    });

    const determineGableSquare = () => {
      let gableSquare = 0;

      if (!enabledFlags.find(item => item === 'gables')) {
        return (gableSquare = 0);
      }

      gables.map(item => {
        switch (item.gableType) {
          case 0:
            return (gableSquare += 0.5 * item.gableWidth * item.gableHeight * item.gableCount);
          case 1:
            return (gableSquare +=
              0.5 * item.gableHeight * (item.gableSmallerWidth + item.gableBiggerWidth) * item.gableCount);
          case 2:
            return (gableSquare +=
              (0.5 * item.gablePentaHeightBetween * (item.gableSmallerWidth + item.gableBiggerWidth) +
                0.5 * item.gableSmallerWidth * item.gablePentaTriangleHeight) *
              item.gableCount);
        }
      });

      return gableSquare;
    };

    if (enabledFlags.find(item => item === 'jumpers')) {
      jumpers.map(jumper => {
        jumperSquare += (jumper.jumperHeight / 1000) * (jumper.jumperWidth / 1000) * jumper.jumperCount;
      });
    }

    let S_st_result = wallsLength * wallsHeight + determineGableSquare() - S_ok - S_dv - jumperSquare;
    this.resultValue = {...this.resultValue, S_st: Math.round(S_st_result * 100) / 100};
  }
  private S_st_facade() {
    const {gables, walls, insulation, facades, enabledFlags} = this.values as IValues;
    const {S_dv, S_ok} = this.resultValue as IResultValues;
    let gableType = 0;
    let gableHeight = 0;
    let gableCount = 0;
    let gableWidth = 0;
    let gableSmallerWidth = 0;
    let gableBiggerWidth = 0;
    let gablePentaHeightBetween = 0;
    let gablePentaTriangleHeight = 0;
    let gableSquare = 0;

    let wallsLength = 0;
    let wallsHeight = 0;
    let wallsOuterAngleCount = 0;
    let wallsInnerAngleCount = 0;

    let insulationThickness = 0;

    let facadeBrickWidth = 0;

    walls.map(item => {
      return (
        (wallsLength = item.wallsLength),
        (wallsHeight = item.wallsHeight),
        (wallsOuterAngleCount = item.wallsOuterAngleCount),
        (wallsInnerAngleCount = item.wallsInnerAngleCount)
      );
    });

    gables.map(item => {
      return (
        (gableType = item.gableType),
        (gableHeight = item.gableHeight),
        (gableCount = item.gableCount),
        (gableWidth = item.gableWidth),
        (gableSmallerWidth = item.gableSmallerWidth),
        (gableBiggerWidth = item.gableBiggerWidth),
        (gablePentaHeightBetween = item.gablePentaHeightBetween),
        (gablePentaTriangleHeight = item.gablePentaTriangleHeight)
      );
    });

    insulation.map(item => {
      if (enabledFlags.find(item => item === 'insulationPanel')) {
        return (insulationThickness = item.insulationThickness / 1000);
      }
    });

    facades.map(item => {
      return (facadeBrickWidth = item.facadeBrickWidth / 1000);
    });

    const determineGableSquare = () => {
      let gableSquare = 0;

      if (!enabledFlags.find(item => item === 'gables')) {
        return (gableSquare = 0);
      }

      gables.map(item => {
        switch (item.gableType) {
          case 0:
            return (gableSquare += 0.5 * item.gableWidth * item.gableHeight * item.gableCount);
          case 1:
            return (gableSquare +=
              0.5 * item.gableHeight * (item.gableSmallerWidth + item.gableBiggerWidth) * item.gableCount);
          case 2:
            return (gableSquare +=
              (0.5 * item.gablePentaHeightBetween * (item.gableSmallerWidth + item.gableBiggerWidth) +
                0.5 * item.gableSmallerWidth * item.gablePentaTriangleHeight) *
              item.gableCount);
        }
      });

      return gableSquare;
    };

    let p_obl =
      wallsLength +
      insulationThickness * wallsOuterAngleCount -
      insulationThickness * wallsInnerAngleCount +
      facadeBrickWidth * wallsOuterAngleCount -
      facadeBrickWidth * wallsInnerAngleCount +
      0.03 * wallsOuterAngleCount -
      0.03 * wallsInnerAngleCount;

    let result = p_obl * wallsHeight + determineGableSquare() - S_ok - S_dv;

    this.resultValue = {
      ...this.resultValue,
      S_st_facade: Math.round((result * 100) / 100),
    };
  }
  private V_st_facade() {
    const {facades} = this.values as IValues;
    const {S_st_facade} = this.resultValue as IResultValues;
    let brickWidth = 0;
    facades.map(item => {
      return (brickWidth = item.facadeBrickWidth / 1000);
    });
    let V_st_facade = S_st_facade * brickWidth;
    this.resultValue = {...this.resultValue, V_st_facade: Math.round(V_st_facade * 100) / 100};
  }
  private N_bl_m3_facade() {
    const {facades} = this.values as IValues;
    let facadeBrickHeight = 0;
    let facadeBrickLength = 0;
    let facadeBrickWidth = 0;
    let facadeSeamThickness = 0;
    facades.map(item => {
      return (
        (facadeBrickHeight = item.facadeBrickHeight),
        (facadeBrickLength = item.facadeBrickLength),
        (facadeBrickWidth = item.facadeBrickWidth),
        (facadeSeamThickness = item.facadeSeamThickness)
      );
    });
    this.resultValue = {
      ...this.resultValue,
      N_bl_m3_facade: Math.ceil(
        1 /
          (((facadeBrickLength / 1000 + facadeSeamThickness / 1000) *
            (facadeBrickHeight / 1000 + facadeSeamThickness / 1000) *
            facadeBrickWidth) /
            1000),
      ),
    };
  }
  private N_bl_facade() {
    const {facades} = this.values as IValues;
    const {N_bl_m3_facade, V_st_facade} = this.resultValue as IResultValues;
    let trimMargin = 0;
    facades.map(item => {
      return (trimMargin = item.facadeTrimMargin / 100);
    });
    let n_bl_zapas = Math.ceil(N_bl_m3_facade * trimMargin);
    let result = Math.ceil(N_bl_m3_facade + n_bl_zapas) * V_st_facade;

    this.resultValue = {...this.resultValue, N_bl_facade: Math.ceil(result)};
  }
  private V_bl_facade() {
    const {facades} = this.values as IValues;
    const {N_bl_facade} = this.resultValue as IResultValues;
    let brickWidth = 0;
    let brickHeight = 0;
    let brickLength = 0;
    facades.map(item => {
      return (
        (brickWidth = item.facadeBrickWidth),
        (brickHeight = item.facadeBrickHeight),
        (brickLength = item.facadeBrickLength)
      );
    });
    this.resultValue = {
      ...this.resultValue,
      V_bl_facade: Math.ceil((brickWidth / 1000) * (brickLength / 1000) * (brickHeight / 1000) * N_bl_facade),
    };
  }
  private N_bl_zapas_facade() {
    const {facades} = this.values as IValues;
    const {N_bl_facade} = this.resultValue as IResultValues;
    let trimMargin = 0;
    facades.map(item => {
      return (trimMargin = item.facadeTrimMargin / 100);
    });
    this.resultValue = {...this.resultValue, N_bl_zapas_facade: Math.ceil(N_bl_facade * trimMargin)};
  }
  private V_p_p_facade() {
    const {N_bl_facade} = this.resultValue as IResultValues;
    const {facades} = this.values as IValues;

    let facadeWallType = '';
    let seamThickness = 0;
    let facadeBrickLength = 0;
    let facadeBrickHeight = 0;
    let facadeBrickWidth = 0;
    let v_p_p = 0;

    facades.map(item => {
      return (
        (facadeWallType = item.facadeType),
        (seamThickness = item.facadeSeamThickness / 1000),
        (facadeBrickLength = item.facadeBrickLength / 1000),
        (facadeBrickHeight = item.facadeBrickHeight / 1000),
        (facadeBrickWidth = item.facadeBrickWidth / 1000)
      );
    });

    switch (facadeWallType) {
      case 'Клинкерный керамический кирпич':
      case 'Облицовочный керамический кирпич':
        v_p_p =
          (seamThickness * facadeBrickLength * facadeBrickWidth +
            seamThickness * facadeBrickHeight * facadeBrickWidth) *
          N_bl_facade;
        break;
      default:
        v_p_p =
          (seamThickness * facadeBrickLength * facadeBrickWidth +
            seamThickness * facadeBrickHeight * facadeBrickWidth) *
          N_bl_facade *
          1.728;
        break;
    }

    this.resultValue = {
      ...this.resultValue,
      V_p_p_facade: Math.round(v_p_p * 100) / 100,
    };
  }
  private M_p_p_facade() {
    const {V_p_p_facade} = this.resultValue as IResultValues;
    this.resultValue = {...this.resultValue, M_p_p_facade: Math.round(V_p_p_facade * 1.8 * 100) / 100};
  }
  private N_p_p_facade() {
    const {M_p_p_facade} = this.resultValue as IResultValues;
    this.resultValue = {...this.resultValue, N_p_p_facade: Math.ceil((M_p_p_facade * 1000) / 25)};
  }
  private N_fp() {
    const {facades} = this.values as IValues;
    const {S_st} = this.resultValue as IResultValues;
    let trimMargin = 0;
    let facadePanelSquare = 0;
    let facadePanelWidth = 0;
    let facadePanelLength = 0;
    let facadeType = '';

    facades.map(item => {
      return (
        (trimMargin = item.facadeTrimMargin / 100 + 1),
        (facadePanelSquare = item.facadePanelSquare / 1000),
        (facadeType = item.facadeType),
        (facadePanelWidth = item.facadePanelWidth / 1000),
        (facadePanelLength = item.facadePanelLength / 1000)
      );
    });

    let facadePanelResult = 0;
    switch (facadeType) {
      case 'Фасадные панели':
        facadePanelResult = (trimMargin * S_st) / facadePanelSquare;
        break;
      case 'Вентилируемый фасад':
      case 'Сайдинг':
        facadePanelResult = (trimMargin * S_st) / (facadePanelWidth * facadePanelLength);
        break;
    }
    this.resultValue = {...this.resultValue, N_fp: Math.round((facadePanelResult * 100) / 100)};
  }
  private N_bl_m3() {
    const {bearingWalls} = this.values as IValues;
    let brickHeight = 0;
    let brickLength = 0;
    let brickWidth = 0;
    let seamThickness = 0;
    bearingWalls.map(item => {
      return (
        (brickHeight = item.brickHeight),
        (brickLength = item.brickLength),
        (brickWidth = item.brickWidth),
        (seamThickness = item.seamThickness)
      );
    });

    this.resultValue = {
      ...this.resultValue,
      N_bl_m3: Math.ceil(
        Math.ceil(
          1 /
            ((brickLength / 1000 + seamThickness / 1000) *
              (brickHeight / 1000 + seamThickness / 1000) *
              (brickWidth / 1000)),
        ),
      ),
    };
  }
  private N_bl() {
    const {bearingWalls} = this.values as IValues;
    const {N_bl_m3, V_st} = this.resultValue as IResultValues;
    let trimMargin = 0;
    bearingWalls.map(item => {
      return (trimMargin = item.trimMargin / 100);
    });
    let n_bl_zapas = Math.ceil(N_bl_m3 * trimMargin);
    let result = Math.ceil(N_bl_m3 + n_bl_zapas) * V_st;
    this.resultValue = {...this.resultValue, N_bl: Math.ceil(Math.round(result * 100) / 100)};
  }
  private V_bl() {
    const {bearingWalls} = this.values as IValues;
    const {N_bl} = this.resultValue as IResultValues;
    let brickWidth = 0;
    let brickLength = 0;
    let brickHeight = 0;
    bearingWalls.map(item => {
      return (brickWidth = item.brickWidth), (brickLength = item.brickLength), (brickHeight = item.brickHeight);
    });
    let v_bl = ((brickWidth / 100) * (brickLength / 100) * (brickHeight / 100) * N_bl * 100) / 100 / 1000;
    this.resultValue = {
      ...this.resultValue,
      V_bl: Math.round(v_bl * 100) / 100,
    };
  }
  private N_bl_zapas() {
    const {bearingWalls} = this.values as IValues;
    const {N_bl} = this.resultValue as IResultValues;
    let trimMargin = 0;
    bearingWalls.map(item => {
      return (trimMargin = item.trimMargin / 100);
    });
    this.resultValue = {...this.resultValue, N_bl_zapas: Math.ceil(N_bl * trimMargin)};
  }
  private V_p_p() {
    const {N_bl} = this.resultValue as IResultValues;
    const {bearingWalls} = this.values as IValues;

    let bearingWallType = '';
    let seamThickness = 0;
    let brickLength = 0;
    let brickHeight = 0;
    let brickWidth = 0;
    let v_p_p = 0;
    let masonryOption = 0;
    bearingWalls.map(item => {
      return (
        (bearingWallType = item.brickType),
        (seamThickness = item.seamThickness / 1000),
        (brickLength = item.brickLength / 1000),
        (brickHeight = item.brickHeight / 1000),
        (brickWidth = item.brickWidth / 1000),
        (masonryOption = item.masonryOption)
      );
    });

    switch (bearingWallType) {
      case 'Шлакоблок':
      case 'Газобетонные блоки':
      case 'Пенобетонные блоки':
      case 'Керамический блок':
        v_p_p = seamThickness * brickLength * brickWidth * N_bl;
        break;
      default:
        if (masonryOption === 0.5) {
          v_p_p = (seamThickness * brickLength * brickWidth + seamThickness * brickHeight * brickWidth) * N_bl;
        } else {
          v_p_p = (seamThickness * brickLength * brickWidth + seamThickness * brickHeight * brickWidth) * N_bl * 1.728;
        }
        break;
    }

    this.resultValue = {
      ...this.resultValue,
      V_p_p: Math.round(v_p_p * 100) / 100,
    };
  }
  private M_p_p() {
    const {V_p_p} = this.resultValue as IResultValues;
    this.resultValue = {...this.resultValue, M_p_p: Math.round(V_p_p * 1.8 * 10) / 10};
  }
  private N_p_p() {
    const {M_p_p} = this.resultValue as IResultValues;
    this.resultValue = {...this.resultValue, N_p_p: Math.ceil((M_p_p * 1000) / 25)};
  }
  private V_sht() {
    const {facades} = this.values as IValues;
    const {S_st_facade} = this.resultValue as IResultValues;

    let facadePlasterThickness = 0;

    facades.map(item => {
      return (facadePlasterThickness = item.facadeBrickWidth / 1000);
    });

    let V_sht = S_st_facade * facadePlasterThickness;

    this.resultValue = {...this.resultValue, V_sht: Math.ceil(V_sht * 1000) / 1000};
  }
  private M_sht() {
    const {facades} = this.values as IValues;
    const {S_st_facade} = this.resultValue as IResultValues;

    let facadePlasterThickness = 0;

    facades.map(item => {
      return (facadePlasterThickness = item.facadeBrickWidth / 1000);
    });

    let M_sht = S_st_facade * 1.4 * facadePlasterThickness;

    this.resultValue = {...this.resultValue, M_sht: Math.ceil(M_sht * 1000) / 1000};
  }
  private N_sht() {
    const {M_sht} = this.resultValue as IResultValues;

    let N_sht = M_sht * 1000 / 25;

    this.resultValue = {...this.resultValue, N_sht: Math.ceil(N_sht)};
  }
  private S_ut() {
    const {S_st} = this.resultValue as IResultValues;
    this.resultValue = {...this.resultValue, S_ut: S_st};
  }
  private V_ut() {
    const {insulation} = this.values as IValues;
    const {S_ut} = this.resultValue as IResultValues;
    let insulationThickness = 0;
    insulation.map(item => {
      return (insulationThickness = item.insulationThickness / 100);
    });
    this.resultValue = {...this.resultValue, V_ut: Math.ceil(S_ut * insulationThickness)};
  }
  private S_st_per() {
    const {partitions} = this.values as IValues;
    const {S_dv_per} = this.resultValue as IResultValues;
    let wallsHeight = 0;
    let wallsLength = 0;
    partitions.map(item => {
      return (wallsHeight = item.partitionWallsHeight), (wallsLength = item.partitionWallsLength);
    });
    this.resultValue = {...this.resultValue, S_st_per: wallsLength * wallsHeight - S_dv_per};
  }
  private V_st_per() {
    const {partitions} = this.values as IValues;
    const {S_st_per} = this.resultValue as IResultValues;
    let masonryOption = 0;
    let brickWidth = 0;
    let brickLength = 0;
    let seamThickness = 0;
    let b_kl = 0;

    partitions.map(item => {
      return (
        (brickWidth = item.partitionBrickWidth),
        (brickLength = item.partitionBrickLength),
        (seamThickness = item.partitionSeamThickness)
      );
    });

    switch (masonryOption) {
      case 0.5:
        b_kl = brickWidth;
        break;
      case 1:
        b_kl = brickLength;
        break;
      case 1.5:
        b_kl = brickLength + brickWidth + seamThickness;
        break;
      case 2:
        b_kl = brickLength * 2 + seamThickness;
        break;
    }

    let result = S_st_per * b_kl;
    this.resultValue = {...this.resultValue, V_st_per: Math.round(result * 100) / 100};
  }
  private N_bl_m3_per() {
    const {partitions} = this.values as IValues;
    let partitionBrickHeight = 0;
    let partitionBrickLength = 0;
    let partitionBrickWidth = 0;
    let partitionSeamThickness = 0;
    partitions.map(item => {
      return (
        (partitionBrickHeight = item.partitionBrickHeight),
        (partitionBrickLength = item.partitionBrickLength),
        (partitionBrickWidth = item.partitionBrickWidth),
        (partitionSeamThickness = item.partitionSeamThickness)
      );
    });
    this.resultValue = {
      ...this.resultValue,
      N_bl_m3_per: Math.ceil(
        1 /
          (((partitionBrickLength / 1000 + partitionSeamThickness / 1000) *
            (partitionBrickHeight / 1000 + partitionSeamThickness / 1000) *
            partitionBrickWidth) /
            1000),
      ),
    };
  }
  private N_bl_per() {
    const {partitions} = this.values as IValues;
    const {N_bl_m3_per, N_bl_zapas_per, S_dv_per} = this.resultValue as IResultValues;
    let wallsHeight = 0;
    let wallsLength = 0;
    let brickWidth = 0;
    partitions.map(item => {
      return (
        (wallsHeight = item.partitionWallsHeight),
        (wallsLength = item.partitionWallsLength),
        (brickWidth = item.partitionBrickWidth / 1000)
      );
    });

    const result = wallsLength * brickWidth * wallsHeight - S_dv_per * brickWidth;
    this.resultValue = {...this.resultValue, N_bl_per: Math.ceil(N_bl_m3_per * result)};
  }
  private V_bl_per() {
    const {partitions} = this.values as IValues;
    const {N_bl_per} = this.resultValue as IResultValues;
    let brickWidth = 0;
    let brickHeight = 0;
    let brickLength = 0;
    partitions.map(item => {
      return (
        (brickWidth = item.partitionBrickWidth / 1000),
        (brickHeight = item.partitionBrickHeight / 1000),
        (brickLength = item.partitionBrickLength / 1000)
      );
    });
    let result = brickWidth * brickLength * brickHeight * N_bl_per;
    this.resultValue = {
      ...this.resultValue,
      V_bl_per: Math.round(result * 100) / 100,
    };
  }
  private N_kl_set_per() {
    const {partitions, partitionDoors, enabledSubFlags} = this.values as IValues;
    let masonryOption = 0;
    let brickWidth = 0;
    let brickLength = 0;
    let brickHeight = 0;
    let seamThickness = 0;
    let wallsHeight = 0;
    let wallsLength = 0;
    let masonryNet = 0;
    let b_kl = 0;
    partitions.map(item => {
      return (
        (masonryOption = item.partitionMasonryOption),
        (brickWidth = item.partitionBrickWidth / 1000),
        (brickLength = item.partitionBrickLength / 1000),
        (seamThickness = item.partitionSeamThickness / 1000),
        (brickHeight = item.partitionBrickHeight / 1000),
        (wallsHeight = item.partitionWallsHeight),
        (wallsLength = item.partitionWallsLength),
        (masonryNet = item.partitionMasonryNet)
      );
    });

    //b_кл
    switch (masonryOption) {
      case 0.5:
        b_kl = brickWidth;
        break;
      case 1:
        b_kl = brickLength;
        break;
      case 1.5:
        b_kl = brickLength + brickWidth + seamThickness;
        break;
      case 2:
        b_kl = brickLength * 2 + seamThickness;
        break;
    }

    let N_s = Math.round(wallsHeight / (masonryNet * brickHeight));
    let b_set = Math.round((b_kl + brickWidth) * 100) / 100;
    let N_kl_set = wallsLength * N_s * b_set;

    // для дверей
    let doorsHeight = 0;
    let doorsWidth = 0;

    let doorsLength = 0;
    partitionDoors.map(door => {
      return (doorsLength +=
        (door.partitionDoorHeight / 100 + (2 * door.partitionDoorWidth) / 100) * door.partitionDoorCount);
    });

    doorsLength = Math.round(doorsLength * 100) / 100;

    partitionDoors.map(item => {
      return (doorsHeight += item.partitionDoorHeight / 1000), (doorsWidth += item.partitionDoorWidth / 1000);
    });

    let N_s_dv = Math.round(doorsHeight / (masonryNet * brickHeight));
    let N_kl_dv = Math.round(doorsLength * N_s_dv * b_set);

    if (enabledSubFlags.find(item => item === 'partitionDoors')) {
      N_kl_set -= N_kl_dv;
    }

    this.resultValue = {...this.resultValue, N_kl_set_per: Math.ceil(N_kl_set)};
  }
  private N_bl_zapas_per() {
    const {partitions} = this.values as IValues;
    const {N_bl_per} = this.resultValue as IResultValues;
    let trimMargin = 0;
    partitions.map(item => {
      return (trimMargin = item.partitionTrimMargin / 100);
    });
    this.resultValue = {...this.resultValue, N_bl_zapas_per: Math.ceil(N_bl_per * trimMargin)};
  }
  private V_p_p_per() {
    const {N_bl_per} = this.resultValue as IResultValues;
    const {partitions} = this.values as IValues;

    let partitionWallType = '';
    let seamThickness = 0;
    let brickLength = 0;
    let brickHeight = 0;
    let brickWidth = 0;
    let v_p_p = 0;
    let masonryOption = 0;

    partitions.map(item => {
      return (
        (partitionWallType = item.partitionBrickType),
        (seamThickness = item.partitionSeamThickness / 1000),
        (brickLength = item.partitionBrickLength / 1000),
        (brickHeight = item.partitionBrickHeight / 1000),
        (brickWidth = item.partitionBrickWidth / 1000),
        (masonryOption = item.partitionMasonryOption)
      );
    });

    switch (partitionWallType) {
      case 'Шлакоблок':
      case 'Газобетонные блоки':
      case 'Пенобетонные блоки':
      case 'Керамический блок':
        v_p_p = seamThickness * brickLength * brickWidth * N_bl_per;
        break;
      default:
        if (masonryOption === 0.5) {
          v_p_p = (seamThickness * brickLength * brickWidth + seamThickness * brickHeight * brickWidth) * N_bl_per;
        } else {
          v_p_p =
            (seamThickness * brickLength * brickWidth + seamThickness * brickHeight * brickWidth) * N_bl_per * 1.728;
        }
        break;
    }

    this.resultValue = {
      ...this.resultValue,
      V_p_p_per: Math.round(v_p_p * 100) / 100,
    };
  }
  private M_p_p_per() {
    const {V_p_p_per} = this.resultValue as IResultValues;
    this.resultValue = {...this.resultValue, M_p_p_per: Math.round(V_p_p_per * 1.8 * 10) / 10};
  }
  private N_p_p_per() {
    const {M_p_p_per} = this.resultValue as IResultValues;
    this.resultValue = {...this.resultValue, N_p_p_per: Math.ceil((M_p_p_per * 1000) / 25)};
  }
  private L_ok() {
    const {windows} = this.values as IValues;
    let windowCount = 0;
    let windowHeight = 0;
    let windowWidth = 0;

    windows.map(window => {
      return (
        (windowCount += window.windowCount), (windowHeight += window.windowHeight), (windowWidth += window.windowWidth)
      );
    });

    const windowsLength = 2 * (windowHeight / 100 + windowWidth / 100) * windowCount;
    this.resultValue = {...this.resultValue, L_ok: windowsLength};
  }
  private S_ok() {
    const {windows, enabledFlags} = this.values as IValues;
    let windowSquare = 0;

    windows.map(window => {
      windowSquare += (window.windowHeight / 100) * (window.windowWidth / 100) * window.windowCount;
    });

    if (!enabledFlags.find(item => item === 'windows')) {
      windowSquare = 0;
    }

    this.resultValue = {...this.resultValue, S_ok: Math.round(windowSquare * 100) / 100};
  }
  private L_dv() {
    const {doors} = this.values as IValues;
    let doorsLength = 0;
    doors.map(door => {
      return (doorsLength += (door.doorHeight / 100 + (2 * door.doorWidth) / 100) * door.doorCount);
    });
    this.resultValue = {...this.resultValue, L_dv: Math.round(doorsLength * 100) / 100};
  }
  private S_dv() {
    const {doors, enabledFlags} = this.values as IValues;
    let doorsSquare = 0;
    doors.map(door => {
      return (doorsSquare += (((door.doorHeight / 100) * door.doorWidth) / 100) * door.doorCount);
    });

    if (!enabledFlags.find(item => item === 'doors')) {
      doorsSquare = 0;
    }
    this.resultValue = {...this.resultValue, S_dv: doorsSquare};
  }
  private L_dv_per() {
    const {partitionDoors} = this.values as IValues;
    let partionDoorsLength = 0;
    partitionDoors.map(partitionDoor => {
      return (partionDoorsLength +=
        (partitionDoor.partitionDoorHeight / 100 + (2 * partitionDoor.partitionDoorWidth) / 100) *
        partitionDoor.partitionDoorCount);
    });
    this.resultValue = {...this.resultValue, L_dv_per: Math.round(partionDoorsLength * 100) / 100};
  }
  private S_dv_per() {
    const {partitionDoors} = this.values as IValues;
    let partitionDoorsSquare = 0;
    partitionDoors.map(partitionDoor => {
      return (partitionDoorsSquare +=
        (((partitionDoor.partitionDoorHeight / 100) * partitionDoor.partitionDoorWidth) / 100) *
        partitionDoor.partitionDoorCount);
    });
    this.resultValue = {...this.resultValue, S_dv_per: partitionDoorsSquare};
  }
  
  private T_ut() {
    const {enabledFlags, insulation} = this.values as IValues;

    let insulationThickness = 0;
    
    insulation.map(item => {
      return (insulationThickness = item.insulationThickness / 1000);
    });

    if (!enabledFlags.find(item => item === 'insulationPanel')) {
      insulationThickness = 0;
    }

    this.resultValue = {...this.resultValue, T_ut: insulationThickness};
  }
  private T_kl() {
    const {bearingWalls} = this.values as IValues;

    let bearingMasonryThickness = 0;
    let bearingWallType = '';
    let masonryOption = 0;
    let brickLength = 0;
    let seamThickness = 0;
    let brickWidth = 0;
    
    bearingWalls.map(item => {
      return (
        (bearingWallType = item.brickType),
        (masonryOption = item.masonryOption),
        (brickLength = item.brickLength / 1000),
        (brickWidth = item.brickWidth / 1000),
        (seamThickness = item.seamThickness / 1000)
      );
    });

    switch (bearingWallType) {
      case 'Керамический кирпич':
      case 'Силикатный кирпич':
        switch (masonryOption) {
          case 0.5:
            bearingMasonryThickness = brickWidth;
            break;
          case 1:
            bearingMasonryThickness = brickLength;
            break;
          case 1.5:
            bearingMasonryThickness = brickLength + seamThickness + brickWidth;
            break;
          case 2:
            bearingMasonryThickness = 2 * brickLength + seamThickness;
            break;
        }
        break;
      case 'Шлакоблок':
      case 'Керамический блок':
      case 'Пенобетонные блоки':
      case 'Газобетонные блоки':
        bearingMasonryThickness = brickWidth;
        break;
    }

    this.resultValue = {...this.resultValue, T_kl: bearingMasonryThickness};
  }
  private T_fac() {
    const {facades} = this.values as IValues;

    let facadeThickness = 0;
    let facadeType = '';
    let facadeBrickWidth = 0;

    facades.map(item => {
      return (
        (facadeType = item.facadeType),
        (facadeBrickWidth = item.facadeBrickWidth / 1000)
      );
    });

    switch (facadeType) {
      case 'Клинкерный керамический кирпич':
      case 'Облицовочный керамический кирпич':
      case 'Фасадная штукатурка':
        facadeThickness = facadeBrickWidth;
        break;
      case 'Вентилируемый фасад':
      case 'Сайдинг':
      case 'Фасадные панели':
        facadeThickness = 0.05;
        break;
    }

    this.resultValue = {...this.resultValue, T_fac: facadeThickness};
  }
  private L_ok_slopes() {
    const {windows, enabledFlags} = this.values as IValues;

    let windowsSlopesLength = 0;

    windows.map(window => {
      return (windowsSlopesLength += 2 * (window.windowHeight + window.windowWidth) / 100 * window.windowCount);
    });

    if (!enabledFlags.find(item => item === 'windows')) {
      windowsSlopesLength = 0;
    }

    this.resultValue = {...this.resultValue, L_ok_slopes: Math.round(windowsSlopesLength * 100) / 100};
  }
  private S_ok_slopes() {
    const {enabledFlags} = this.values as IValues;
    const {L_ok_slopes, T_ut, T_kl, T_fac} = this.resultValue as IResultValues;

    let windowsSlopesSquare = 0;

    windowsSlopesSquare = L_ok_slopes * (T_kl + T_ut + T_fac - .07);

    if (!enabledFlags.find(item => item === 'windows')) {
      windowsSlopesSquare = 0;
    }

    this.resultValue = {...this.resultValue, S_ok_slopes: Math.round(windowsSlopesSquare * 10000) / 10000};
  }
  private L_dv_slopes() {
    const {doors, enabledFlags} = this.values as IValues;

    let doorsSlopesLength = 0;

    doors.map(door => {
      return (doorsSlopesLength += (2 * door.doorHeight + door.doorWidth) / 100 * door.doorCount);
    });

    if (!enabledFlags.find(item => item === 'doors')) {
      doorsSlopesLength = 0;
    }

    this.resultValue = {...this.resultValue, L_dv_slopes: Math.round(doorsSlopesLength * 100) / 100};
  }
  private S_dv_slopes() {
    const {enabledFlags} = this.values as IValues;
    const {L_dv_slopes, T_ut, T_kl, T_fac} = this.resultValue as IResultValues;

    let doorsSlopesSquare = 0;

    doorsSlopesSquare = L_dv_slopes * (T_kl + T_ut + T_fac - .12);

    if (!enabledFlags.find(item => item === 'doors')) {
      doorsSlopesSquare = 0;
    }
    this.resultValue = {...this.resultValue, S_dv_slopes: Math.round(doorsSlopesSquare * 10000) / 10000};
  }
  private L_dv_per_slopes() {
    const {partitionDoors, enabledFlags, enabledSubFlags} = this.values as IValues;

    let partionDoorsSlopesLength = 0;

    partitionDoors.map(partitionDoor => {
      return (partionDoorsSlopesLength += (2 * partitionDoor.partitionDoorHeight + partitionDoor.partitionDoorWidth) / 100 
        * partitionDoor.partitionDoorCount);
    });

    if (!enabledFlags.find(item => item === 'partitions') || !enabledSubFlags.find(item => item === 'partitionDoors')) {
      partionDoorsSlopesLength = 0;
    }

    this.resultValue = {...this.resultValue, L_dv_per_slopes: Math.round(partionDoorsSlopesLength * 100) / 100};
  }
  private S_dv_per_slopes() {
    const {partitions, enabledFlags, enabledSubFlags} = this.values as IValues;
    const {L_dv_per_slopes} = this.resultValue as IResultValues;

    let partitionDoorsSlopesSquare = 0;
    let partitionMasonryThickness = 0;
    let partitionWallType = '';
    let masonryOption = 0;
    let brickLength = 0;
    let brickWidth = 0;
    let seamThickness = 0;

    partitions.map(item => {
      return (
        (partitionWallType = item.partitionBrickType),
        (masonryOption = item.partitionMasonryOption),
        (brickLength = item.partitionBrickLength / 1000),
        (brickWidth = item.partitionBrickWidth / 1000),
        (seamThickness = item.partitionSeamThickness / 1000)
      );
    });

    switch (partitionWallType) {
      case 'Керамический кирпич':
      case 'Силикатный кирпич':
        switch (masonryOption) {
          case 0.5:
            partitionMasonryThickness = brickWidth;
            break;
          case 1:
            partitionMasonryThickness = brickLength;
            break;
          case 1.5:
            partitionMasonryThickness = brickLength + seamThickness + brickWidth;
            break;
          case 2:
            partitionMasonryThickness = 2 * brickLength + seamThickness;
            break;
        }
        break;
      case 'Шлакоблок':
      case 'Керамический блок':
      case 'Пенобетонные блоки':
      case 'Газобетонные блоки':
        partitionMasonryThickness = brickWidth;
        break;
    }

    partitionDoorsSlopesSquare = L_dv_per_slopes * (partitionMasonryThickness - .07);

    if (!enabledFlags.find(item => item === 'partitions') || !enabledSubFlags.find(item => item === 'partitionDoors')) {
      partitionDoorsSlopesSquare = 0;
    }

    this.resultValue = {...this.resultValue, S_dv_per_slopes: Math.round(partitionDoorsSlopesSquare * 10000) / 10000};
  }
  private N_fund() {
    const {bearingWalls, insulation, facades, walls} = this.values as IValues;
    const {M_p_p, V_ut, N_bl, V_st, S_st, M_sht} = this.resultValue as IResultValues;

    let insulationDensity = 0;
    let insulationThickness = 0;
    let n_obl = 0;

    let facadeBrickHeight = 0;
    let facadeBrickLength = 0;
    let facadeBrickWidth = 0;
    let facadeSeamThickness = 0;
    let facadeBrickWeight = 0;

    let facadeType = '';

    let facadeWeight = 0;
    let facadePanelWidth = 0;
    let facadePanelLength = 0;
    let facadePanelSquare = 0;

    let bearingBrickWidth = 0;

    let wallsLength = 0;

    walls.map(item => {
      return (wallsLength = item.wallsLength);
    });

    let brickWeight = 0;

    facades.map(item => {
      return (
        (facadeBrickHeight = item.facadeBrickHeight),
        (facadeBrickLength = item.facadeBrickLength),
        (facadeBrickWidth = item.facadeBrickWidth),
        (facadePanelWidth = item.facadePanelWidth),
        (facadePanelLength = item.facadePanelLength),
        (facadePanelSquare = item.facadePanelSquare),
        (facadeSeamThickness = item.facadeSeamThickness),
        (facadeBrickWeight = item.facadeBrickWeight),
        (facadeType = item.facadeType),
        (facadeWeight = item.facadeWeight)
      );
    });

    if (facadeBrickHeight === null) {
      facadeBrickHeight = 0;
    }
    if (facadeBrickLength === null) {
      facadeBrickHeight = 0;
    }
    if (facadeSeamThickness === null) {
      facadeBrickHeight = 0;
    }
    if (facadeType === 'Фасадная штукатурка') {
      facadeBrickWeight = 25;
    }

    n_obl =
      1 /
      ((facadeBrickLength / 1000 + facadeSeamThickness / 1000) *
        (facadeBrickHeight / 1000 + facadeSeamThickness / 1000));

    if (
      facadeType === 'Фасадная штукатурка' ||
      facadeType === 'Сайдинг' ||
      facadeType === 'Вентилируемый фасад' ||
      facadeType === 'Фасадные панели'
    ) {
      n_obl = 0;
    }

    let M_bl = 0;
    bearingWalls.map(item => {
      return (
        (M_bl = Math.ceil(item.brickWeight * N_bl)),
        (brickWeight = item.brickWeight),
        (bearingBrickWidth = item.brickWidth)
      );
    });

    insulation.map(item => {
      return (insulationDensity = item.insulationDensity), (insulationThickness = item.insulationThickness / 1000);
    });

    let N_obl = S_st * n_obl;
    let M_obl_p_p = N_obl;
    let M_obl = N_obl * facadeBrickWeight;

    if (facadeWeight !== 0 && facadeWeight !== null) {
      M_obl = Math.round((facadeWeight * 100) / 100);
    }

    let M_kr = N_bl * brickWeight;
    let M_kl_r = N_bl * 1.4;

    let n_fund = Math.ceil(
      M_bl + M_kr + V_st * 1.4 + M_p_p + M_obl + M_sht + M_obl_p_p + M_kl_r + V_ut * insulationDensity,
    );

    let S_fund = wallsLength * (bearingBrickWidth / 1000 + insulationThickness + facadeBrickWidth / 1000);

    let result = n_fund / S_fund;

    this.resultValue = {
      ...this.resultValue,
      N_fund: Math.round((result * 100) / 100),
    };
  }
  private N_fund_per() {
    const {partitions, trims, walls} = this.values as IValues;
    const {N_bl_per, N_bl_zapas_per, M_p_p_per} = this.resultValue as IResultValues;
    let brickWeight = 0;
    let brickWidth = 0;
    let partitionWallsLength = 0;
    let trimThickness = 0;
    let wallsLength = 0;

    partitions.map(item => {
      return (
        (brickWeight = item.partitionBrickWeight),
        (brickWidth = item.partitionBrickWidth / 1000),
        (partitionWallsLength = item.partitionWallsLength)
      );
    });
    trims.map(item => {
      return (trimThickness = item.trimThickness);
    });
    walls.map(item => {
      return (wallsLength = item.wallsLength);
    });

    let M_per = (N_bl_per + N_bl_zapas_per) * brickWeight;
    let S_fund_per = partitionWallsLength * (brickWidth + 2 * trimThickness);

    let N_fund_result = (M_per + M_p_p_per) / S_fund_per;

    this.resultValue = {...this.resultValue, N_fund_per: Math.ceil(N_fund_result)};
  }
  private N_gkl() {
    const {trims} = this.values as IValues;
    const {S_st, S_st_per} = this.resultValue as IResultValues;

    let trimLength = 0;
    let trimWidth = 0;

    trims.map(item => {
      return (trimLength = item.trimLength / 1000), (trimWidth = item.trimWidth / 1000);
    });

    let n_gkl = (1.05 * (S_st + S_st_per)) / (trimLength * trimWidth);

    this.resultValue = {...this.resultValue, N_gkl: Math.round((n_gkl * 100) / 100)};
  }
  private V_sht_trim() {
    const {trims} = this.values as IValues;
    const {S_st} = this.resultValue as IResultValues;

    let trimThickness = 0;

    trims.map(item => {
      return (trimThickness = item.trimThickness / 1000);
    });

    let V_sht_trim = S_st * trimThickness;

    this.resultValue = {...this.resultValue, V_sht_trim: Math.ceil(V_sht_trim * 1000) / 1000};
  }
  private M_sht_trim() {
    const {trims} = this.values as IValues;
    const {S_st} = this.resultValue as IResultValues;

    let trimThickness = 0;

    trims.map(item => {
      return (trimThickness = item.trimThickness / 1000);
    });

    let M_sht_trim = S_st * 1.4 * trimThickness;

    this.resultValue = {...this.resultValue, M_sht_trim: Math.ceil(M_sht_trim * 1000) / 1000};
  }
  private N_sht_trim() {
    const {M_sht_trim} = this.resultValue as IResultValues;

    let N_sht_trim = M_sht_trim * 1000 / 25;

    this.resultValue = {...this.resultValue, N_sht_trim: Math.ceil(N_sht_trim)};
  }

  calculate(values: IValues) {
    this.values = values;
    // Окна и двери
    this.L_ok();
    this.S_ok();
    this.L_dv();
    this.S_dv();
    this.L_dv_per();
    this.S_dv_per();
    // Откосы окон и дверей
    this.L_ok_slopes();
    this.S_ok_slopes();
    this.L_dv_slopes();
    this.S_dv_slopes();
    this.L_dv_per_slopes();
    this.S_dv_per_slopes();
    // несущие стены
    this.N_kl_set();
    this.V_st();
    this.N_bl_m3();
    this.N_bl_zapas();
    this.S_st();
    this.N_bl();
    this.V_bl();
    this.V_p_p();
    this.M_p_p();
    this.N_p_p();
    // толщина несущей кладки
    this.T_kl();
    // фасадные материалы
    // кирпич
    this.S_st_facade();
    this.V_st_facade();
    this.N_bl_m3_facade();
    this.N_bl_facade();
    this.V_bl_facade();
    this.N_bl_zapas_facade();
    this.V_p_p_facade();
    this.M_p_p_facade();
    this.N_p_p_facade();
    // штукатурка
    this.V_sht();
    this.M_sht();
    this.N_sht();
    // прочие фасады
    this.N_fp();
    // толщина фасадного слоя
    this.T_fac();
    // утеплитель
    this.S_ut();
    this.V_ut();
    // толщина утеплителя
    this.T_ut();
    // перегородки
    this.N_kl_set_per();
    this.N_bl_m3_per();
    this.S_st_per();
    this.V_st_per();
    this.N_bl_per();
    this.N_bl_zapas_per();
    this.V_bl_per();
    this.V_p_p_per();
    this.M_p_p_per();
    this.N_p_p_per();
    // Отделка
    this.N_gkl();
    this.V_sht_trim();
    this.M_sht_trim();
    this.N_sht_trim();
    // нагрузка на фундамент
    this.N_fund();
    this.N_fund_per();
    return this.resultValue;
  }
}

const calculation2Service = new Calculation2Service();
export default calculation2Service;
