import {StoreonModule} from 'storeon';
import {toCamelCaseItem, toCamelCaseList, toSnakeCase} from '~src/utils/query';
import {IEvents, IState} from '~src/store/index';
import {api} from '~src/api';
import {UserRole} from '~src/types';
import {windowGlobal} from '~src/utils/dom';
import * as jwt from 'jsonwebtoken';
import {tokenPublicKey} from '~src/config';

export interface IUser {
  userName: string;
  userEmail: string;
  userPassword?: string;
  createdAt: string;
  role: UserRole;
  userId: string;
}
export interface IAccount {
  userId: string;
  userName: string;
  userEmail: string;
  role: UserRole;
}
export interface IUsersStore {
  isLoading: boolean;
  values: IUser[];
  count: number;
  error: string;
}
export interface IUserResponse {
  refresh_token?: string;
  user_name: string;
  user_id: string;
  email: string;
  created_at: string;
  role: string;
}
export interface IUsersResponse {
  data: {
    items: IUserResponse[];
    count: number;
  };
}

export const usersModule: StoreonModule<IState, IEvents> = store => {
  store.on('users/set-users', (state, users) => {
    return {
      users: {...state.users, values: [...users.items], count: users.count},
    };
  });
  store.on('users/fetch-users', async (state, params) => {
    try {
      const response = await api.get('users', {params: toSnakeCase({...params, service: 'admin'})});
      store.dispatch('users/set-users', {...response.data, items: toCamelCaseList(response.data.items)});
    } catch (error) {
      store.dispatch('users/error', error);
    }
  });
  store.on('users/create-user', async (state, payload) => {
    try {
      const response = await api.post<IUserResponse>(
        'users',
        toSnakeCase({...payload, service: 'admin', role: 'manager'}),
      );
      await store.dispatch('users/set-users', {
        items: toCamelCaseList([response.data, ...state.users.values]),
        count: state.users.count + 1,
      });
    } catch (error) {
      store.dispatch('users/error', error);
    }
  });
  store.on('users/update-user', async (state, payload) => {
    try {
      const withRefreshToken = payload.userId === state.app.account.userId;
      const response = await api.patch<IUserResponse>('users', toSnakeCase({...payload, withRefreshToken}));
      if (withRefreshToken && response.data.refresh_token) {
        if (windowGlobal?.localStorage.getItem('accessToken'))
          windowGlobal?.localStorage.setItem('accessToken', response.data.refresh_token);
        if (windowGlobal?.sessionStorage.getItem('accessToken'))
          windowGlobal?.sessionStorage.setItem('accessToken', response.data.refresh_token);
        store.dispatch(
          'app/set-account',
          toCamelCaseItem(jwt.verify(response.data.refresh_token, tokenPublicKey) as IAccount) as IAccount,
        );
        api.defaults.headers = {...api.defaults.headers, Authorization: `Bearer ${response.data.refresh_token}`};
      }
      await store.dispatch('users/set-users', {
        items: state.users.values.map(user => {
          if (user.userId === response.data.user_id) {
            return {
              ...user,
              ...toCamelCaseItem(response.data),
            };
          }
          return user;
        }),
        count: state.users.count,
      });
    } catch (error) {
      store.dispatch('users/error', error);
    }
  });
  store.on('users/delete-users', async (state, payload) => {
    try {
      await api.delete('users', {data: toSnakeCase({usersIds: payload.usersIds})});
      if (payload.withFetchUsers) {
        await store.dispatch('users/fetch-users', {page: 1, count: 1000, orderBy: 'DESC'});
      }
    } catch (error) {
      store.dispatch('users/error', error);
    }
  });
  store.on('users/error', ({comments}, error) => ({comments: {...comments, error}}));
};
