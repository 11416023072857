import {StoreonModule} from 'storeon';
import {toCamelCaseItem, toCamelCaseList, toSnakeCase} from '~src/utils/query';
import {IEvents, IState} from '~src/store/index';
import omit from 'ramda/es/omit';
import {api} from '~src/api';
import {IMaterialsResponse} from '~src/store/Materials';
import {CalculationType} from '~src/types';
import {delay} from '~src/utils';
import {IUserValuesAtCalculation1, IUserValuesAtCalculation2, IUserValuesAtCalculation3} from '~src/store/Calculations';
import {IResultValues} from '~src/services/Calculation1Service';
import {IResultValues as ICalc2ResultValues} from '~src/services/Calculation2Service';
import {IResultValues as ICalc3ResultValues} from '~src/services/Calculation3Service';

export interface IStatItem {
  calculationId: string;
  calculationType: 1 | 2;
  userEmail: string;
  pdfUrl: string;
  initialData: object;
  resultData: object;
  createdAt: string;
}
export interface IStatsStore {
  isLoading: boolean;
  values: IStatItem[];
  count: number;
  error: string;
  calculation: {
    calculationType?: 1 | 2;
    userValues?: IUserValuesAtCalculation1 | IUserValuesAtCalculation2 | IUserValuesAtCalculation3;
    resultValues?: IResultValues | ICalc2ResultValues | ICalc3ResultValues;
  };
}

export interface IStatResponse {
  data: {
    items: ICalculationStatResponse[];
    count: number;
  };
}
export interface ICalculationStatResponse {
  calculation_id: string;
  calculation_type: CalculationType;
  user_email: string;
  pdf_url: string;
  initial_data: object;
  result_data: object;
  created_at: string;
}

export const statsModule: StoreonModule<IState, IEvents> = store => {
  store.on('stats/set-stats', (state, stats) => {
    return {
      stats: {...state.stats, values: [...stats.items], count: stats.count},
    };
  });
  store.on('stats/fetch-stats', async (state, payload) => {
    try {
      store.dispatch('stats/loading', true);
      const response = await api.get<IMaterialsResponse>('stats/calculations', {params: toSnakeCase(payload)});
      await delay(500);
      await store.dispatch('stats/set-stats', {...response.data, items: toCamelCaseList(response.data.items)});
    } catch (error) {
      store.dispatch('stats/error', error);
    } finally {
      store.dispatch('stats/loading', false);
    }
  });
  store.on('stats/create-calculation', async (state, payload) => {
    try {
      let calculationType = payload.calculationType;
      const calculation = state.calculations[calculationType];
      store.dispatch('stats/loading', true);
      await api.post('stats/calculation', {
        ...toSnakeCase(omit(['image'], payload)),
        calculation_type: payload.calculationType === 3 ? 2 : payload.calculationType,
        initial_data: calculation.userValues,
        result_data: {
          ...calculation.resultValues,
          calculationSubtype: payload.calculationSubtype,
          image: payload.image,
        },
      });
      await delay(500);
    } catch (error) {
      store.dispatch('stats/error', error);
    } finally {
      store.dispatch('stats/loading', false);
    }
  });
  store.on('stats/fetch-calculation', async (state, payload) => {
    try {
      const response = await api.get<ICalculationStatResponse>(`stats/calculation/${payload.calculationId}`);
      const data = toCamelCaseItem(response.data);
      await delay(500);

      store.dispatch('stats/set-calculation', {
        calculation: {
          resultValues: data.resultData,
          userValues: data.initialData,
          calculationType: data.calculationType,
        },
      });
    } catch (error) {
      store.dispatch('stats/error', error);
    } finally {
      store.dispatch('stats/loading', false);
    }
  });
  store.on('stats/set-calculation', (state, payload) => {
    return {stats: {...state.stats, calculation: payload.calculation}};
  });
  store.on('stats/loading', ({stats}, isLoading) => ({stats: {...stats, isLoading}}));
  store.on('stats/error', ({stats}, error) => ({stats: {...stats, error}}));
};
