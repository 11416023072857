import {StoreonModule} from 'storeon';
import {toSnakeCase} from '~src/utils/query';
import {IEvents, IState} from '~src/store/index';
import {api} from '~src/api';
import {delay} from '~src/utils';
import {ILayer} from '~src/services/Calculation1Service';
import {layersWidth} from '~src/structs/layersWidth';
import {airGapRMapByName, airGapT} from '~src/structs/airGapR';

export interface IMaterial {
  id: string;
  layerName: string;
  layerType: 'bearingWall' | 'insulation' | 'facadeMaterial' | 'finishingMaterial' | 'waterproofing' | 'airGap';
  materialType: string;
  materialName: string;
  materialDensity: number;
  materialTranscalencyA: number;
  materialTranscalencyB: number;
  materialVaporRermeability: number;
  materialDeltaW: number;
  t?: number;
}

export interface IOption {
  label: string;
  value: number;
}

export interface ICalc2BrickMaterial {
  id: string;
  brickType: number;
  executionType: number;
  brickLength: number;
  brickWidth: number;
  brickHeight: number;
  brickWeight: number;
  voidness: boolean;
  inputType: number;
}

export interface ICalc2FacadeMaterial {
  id: string;
  facadeType: number;
  label: string;
  name: string;
  facadeBrickLength: number;
  facadeBrickWidth: number;
  facadeBrickHeight: number;
  facadeBrickWeight: number;
  inputType: number;
}

export interface ICalc2InsulationMaterial {
  insulationType: number;
  insulationThickness: number;
  insulationDensity: number;
  category: string;
  inputType: number;
}

export interface ICalc2BalkMaterial {
  balkType: number;
  name: string;
  id: string;
  balkLength: number;
  balkWidth: number;
  balkHeight: number;
  inputType: number;
}

export interface ICalc2TrimMaterial {
  id: string;
  name: string;
  trimHeight: number;
  trimLength: number;
  trimType: string;
  trimWeight: number;
  trimWidth: number;
  inputType: number;
}

export type ICalculation1Values = {[key: string]: {[key: string]: IMaterial[]}};
export type ICalculation2Values = {[key: string]: {[key: string]: IMaterial[]}};
export interface IMaterialsStore {
  isLoading: boolean;
  values: IMaterial[];
  groupedValues: ICalculation1Values;
  count: number;
  error: string;
  calculation2: {
    brickType: {
      materials: ICalc2BrickMaterial[];
      options: IOption[];
    };
    facadeType: {
      materials: ICalc2FacadeMaterial[];
      options: IOption[];
    };
    insulationType: {
      materials: ICalc2InsulationMaterial[];
      options: IOption[];
    };
    balkType: {
      materials: ICalc2BalkMaterial[];
      options: IOption[];
    };
    trimType: {
      materials: ICalc2TrimMaterial[];
      options: IOption[];
    };
  };
}
export interface IMaterialsResponse {
  items: IMaterial[];
  count: number;
}

export const materialsModule: StoreonModule<IState, IEvents> = store => {
  store.on('materials/set-materials', (state, stats) => {
    return {
      materials: {...state.materials, values: [...stats.items], count: stats.count},
    };
  });
  store.on('materials/set-grouped-materials', (state, values) => {
    return {
      materials: {...state.materials, groupedValues: values},
    };
  });
  store.on('materials/fetch-materials', async (_, payload) => {
    try {
      store.dispatch('materials/loading', true);
      const response = await api.get<IMaterialsResponse>('materials', {params: toSnakeCase(payload)});
      await delay(500);
      await store.dispatch('materials/set-materials', {
        count: response.data.count,
        items: response.data.items,
      });
      if (payload.withGroupedValues) {
        const materialsData: ICalculation1Values = {};
        const defaultLayers: ILayer[] = [];
        response.data.items.forEach(product => {
          if (!materialsData[product.layerName]) {
            materialsData[product.layerName] = {};
            defaultLayers.push({
              ...product,
              isEnabled: true,
              layerId: defaultLayers.length + 1,
              t: layersWidth[product.layerType],
              materialTranscalencyA:
                product.layerType === 'airGap'
                  ? Math.round(layersWidth[product.layerType] / airGapRMapByName[product.materialName])
                  : product.materialTranscalencyA,
              materialTranscalencyB:
                product.layerType === 'airGap'
                  ? Math.round(layersWidth[product.layerType] / airGapRMapByName[product.materialName])
                  : product.materialTranscalencyB,
              materialVaporRermeability: product.layerType === 'airGap' ? 1 : product.materialVaporRermeability,
            });
          }
          if (!materialsData[product.layerName][product.materialType]) {
            materialsData[product.layerName][product.materialType] = [];
          }
          const normalizedProduct: IMaterial = {...product};
          if (product.layerType === 'airGap') {
            normalizedProduct.t = airGapT[normalizedProduct.materialName];
            normalizedProduct.materialTranscalencyA = Math.round(
              airGapT[normalizedProduct.materialName] / airGapRMapByName[normalizedProduct.materialName],
            );
            normalizedProduct.materialTranscalencyB = Math.round(
              airGapT[normalizedProduct.materialName] / airGapRMapByName[normalizedProduct.materialName],
            );
            normalizedProduct.materialVaporRermeability = 1;
          }
          materialsData[product.layerName][product.materialType].push(normalizedProduct);
        });
        store.dispatch('calculations/1/set-values', [{name: 'layers', value: defaultLayers}]);
        store.dispatch('materials/set-grouped-materials', materialsData);
      }
    } catch (error) {
      store.dispatch('materials/error', error);
    } finally {
      store.dispatch('materials/loading', false);
    }
  });
  store.on('materials/set-materials-and-options', (state, values) => {
    return {
      materials: {...state.materials, calculation2: values},
    };
  });
  store.on('materials/get-materials-by-id', async (state, payload) => {
    try {
      store.dispatch('materials/loading', true);
      const response = await api.get('materials', {params: toSnakeCase(payload)});
      await store.dispatch('materials/set-materials-and-options', {
        ...state.materials.calculation2,
        [payload.filterKey]: {
          options: state.materials.calculation2[payload.filterKey].options,
          materials: response.data.items,
        },
      });
    } catch (error) {
      store.dispatch('materials/error', error);
    } finally {
      store.dispatch('materials/loading', false);
    }
  });
  store.on('materials/fetch-materials-and-options', async (_, payload) => {
    try {
      store.dispatch('materials/loading', true);
      const response = await api.get('materials', {params: toSnakeCase(payload)});
      let brickTypeData = response.data.brickType;
      let facadeTypeData = response.data.facadeType;
      let insulationTypeData = response.data.insulationType;
      let trimTypeData = response.data.trimType;
      let balkTypeData = response.data.balkType;
      await store.dispatch('materials/set-materials-and-options', {
        brickType: {
          options: brickTypeData.options,
          materials: brickTypeData.materials,
        },
        facadeType: {
          options: facadeTypeData.options,
          materials: facadeTypeData.materials,
        },
        insulationType: {
          options: insulationTypeData.options,
          materials: insulationTypeData.materials,
        },
        trimType: {
          options: trimTypeData.options,
          materials: trimTypeData.materials,
        },
        balkType: {
          options: balkTypeData.options,
          materials: balkTypeData.materials,
        },
      });
    } catch (error) {
      store.dispatch('materials/error', error);
    } finally {
      store.dispatch('materials/loading', false);
    }
  });
  store.on('materials/loading', ({materials}, isLoading) => ({materials: {...materials, isLoading}}));
  store.on('materials/error', ({materials}, error) => ({materials: {...materials, error}}));
};
