import {materialsUploadUrl} from '~src/api';
import {Routes} from '~src/utils/routes';
import {BarChartOutlined, DesktopOutlined, MessageOutlined, UserOutlined, EditOutlined} from '@ant-design/icons/lib';
import CalcImageBanner from '~src/assets/images/banners/calcBanner.webp';
import HeatImageBanner from '~src/assets/images/banners/heatBanner.webp';
import AdaptiveImageCard from '~src/assets/images/cards/adaptive.webp';
import CalculateImageCard from '~src/assets/images/cards/calculate.webp';
import OptimizeImageCard from '~src/assets/images/cards/optimize.webp';
import TimeImageCard from '~src/assets/images/cards/time.webp';

export const components = {
  logo: {
    caption: 'расчетстен.рф',
  },
  modals: {
    report: {
      title: 'Скачать отчёт',
      caption: 'Заполните данные ниже и мы вышлем вам отчет на электронную почту',
      nameInput: {
        label: 'Ваше имя',
        placeholder: 'Имя',
      },
      emailInput: {
        label: 'Ваш email',
        placeholder: 'example@mail.com',
      },
      button: 'Отправить',
      checkbox: [
        'Согласен с условиями',
        {
          title: 'политики конфиденциальности',
          href: '',
        },
        'сайта',
      ],
      success: {
        title: 'Спасибо! Отчёт отправлен на почту',
        button: 'Понятно',
      },
    },
    feedback: {
      title: 'Остались вопросы? Свяжитесь с нами',
      caption: 'Если у Вас есть вопросы, замечания или пожелания, Вы можете прислать их, заполнив форму.',
      nameInput: {
        label: 'Ваше имя',
        placeholder: 'Имя',
      },
      emailInput: {
        label: 'Ваш email',
        placeholder: 'example@mail.com',
      },
      commentInput: {
        label: 'Комментарий',
        placeholder: 'Ваш комментарий',
      },
      button: 'Отправить',
      checkbox: [
        'Согласен с условиями',
        {
          title: 'политики конфиденциальности',
          href: '',
        },
        'сайта',
      ],
      textAreaPlaceholder: 'Ваш комментарий',
    },
    loadXls: {
      title: 'Импорт XLS-файла',
      loadButton: 'Загрузить файл',
      tryAgainButton: 'Попробовать еще раз',
      reloadPageButton: 'Обновить страницу',
      fileName: 'file',
      uploadUrl: materialsUploadUrl,
    },
    user: {
      createTitle: 'Добавить пользователя',
      editTitle: 'Данные пользователя',
      userName: {
        label: 'Имя пользователя',
        placeholder: 'Введите имя',
      },
      userEmail: {
        label: 'Email',
        placeholder: 'Введите email',
      },
      userPassword: {
        label: 'Новый пароль',
        placeholder: 'Введите пароль',
      },
      userPasswordCheck: {
        label: 'Подтвердить новый пароль',
        placeholder: 'Введите пароль',
      },
      saveButton: 'Сохранить',
      cancelButton: 'Отмена',
    },
    approve: {
      title: 'Подтвердите действие',
      okButton: 'Подтвердить',
      cancelButton: 'Отменить',
    },
    materials: {
      title: 'Выбрать слой материала',
      okButton: 'Выбрать',
      cancelButton: 'Отменить',
    },
  },
  menu: {
    app: [
      {
        key: Routes.calculation1,
        caption: 'Тепловая защита зданий',
      },
      {
        key: Routes.calculation2,
        caption: 'Расчет количества материалов',
      },
      {
        key: Routes.articles,
        caption: 'Статьи',
      },
      {
        key: Routes.faq,
        caption: 'Вопросы и ответы',
      },
      {
        key: Routes.contacts,
        caption: 'Контакты',
      },
    ],
    admin: [
      {
        key: 'users',
        title: 'Пользователи',
        icon: UserOutlined,
        route: Routes.users,
      },
      {
        key: 'materials',
        title: 'Материалы',
        icon: DesktopOutlined,
        route: Routes.materials,
      },
      {
        key: 'stats',
        title: 'Расчеты',
        icon: BarChartOutlined,
        route: Routes.stats,
      },
      {
        key: 'comments',
        title: 'Комментарии',
        icon: MessageOutlined,
        route: Routes.commentsModeration,
      },
      {
        key: 'articles',
        title: 'Статьи',
        icon: EditOutlined,
        route: Routes.articlesModeration,
      },
    ],
  },
  card: {
    app: [
      {
        key: 1,
        caption: 'Рассчитывайте количество и стоимость',
        image: CalculateImageCard,
      },
      {
        key: 2,
        caption: 'Экономьте время на типовых операциях',
        image: TimeImageCard,
      },
      {
        key: 3,
        caption: 'Оптимизируйте бюджет от перерасхода',
        image: OptimizeImageCard,
      },
      {
        key: 4,
        caption: 'Используйте на любых устройствах',
        image: AdaptiveImageCard,
      },
    ],
  },
  banner: {
    app: [
      {
        key: 1,
        displayTitle: 'Тепловая защита зданий',
        subtitle:
          'Выбор конструктивных решений ограждающих кострукций и подбор материалов с точки зрения теплотехнических показателей материалов.',
        tooltip: 'Определение необходимой толщины теплоизолирующих материалов в конструкции',
        image: HeatImageBanner,
        link: Routes.calculation1,
      },
      {
        key: 2,
        displayTitle: 'Расчет количества материалов',
        subtitle:
          'Калькулятор строительных материалов необходимых для постройки стен домов, гаражей, хозяйственных и других помещений. ',
        tooltip: 'В расчетах могут быть учтены размеры фронтонов постройки, дверные и оконные проемы',
        image: CalcImageBanner,
        link: Routes.calculation2,
      },
    ],
  },
};
