import {createStoreon} from 'storeon';
import {storeonDevtools} from 'storeon/devtools';
import {usersModule, IAccount, IUser, IUsersStore} from '~src/store/Users';
import {commentsModule, IComment, ICommentsStore} from '~src/store/Comments';
import {articlesModule, IArticle, IArticlesStore} from '~src/store/Articles';
import {appModule, IAppStore} from '~src/store/App';
import {authModule, IAuthStore} from '~src/store/Auth';
import {statsModule, IStatItem, IStatsStore} from '~src/store/Stats';
import {
  materialsModule,
  ICalculation1Values,
  IMaterial,
  IMaterialsStore,
  ICalc2BrickMaterial,
  IOption,
  ICalc2FacadeMaterial,
  ICalc2InsulationMaterial,
  ICalc2BalkMaterial,
  ICalc2TrimMaterial,
} from '~src/store/Materials';
import {
  calculationModule,
  ICalculationsStore,
  initialState as initialStateAtCalculations,
  IResultValuesAtCalculation1,
  IUserValuesAtCalculation1,
  IUserValuesAtCalculation2,
  IUserValuesAtCalculation3,
} from '~src/store/Calculations';
import {OrderBy} from '~src/types';
import {
  IGable,
  IArmobelt,
  IDoor,
  IJumper,
  ITrim,
  IWindow,
  IPartition,
  IPartitionDoors,
  IPartitionJumpers,
  IBearingWall,
  IFacade,
  IWall,
  IInsulation,
} from '~src/services/Calculation2Service';
import {IBalkBearingWall} from '~src/services/Calculation3Service';
import {ILayer} from '~src/services/Calculation1Service';
import {reportsModule} from '~src/store/Reports';
import {leadsModule, ILeadsStore} from '~src/store/Leads';
import {IResultValues as ICalc3ResultValues} from '~src/services/Calculation3Service';
import {modalsModule, IModalsStore} from '~src/store/Modals';

export interface IState {
  app: IAppStore;
  comments: ICommentsStore;
  articles: IArticlesStore;
  auth: IAuthStore;
  users: IUsersStore;
  stats: IStatsStore;
  materials: IMaterialsStore;
  calculations: ICalculationsStore;
  leads: ILeadsStore;
  modals: IModalsStore;
}

export interface IEvents {
  'modals/show-modal': {key: keyof IModalsStore; value: boolean};

  'calculations/1/set-values': {name: keyof IUserValuesAtCalculation1; value: string | ILayer[] | number | boolean}[];
  'calculations/1/set-result-values': {
    name: keyof IResultValuesAtCalculation1;
    value: string | number | (string | number | any)[];
  }[];
  'calculations/1/calculate': undefined;

  'reports/generate-pdf': {calculationType: 1 | 2; userEmail: string; userName: string};
  'reports/generate-pdf-set-error': string;
  'reports/generate-pdf-set-loading': boolean;
  'reports/generate-pdf-set-url': string;

  'calculations/2/calculate': undefined;
  'calculations/2/set-values': {name: keyof IUserValuesAtCalculation2; value: string | string[] | number | boolean}[];

  'calculations/2/set-group-values': {
    groupName: keyof IUserValuesAtCalculation2;
    values: {
      name:
        | keyof IBearingWall
        | keyof IFacade
        | keyof IWall
        | keyof IInsulation
        | keyof IGable
        | keyof IWindow
        | keyof IArmobelt
        | keyof IDoor
        | keyof IJumper
        | keyof ITrim
        | keyof IPartition
        | keyof IPartitionDoors
        | keyof IPartitionJumpers;
      value: string | number | boolean | null | undefined;
      index: number;
    }[];
  };

  'calculations/2/remove-item': {
    itemName: keyof IUserValuesAtCalculation2;
    index: number;
  };

  'calculations/2/add-item': {
    itemName: keyof IUserValuesAtCalculation2;
  };

  'calculations/3/calculate': undefined;

  'calculations/3/set-values': {name: keyof IUserValuesAtCalculation3; value: string | string[] | number | boolean}[];

  'calculations/3/set-group-values': {
    groupName: keyof IUserValuesAtCalculation3;
    values: {
      name: keyof IBalkBearingWall | keyof IGable | keyof IWindow | keyof IDoor | keyof IJumper;
      value: number | string;
      index: number;
    }[];
  };

  'calculations/3/add-item': {
    itemName: keyof IUserValuesAtCalculation3;
  };

  'calculations/3/remove-item': {
    itemName: keyof IUserValuesAtCalculation3;
    index: number;
  };

  'app/init': undefined;
  'app/set-account': IAccount;

  'comments/fetch-comments': {
    page: number;
    count: number;
    approved?: 0 | 1;
    orderBy: OrderBy;
    strategy: 'overwrite' | 'push';
  };
  'comments/set-comments': {items: IComment[]; count: number; strategy: 'overwrite' | 'push'};
  'comments/send-comment': {userName: string; message: string};
  'comments/set-is-sending': boolean;
  'comments/set-is-sent': undefined;
  'comments/approve-comments': {commentsIds: string[]; approved: boolean};
  'comments/delete-comments': {commentsIds: string[]};
  'comments/error': string;

  'articles/fetch-articles': {strategy: 'overwrite' | 'push'};
  'articles/set-articles': {items: IArticle[]; strategy: 'overwrite' | 'push'};
  'articles/send-article': {
    creation_time: string;
    title: string;
    preview_url: string;
    description: string;
    text: string;
  };
  'articles/patch-article': {
    id: number;
    creation_time: string;
    title: string;
    preview_url: string;
    description: string;
    text: string;
  };
  'articles/delete-article': {id: number};
  'articles/upload-image': object;

  'articles/set-is-sending': boolean;
  'articles/set-is-sent': undefined;
  'articles/error': string;

  'users/fetch-users': {
    page: number;
    count: number;
    approved?: 0 | 1;
    orderBy: OrderBy;
  };
  'users/set-users': {items: IUser[]; count: number};
  'users/create-user': IUser;
  'users/update-user': Partial<IUser>;
  'users/delete-users': {usersIds: string[]; withFetchUsers: boolean};
  'users/error': string;

  'stats/fetch-stats': {
    page: number;
    count: number;
    approved?: 0 | 1;
    orderBy: OrderBy;
    createdAt?: string;
    calculationType: 1 | 2;
  };
  'stats/set-stats': {items: IStatItem[]; count: number};
  'stats/loading': boolean;
  'stats/error': string;

  'stats/create-calculation': {
    userName: string;
    userEmail: string;
    calculationType: 1 | 2 | 3;
    calculationSubtype: number;
    image?: string;
  };
  'stats/fetch-calculation': {
    calculationId: string;
  };
  'stats/set-calculation': {
    calculation: {
      calculationType: 1 | 2;
      userValues: IUserValuesAtCalculation3;
      resultValues: ICalc3ResultValues;
    };
  };

  'materials/fetch-materials': {
    page: number;
    count: number;
    orderBy: OrderBy;
    calculationType: number;
    calculationSubtype?: number;
    withGroupedValues?: boolean;
  };
  'materials/fetch-materials-and-options': {
    page: number;
    count: number;
    orderBy: OrderBy;
    calculationType: number;
    withGroupedValues?: boolean;
  };
  'materials/set-materials': {items: IMaterial[]; count: number};
  'materials/get-materials-by-id': {filterKey: string; filter_value: string | number; calculationType: number};
  'materials/set-materials-and-options': {
    brickType: {
      options: IOption[];
      materials: ICalc2BrickMaterial[];
    };
    facadeType: {
      options: IOption[];
      materials: ICalc2FacadeMaterial[];
    };
    insulationType: {
      options: IOption[];
      materials: ICalc2InsulationMaterial[];
    };
    trimType: {
      options: IOption[];
      materials: ICalc2TrimMaterial[];
    };
    balkType: {
      options: IOption[];
      materials: ICalc2BalkMaterial[];
    };
  };
  'materials/fetch-grouped-all-materials': undefined;
  'materials/set-grouped-materials': ICalculation1Values;
  'materials/loading': boolean;
  'materials/error': string;

  'auth/sing-out': undefined;
  'auth/sign-in': {userName: string; userPassword: string; withRemember?: boolean};
  'auth/sign-in-loading': boolean;
  'auth/recovery-start': {userEmail: string};
  'auth/recovery-start-loading': boolean;
  'auth/recovery-start-loaded': boolean;
  'auth/recovery-end': {userPassword: string; recoveryLinkId: string};
  'auth/recovery-end-loading': boolean;
  'auth/recovery-end-loaded': boolean;
  'auth/recovery-end-error': string;
  'auth/set-reset-status': boolean;
  'auth/error': string;

  'leads/send-feedback': {
    userName: string;
    userEmail: string;
    message: string;
    onSuccess: () => void;
    onFail: () => void;
  };
  'leads/loading': boolean;
  'leads/error': string;
}

export const initialState: IState = {
  app: {
    isInit: false,
    account: {
      userName: '',
      userEmail: '',
      userId: '',
      role: 'manager',
    },
  },
  comments: {isLoading: false, isSending: false, isSent: false, values: [], count: 0, error: ''},

  articles: {isLoading: false, isSending: false, isSent: false, values: [], count: 0, error: ''},

  users: {
    isLoading: false,
    count: 0,
    error: '',
    values: [],
  },
  stats: {isLoading: false, count: 0, error: '', values: [], calculation: {}},
  materials: {
    isLoading: false,
    count: 0,
    error: '',
    values: [],
    groupedValues: {},
    calculation2: {
      brickType: {
        materials: [],
        options: [],
      },
      facadeType: {
        materials: [],
        options: [],
      },
      insulationType: {
        materials: [],
        options: [],
      },
      balkType: {
        materials: [],
        options: [],
      },
      trimType: {
        materials: [],
        options: [],
      },
    },
  },
  auth: {
    showReset: false,
    isSending: false,
    isSent: false,
    isReset: false,
    error: '',
    recoveryEndError: '',
    isLoadingRecoveryEndPassword: false,
    isLoadedRecoveryEndPassword: false,
  },
  calculations: initialStateAtCalculations,
  leads: {
    error: '',
    isLoading: false,
  },
  modals: {
    feedbackModal: false,
  },
};

export const store = createStoreon<IState, IEvents>([
  appModule,
  commentsModule,
  articlesModule,
  usersModule,
  statsModule,
  materialsModule,
  authModule,
  calculationModule,
  reportsModule,
  leadsModule,
  modalsModule,
  storeonDevtools,
]);
