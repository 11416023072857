import {StoreonModule} from 'storeon';
import * as jwt from 'jsonwebtoken';
import {navigate} from 'gatsby';
import {IEvents, initialState, IState} from '~src/store';
import {IAccount} from '~src/store/Users';
import {Routes} from '~src/utils/routes';
import {tokenPublicKey} from '~src/config';
import {toCamelCaseItem} from '~src/utils/query';
import {windowGlobal} from '~src/utils/dom';

export interface IAppStore {
  isInit: boolean;
  account: IAccount;
}

export const appModule: StoreonModule<IState, IEvents> = store => {
  store.on('@init', () => ({...initialState}));
  store.on('app/init', () => {
    const updatedApp = {app: {account: {}, isInit: true}};
    const accessTokenFromLocalStorage = windowGlobal?.localStorage.getItem('accessToken') || '';
    const accessTokenFromSessionStorage = windowGlobal?.sessionStorage.getItem('accessToken') || '';
    if (
      windowGlobal?.location.pathname.includes('/admin') &&
      !accessTokenFromLocalStorage &&
      !accessTokenFromSessionStorage &&
      !windowGlobal?.location.pathname.includes(Routes.signIn)
    ) {
      navigate(Routes.signIn);
    } else {
      if (accessTokenFromLocalStorage) {
        updatedApp.app.account = toCamelCaseItem(jwt.verify(accessTokenFromLocalStorage, tokenPublicKey) as IAccount);
      } else if (accessTokenFromSessionStorage) {
        updatedApp.app.account = toCamelCaseItem(jwt.verify(accessTokenFromSessionStorage, tokenPublicKey) as IAccount);
      }
    }
    return updatedApp;
  });
  store.on('app/set-account', (state, payload) => ({app: {...state.app, account: payload}}));
};
