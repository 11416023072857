import axios from 'axios';
import {navigate} from 'gatsby';
import {Routes} from '~src/utils/routes';
import {windowGlobal} from '~src/utils/dom';
import {apiUrl} from '~src/config';

export const api = axios.create({
  baseURL: apiUrl + '/api',
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${windowGlobal?.sessionStorage.getItem('accessToken') ||
      windowGlobal?.localStorage.getItem('accessToken') ||
      ''}`,
  },
  transformResponse: [
    initialData => {
      const data = JSON.parse(initialData);
      if (data.statusCode === 401) {
        navigate(Routes.signIn);
      }
      return data;
    },
  ],
});

export const materialsUploadUrl = apiUrl + '/api/materials/upload';
export const imagesUploadUrl = apiUrl + '/uploads';
export const noopUrl = apiUrl + '/api/articles/noop';
