import {snakeCase} from 'snake-case';
import camelCase from 'camelcase';

export const toSnakeCase = (obj: {[key: string]: any}) => {
  const result: {[key: string]: any} = {};

  Object.keys(obj).forEach(key => {
    result[snakeCase(key)] = obj[key];
  });

  return result;
};

export const toCamelCaseList = (arr: {[key: string]: any}[]) => {
  const result: any[] = [];

  arr.forEach(item => {
    const updatedItem: {[key: string]: any} = {};
    Object.keys(item).forEach(key => {
      // @ts-ignore
      updatedItem[camelCase(key)] = item[key];
    });
    result.push(updatedItem);
  });

  return result;
};

export const toCamelCaseItem = (obj: {[key: string]: any}) => {
  const result: {[key: string]: any} = {};

  Object.keys(obj).forEach(key => {
    // @ts-ignore
    result[camelCase(key)] = obj[key];
  });

  return result;
};
