import {createDataSourceResult5, ICommonPropCity} from '~src/utils/calculations';

import locales from '~src/locales';
import {round} from '~src/utils/math';
import {dependencyComplex} from '~src/structs/dependencyComplex';
import {airGapRMapByName} from '~src/structs/airGapR';
import {IUserValuesAtCalculation1} from '~src/store/Calculations';
import {calculation1} from '~src/locales/calculation1';

export interface ILayer {
  id: string;
  isEnabled: boolean;
  layerId: number;
  layerType: 'bearingWall' | 'insulation' | 'facadeMaterial' | 'finishingMaterial' | 'waterproofing' | 'airGap';
  layerName: string;
  materialType: string;
  materialName: string;
  materialDensity: number;
  materialTranscalencyA: number;
  materialTranscalencyB: number;
  materialVaporRermeability: number;
  materialDeltaW: number;
  t: number;
}

export interface IConclusion1 {
  type: 'success' | 'error';
  symbol: string;
  description: string;
}
export interface IConclusion2 {
  type: 'success' | 'warning' | 'error';
  symbol: string;
  description: string;
}
export interface IConclusion5 {
  type: 'success' | 'error';
  symbol: string;
  description: string;
}
export interface IConclusion6 {
  type: 'success' | 'error';
  symbol: string;
  description: string;
}
export interface IConclusion7 {
  type: 'success' | 'error';
  symbol: string;
  description: string;
}
export interface IConclusion8 {
  type: 'success' | 'error';
  symbol: string;
  description: string;
}
export interface IValues extends ICommonPropCity {
  regionCode: string;
  regionCity: string;
  typeRoom: number;
  categoryPublicRoom: number;
  typeConstruction: number;
  t_b: number;
  wetInRoom: number;
  n: number;
  alfa_b: 8.7;
  alfa_h: number;
  delta_t_h: number;
  layers: ILayer[];
  containsAirGap: boolean;
  r: number;
  operatingConditions: 'А' | 'Б' | '';
  sum_xi_sh: number;
  sum_xi: number;
  h_y: number;
  b_y: number;
  h_gap: number;
  h_all: number;
}

export interface ICalculation1Values extends IValues {
  enabledLayers: ILayer[];
  enabledLayersByAllParams: ILayer[];
}

export interface IResultValues {
  image: string;
  calculationSubtype: number;
  gsop: number;
  r_t: number;
  r_c: number;
  r_e: number;
  r_usl: number;
  r_uslWithVAG: number;
  R: number;
  conclusion1: IConclusion1;
  conclusion2: IConclusion2;
  conclusion3: number;
  conclusion4: number;
  'r10%': number;
  'r25%': number;
  'r50%': number;
  'r100%': number;
  'r+-_c': number;
  'r+-_e': number;
  'r+-_t': number;
  'r+-': 0;
  'r+-10%': 10;
  'r+-25%': 25;
  'r+-50%': 50;
  'r+-100%': 100;
  q: number;
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  q5: number;
  q6: number;
  q7: number;
  'q+-': 0;
  'q+-1': number;
  'q+-2': number;
  'q+-3': number;
  'q+-4': number;
  'q+-5': number;
  'q+-6': number;
  'q+-7': number;
  f_i: number;

  // --------result2
  // r_op
  // E_b
  // e_b
  // t_notr
  // e_notr
  // with_x_mu
  // x_mu
  // R_ni
  // termResist
  // z_n
  // t_on
  // t_n
  // E_n
  // E
  // R_pn
  // R_tr_n1
  // userValues.z0 userValues.t0
  // t_o1
  // E_o
  // eta
  // R_tr_n2

  // layersE layersTemperature
  // layersEp  {layersTemperature, e_b, r_op, t_min}

  // layersTemperature r_op

  r_op: number;
  r_opWithVAG: number;

  E_b: number;
  e_b: number;
  E: number;
  EWithVAG: number;
  e_notr: number;
  t_notr: number;
  t_min: number;
  x_mu: number;
  x_mu_index: number;
  with_x_mu: boolean;
  R_ni: number;

  layersTemperature: number[];
  layersTemperatureWithoutVAG: number[];
  // layersTemperatureWithVAG: number[];

  layersDewTemperature: number[];
  // layersDewTemperatureWithVAG: number[];

  layersFi: number[];
  layersTmu: number[];

  layersE: number[];
  layersEp: number[];
  // layersEWithVAG: number[];
  // layersEpWithVAG: number[];

  z_n: number[][];
  t_n: number[];
  t_nWithVAG: number[];
  termResist: number;
  termResistWithVAG: number;
  t_on: number[];
  E_n: number[];
  E_nWithVAG: number[];
  R_pn: number;
  R_tr_n1: number;
  t_o1: number;
  t_o1WithVAG: number;
  E_o: number;
  E_oWithVAG: number;
  eta: number;
  R_tr_n2: number;
  conclusion5: IConclusion5;
  // Вентилируемая воздушная прослойка
  R_prime_p: number;
  R_p: number;
  R_pr_p_prime: number;
  Mu: number;
  R_prime_op: number;
  R_tr_n1_prime: number;
  R_tr_n2_prime: number;
  conclusion6: IConclusion6;
  eta_prime: number;
  sum_R_ni_section: number;
  e_tau: number;
  sum_R_i_section: number;
  //
  greatestCondensateMonth: string;
  t_n_greatestCondensate: number;
  t_sr_greatestCondensate: number;
  fi_sr_greatestCondensate: number;
  //
  tau_p: number;
  E_tau: number;
  //
  E_sr: number;
  e_sr: number;
  V_pr: number;
  gamma_pr: number;
  W: number;
  alfa_l: number;
  alfa_pr: number;
  R_pr: number;
  k_v: number;
  k_n: number;
  t_y: number;
  E_y: number;
  M_v: number;
  M_n: number;
  B: number;
  e_y: number;
  //
  t_n_coldestFiveDays: number;
  t_sr_coldestFiveDays: number;
  fi_sr_coldestFiveDays: number;
  //
  E_sr_h: number;
  e_sr_h: number;
  V_pr_h: number;
  gamma_pr_h: number;
  W_h: number;
  alfa_l_h: number;
  alfa_pr_h: number;
  R_pr_h: number;
  k_v_h: number;
  k_n_h: number;
  t_y_h: number;
  E_y_h: number;
  M_v_h: number;
  M_n_h: number;
  B_h: number;
  e_y_h: number;

  conclusion7: IConclusion7;
  conclusion8: IConclusion8;
}

class Calculation1Service {
  values: ICalculation1Values | {} = {};
  resultValues: IResultValues | {} = {};
  private gsop() {
    const {t_b, t_ot, z_ot} = this.values as ICalculation1Values;
    this.resultValues = {...this.resultValues, gsop: round((t_b - t_ot) * z_ot)};
  }
  private r_t() {
    const {gsop} = this.resultValues as IResultValues;
    const a = 0.00035;
    const b = 1.4;
    this.resultValues = {...this.resultValues, r_t: round(a * gsop + b, 1000)};
  }
  private r_c() {
    const {t_b, t_h, n, delta_t_h, alfa_b} = this.values as ICalculation1Values;
    this.resultValues = {...this.resultValues, r_c: round(((t_b - t_h) * n) / (delta_t_h * alfa_b), 1000)};
  }
  private r_e() {
    const {r_t} = this.resultValues as IResultValues;
    const m_p = 0.63;
    this.resultValues = {...this.resultValues, r_e: round(r_t * m_p, 1000)};
  }

  private r_usl() {
    const {enabledLayers, operatingConditions, alfa_h} = this.values as ICalculation1Values;

    const enabledLayersWithoutVAG = enabledLayers.filter(
      layer => layer.materialType !== 'Вентилируемая воздушная прослойка' && layer.materialType !== 'Экран',
    );

    const r_uslWithoutVAG =
      enabledLayersWithoutVAG.reduce((acc, layer) => {
        const lambda = operatingConditions === 'А' ? layer.materialTranscalencyA : layer.materialTranscalencyB;
        const v = layer.layerType === 'airGap' ? airGapRMapByName[layer.materialName] : (layer.t * 0.001) / lambda;
        return acc + v;
      }, 0) +
      1 / alfa_h +
      1 / 8.7;
    this.resultValues = {...this.resultValues, r_usl: round(r_uslWithoutVAG, 1000)};
  }
  private r_uslWithVAG() {
    const {enabledLayers, operatingConditions, alfa_h} = this.values as ICalculation1Values;
    const r_uslWithVAG =
      enabledLayers.reduce((acc, layer) => {
        const lambda = operatingConditions === 'А' ? layer.materialTranscalencyA : layer.materialTranscalencyB;
        const v = layer.layerType === 'airGap' ? airGapRMapByName[layer.materialName] : (layer.t * 0.001) / lambda;
        return acc + v;
      }, 0) +
      1 / alfa_h +
      1 / 8.7;
    this.resultValues = {...this.resultValues, r_uslWithVAG: round(r_uslWithVAG, 1000)};
  }

  private R() {
    const {r} = this.values as ICalculation1Values;
    const {r_usl} = this.resultValues as IResultValues;
    this.resultValues = {...this.resultValues, R: round(r_usl * r, 1000)};
  }
  private conclusion1() {
    const {R, r_c} = this.resultValues as IResultValues;
    let conclusion1: IConclusion1 = locales.services.calculationService[1].conclusion1.error;
    if (R > r_c) {
      conclusion1 = locales.services.calculationService[1].conclusion1.success;
    }
    this.resultValues = {
      ...this.resultValues,
      conclusion1,
    };
  }
  private conclusion2() {
    const {R, r_t} = this.resultValues as IResultValues;
    let conclusion2: IConclusion2 = locales.services.calculationService[1].conclusion2.error;
    if (R > r_t * 1.25) {
      conclusion2 = {
        ...locales.services.calculationService[1].conclusion2.warning,
        description: locales.services.calculationService[1].conclusion2.warning.description.replace(
          '{value}',
          String(round(R / r_t, 100)),
        ),
      };
    } else if (R > r_t) {
      conclusion2 = locales.services.calculationService[1].conclusion2.success;
    }
    this.resultValues = {
      ...this.resultValues,
      conclusion2,
    };
  }
  private conclusion3() {
    const {R, gsop} = this.resultValues as IResultValues;
    this.resultValues = {...this.resultValues, conclusion3: round(0.024 * (1 / R) * gsop, 1000)};
  }
  private conclusion4() {
    const {t_b, t_h} = this.values as ICalculation1Values;
    const {R} = this.resultValues as IResultValues;
    this.resultValues = {...this.resultValues, conclusion4: round(24 * (1 / R) * ((t_b - t_h) / 24), 1000)};
  }
  private allR() {
    const {R} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      'r10%': round(R * 1.1, 1000),
      'r25%': round(R * 1.25, 1000),
      'r50%': round(R * 1.5, 1000),
      'r100%': round(R * 2, 1000),
    };
  }
  private ['getR+-'](r_i: number, R: number) {
    return round((r_i * 100) / R - 100, 1000);
  }
  private ['allR+-']() {
    const {r_c, r_e, r_t, R} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      'r+-_c': this['getR+-'](r_c, R),
      'r+-_e': this['getR+-'](r_e, R),
      'r+-_t': this['getR+-'](r_t, R),
      'r+-': 0,
      'r+-10%': 10,
      'r+-25%': 25,
      'r+-50%': 50,
      'r+-100%': 100,
    };
  }
  private getQ(r_i: number) {
    const {t_b, t_h} = this.values as ICalculation1Values;

    return round(24 * (1 / r_i) * ((t_b - t_h) / 24), 1000);
  }
  private allQ() {
    const resultValues = this.resultValues as IResultValues;
    const {r_c, r_e, r_t, R} = this.resultValues as IResultValues;

    this.resultValues = {
      ...this.resultValues,
      q1: this.getQ(r_c),
      q2: this.getQ(r_e),
      q3: this.getQ(r_t),
      q: this.getQ(R),
      q4: this.getQ(resultValues['r10%']),
      q5: this.getQ(resultValues['r25%']),
      q6: this.getQ(resultValues['r50%']),
      q7: this.getQ(resultValues['r100%']),
    };
  }
  private ['getQ+-'](q: number, q_n: number) {
    return round(q_n - q, 1000);
  }
  private ['allQ+-']() {
    const {q, q1, q2, q3, q4, q5, q6, q7} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      'q+-1': this['getQ+-'](q, q1),
      'q+-2': this['getQ+-'](q, q2),
      'q+-3': this['getQ+-'](q, q3),
      'q+-': 0,
      'q+-4': this['getQ+-'](q, q4),
      'q+-5': this['getQ+-'](q, q5),
      'q+-6': this['getQ+-'](q, q6),
      'q+-7': this['getQ+-'](q, q7),
    };
  }

  private r_op() {
    const {enabledLayersByAllParams} = this.values as ICalculation1Values;

    const enabledLayersByAllParamsWithoutVAG = enabledLayersByAllParams.filter(
      layer => layer.materialType !== 'Вентилируемая воздушная прослойка' && layer.materialType !== 'Экран',
    );

    this.resultValues = {
      ...this.resultValues,
      r_op: round(
        enabledLayersByAllParamsWithoutVAG.reduce((acc, layer) => {
          return acc + (layer.t * 0.001) / layer.materialVaporRermeability;
        }, 0),
        1000,
      ),
    };
  }

  private r_opWithVAG() {
    const {enabledLayersByAllParams} = this.values as ICalculation1Values;

    this.resultValues = {
      ...this.resultValues,
      r_opWithVAG: round(
        enabledLayersByAllParams.reduce((acc, layer) => {
          return acc + (layer.t * 0.001) / layer.materialVaporRermeability;
        }, 0),
        1000,
      ),
    };
  }

  private E_b() {
    const {t_b} = this.values as ICalculation1Values;
    this.resultValues = {
      ...this.resultValues,
      E_b: round(1.84 * Math.pow(10, 11) * Math.exp(-5330 / (273 + t_b)), 1000),
    };
  }
  private e_b() {
    const {wetInRoom} = this.values as ICalculation1Values;
    const {E_b} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      e_b: round((wetInRoom / 100) * E_b, 1000),
    };
  }
  private t_notr() {
    const arr = this.getAllT().filter(t => t < 0);
    this.resultValues = {
      ...this.resultValues,
      t_notr: round(arr.reduce((acc, t) => acc + t, 0) / arr.length, 1000),
    };
  }
  private e_notr() {
    let count = 0;
    const value = this.getAllT().reduce((acc, t, index) => {
      if (t >= 0) return acc;
      count += 1;
      return acc + this.getAllP()[index];
    }, 0);
    this.resultValues = {
      ...this.resultValues,
      e_notr: round(value / count, 1000) * 100,
    };
  }
  private getAllT() {
    const {t_apr, t_aug, t_dec, t_feb, t_jan, t_jul, t_jun, t_mar, t_may, t_nov, t_oct, t_sep} = this
      .values as ICalculation1Values;
    return [t_jan, t_feb, t_mar, t_apr, t_may, t_jun, t_jul, t_aug, t_sep, t_oct, t_nov, t_dec];
  }
  private getAllP() {
    const {p_jan, p_feb, p_mar, p_apr, p_may, p_jun, p_jul, p_aug, p_sep, p_oct, p_nov, p_dec} = this
      .values as ICalculation1Values;
    return [p_jan, p_feb, p_mar, p_apr, p_may, p_jun, p_jul, p_aug, p_sep, p_oct, p_nov, p_dec];
  }
  private z_n() {
    const z1: number[] = [];
    const z2: number[] = [];
    const z3: number[] = [];
    this.getAllT().forEach(t => {
      if (t <= -5) z1.push(t);
      else if (t > -5 && t < 5) z2.push(t);
      else if (t >= 5) z3.push(t);
    });
    this.resultValues = {
      ...this.resultValues,
      z_n: [z1, z2, z3],
    };
  }
  private t_on() {
    const {z_n} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      t_on: z_n.map(z => round(z.reduce((acc, value) => acc + value, 0) / z.length, 1000)),
    };
  }

  private E() {
    const {E_n, z_n} = this.resultValues as IResultValues;
    const E1 = E_n[0] ? E_n[0] * z_n[0].length : 0;
    const E2 = E_n[1] ? E_n[1] * z_n[1].length : 0;
    const E3 = E_n[2] ? E_n[2] * z_n[2].length : 0;
    this.resultValues = {
      ...this.resultValues,
      E: round((E1 + E2 + E3) / 12, 1000),
    };
  }
  private EWithVAG() {
    const {E_nWithVAG, z_n} = this.resultValues as IResultValues;
    const E1 = E_nWithVAG[0] ? E_nWithVAG[0] * z_n[0].length : 0;
    const E2 = E_nWithVAG[1] ? E_nWithVAG[1] * z_n[1].length : 0;
    const E3 = E_nWithVAG[2] ? E_nWithVAG[2] * z_n[2].length : 0;
    this.resultValues = {
      ...this.resultValues,
      EWithVAG: round((E1 + E2 + E3) / 12, 1000),
    };
  }

  private layersTemperature() {
    const {enabledLayers, t_b, alfa_b, operatingConditions} = this.values as ICalculation1Values;
    const {r_usl, t_min} = this.resultValues as IResultValues;

    this.resultValues = {
      ...this.resultValues,
      layersTemperature: enabledLayers.reduce(
        (acc: number[], layer, index) => {
          let layerSum = 0;
          let i = 0;
          while (i < index + 1) {
            const prevLayer = enabledLayers[i];
            const lambda =
              operatingConditions === 'А' ? prevLayer.materialTranscalencyA : prevLayer.materialTranscalencyB;
            layerSum +=
              prevLayer.layerType === 'airGap'
                ? airGapRMapByName[prevLayer.materialName]
                : (prevLayer.t * 0.001) / lambda;
            i++;
          }
          return [...acc, round(t_b - ((t_b - (t_min as number)) / r_usl) * (1 / alfa_b + layerSum), 1000)];
        },
        [round(t_b - ((t_b - (t_min as number)) / r_usl) * (1 / alfa_b), 1000)],
      ),
    };
  }

  private layersTemperatureWithoutVAG() {
    const {enabledLayers, t_b, alfa_b, operatingConditions} = this.values as ICalculation1Values;
    const {r_usl, t_min} = this.resultValues as IResultValues;

    const enabledLayersWithoutVAG = enabledLayers.filter(
      layer => layer.materialType !== 'Вентилируемая воздушная прослойка' && layer.materialType !== 'Экран',
    );

    this.resultValues = {
      ...this.resultValues,
      layersTemperatureWithoutVAG: enabledLayersWithoutVAG.reduce(
        (acc: number[], layer, index) => {
          let layerSum = 0;
          let i = 0;
          while (i < index + 1) {
            const prevLayer = enabledLayersWithoutVAG[i];
            const lambda =
              operatingConditions === 'А' ? prevLayer.materialTranscalencyA : prevLayer.materialTranscalencyB;
            layerSum +=
              prevLayer.layerType === 'airGap'
                ? airGapRMapByName[prevLayer.materialName]
                : (prevLayer.t * 0.001) / lambda;
            i++;
          }
          return [...acc, round(t_b - ((t_b - (t_min as number)) / r_usl) * (1 / alfa_b + layerSum), 1000)];
        },
        [round(t_b - ((t_b - (t_min as number)) / r_usl) * (1 / alfa_b), 1000)],
      ),
    };
  }

  // private layersTemperatureWithVAG() {
  //   const {enabledLayers, t_b, alfa_b, operatingConditions} = this.values as ICalculation1Values;
  //   const {r_usl, t_min} = this.resultValues as IResultValues;
  //
  //   this.resultValues = {
  //     ...this.resultValues,
  //     layersTemperatureWithVAG: enabledLayers.reduce(
  //       (acc: number[], layer, index) => {
  //         let layerSum = 0;
  //         let i = 0;
  //         while (i < index + 1) {
  //           const prevLayer = enabledLayers[i];
  //           const lambda =
  //             operatingConditions === 'А' ? prevLayer.materialTranscalencyA : prevLayer.materialTranscalencyB;
  //           layerSum +=
  //             prevLayer.layerType === 'airGap'
  //               ? airGapRMapByName[prevLayer.materialName]
  //               : (prevLayer.t * 0.001) / lambda;
  //           i++;
  //         }
  //         return [...acc, round(t_b - ((t_b - (t_min as number)) / r_usl) * (1 / alfa_b + layerSum), 1000)];
  //       },
  //       [round(t_b - ((t_b - (t_min as number)) / r_usl) * (1 / alfa_b), 1000)],
  //     ),
  //   };
  // }
  // private layersDewTemperature() {
  //   const {layersTemperature, layersEp} = this.resultValues as IResultValues;
  //   this.resultValues = {
  //     ...this.resultValues,
  //     layersDewTemperature: layersTemperature.map((value, index) => {
  //       const resultValue = -(5330 / Math.log(layersEp[index] / (1.84 * Math.pow(10, 11)))) - 273;
  //       return round(resultValue, 1000);
  //     }),
  //   };
  // }

  private layersDewTemperature() {
    const {layersTemperatureWithoutVAG, layersEp} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      layersDewTemperature: layersTemperatureWithoutVAG.map((value, index) => {
        const resultValue = -(5330 / Math.log(layersEp[index] / (1.84 * Math.pow(10, 11)))) - 273;
        return round(resultValue, 1000);
      }),
    };
  }

  // private layersDewTemperatureWithVAG() {
  //   const {layersTemperatureWithVAG, layersEpWithVAG} = this.resultValues as IResultValues;
  //   this.resultValues = {
  //     ...this.resultValues,
  //     layersDewTemperatureWithVAG: layersTemperatureWithVAG.map((value, index) => {
  //       const resultValue = -(5330 / Math.log(layersEpWithVAG[index] / (1.84 * Math.pow(10, 11)))) - 273;
  //       return round(resultValue, 1000);
  //     }),
  //   };
  // }

  private layersFi() {
    const {enabledLayers, t_b, operatingConditions} = this.values as ICalculation1Values;
    const {r_op, t_notr, r_usl, e_b, e_notr} = this.resultValues as IResultValues;

    this.resultValues = {
      ...this.resultValues,
      layersFi: enabledLayers.reduce((acc: number[], layer) => {
        if (!layer.materialVaporRermeability) return [...acc, 0];
        const lambda = operatingConditions === 'А' ? layer.materialTranscalencyA : layer.materialTranscalencyB;
        return [
          ...acc,
          round(
            5330 * (layer.materialVaporRermeability / lambda) * ((r_op * (t_b - t_notr)) / (r_usl * (e_b - e_notr))),
            1000,
          ),
        ];
      }, []),
    };
  }
  private layersTmu() {
    const {layersFi} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      layersTmu: layersFi.map(f_imu => {
        let t_mu = 0;
        if (f_imu === 0) return 0;
        dependencyComplex.forEach((value, index) => {
          const nextValue = dependencyComplex[index + 1];
          if (f_imu === value.f_imu) t_mu = value.t_mu;
          if (!nextValue) return;
          if (f_imu === nextValue.f_imu) t_mu = value.t_mu;
          if (f_imu < value.f_imu && f_imu > nextValue.f_imu) {
            t_mu =
              value.t_mu + ((nextValue.t_mu - value.t_mu) * (f_imu - value.f_imu)) / (nextValue.f_imu - value.f_imu);
            return;
          }
          if (f_imu < value.f_imu) {
            t_mu = value.t_mu;
          }
        });
        return round(t_mu, 1000);
      }),
    };
  }

  // private layersE() {
  //   const {layersTemperature} = this.resultValues as IResultValues;
  //   this.resultValues = {
  //     ...this.resultValues,
  //     layersE: layersTemperature.map(t => {
  //       return round(1.84 * Math.pow(10, 11) * Math.exp(-5330 / (273 + t)), 1);
  //     }),
  //   };
  // }

  private layersE() {
    const {layersTemperatureWithoutVAG} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      layersE: layersTemperatureWithoutVAG.map(t => {
        return round(1.84 * Math.pow(10, 11) * Math.exp(-5330 / (273 + t)), 1);
      }),
    };
  }
  private t_min() {
    this.resultValues = {
      ...this.resultValues,
      t_min: Math.min(...this.getAllT()),
    };
  }

  // private layersEWithVAG() {
  //   const {layersTemperatureWithVAG} = this.resultValues as IResultValues;
  //   this.resultValues = {
  //     ...this.resultValues,
  //     layersEWithVAG: layersTemperatureWithVAG.map(t => {
  //       return round(1.84 * Math.pow(10, 11) * Math.exp(-5330 / (273 + t)), 1);
  //     }),
  //   };
  // }
  // private t_min() {
  //   this.resultValues = {
  //     ...this.resultValues,
  //     t_min: Math.min(...this.getAllT()),
  //   };
  // }
  // private layersEp() {
  //   const {enabledLayers, f} = this.values as ICalculation1Values;
  //   const {layersTemperature, e_b, r_op, t_min} = this.resultValues as IResultValues;
  //   const e_n = (f / 100) * (1.84 * Math.pow(10, 11) * Math.exp(-5330 / (273 + t_min)));
  //   this.resultValues = {
  //     ...this.resultValues,
  //     layersEp: layersTemperature.map((t, index) => {
  //       if (index === 0) return round(e_b, 1);
  //       const currentLayer = enabledLayers[index - 1];
  //       const layerValue = [...enabledLayers].reduce((acc, layer, index, list) => {
  //         if (currentLayer === layer) {
  //           list.splice(index);
  //         }
  //         if (!layer.materialVaporRermeability) return acc;
  //         return acc + (layer.t * 0.001) / layer.materialVaporRermeability;
  //       }, 0);
  //       return round(e_b - (e_b - e_n) * (layerValue / r_op), 1);
  //     }),
  //   };
  // }

  private layersEp() {
    const {enabledLayers, f} = this.values as ICalculation1Values;
    const {layersTemperatureWithoutVAG, e_b, r_op, t_min} = this.resultValues as IResultValues;
    const e_n = (f / 100) * (1.84 * Math.pow(10, 11) * Math.exp(-5330 / (273 + t_min)));
    this.resultValues = {
      ...this.resultValues,
      layersEp: layersTemperatureWithoutVAG.map((t, index) => {
        if (index === 0) return round(e_b, 1);
        const currentLayer = enabledLayers[index - 1];
        const layerValue = [...enabledLayers].reduce((acc, layer, index, list) => {
          if (currentLayer === layer) {
            list.splice(index);
          }
          if (!layer.materialVaporRermeability) return acc;
          return acc + (layer.t * 0.001) / layer.materialVaporRermeability;
        }, 0);
        return round(e_b - (e_b - e_n) * (layerValue / r_op), 1);
      }),
    };
  }

  // private layersEpWithVAG() {
  //   const {enabledLayers, f} = this.values as ICalculation1Values;
  //   const {layersTemperatureWithVAG, e_b, r_opWithVAG, t_min} = this.resultValues as IResultValues;
  //   const e_n = (f / 100) * (1.84 * Math.pow(10, 11) * Math.exp(-5330 / (273 + t_min)));
  //   this.resultValues = {
  //     ...this.resultValues,
  //     layersEpWithVAG: layersTemperatureWithVAG.map((t, index) => {
  //       if (index === 0) return round(e_b, 1);
  //       const currentLayer = enabledLayers[index - 1];
  //       const layerValue = [...enabledLayers].reduce((acc, layer, index, list) => {
  //         if (currentLayer === layer) {
  //           list.splice(index);
  //         }
  //         if (!layer.materialVaporRermeability) return acc;
  //         return acc + (layer.t * 0.001) / layer.materialVaporRermeability;
  //       }, 0);
  //       return round(e_b - (e_b - e_n) * (layerValue / r_opWithVAG), 1);
  //     }),
  //   };
  // }

  private x_mu() {
    const {enabledLayers, operatingConditions} = this.values as ICalculation1Values;
    const {r_usl, layersTemperature, layersTmu} = this.resultValues as IResultValues;
    const insulationLayer = enabledLayers.find(layer => layer.layerType === 'insulation');
    let x_muLayerIndex = enabledLayers.reduceRight((acc, layer, index) => {
      if (layer.layerType === 'airGap') return acc;
      if (acc >= 0) return acc;
      return index;
    }, -1);

    const firstCheck = () => {
      const facadeMaterialLayer = enabledLayers.find(layer => layer.layerType === 'facadeMaterial');

      if (insulationLayer && facadeMaterialLayer) {
        const insulationLayerLambda =
          operatingConditions === 'А' ? insulationLayer.materialTranscalencyA : insulationLayer.materialTranscalencyB;
        const firstCondition = (insulationLayer.t * 0.001) / insulationLayerLambda > (2 / 3) * r_usl;
        const secondCondition =
          facadeMaterialLayer.materialVaporRermeability < insulationLayer.materialVaporRermeability;
        const thirdCondition = insulationLayer.materialVaporRermeability / insulationLayerLambda > 2;

        if (firstCondition && secondCondition && thirdCondition) {
          x_muLayerIndex = insulationLayer.layerId - 1;
          return insulationLayer.t * 0.001;
        }
      }
    };

    const secondCheck = () => {
      const x_mus_indexes: number[] = [];
      const x_mus = enabledLayers.reduce((acc: number[], layer, index) => {
        const t_n = layersTemperature[index];
        const t_k = layersTemperature[index + 1];
        const t_mu = layersTmu[index];
        const condition1 = t_n < t_mu && t_mu < t_k;
        const condition2 = t_n > t_mu && t_mu > t_k;

        if ((condition1 || condition2) && layer.layerType !== 'airGap') {
          x_mus_indexes.push(index);
          const t1 = condition1 ? t_k : t_n;
          const t2 = condition1 ? t_n : t_k;
          return [...acc, ((t1 - t_mu) / (t1 - t2)) * (layer.t * 0.001)];
        }
        return acc;
      }, []);
      if (x_mus.length === 0) return;
      if (x_mus.length === 1) {
        x_muLayerIndex = x_mus_indexes[0];
        return x_mus[0];
      }
      return [...enabledLayers].reduce((acc, layer, index, arr) => {
        if (layer.layerType === 'insulation') {
          x_muLayerIndex = index;
          arr.splice(index);
          return layer.t * 0.001;
        }
        return acc;
      }, 0);
    };

    const thirdCheck = () => {
      let index = 0;
      let x_mu_index: number | undefined;
      while (index < enabledLayers.length) {
        const currentLayer_t_n = layersTemperature[index];
        const currentLayer_t_k = layersTemperature[index + 1];
        const currentLayer_t_mu = layersTmu[index];
        const nextLayer_t_n = layersTemperature[index + 1];
        const nextLayer_t_k = layersTemperature[index + 2];
        const nextLayer_t_mu = layersTmu[index + 1];

        if (!nextLayer_t_k) break;

        const firstCondition =
          currentLayer_t_mu > currentLayer_t_k &&
          currentLayer_t_mu > currentLayer_t_n &&
          nextLayer_t_mu < nextLayer_t_k &&
          nextLayer_t_mu < nextLayer_t_n;

        const secondCondition =
          currentLayer_t_mu < currentLayer_t_k &&
          currentLayer_t_mu < currentLayer_t_n &&
          nextLayer_t_mu > nextLayer_t_k &&
          nextLayer_t_mu > nextLayer_t_n;

        if (firstCondition || secondCondition) {
          x_mu_index = index;
          x_muLayerIndex = index;
        }
        index++;
      }
      if (!x_mu_index) return;
      return [...enabledLayers].reduce((acc, layer, index, arr) => {
        if (index === x_mu_index) arr.splice(index);
        return acc + layer.t * 0.001;
      }, 0);
    };

    const fourthCheck = () => {
      return enabledLayers.reduce((acc, layer) => (layer.layerType !== 'airGap' ? acc + layer.t * 0.001 : acc), 0);
    };

    const firstResult = firstCheck();
    const secondResult = secondCheck();
    const thirdResult = thirdCheck();
    const fourthResult = fourthCheck();

    this.resultValues = {
      ...this.resultValues,
      x_mu: round(firstResult || secondResult || thirdResult || fourthResult, 1000),
      x_mu_index: x_muLayerIndex,
      with_x_mu: Boolean(secondResult),
    };
  }
  private R_ni() {
    const {enabledLayers} = this.values as ICalculation1Values;
    const {x_mu_index, x_mu, with_x_mu} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      R_ni: round(
        [...enabledLayers].reduce((acc, layer, index, arr) => {
          if (!layer.materialVaporRermeability) return acc;

          if (index === x_mu_index) arr.splice(index);
          const t = index === x_mu_index && with_x_mu ? x_mu : layer.t * 0.001;
          return acc + t / layer.materialVaporRermeability;
        }, 0),
        1000,
      ),
    };
  }

  private termResist() {
    const {enabledLayers, alfa_b, operatingConditions} = this.values as ICalculation1Values;
    const {x_mu_index, x_mu, with_x_mu} = this.resultValues as IResultValues;

    const enabledLayersWithoutVAG = enabledLayers.filter(
      layer => layer.materialType !== 'Вентилируемая воздушная прослойка' && layer.materialType !== 'Экран',
    );

    this.resultValues = {
      ...this.resultValues,
      termResist: round(
        [...enabledLayersWithoutVAG].reduce((acc, layer, index, arr) => {
          if (!layer.materialVaporRermeability) return acc;

          if (index === x_mu_index) arr.splice(index);
          const t = index === x_mu_index && with_x_mu ? x_mu : layer.t * 0.001;
          const lambda = operatingConditions === 'А' ? layer.materialTranscalencyA : layer.materialTranscalencyB;
          return acc + t / lambda;
        }, 1 / alfa_b),
        1000,
      ),
    };
  }
  private termResistWithVAG() {
    const {enabledLayers, alfa_b, operatingConditions} = this.values as ICalculation1Values;
    const {x_mu_index, x_mu, with_x_mu} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      termResistWithVAG: round(
        [...enabledLayers].reduce((acc, layer, index, arr) => {
          if (!layer.materialVaporRermeability) return acc;

          if (index === x_mu_index) arr.splice(index);
          const t = index === x_mu_index && with_x_mu ? x_mu : layer.t * 0.001;
          const lambda = operatingConditions === 'А' ? layer.materialTranscalencyA : layer.materialTranscalencyB;
          return acc + t / lambda;
        }, 1 / alfa_b),
        1000,
      ),
    };
  }

  private t_n() {
    const {t_b} = this.values as ICalculation1Values;
    const {t_on, termResist, r_usl} = this.resultValues as IResultValues;

    this.resultValues = {
      ...this.resultValues,
      t_n: t_on.map(t => round(t_b - ((t_b - t) / r_usl) * termResist, 1000)),
    };
  }
  private t_nWithVAG() {
    const {t_b} = this.values as ICalculation1Values;
    const {t_on, termResistWithVAG, r_uslWithVAG} = this.resultValues as IResultValues;

    this.resultValues = {
      ...this.resultValues,
      t_nWithVAG: t_on.map(t => round(t_b - ((t_b - t) / r_uslWithVAG) * termResistWithVAG, 1000)),
    };
  }

  private E_n() {
    const {t_n} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      E_n: t_n.map(t => round(1.84 * Math.pow(10, 11) * Math.exp(-5330 / (273 + t)), 1000)),
    };
  }
  private E_nWithVAG() {
    const {t_nWithVAG} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      E_nWithVAG: t_nWithVAG.map(t => round(1.84 * Math.pow(10, 11) * Math.exp(-5330 / (273 + t)), 1000)),
    };
  }

  private R_pn() {
    const {enabledLayers} = this.values as ICalculation1Values;
    const {x_mu_index, x_mu, with_x_mu} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      R_pn: round(
        enabledLayers.reduceRight((acc, layer, index) => {
          if (!layer.materialVaporRermeability) return acc;

          if (index === x_mu_index) {
            return acc + (with_x_mu ? x_mu / layer.materialVaporRermeability : 0);
          }
          if (index > x_mu_index) {
            return acc + (layer.t * 0.001) / layer.materialVaporRermeability;
          }
          return acc;
        }, 0),
        1000,
      ),
    };
  }
  private R_tr_n1() {
    const {p_year} = this.values as ICalculation1Values;
    const {e_b, E, R_pn} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      R_tr_n1: round(((e_b - E) * R_pn) / (E - p_year * 100), 1000),
    };
  }

  private t_o1() {
    const {t_b, t0} = this.values as ICalculation1Values;
    const {termResist, r_usl} = this.resultValues as IResultValues;

    this.resultValues = {
      ...this.resultValues,
      t_o1: round(t_b - ((t_b - t0) / r_usl) * termResist, 1000),
    };
  }
  private t_o1WithVAG() {
    const {t_b, t0} = this.values as ICalculation1Values;
    const {termResistWithVAG, r_uslWithVAG} = this.resultValues as IResultValues;

    this.resultValues = {
      ...this.resultValues,
      t_o1WithVAG: round(t_b - ((t_b - t0) / r_uslWithVAG) * termResistWithVAG, 1000),
    };
  }

  private E_o() {
    const {t_o1} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      E_o: round(1.84 * Math.pow(10, 11) * Math.exp(-5330 / (273 + t_o1)), 1000),
    };
  }
  private E_oWithVAG() {
    const {t_o1WithVAG} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      E_oWithVAG: round(1.84 * Math.pow(10, 11) * Math.exp(-5330 / (273 + t_o1WithVAG)), 1000),
    };
  }

  private eta() {
    const {z0} = this.values as ICalculation1Values;
    const {R_pn, E_o, e_notr} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      eta: round((0.0024 * z0 * (E_o - e_notr)) / R_pn, 1000),
    };
  }
  private R_tr_n2() {
    const {z0, enabledLayers} = this.values as ICalculation1Values;
    const {E_o, e_b, x_mu_index, eta} = this.resultValues as IResultValues;
    const layer = enabledLayers[x_mu_index];

    this.resultValues = {
      ...this.resultValues,
      R_tr_n2: round(
        (0.0024 * z0 * (e_b - E_o)) / (layer.materialDensity * layer.t * 0.001 * layer.materialDeltaW + eta),
        1000,
      ),
    };
  }
  private conclusion5() {
    const {R_ni, R_tr_n1, R_tr_n2} = this.resultValues as IResultValues;
    let conclusion5: IConclusion5 = locales.services.calculationService[1].conclusion5.error;

    if (R_ni > Math.max(R_tr_n1, R_tr_n2)) {
      conclusion5 = locales.services.calculationService[1].conclusion5.success;
    }

    this.resultValues = {
      ...this.resultValues,
      conclusion5,
    };
  }
  // Вентилируемая воздушная прослойка
  // Влагонакопление с учётом неоднородности экрана
  private R_prime_p() {
    const {enabledLayers, sum_xi_sh} = this.values as ICalculation1Values;
    const screenLayer = enabledLayers.find(layer => layer.materialType === 'Экран');

    const b = 1;
    const mu_sh = 6.5;
    const rtst2pa = 0.133322;

    this.resultValues = {
      ...this.resultValues,
      R_prime_p: screenLayer ? round(((screenLayer.t * 0.001) / ((b * mu_sh) / sum_xi_sh)) * rtst2pa, 1000000) : 0,
    };
  }
  private R_p() {
    const {enabledLayers} = this.values as ICalculation1Values;
    const screenLayer = enabledLayers.find(layer => layer.materialType === 'Экран');

    this.resultValues = {
      ...this.resultValues,
      R_p: screenLayer ? round((screenLayer.t * 0.001) / screenLayer.materialVaporRermeability, 1000000) : 0,
    };
  }
  private R_pr_p_prime() {
    const {h_gap} = this.values as ICalculation1Values;
    const {R_prime_p, R_p} = this.resultValues as IResultValues;

    const normalize_h_gap = h_gap * 0.001;
    const F_gl = 1 - normalize_h_gap;
    const F_prime = normalize_h_gap;
    const F_sum = Math.round((F_gl + F_prime) * 1000) / 1000; // = 1

    this.resultValues = {
      ...this.resultValues,
      R_pr_p_prime: round(F_sum / (F_gl / R_p + F_prime / R_prime_p), 1000000),
    };
  }
  private Mu() {
    const {enabledLayers} = this.values as ICalculation1Values;
    const {R_pr_p_prime} = this.resultValues as IResultValues;
    const screenLayer = enabledLayers.find(layer => layer.materialType === 'Экран');

    this.resultValues = {
      ...this.resultValues,
      Mu: screenLayer ? round((screenLayer.t * 0.001) / R_pr_p_prime, 1000000) : 0,
    };
  }

  private R_prime_op() {
    const {r_opWithVAG, R_p} = this.resultValues as IResultValues;

    this.resultValues = {
      ...this.resultValues,
      R_prime_op: round(r_opWithVAG - R_p, 1000000),
    };
  }

  private R_tr_n1_prime() {
    const {p_year} = this.values as ICalculation1Values;
    const {e_b, EWithVAG, R_pr_p_prime} = this.resultValues as IResultValues;

    this.resultValues = {
      ...this.resultValues,
      R_tr_n1_prime: round(((e_b - EWithVAG) * R_pr_p_prime) / (EWithVAG - p_year * 100), 1000000),
    };
  }
  private R_tr_n2_prime() {
    const {z0} = this.values as ICalculation1Values;
    const {R_pr_p_prime, e_notr, E_oWithVAG, e_b} = this.resultValues as IResultValues;
    const eta_prime = round((0.0024 * z0 * (E_oWithVAG - e_notr)) / R_pr_p_prime, 1000000);

    this.resultValues = {
      ...this.resultValues,
      R_tr_n2_prime: round((0.0024 * z0 * (e_b - E_oWithVAG)) / eta_prime, 1000000),
    };
  }
  private conclusion6() {
    const {R_prime_op, R_tr_n1_prime, R_tr_n2_prime} = this.resultValues as IResultValues;
    let conclusion6: IConclusion6 = locales.services.calculationService[1].conclusion6.error;

    if (R_prime_op > Math.max(R_tr_n1_prime, R_tr_n2_prime)) {
      conclusion6 = locales.services.calculationService[1].conclusion6.success;
    }

    this.resultValues = {
      ...this.resultValues,
      conclusion6,
    };
  }

  private eta_prime() {
    const {z0} = this.values as ICalculation1Values;
    const {R_pr_p_prime, E_oWithVAG, e_notr} = this.resultValues as IResultValues;

    this.resultValues = {
      ...this.resultValues,
      eta_prime: round((0.0024 * z0 * (E_oWithVAG - e_notr)) / R_pr_p_prime, 1000000),
    };
  }

  // Расчет влажностного режима стены по годовому балансу влаги

  private sum_R_ni_section() {
    const {enabledLayers} = this.values as ICalculation1Values;
    const screenLayerIndex = enabledLayers.findIndex(layer => layer.materialType === 'Экран');
    const sectionEnabledLayers = enabledLayers.slice(0, screenLayerIndex);

    const sum_R_ni_section = sectionEnabledLayers.reduce((acc: number, currentLayer: ILayer) => {
      return acc + (currentLayer.t * 0.001) / currentLayer.materialVaporRermeability;
    }, 0);

    this.resultValues = {
      ...this.resultValues,
      sum_R_ni_section: screenLayerIndex ? round(sum_R_ni_section, 1000000) : 0,
    };
  }
  private sum_R_i_section() {
    const {enabledLayers, operatingConditions} = this.values as ICalculation1Values;
    const screenLayerIndex = enabledLayers.findIndex(layer => layer.materialType === 'Экран');
    const sectionEnabledLayers = enabledLayers.slice(0, screenLayerIndex);

    const sum_R_i_section = sectionEnabledLayers.reduce((acc: number, currentLayer: ILayer) => {
      const materialTranscalency =
        operatingConditions === 'А' ? currentLayer.materialTranscalencyA : currentLayer.materialTranscalencyB;

      return acc + (currentLayer.t * 0.001) / materialTranscalency;
    }, 0);

    this.resultValues = {
      ...this.resultValues,
      sum_R_i_section: screenLayerIndex ? round(sum_R_i_section, 1000000) : 0,
    };
  }

  // Расчёт по средней температуре января — (выбрать месяц по таблице с наибольшим накоплением конденсата)
  private greatestCondensateMonth() {
    const {greatestCondensateMonth} = createDataSourceResult5(
      this.values as ICalculation1Values,
      this.resultValues,
      calculation1.result,
    ); // const greatestCondensateMonth = 'jan';

    this.resultValues = {
      ...this.resultValues,
      greatestCondensateMonth: greatestCondensateMonth,
    };
  }

  // Влагонакопление с учётом тепловлажностного режима прослойки. Расчёт по средней температуре января
  private t_n_greatestCondensate() {
    const {greatestCondensateMonth} = this.resultValues as IResultValues;
    const values = this.values as ICalculation1Values;
    const t_n_greatestCondensate = values[('t_' + greatestCondensateMonth) as keyof IUserValuesAtCalculation1];

    this.resultValues = {
      ...this.resultValues,
      t_n_greatestCondensate: t_n_greatestCondensate,
    };
  }
  private t_sr_greatestCondensate() {
    const {t_n_greatestCondensate} = this.resultValues as IResultValues;
    const t_sr_greatestCondensate = (t_n_greatestCondensate as number) * 0.95;
    this.resultValues = {
      ...this.resultValues,
      t_sr_greatestCondensate: Math.round(t_sr_greatestCondensate * 1000) / 1000,
    };
  }
  private fi_sr_greatestCondensate() {
    const {f} = this.values as ICalculation1Values;
    this.resultValues = {
      ...this.resultValues,
      fi_sr_greatestCondensate: f,
    };
  }

  private E_sr() {
    const {t_sr_greatestCondensate} = this.resultValues as IResultValues;

    this.resultValues = {
      ...this.resultValues,
      E_sr: Math.round(1.84 * Math.pow(10, 11) * Math.exp(-5330 / (273 + t_sr_greatestCondensate)) * 1000) / 1000,
    };
  }
  private e_sr() {
    const {f} = this.values as ICalculation1Values;
    const {E_sr} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      e_sr: Math.round((f / 100) * E_sr * 1000) / 1000,
    };
  }
  private V_pr() {
    const {t_n_greatestCondensate, t_sr_greatestCondensate} = this.resultValues as IResultValues;
    const values = this.values as ICalculation1Values;
    const {sum_xi, h_all} = values;

    this.resultValues = {
      ...this.resultValues,
      V_pr:
        Math.round(
          Math.sqrt((0.08 * h_all * Math.abs(t_sr_greatestCondensate - t_n_greatestCondensate)) / sum_xi) * 0.93 * 1000,
        ) / 1000,
    };
  }
  private gamma_pr() {
    const {t_sr_greatestCondensate} = this.resultValues as IResultValues;

    const g = 9.807;
    this.resultValues = {
      ...this.resultValues,
      gamma_pr: Math.round((3463 / ((273 + t_sr_greatestCondensate) * g)) * 1000) / 1000,
    };
  }
  private W() {
    const {V_pr, gamma_pr} = this.resultValues as IResultValues;
    const {enabledLayers} = this.values as ICalculation1Values;
    const AirGapLayer = enabledLayers.find(layer => layer.materialType === 'Вентилируемая воздушная прослойка');

    const normalize_delta_t = AirGapLayer ? AirGapLayer.t * 0.001 : 0;

    this.resultValues = {
      ...this.resultValues,
      W: Math.round(V_pr * 3600 * normalize_delta_t * gamma_pr * 1000) / 1000,
    };
  }
  private alfa_l() {
    const alfa_l = 2.44;

    this.resultValues = {
      ...this.resultValues,
      alfa_l: alfa_l,
    };
  }
  private alfa_pr() {
    const {V_pr, alfa_l} = this.resultValues as IResultValues;
    const alfa_pr = 5.5 + 5.7 * V_pr + alfa_l;

    this.resultValues = {
      ...this.resultValues,
      alfa_pr: Math.round(alfa_pr * 1000) / 1000,
    };
  }
  private R_pr() {
    const {alfa_pr} = this.resultValues as IResultValues;
    const R_pr = 1 / alfa_pr;

    this.resultValues = {
      ...this.resultValues,
      R_pr: Math.round(R_pr * 1000) / 1000,
    };
  }
  private k_v() {
    const {enabledLayers, operatingConditions} = this.values as ICalculation1Values;
    const AirGapLayer = enabledLayers.find(layer => layer.materialType === 'Вентилируемая воздушная прослойка');
    const AirGapLayerIndex = enabledLayers.findIndex(
      layer => layer.materialType === 'Вентилируемая воздушная прослойка',
    );

    const v_pr = AirGapLayer ? airGapRMapByName[AirGapLayer.materialName] : 0;

    const summ_v = (layers: ILayer[]) =>
      layers.reduce((acc, layer) => {
        const lambda = operatingConditions === 'А' ? layer.materialTranscalencyA : layer.materialTranscalencyB;
        const v = layer.layerType === 'airGap' ? airGapRMapByName[layer.materialName] : (layer.t * 0.001) / lambda;
        return acc + v;
      }, 0);

    const k_v = v_pr / 2 + summ_v(enabledLayers.slice(0, AirGapLayerIndex));

    this.resultValues = {
      ...this.resultValues,
      k_v: Math.round(k_v * 1000) / 1000,
    };
  }
  private k_n() {
    const {enabledLayers, operatingConditions} = this.values as ICalculation1Values;
    const AirGapLayer = enabledLayers.find(layer => layer.materialType === 'Вентилируемая воздушная прослойка');
    const AirGapLayerIndex = enabledLayers.findIndex(
      layer => layer.materialType === 'Вентилируемая воздушная прослойка',
    );

    const v_pr = AirGapLayer ? airGapRMapByName[AirGapLayer.materialName] : 0;
    const summ_v = (layers: ILayer[]) =>
      layers.reduce((acc: number, layer: ILayer) => {
        const lambda = operatingConditions === 'А' ? layer.materialTranscalencyA : layer.materialTranscalencyB;
        const v = layer.layerType === 'airGap' ? airGapRMapByName[layer.materialName] : (layer.t * 0.001) / lambda;
        return acc + v;
      }, 0);

    const k_n = v_pr / 2 + summ_v(enabledLayers.slice(AirGapLayerIndex + 1));

    this.resultValues = {
      ...this.resultValues,
      k_n: Math.round(k_n * 1000) / 1000,
    };
  }
  private t_y() {
    const {k_v, k_n, W, t_n_greatestCondensate, t_sr_greatestCondensate} = this.resultValues as IResultValues;
    const values = this.values as ICalculation1Values;
    const {t_b, h_y} = values;

    const normalize_h_y = h_y * 0.001;

    const C_b = 3.6;
    const C = 1009;

    const t_y =
      (k_v * t_b +
        k_n * t_n_greatestCondensate +
        (t_sr_greatestCondensate * (k_v + k_n) -
          (k_v * t_b + k_n * t_n_greatestCondensate) * Math.exp(-((C_b * (k_v + k_n) * normalize_h_y) / (W * C))))) /
      (k_v + k_n);

    this.resultValues = {
      ...this.resultValues,
      t_y: Math.round(t_y * 1000) / 1000,
    };
  }
  private E_y() {
    const {t_y} = this.resultValues as IResultValues;

    this.resultValues = {
      ...this.resultValues,
      E_y: Math.round(1.84 * Math.pow(10, 11) * Math.exp(-5330 / (273 + t_y)) * 1000) / 1000,
    };
  }
  private M_v() {
    const {R_prime_op} = this.resultValues as IResultValues;

    const M_v = 1 / R_prime_op;

    this.resultValues = {
      ...this.resultValues,
      M_v: Math.round(M_v * 1000) / 1000,
    };
  }
  private M_n() {
    const {R_pr_p_prime} = this.resultValues as IResultValues;

    const M_n = 1 / R_pr_p_prime;

    this.resultValues = {
      ...this.resultValues,
      M_n: Math.round(M_n * 1000) / 1000,
    };
  }
  private B() {
    const {t_y} = this.resultValues as IResultValues;

    const B = 1.058 / (1 + t_y / 273);

    this.resultValues = {
      ...this.resultValues,
      B: Math.round(B * 1000) / 1000,
    };
  }
  private e_y() {
    const {M_v, M_n, e_sr, e_b, W, B, t_n_greatestCondensate} = this.resultValues as IResultValues;
    const {f, h_y} = this.values as ICalculation1Values;

    const normalize_h_y = h_y * 0.001;

    const e_n_greatestCondensate =
      (f / 100) * (1.84 * Math.pow(10, 11) * Math.exp(-5330 / (273 + t_n_greatestCondensate)));

    const e_y =
      (M_v * e_b +
        M_n * e_n_greatestCondensate +
        (e_sr * (M_v + M_n) - (M_v * e_b + M_n * e_n_greatestCondensate)) *
          Math.exp(-((M_v + M_n) * normalize_h_y) / (W * B))) /
      (M_v + M_n);

    this.resultValues = {
      ...this.resultValues,
      e_y: Math.round(e_y * 1000) / 1000,
    };
  }

  // Влагонакопление с учётом тепловлажностного режима прослойки. Расчёт по самой холодной пятидневке
  private t_n_coldestFiveDays() {
    const {t_h} = this.values as ICalculation1Values;

    this.resultValues = {
      ...this.resultValues,
      t_n_coldestFiveDays: t_h,
    };
  }
  private t_sr_coldestFiveDays() {
    const {t_n_coldestFiveDays} = this.resultValues as IResultValues;
    const t_sr_coldestFiveDays = (t_n_coldestFiveDays as number) * 0.95;
    this.resultValues = {
      ...this.resultValues,
      t_sr_coldestFiveDays: Math.round(t_sr_coldestFiveDays * 1000) / 1000,
    };
  }
  private fi_sr_coldestFiveDays() {
    const {f} = this.values as ICalculation1Values;
    this.resultValues = {
      ...this.resultValues,
      fi_sr_coldestFiveDays: f,
    };
  }

  private E_sr_h() {
    const {t_sr_coldestFiveDays} = this.resultValues as IResultValues;

    this.resultValues = {
      ...this.resultValues,
      E_sr_h: Math.round(1.84 * Math.pow(10, 11) * Math.exp(-5330 / (273 + t_sr_coldestFiveDays)) * 1000) / 1000,
    };
  }
  private e_sr_h() {
    const {fi_sr_coldestFiveDays} = this.resultValues as IResultValues;

    const {E_sr_h} = this.resultValues as IResultValues;
    this.resultValues = {
      ...this.resultValues,
      e_sr_h: Math.round((fi_sr_coldestFiveDays / 100) * E_sr_h * 1000) / 1000,
    };
  }
  private V_pr_h() {
    const {sum_xi, h_all} = this.values as ICalculation1Values;
    const {t_n_coldestFiveDays, t_sr_coldestFiveDays} = this.resultValues as IResultValues;

    this.resultValues = {
      ...this.resultValues,
      V_pr_h:
        Math.round(
          Math.sqrt((0.08 * h_all * Math.abs(t_sr_coldestFiveDays - t_n_coldestFiveDays)) / sum_xi) * 0.93 * 1000,
        ) / 1000,
    };
  }
  private gamma_pr_h() {
    const {t_sr_coldestFiveDays} = this.resultValues as IResultValues;

    const g = 9.807;
    this.resultValues = {
      ...this.resultValues,
      gamma_pr_h: Math.round((3463 / ((273 + t_sr_coldestFiveDays) * g)) * 1000) / 1000,
    };
  }
  private W_h() {
    const {V_pr_h, gamma_pr_h} = this.resultValues as IResultValues;
    const {enabledLayers} = this.values as ICalculation1Values;
    const AirGapLayer = enabledLayers.find(layer => layer.materialType === 'Вентилируемая воздушная прослойка');

    const normalize_delta_t = AirGapLayer ? AirGapLayer.t * 0.001 : 0;

    this.resultValues = {
      ...this.resultValues,
      W_h: Math.round(V_pr_h * 3600 * normalize_delta_t * gamma_pr_h * 1000) / 1000,
    };
  }
  private alfa_pr_h() {
    const {V_pr_h, alfa_l} = this.resultValues as IResultValues;
    const alfa_pr_h = 5.5 + 5.7 * V_pr_h + alfa_l;

    this.resultValues = {
      ...this.resultValues,
      alfa_pr_h: Math.round(alfa_pr_h * 1000) / 1000,
    };
  }
  private R_pr_h() {
    const {alfa_pr_h} = this.resultValues as IResultValues;
    const R_pr_h = 1 / alfa_pr_h;

    this.resultValues = {
      ...this.resultValues,
      R_pr_h: Math.round(R_pr_h * 1000) / 1000,
    };
  }
  private k_v_h() {
    const {enabledLayers, operatingConditions} = this.values as ICalculation1Values;
    const AirGapLayer = enabledLayers.find(layer => layer.materialType === 'Вентилируемая воздушная прослойка');
    const AirGapLayerIndex = enabledLayers.findIndex(
      layer => layer.materialType === 'Вентилируемая воздушная прослойка',
    );

    const v_pr = AirGapLayer ? airGapRMapByName[AirGapLayer.materialName] : 0;

    const summ_v = (layers: ILayer[]) =>
      layers.reduce((acc, layer) => {
        const lambda = operatingConditions === 'А' ? layer.materialTranscalencyA : layer.materialTranscalencyB;
        const v = layer.layerType === 'airGap' ? airGapRMapByName[layer.materialName] : (layer.t * 0.001) / lambda;
        return acc + v;
      }, 0);

    const k_v_h = v_pr / 2 + summ_v(enabledLayers.slice(0, AirGapLayerIndex));

    this.resultValues = {
      ...this.resultValues,
      k_v_h: Math.round(k_v_h * 1000) / 1000,
    };
  }
  private k_n_h() {
    const {enabledLayers, operatingConditions} = this.values as ICalculation1Values;
    const AirGapLayer = enabledLayers.find(layer => layer.materialType === 'Вентилируемая воздушная прослойка');
    const AirGapLayerIndex = enabledLayers.findIndex(
      layer => layer.materialType === 'Вентилируемая воздушная прослойка',
    );

    const v_pr = AirGapLayer ? airGapRMapByName[AirGapLayer.materialName] : 0;
    const summ_v = (layers: ILayer[]) =>
      layers.reduce((acc, layer) => {
        const lambda = operatingConditions === 'А' ? layer.materialTranscalencyA : layer.materialTranscalencyB;
        const v = layer.layerType === 'airGap' ? airGapRMapByName[layer.materialName] : (layer.t * 0.001) / lambda;
        return acc + v;
      }, 0);

    const k_n_h = v_pr / 2 + summ_v(enabledLayers.slice(AirGapLayerIndex + 1));

    this.resultValues = {
      ...this.resultValues,
      k_n_h: Math.round(k_n_h * 1000) / 1000,
    };
  }
  private t_y_h() {
    const {t_b, h_y} = this.values as ICalculation1Values;
    const {k_v_h, k_n_h, W_h, t_n_coldestFiveDays, t_sr_coldestFiveDays} = this.resultValues as IResultValues;

    const normalize_h_y = h_y * 0.001;

    const C_b = 3.6;
    const C = 1009;

    const t_y_h =
      (k_v_h * t_b +
        k_n_h * t_n_coldestFiveDays +
        (t_sr_coldestFiveDays * (k_v_h + k_n_h) -
          (k_v_h * t_b + k_n_h * t_n_coldestFiveDays) *
            Math.exp(-((C_b * (k_v_h + k_n_h) * normalize_h_y) / (W_h * C))))) /
      (k_v_h + k_n_h);

    this.resultValues = {
      ...this.resultValues,
      t_y_h: Math.round(t_y_h * 1000) / 1000,
    };
  }
  private E_y_h() {
    const {t_y_h} = this.resultValues as IResultValues;

    this.resultValues = {
      ...this.resultValues,
      E_y_h: Math.round(1.84 * Math.pow(10, 11) * Math.exp(-5330 / (273 + t_y_h)) * 1000) / 1000,
    };
  }
  private M_v_h() {
    const {R_prime_op} = this.resultValues as IResultValues;

    const M_v_h = 1 / R_prime_op;

    this.resultValues = {
      ...this.resultValues,
      M_v_h: Math.round(M_v_h * 1000) / 1000,
    };
  }
  private M_n_h() {
    const {R_pr_p_prime} = this.resultValues as IResultValues;

    const M_n_h = 1 / R_pr_p_prime;

    this.resultValues = {
      ...this.resultValues,
      M_n_h: Math.round(M_n_h * 1000) / 1000,
    };
  }
  private B_h() {
    const {t_y_h} = this.resultValues as IResultValues;

    const B_h = 1.058 / (1 + t_y_h / 273);

    this.resultValues = {
      ...this.resultValues,
      B_h: Math.round(B_h * 1000) / 1000,
    };
  }
  private e_y_h() {
    const {f, h_y} = this.values as ICalculation1Values;
    const {M_v_h, M_n_h, e_sr_h, e_b, W_h, B_h, t_n_coldestFiveDays} = this.resultValues as IResultValues;

    const normalize_h_y = h_y * 0.001;

    const e_n_coldestFiveDays = (f / 100) * (1.84 * Math.pow(10, 11) * Math.exp(-5330 / (273 + t_n_coldestFiveDays)));

    const e_y_h =
      (M_v_h * e_b +
        M_n_h * e_n_coldestFiveDays +
        (e_sr_h * (M_v_h + M_n_h) - (M_v_h * e_b + M_n_h * e_n_coldestFiveDays)) *
          Math.exp(-((M_v_h + M_n_h) * normalize_h_y) / (W_h * B_h))) /
      (M_v_h + M_n_h);

    this.resultValues = {
      ...this.resultValues,
      e_y_h: Math.round(e_y_h * 1000) / 1000,
    };
  }

  private conclusion7() {
    const {e_y, E_y} = this.resultValues as IResultValues;
    let conclusion7: IConclusion7 = locales.services.calculationService[1].conclusion7.error;

    if (e_y < E_y) {
      conclusion7 = locales.services.calculationService[1].conclusion7.success;
    }

    this.resultValues = {
      ...this.resultValues,
      conclusion7,
    };
  }

  private conclusion8() {
    const {e_y_h, E_y_h} = this.resultValues as IResultValues;
    let conclusion8: IConclusion8 = locales.services.calculationService[1].conclusion8.error;

    if (e_y_h < E_y_h) {
      conclusion8 = locales.services.calculationService[1].conclusion8.success;
    }

    this.resultValues = {
      ...this.resultValues,
      conclusion8,
    };
  }

  calculate(values: ICalculation1Values) {
    this.values = {
      ...values,
      enabledLayers: values.layers.filter(layer => layer.isEnabled),
      enabledLayersByAllParams: values.layers.filter(layer => layer.isEnabled && layer.materialVaporRermeability),
    };
    this.gsop();
    this.r_t();
    this.r_c();
    this.r_e();
    this.r_usl();
    this.r_uslWithVAG();
    this.R();
    this.conclusion1();
    this.conclusion2();
    this.t_min();
    this.allR();
    this['allR+-']();
    this.allQ();
    this['allQ+-']();
    this.conclusion3();
    this.conclusion4();

    this.r_op();
    this.r_opWithVAG();

    this.E_b();
    this.e_b();
    this.t_notr();
    this.e_notr();

    this.layersTemperature();
    this.layersTemperatureWithoutVAG();
    // this.layersTemperatureWithVAG();

    this.layersE();
    this.layersEp();

    // this.layersEWithVAG();
    // this.layersEpWithVAG();

    this.layersDewTemperature();
    // this. WithVAG();

    this.layersFi();
    this.layersTmu();
    this.x_mu();
    this.R_ni();
    this.z_n();
    this.t_on();
    this.termResist();
    this.termResistWithVAG();
    this.t_n();
    this.t_nWithVAG();
    this.E_n();
    this.E_nWithVAG();
    this.E();
    this.EWithVAG();
    this.R_pn();
    this.R_tr_n1();
    this.t_o1();
    this.t_o1WithVAG();
    this.E_o();
    this.E_oWithVAG();
    this.eta();
    this.R_tr_n2();
    this.conclusion5();
    // Вентилируемая воздушная прослойка
    this.R_prime_p();
    this.R_p();
    this.R_pr_p_prime();
    this.Mu();
    this.R_prime_op();
    this.R_tr_n1_prime();
    this.R_tr_n2_prime();
    this.conclusion6();
    this.eta_prime();
    this.sum_R_ni_section();
    this.sum_R_i_section();
    //
    this.greatestCondensateMonth();
    this.t_n_greatestCondensate();
    this.t_sr_greatestCondensate();
    this.fi_sr_greatestCondensate();
    //
    this.E_sr();
    this.e_sr();
    this.V_pr();
    this.gamma_pr();
    this.W();
    this.alfa_l();
    this.alfa_pr();
    this.R_pr();
    this.k_v();
    this.k_n();
    this.t_y();
    this.E_y();
    this.M_v();
    this.M_n();
    this.B();
    this.e_y();
    //
    this.t_n_coldestFiveDays();
    this.t_sr_coldestFiveDays();
    this.fi_sr_coldestFiveDays();
    this.E_sr_h();
    this.e_sr_h();
    this.V_pr_h();
    this.gamma_pr_h();
    this.W_h();
    this.alfa_pr_h();
    this.R_pr_h();
    this.k_v_h();
    this.k_n_h();
    this.t_y_h();
    this.E_y_h();
    this.M_v_h();
    this.M_n_h();
    this.B_h();
    this.e_y_h();
    //
    this.conclusion7();
    this.conclusion8();
    return this.resultValues;
  }
}

const symbEq = {type: 'symbol', value: '\u00a0=\u00a0'};
const symbPlus = {type: 'symbol', value: '\u00a0+\u00a0'};
const symbMinus = {type: 'symbol', value: '\u00a0—\u00a0'};
const symbMult = {type: 'symbol', value: '\u00a0×\u00a0'};
const symbSeg = {type: 'symbol', value: '\u00a0/\u00a0'};
const symbBracketLeft = {type: 'symbol', value: '('};
const symbBracketRight = {type: 'symbol', value: ')'};
const symbSquareBracketLeft = {type: 'symbol', value: '['};
const symbSquareBracketRight = {type: 'symbol', value: ']'};
const vartot = {type: 'var', value: 't', fontStyle: 'italic', sub: 'от'};
const vartb = {type: 'var', value: 't', fontStyle: 'italic', sub: 'в'};
const varth = {type: 'var', value: 't', fontStyle: 'italic', sub: 'н'};
const vartnotr = {type: 'var', value: 't', sub: 'н,отр', fontStyle: 'italic'};
const vargsop = {type: 'var', value: 'ГСОП'};
const varrt = {type: 'var', value: 'R', fontStyle: 'italic', sub: 'т'};
const varrc = {type: 'var', value: 'R', fontStyle: 'italic', sub: 'с'};
const varre = {type: 'var', value: 'R', fontStyle: 'italic', sub: 'э'};
const varrpn = {type: 'var', value: 'R', fontStyle: 'italic', sub: 'п,н'};
const varrx = {type: 'var', value: 'R', fontStyle: 'italic', sub: 'х'};
const varrusl = {type: 'var', value: 'R', fontStyle: 'italic', sub: '0', sup: 'усл'};
const varfi = {type: 'var', value: 'f', fontStyle: 'italic', sub: 'i'};
const vartx = {type: 'var', value: 't', fontStyle: 'italic', sub: 'х'};
const varEb = {type: 'var', value: 'E', fontStyle: 'italic'};
const varE = {type: 'var', value: 'E', fontStyle: 'italic'};
const vareb = {type: 'var', value: 'e', sub: 'в', fontStyle: 'italic'};
const varE0 = {type: 'var', value: 'E', sub: '0', fontStyle: 'italic'};
const varnotr = {type: 'var', value: 'e', sub: 'н,отр'};
const vareta = {type: 'var', value: 'η'};
const varmu = {type: 'var', value: 'μ'};
const varz0 = {type: 'var', value: 'z', sub: '0', fontStyle: 'italic'};

export const formulas = {
  gsop: {
    name: 'gsop',
    value: [
      vargsop,
      symbEq,
      symbBracketLeft,
      vartb,
      symbMinus,
      vartot,
      symbBracketRight,
      symbMult,
      {type: 'var', value: 'Z', fontStyle: 'italic', sub: 'от'},
      symbEq,
    ],
    unit: '°С сут/год',
  },
  r_t: {
    name: 'r_t',
    value: [
      varrt,
      symbEq,
      {type: 'var', value: 'a', fontStyle: 'italic'},
      symbMult,
      vargsop,
      symbPlus,
      {type: 'var', value: 'b', fontStyle: 'italic'},
      symbEq,
    ],
    unit: 'м²°С/Вт',
  },
  r_c: {
    name: 'r_c',
    value: [
      varrc,
      symbEq,
      {
        type: 'fraction',
        value: [
          [symbBracketLeft, vartb, symbMinus, vartot, symbBracketRight, symbMult, {type: 'var', value: 'n'}],
          [{type: 'var', value: 'Δt', sup: 'н'}, symbMult, {type: 'var', value: 'α', sub: 'в'}],
        ],
      },
      symbEq,
    ],
    unit: 'м²°С/Вт',
  },
  r_e: {
    name: 'r_e',
    value: [varre, symbEq, varrt, symbMult, {type: 'var', value: 'm', sub: 'р', fontStyle: 'italic'}, symbEq],
    unit: 'м²°С/Вт',
  },
  r_usl: {
    name: 'r_usl',
    value: [
      varrusl,
      symbEq,
      {
        type: 'var',
        value: '∑',
      },
      {
        type: 'fraction',
        value: [
          [{type: 'var', value: 'δ', sub: 'i', fontStyle: 'italic'}],
          [{type: 'var', value: 'λ', sub: 'i', fontStyle: 'italic'}],
        ],
      },
      symbPlus,
      {
        type: 'fraction',
        value: [[{type: 'var', value: '1'}], [{type: 'var', value: 'α', sub: 'в'}]],
      },
      symbPlus,
      {
        type: 'fraction',
        value: [[{type: 'var', value: '1'}], [{type: 'var', value: 'α', sub: 'н'}]],
      },
      symbEq,
    ],
    unit: 'м²°С/Вт',
  },
  R: {
    name: 'R',
    value: [
      {type: 'var', value: 'R', fontStyle: 'italic'},
      symbEq,
      varrusl,
      symbMult,
      {type: 'var', value: 'r', fontStyle: 'italic'},
      symbEq,
    ],
    unit: 'м²°С/Вт',
  },
  f_i: {
    name: 'f_i',
    value: [
      varfi,
      symbBracketLeft,
      {type: 'var', value: 't', sub: 'м.у.', fontStyle: 'italic'},
      symbBracketRight,
      symbEq,
      {type: 'var', value: 5330},
      symbMult,
      {
        type: 'fraction',
        value: [
          [
            {type: 'var', value: 'R', sub: 'о,п', fontStyle: 'italic'},
            symbBracketLeft,
            vartb,
            symbMinus,
            {type: 'var', value: 't', sub: 'н,отр', fontStyle: 'italic'},
            symbBracketRight,
          ],
          [
            varrusl,
            symbBracketLeft,
            vareb,
            symbMinus,
            {type: 'var', value: 'e', sub: 'н,отр', fontStyle: 'italic'},
            symbBracketRight,
          ],
        ],
      },
      symbMult,
      {
        type: 'fraction',
        value: [
          [{type: 'var', value: 'μ', sub: 'i', fontStyle: 'italic'}],
          [{type: 'var', value: 'λ', sub: 'i', fontStyle: 'italic'}],
        ],
      },
      symbEq,
    ],
  },
  r_op: {
    name: 'r_op',
    value: [
      {type: 'var', value: 'R', fontStyle: 'italic', sub: 'о,п'},
      symbEq,
      {type: 'var', value: '∑', fontStyle: 'italic'},
      {type: 'var', value: 'R', fontStyle: 'italic', sub: 'ni'},
      symbEq,
    ],
    unit: 'м²·ч·Па/мг',
  },
  R_ni: {
    name: 'R_ni',
    value: [{type: 'var', value: 'R', fontStyle: 'italic', sub: 'ni'}, symbEq],
    unit: 'м²·ч·Па/мг',
  },
  E_b: {
    name: 'E_b',
    value: [
      varEb,
      symbEq,
      {type: 'var', value: '1.84'},
      symbMult,
      {type: 'var', value: '10^11'},
      symbMult,
      {type: 'var', value: 'exp'},
      symbBracketLeft,
      {type: 'var', value: '-5330'},
      symbSeg,
      symbBracketLeft,
      {type: 'var', value: '273'},
      symbPlus,
      {type: 'var', value: 't', fontStyle: 'italic'},
      symbBracketRight,
      symbBracketRight,
      symbEq,
    ],
    unit: 'Па',
  },
  e_b: {
    name: 'e_b',
    value: [
      vareb,
      symbEq,
      symbBracketLeft,
      {type: 'var', value: 'φ', fontStyle: 'italic', sub: 'в'},
      symbSeg,
      {type: 'var', value: '100'},
      symbBracketRight,
      symbMult,
      varEb,
      symbEq,
    ],
    unit: 'Па',
  },
  t_x: {
    name: 't_x',
    value: [
      vartx,
      symbEq,
      vartb,
      symbMinus,
      {
        type: 'fraction',
        value: [
          [symbBracketLeft, vartb, symbMinus, varth, symbBracketRight],
          [varrusl, symbMult, varrx],
        ],
      },
      symbEq,
    ],
    unit: 'Па',
  },
  E: {
    name: 'E',
    value: [
      varE,
      symbEq,
      symbBracketLeft,
      {type: 'var', value: 'E', sub: '1', fontStyle: 'italic'},
      symbMult,
      {type: 'var', value: 'z', sub: '1', fontStyle: 'italic'},
      symbPlus,
      {type: 'var', value: 'E', sub: '2', fontStyle: 'italic'},
      symbMult,
      {type: 'var', value: 'z', sub: '2', fontStyle: 'italic'},
      symbPlus,
      {type: 'var', value: 'E', sub: '3', fontStyle: 'italic'},
      symbMult,
      {type: 'var', value: 'z', sub: '3', fontStyle: 'italic'},
      symbBracketRight,
      symbSeg,
      {type: 'var', value: 12},
      symbEq,
    ],
    unit: 'Па',
  },
  E_i: {
    name: 'E_i',
    value: [
      varEb,
      symbEq,
      {type: 'var', value: '1.84'},
      symbMult,
      {type: 'var', value: '10^11'},
      symbMult,
      {type: 'var', value: 'exp'},
      symbBracketLeft,
      {type: 'var', value: '-5330'},
      symbSeg,
      symbBracketLeft,
      {type: 'var', value: '273'},
      symbPlus,
      {type: 'var', value: 't', fontStyle: 'italic', sub: 'i'},
      symbBracketRight,
      symbBracketRight,
      symbEq,
    ],
    unit: 'Па',
  },
  R_tr_n1: {
    name: 'R_tr_n1',
    value: [
      {type: 'var', value: 'R', sub: 'n1', sup: 'тр', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [
            symbBracketLeft,
            vareb,
            symbMinus,
            varE,
            symbBracketRight,
            symbMult,
            {type: 'var', value: 'R', sub: 'п,н', fontStyle: 'italic'},
          ],
          [varE, symbMinus, {type: 'var', value: 'e', sub: 'n'}],
        ],
      },
      symbEq,
    ],
    unit: 'м²·ч·Па/мг',
  },
  E_0: {
    name: 'E_0',
    value: [
      varE0,
      symbEq,
      {type: 'var', value: '1.84'},
      symbMult,
      {type: 'var', value: '10^11'},
      symbMult,
      {type: 'var', value: 'exp'},
      symbBracketLeft,
      {type: 'var', value: '-5330'},
      symbSeg,
      symbBracketLeft,
      {type: 'var', value: '273'},
      symbPlus,
      {type: 'var', value: 't', fontStyle: 'italic', sub: '0'},
      symbBracketRight,
      symbBracketRight,
      symbEq,
    ],
    unit: 'Па',
  },
  t_0: {
    name: 't_0',
    value: [
      vartx,
      symbEq,
      vartb,
      symbMinus,
      {
        type: 'fraction',
        value: [
          [symbBracketLeft, vartb, symbMinus, varth, symbBracketRight],
          [varrusl, symbMult, varrx],
        ],
      },
      symbMult,
      varrusl,
      symbEq,
    ],
    unit: 'Па',
  },
  e_notr: {
    name: 'e_notr',
    value: [
      varnotr,
      symbEq,
      {type: 'var', value: '∑e', fontStyle: 'italic'},
      symbSeg,
      {type: 'var', value: 'n', fontStyle: 'italic'},
      symbEq,
    ],
    unit: 'Па',
  },
  eta: {
    name: 'eta',
    value: [
      vareta,
      symbEq,
      {
        type: 'fraction',
        value: [
          [
            {type: 'var', value: '0.0024'},
            symbMult,
            symbBracketLeft,
            varE0,
            symbMinus,
            varnotr,
            symbBracketRight,
            symbMult,
            varz0,
          ],
          [varrpn],
        ],
      },
      symbEq,
    ],
    unit: '',
  },
  R_tr_n2: {
    name: 'R_tr_n2',
    value: [
      {type: 'var', value: 'R', sub: 'n2', sup: 'тр', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [
            {type: 'var', value: '0.0024'},
            symbMult,
            varz0,
            symbMult,
            symbBracketLeft,
            vareb,
            symbMinus,
            varE0,
            symbBracketRight,
          ],
          [
            {type: 'var', value: 'p', sub: 'w', fontStyle: 'italic'},
            symbMult,
            {type: 'var', value: 'δ', sub: 'w', fontStyle: 'italic'},
            symbMult,
            {type: 'var', value: 'Δ', sub: 'w', fontStyle: 'italic'},
            symbPlus,
            vareta,
          ],
        ],
      },
      symbEq,
    ],
    unit: 'м²·ч·Па/мг',
  },
  t_notr: {
    name: 't_notr',
    value: [
      vartnotr,
      symbEq,
      {
        type: 'fraction',
        value: [
          [
            {type: 'var', value: '∑t'},
            symbBracketLeft,
            {type: 'symb', value: '<'},
            {type: 'var', value: '0'},
            symbBracketRight,
          ],
          [{type: 'var', value: 'n', fontStyle: 'italic'}],
        ],
      },
      symbEq,
    ],
    unit: '°С',
  },
  x_mu: {
    name: 'x_mu',
    value: [{type: 'var', value: 'x', sub: 'м.у.'}, symbEq],
    unit: 'м',
  },
  termResist: {
    name: 'termResist',
    value: [{type: 'var', value: '∑R', fontStyle: 'italic'}, symbEq],
    unit: 'м²·°С/Вт',
  },
  z1: {name: 'z1', value: [{type: 'var', value: 'z', sub: '1', fontStyle: 'italic'}, symbEq], unit: 'мес'},
  z2: {name: 'z2', value: [{type: 'var', value: 'z', sub: '2', fontStyle: 'italic'}, symbEq], unit: 'мес'},
  z3: {name: 'z3', value: [{type: 'var', value: 'z', sub: '3', fontStyle: 'italic'}, symbEq], unit: 'мес'},
  to1: {name: 'to1', value: [{type: 'var', value: 't', sub: 'о1', fontStyle: 'italic'}, symbEq], unit: '°С'},
  to2: {name: 'to2', value: [{type: 'var', value: 't', sub: 'о2', fontStyle: 'italic'}, symbEq], unit: '°С'},
  to3: {name: 'to3', value: [{type: 'var', value: 't', sub: 'о3', fontStyle: 'italic'}, symbEq], unit: '°С'},
  t1: {name: 't1', value: [{type: 'var', value: 't', sub: '1', fontStyle: 'italic'}, symbEq], unit: '°С'},
  t2: {name: 't2', value: [{type: 'var', value: 't', sub: '2', fontStyle: 'italic'}, symbEq], unit: '°С'},
  t3: {name: 't3', value: [{type: 'var', value: 't', sub: '3', fontStyle: 'italic'}, symbEq], unit: '°С'},
  E1: {name: 'E1', value: [{type: 'var', value: 'E', sub: '1', fontStyle: 'italic'}, symbEq], unit: 'Па'},
  E2: {name: 'E2', value: [{type: 'var', value: 'E', sub: '2', fontStyle: 'italic'}, symbEq], unit: 'Па'},
  E3: {name: 'E3', value: [{type: 'var', value: 'E', sub: '3', fontStyle: 'italic'}, symbEq], unit: 'Па'},
  z_o: {name: 'z_o', value: [{type: 'var', value: 'z', sub: 'о', fontStyle: 'italic'}, symbEq], unit: 'сут'},
  t_o: {name: 't_o', value: [{type: 'var', value: 't', sub: 'о', fontStyle: 'italic'}, symbEq], unit: '°С'},
  E_o: {name: 'E_o', value: [{type: 'var', value: 'E', sub: 'о', fontStyle: 'italic'}, symbEq], unit: 'Па'},
  R_pn: {
    name: 'R_pn',
    value: [
      {type: 'var', value: 'R', sub: 'п,н', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [{type: 'var', value: 'δ', sub: 'i', fontStyle: 'italic'}],
          [{type: 'var', value: 'μ', sub: 'i', fontStyle: 'italic'}],
        ],
      },
      symbEq,
    ],
    unit: 'м²·ч·Па/мг',
  },

  R_nix: {
    name: 'R_nix',
    value: [{type: 'var', value: 'R', fontStyle: 'italic', sub: 'ni'}, symbEq],
    unit: 'м²·ч·Па/мг',
  },
  // Вентилируемая воздушная прослойка
  R_prime_p: {
    name: 'R_prime_p',
    value: [
      {type: 'var', value: 'R′', sub: 'п', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [{type: 'var', value: 'δ', sub: 'э', fontStyle: 'italic'}],
          [
            {type: 'var', value: 'B', fontStyle: 'italic'},
            {type: 'var', value: 'η', sub: 'ш', fontStyle: 'italic'},
            symbSeg,
            {
              type: 'var',
              value: '∑',
            },
            {
              type: 'var',
              value: 'ξ',
              sub: 'ш',
              fontStyle: 'italic',
            },
          ],
        ],
      },
      symbEq,
    ],
    unit: 'м²·ч·Па/мг',
  },
  R_p: {
    name: 'R_p',
    value: [
      {type: 'var', value: 'R', sub: 'п', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [{type: 'var', value: 'δ', sub: 'э', fontStyle: 'italic'}],
          [{type: 'var', value: 'μ', sub: 'э', fontStyle: 'italic'}],
        ],
      },
      symbEq,
    ],
    unit: 'м²·ч·Па/мг',
  },
  R_pr_p_prime: {
    name: 'R_pr_p_prime',
    value: [
      {type: 'var', value: 'R', sub: 'п', sup: 'пр', postfix: '′', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [{type: 'var', value: '∑F'}],
          [
            symbBracketLeft,
            {type: 'var', value: 'F', sub: 'гл', fontStyle: 'italic'},
            symbSeg,
            {type: 'var', value: 'R', sub: 'п', fontStyle: 'italic'},
            symbBracketRight,
            symbPlus,
            symbBracketLeft,
            {type: 'var', value: 'F′', fontStyle: 'italic'},
            symbSeg,
            {type: 'var', value: 'R′', sub: 'п', fontStyle: 'italic'},
            symbBracketRight,
          ],
        ],
      },
      symbEq,
    ],
    unit: 'м²·ч·Па/мг',
  },
  Mu: {
    name: 'Mu',
    value: [
      varmu,
      symbEq,
      {
        type: 'fraction',
        value: [
          [{type: 'var', value: 'δ', sub: 'э', fontStyle: 'italic'}],
          [{type: 'var', value: 'R', sub: 'п', sup: 'пр', postfix: '′', fontStyle: 'italic'}],
        ],
      },
      symbEq,
    ],
    unit: 'мг/(м·ч·Па)',
  },
  R_prime_op: {
    name: 'R_prime_op',
    value: [
      {type: 'var', value: 'R′', sub: 'о,п', fontStyle: 'italic'},
      symbEq,
      {type: 'var', value: 'R', sub: 'о,п', fontStyle: 'italic'},
      symbMinus,
      {type: 'var', value: 'R', sub: 'п', fontStyle: 'italic'},
      symbEq,
    ],
    unit: 'м²·ч·Па/мг',
  },
  R_tr_n1_prime: {
    name: 'R_tr_n1_prime',
    value: [
      {type: 'var', value: 'R', sub: 'n1', sup: 'тр', postfix: '′', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [
            symbBracketLeft,
            {type: 'var', value: 'e', sub: 'в', fontStyle: 'italic'},
            symbMinus,
            {type: 'var', value: 'E', fontStyle: 'italic'},
            symbBracketRight,
            {type: 'var', value: 'R', sub: 'п', sup: 'пр', postfix: '′', fontStyle: 'italic'},
          ],
          [
            {type: 'var', value: 'E', fontStyle: 'italic'},
            symbMinus,
            {type: 'var', value: 'e', sub: 'n', fontStyle: 'italic'},
          ],
        ],
      },
      symbEq,
    ],
    unit: 'м²·ч·Па/мг',
  },
  R_tr_n2_prime: {
    name: 'R_tr_n2_prime',
    value: [
      {type: 'var', value: 'R', sub: 'n2', sup: 'тр', postfix: '′', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [
            {type: 'var', value: '0.0024'},
            symbMult,
            {type: 'var', value: 'z', sub: '0', fontStyle: 'italic'},
            symbBracketLeft,
            {type: 'var', value: 'e', sub: 'в', fontStyle: 'italic'},
            symbMinus,
            {type: 'var', value: 'E', sub: '0', fontStyle: 'italic'},
            symbBracketRight,
          ],
          [
            {type: 'var', value: 'ρ', sub: 'w', fontStyle: 'italic'},
            {type: 'var', value: 'δ', sub: 'w', fontStyle: 'italic'},
            {type: 'var', value: 'Δ', sub: 'w', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'η′', fontStyle: 'italic'},
          ],
        ],
      },
      symbEq,
    ],
    unit: 'м²·ч·Па/мг',
  },
  eta_prime: {
    name: 'eta_prime',
    value: [
      {type: 'var', value: 'η′', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [
            {type: 'var', value: '0.0024'},
            symbBracketLeft,
            {type: 'var', value: 'E', sub: '0', fontStyle: 'italic'},
            symbMinus,
            {type: 'var', value: 'e', sub: 'н, отр', fontStyle: 'italic'},
            symbBracketRight,
            {type: 'var', value: 'z', sub: '0', fontStyle: 'italic'},
          ],
          [{type: 'var', value: 'R', sub: 'п', sup: 'пр', postfix: '′', fontStyle: 'italic'}],
        ],
      },
      symbEq,
    ],
  },
  delta_t: {
    name: 'delta_t',
    value: [
      {type: 'var', value: 'Δt', fontStyle: 'italic'},
      symbEq,
      {type: 'var', value: 't', sub: 'в', fontStyle: 'italic'},
      symbMinus,
      {type: 'var', value: 't', sub: 'н', fontStyle: 'italic'},
    ],
    unit: '°С',
  },
  e_v: {
    name: 'e_v',
    value: [
      {type: 'var', value: 'e', sub: 'в', fontStyle: 'italic'},
      symbEq,
      symbBracketLeft,
      {type: 'var', value: 'φ', sub: 'в', fontStyle: 'italic'},
      symbSeg,
      {type: 'var', value: '100'},
      symbBracketRight,
      symbMult,
      {type: 'var', value: 'E', sub: 'в', fontStyle: 'italic'},
    ],
  },
  delta_e: {
    name: 'delta_e',
    value: [
      {type: 'var', value: 'Δe', fontStyle: 'italic'},
      symbEq,
      {type: 'var', value: 'e', sub: 'в', fontStyle: 'italic'},
      symbMinus,
      {type: 'var', value: 'e', sub: 'н', fontStyle: 'italic'},
    ],
    unit: 'Па',
  },
  sum_R_ni_section: {
    name: 'sum_R_ni_section',
    value: [
      {type: 'var', value: '∑R', sub: 'ni', sup: 'сеч', fontStyle: 'italic'},
      symbEq,
      {type: 'var', value: 'R', sub: 'n1', fontStyle: 'italic'},
      symbPlus,
      {type: 'var', value: 'R', sub: 'n2', fontStyle: 'italic'},
      symbPlus,
      {type: 'var', value: '...'},
      symbPlus,
      {type: 'var', value: 'R', sub: 'ni', sup: 'вент.пр.', fontStyle: 'italic'},
      symbEq,
    ],
    unit: 'м²°С/Вт',
  },
  e_tau: {
    name: 'e_tau',
    value: [
      {type: 'var', value: 'e', sub: 'τ', fontStyle: 'italic'},
      symbEq,
      {type: 'var', value: 'e', sub: 'в', fontStyle: 'italic'},
      symbMinus,
      {
        type: 'fraction',
        value: [
          [
            {type: 'var', value: 'e', sub: 'в', fontStyle: 'italic'},
            symbMinus,
            {type: 'var', value: 'e', sub: 'н', fontStyle: 'italic'},
          ],
          [{type: 'var', value: 'R', sub: 'о.п', fontStyle: 'italic'}],
        ],
      },
      {type: 'var', value: '∑R', sub: 'ni', sup: 'сеч', fontStyle: 'italic'},
      symbEq,
    ],
    unit: 'Па',
  },
  sum_R_i_section: {
    name: 'sum_R_i_section',
    value: [
      {type: 'var', value: '∑R', sub: 'i', sup: 'сеч', fontStyle: 'italic'},
      symbEq,
      {type: 'var', value: 'R', sub: '1', fontStyle: 'italic'},
      symbPlus,
      {type: 'var', value: 'R', sub: '2', fontStyle: 'italic'},
      symbPlus,
      {type: 'var', value: '...'},
      symbPlus,
      {type: 'var', value: 'R', sub: 'i', sup: 'вент.пр.', fontStyle: 'italic'},
      symbEq,
    ],
    unit: 'м²°С/Вт',
  },
  tau_p: {
    name: 'tau_p',
    value: [
      {type: 'var', value: 'τ', sub: 'п', fontStyle: 'italic'},
      symbEq,
      {type: 'var', value: 't', sub: 'в', fontStyle: 'italic'},
      symbMinus,
      {
        type: 'fraction',
        value: [
          [
            {type: 'var', value: 't', sub: 'в', fontStyle: 'italic'},
            symbMinus,
            {type: 'var', value: 't', sub: 'н', fontStyle: 'italic'},
          ],
          [{type: 'var', value: 'R', sub: 'о', sup: 'усл', fontStyle: 'italic'}],
        ],
      },
      {type: 'var', value: '∑R', sub: 'i', sup: 'сеч', fontStyle: 'italic'},
      symbEq,
    ],
    unit: '°С',
  },
  E_tau: {
    name: 'E_tau',
    value: [
      {type: 'var', value: 'E', sub: 'τ', fontStyle: 'italic'},
      symbEq,
      {type: 'var', value: '1.84'},
      symbMult,
      {type: 'var', value: '10^11'},
      symbMult,
      {type: 'var', value: 'exp', fontStyle: 'italic'},
      symbBracketLeft,
      {type: 'var', value: '-5330'},
      symbSeg,
      symbBracketLeft,
      {type: 'var', value: '273'},
      symbPlus,
      {type: 'var', value: 'τ', sub: 'п', fontStyle: 'italic'},
      symbBracketRight,
      symbBracketRight,
      symbEq,
    ],
    unit: 'Па',
  },
  Q_v_sl: {
    name: 'Q_v_sl',
    value: [
      {type: 'var', value: 'Q', sub: 'в.сл', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [
            {type: 'var', value: 'e', sub: 'в', fontStyle: 'italic'},
            symbMinus,
            {type: 'var', value: 'e', sub: 'τ', fontStyle: 'italic'},
          ],
          [{type: 'var', value: 'R', sub: 'ni', sup: 'вн', fontStyle: 'italic'}],
        ],
      },
      symbEq,
    ],
    unit: 'г/м²·час',
  },
  Q_n_sl: {
    name: 'Q_n_sl',
    value: [
      {type: 'var', value: 'Q', sub: 'н.сл', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [
            {type: 'var', value: 'e', sub: 'τ', fontStyle: 'italic'},
            symbMinus,
            {type: 'var', value: 'e', sub: 'н', fontStyle: 'italic'},
          ],
          [
            {type: 'var', value: 'R', sub: 'о.п', fontStyle: 'italic'},
            symbMinus,
            {type: 'var', value: 'R', sub: 'ni', sup: 'вн', fontStyle: 'italic'},
          ],
        ],
      },
      symbEq,
    ],
    unit: 'г/м²·час',
  },
  delta_Q: {
    name: 'delta_Q',
    value: [
      {type: 'var', value: 'ΔQ', sub: 'ср', fontStyle: 'italic'},
      symbEq,
      {type: 'var', value: 'Q', sub: 'в.сл', fontStyle: 'italic'},
      symbMinus,
      {type: 'var', value: 'Q', sub: 'н.сл', fontStyle: 'italic'},
      symbEq,
    ],
    unit: 'г/м²·час',
  },
  sum_delta_Q: {
    name: 'sum_delta_Q',
    value: [
      {type: 'var', value: '∑ΔQ', sub: 'ср', fontStyle: 'italic'},
      symbEq,
      {type: 'var', value: '∑ΔQ', sub: 'м-1', fontStyle: 'italic'},
      symbPlus,
      {type: 'var', value: 'ΔQ', sub: 'м', fontStyle: 'italic'},
      symbEq,
    ],
    unit: 'г/м²·час',
  },
  t_n_greatestCondensate: {
    name: 't_n_greatestCondensate',
    value: [{type: 'var', value: 't', sub: 'н', fontStyle: 'italic'}, symbEq],
    unit: '°С',
  },
  t_sr_greatestCondensate: {
    name: 't_sr_greatestCondensate',
    value: [
      {type: 'var', value: 't', sub: 'ср', fontStyle: 'italic'},
      symbEq,
      {type: 'var', value: '0.95'},
      symbMult,
      {type: 'var', value: 't', sub: 'н', fontStyle: 'italic'},
      symbEq,
    ],
    unit: '°С',
  },
  fi_sr_greatestCondensate: {
    name: 't_n',
    value: [{type: 'var', value: 'φ', sub: 'ср', fontStyle: 'italic'}, symbEq],
    unit: '%',
  },
  E_sr: {
    name: 'E_sr',
    value: [
      {type: 'var', value: 'E', sub: 'ср', fontStyle: 'italic'},
      symbEq,
      {type: 'var', value: '1.84'},
      symbMult,
      {type: 'var', value: '10^11'},
      symbMult,
      {type: 'var', value: 'exp', fontStyle: 'italic'},
      symbBracketLeft,
      {type: 'var', value: '-5330'},
      symbSeg,
      symbBracketLeft,
      {type: 'var', value: '273'},
      symbPlus,
      {type: 'var', value: 't', sub: 'ср', fontStyle: 'italic'},
      symbBracketRight,
      symbBracketRight,
      symbEq,
    ],
    unit: 'Па',
  },
  e_sr: {
    name: 'e_sr',
    value: [
      {type: 'var', value: 'e', sub: 'ср', fontStyle: 'italic'},
      symbEq,
      symbBracketLeft,
      {type: 'var', value: 'φ', sub: 'ср', fontStyle: 'italic'},
      symbSeg,
      {type: 'symb', value: '100'},
      symbBracketRight,
      symbMult,
      {type: 'var', value: 'E', sub: 'ср', fontStyle: 'italic'},
      symbEq,
    ],
    unit: 'Па',
  },
  V_pr: {
    name: 'V_pr',
    value: [
      {type: 'var', value: 'V', sub: 'пр', fontStyle: 'italic'},
      symbEq,
      {type: 'var', value: '√'},
      symbBracketLeft,
      {type: 'symb', value: '0.08'},
      {type: 'var', value: 'H', fontStyle: 'italic'},
      symbBracketLeft,
      {type: 'var', value: 't', sub: 'ср', fontStyle: 'italic'},
      symbMinus,
      {type: 'var', value: 't', sub: 'н', fontStyle: 'italic'},
      symbBracketRight,
      symbSeg,
      {type: 'var', value: '∑ξ', fontStyle: 'italic'},
      symbBracketRight,
      symbMinus,
      {type: 'var', value: '7%'},
      symbEq,
    ],
    unit: 'м/с',
  },
  gamma_pr: {
    name: 'gamma_pr',
    value: [
      {type: 'var', value: 'γ', sub: 'пр', fontStyle: 'italic'},
      symbEq,
      {type: 'symb', value: '3463'},
      symbSeg,
      symbBracketLeft,
      {type: 'var', value: '273'},
      symbPlus,
      {type: 'var', value: 't', sub: 'ср', fontStyle: 'italic'},
      symbBracketRight,
      {type: 'var', value: 'g'},
      symbEq,
    ],
    unit: 'кг/м³',
  },
  W: {
    name: 'W',
    value: [
      {type: 'var', value: 'W', fontStyle: 'italic'},
      symbEq,
      {type: 'var', value: 'V', sub: 'пр', fontStyle: 'italic'},
      symbMult,
      {type: 'symb', value: '3600'},
      symbMult,
      {type: 'var', value: 'δ', sub: 'пр', fontStyle: 'italic'},
      symbMult,
      {type: 'var', value: 'γ', sub: 'пр', fontStyle: 'italic'},
      symbEq,
    ],
    unit: 'кг/п.м.·час',
  },
  alfa_pr: {
    name: 'alfa_pr',
    value: [
      {type: 'var', value: 'α', sub: 'пр', fontStyle: 'italic'},
      symbEq,
      {type: 'symb', value: '5,5'},
      symbPlus,
      {type: 'symb', value: '5,7'},
      {type: 'var', value: 'V', sub: 'пр', fontStyle: 'italic'},
      symbPlus,
      {type: 'var', value: 'α', sub: 'л', fontStyle: 'italic'},
      symbEq,
    ],
    unit: 'Вт/м²·ºС',
  },
  alfa_l: {
    name: 'alfa_l',
    value: [
      {type: 'var', value: 'α', sub: 'л', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [{type: 'symb', value: '1'}],
          [{type: 'symb', value: '1'}, symbSeg, {type: 'var', value: 'с', sub: '1', fontStyle: 'italic'}],
        ],
      },
      symbPlus,
      {
        type: 'fraction',
        value: [
          [{type: 'symb', value: '1'}],
          [{type: 'symb', value: '1'}, symbSeg, {type: 'var', value: 'с', sub: '2', fontStyle: 'italic'}],
        ],
      },
      symbMinus,
      {
        type: 'fraction',
        value: [
          [{type: 'symb', value: '1'}],
          [{type: 'symb', value: '1'}, symbSeg, {type: 'var', value: 'с', fontStyle: 'italic'}],
        ],
      },
      symbEq,
    ],
    unit: 'Вт/м²·ºС',
  },
  R_pr: {
    name: 'R_pr',
    value: [
      {type: 'var', value: 'R', sub: 'пр', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [[{type: 'symb', value: '1'}], [{type: 'var', value: 'α', sub: 'пр', fontStyle: 'italic'}]],
      },
      symbEq,
    ],
    unit: 'м²·ч·Па/мг',
  },
  k_v: {
    name: 'k_v',
    value: [
      {type: 'var', value: 'κ', sub: 'в', fontStyle: 'italic'},
      symbEq,
      {type: 'symb', value: '1'},
      symbSeg,
      symbBracketLeft,
      {
        type: 'fraction',
        value: [[{type: 'var', value: 'R', sub: 'пр', fontStyle: 'italic'}], [{type: 'symb', value: '2'}]],
      },
      symbPlus,
      {type: 'var', value: 'R', sub: '1', fontStyle: 'italic'},
      symbPlus,
      {type: 'var', value: 'R', sub: '2', fontStyle: 'italic'},
      symbPlus,
      {type: 'symb', value: '...'},
      symbPlus,
      {type: 'var', value: 'R', sub: 'i', fontStyle: 'italic'},
      symbBracketRight,
      symbEq,
    ],
    unit: 'Вт/м²·°С',
  },
  k_n: {
    name: 'k_n',
    value: [
      {type: 'var', value: 'κ', sub: 'н', fontStyle: 'italic'},
      symbEq,
      {type: 'symb', value: '1'},
      symbSeg,
      symbBracketLeft,
      {
        type: 'fraction',
        value: [[{type: 'var', value: 'R', sub: 'пр', fontStyle: 'italic'}], [{type: 'symb', value: '2'}]],
      },
      symbPlus,
      {type: 'var', value: 'R', sub: 'пр+1', fontStyle: 'italic'},
      symbPlus,
      {type: 'var', value: 'R', sub: 'пр+2', fontStyle: 'italic'},
      symbPlus,
      {type: 'symb', value: '...'},
      symbPlus,
      {type: 'var', value: 'R', sub: 'пр+i', fontStyle: 'italic'},
      symbBracketRight,
      symbEq,
    ],
    unit: 'Вт/м²·°С',
  },
  t_y: {
    name: 't_y',
    value: [
      {type: 'var', value: 't', sub: 'y', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [
            symbBracketLeft,
            {type: 'var', value: 'κ', sub: 'в', fontStyle: 'italic'},
            {type: 'var', value: 't', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'κ', sub: 'н', fontStyle: 'italic'},
            {type: 'var', value: 't', sub: 'н', fontStyle: 'italic'},
            symbBracketRight,
            symbPlus,
            symbSquareBracketLeft,
            {type: 'var', value: 't', sub: 'ср', fontStyle: 'italic'},
            symbBracketLeft,
            {type: 'var', value: 'κ', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'κ', sub: 'н', fontStyle: 'italic'},
            symbBracketRight,
            symbMinus,
            symbBracketLeft,
            {type: 'var', value: 'κ', sub: 'в', fontStyle: 'italic'},
            {type: 'var', value: 't', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'κ', sub: 'н', fontStyle: 'italic'},
            {type: 'var', value: 't', sub: 'н', fontStyle: 'italic'},
            symbBracketRight,
            symbMult,
            {type: 'symb', value: 'exp'},
            symbBracketLeft,
            {type: 'symb', value: '-'},
            symbBracketLeft,
            {type: 'var', value: 'C', sub: 'в', fontStyle: 'italic'},
            symbBracketLeft,
            {type: 'var', value: 'κ', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'κ', sub: 'н', fontStyle: 'italic'},
            symbBracketRight,
            {type: 'var', value: 'h', sub: 'y', fontStyle: 'italic'},
            symbSeg,
            {type: 'var', value: 'W', fontStyle: 'italic'},
            {type: 'var', value: 'C', fontStyle: 'italic'},
            symbBracketRight,
            symbBracketRight,
            symbSquareBracketRight,
          ],
          [
            {type: 'var', value: 'κ', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'κ', sub: 'н', fontStyle: 'italic'},
          ],
        ],
      },
      symbEq,
    ],
    unit: '°С',
  },
  E_y: {
    name: 'E_y',
    value: [
      {type: 'var', value: 'E', sub: 'y', fontStyle: 'italic'},
      symbEq,
      {type: 'symb', value: '1.84'},
      symbMult,
      {type: 'symb', value: '10', sup: '11'},
      symbMult,
      {type: 'symb', value: 'exp'},
      symbBracketLeft,
      symbMinus,
      {
        type: 'fraction',
        value: [
          [{type: 'symb', value: '5330'}],
          [{type: 'symb', value: '273'}, symbPlus, {type: 'var', value: 't', sub: 'y', fontStyle: 'italic'}],
        ],
      },
      symbBracketRight,
      symbEq,
    ],
    unit: 'Па',
  },
  M_v: {
    name: 'M_v',
    value: [
      {type: 'var', value: 'M', sub: 'в', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [[{type: 'symb', value: '1'}], [{type: 'var', value: '∑R', sub: 'вп', fontStyle: 'italic'}]],
      },
      symbEq,
    ],
    unit: 'мг/м²·ч·Па',
  },
  M_n: {
    name: 'M_n',
    value: [
      {type: 'var', value: 'M', sub: 'н', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [[{type: 'symb', value: '1'}], [{type: 'var', value: '∑R', sub: 'пн', fontStyle: 'italic'}]],
      },
      symbEq,
    ],
    unit: 'мг/м²·ч·Па',
  },
  B: {
    name: 'B',
    value: [
      {type: 'var', value: 'B'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [{type: 'symb', value: '1,085'}],
          [
            {type: 'symb', value: '1'},
            symbPlus,
            {type: 'var', value: 't', sub: 'y', fontStyle: 'italic'},
            symbSeg,
            {type: 'symb', value: '273'},
          ],
        ],
      },
      symbEq,
    ],
  },
  e_y: {
    name: 'e_y',
    value: [
      {type: 'var', value: 'e', sub: 'y', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [
            symbBracketLeft,
            {type: 'var', value: 'M', sub: 'в', fontStyle: 'italic'},
            {type: 'var', value: 'e', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'M', sub: 'н', fontStyle: 'italic'},
            {type: 'var', value: 'e', sub: 'н', fontStyle: 'italic'},
            symbBracketRight,
            symbPlus,
            symbSquareBracketLeft,
            {type: 'var', value: 'e', sub: 'ср', fontStyle: 'italic'},
            symbBracketLeft,
            {type: 'var', value: 'M', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'M', sub: 'н', fontStyle: 'italic'},
            symbBracketRight,
            symbMinus,
            symbBracketLeft,
            {type: 'var', value: 'M', sub: 'в', fontStyle: 'italic'},
            {type: 'var', value: 'e', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'M', sub: 'н', fontStyle: 'italic'},
            {type: 'var', value: 'e', sub: 'н', fontStyle: 'italic'},
            symbBracketRight,
            symbSquareBracketRight,
            symbMult,
            {type: 'symb', value: 'exp'},
            symbBracketLeft,
            {type: 'symb', value: '-'},
            symbBracketLeft,
            symbBracketLeft,
            {type: 'var', value: 'M', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'M', sub: 'н', fontStyle: 'italic'},
            symbBracketRight,
            {type: 'var', value: 'h', sub: 'y', fontStyle: 'italic'},
            symbSeg,
            {type: 'var', value: 'W', fontStyle: 'italic'},
            {type: 'var', value: 'B', fontStyle: 'italic'},
            symbBracketRight,
            symbBracketRight,
          ],
          [
            {type: 'var', value: 'M', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'M', sub: 'н', fontStyle: 'italic'},
          ],
        ],
      },
      symbEq,
    ],
    unit: 'Па',
  },
  t_n_coldestFiveDays: {
    name: 't_n_greatestCondensate',
    value: [{type: 'var', value: 't', sub: 'н', fontStyle: 'italic'}, symbEq],
    unit: '°С',
  },
  t_sr_coldestFiveDays: {
    name: 't_sr_greatestCondensate',
    value: [
      {type: 'var', value: 't', sub: 'ср', fontStyle: 'italic'},
      symbEq,
      {type: 'var', value: '0.95'},
      symbMult,
      {type: 'var', value: 't', sub: 'н', fontStyle: 'italic'},
      symbEq,
    ],
    unit: '°С',
  },
  fi_sr_coldestFiveDays: {
    name: 't_n',
    value: [{type: 'var', value: 'φ', sub: 'ср', fontStyle: 'italic'}, symbEq],
    unit: '%',
  },
  E_sr_h: {
    name: 'E_sr_h',
    value: [
      {type: 'var', value: 'E', sub: 'ср', fontStyle: 'italic'},
      symbEq,
      {type: 'var', value: '1.84'},
      symbMult,
      {type: 'var', value: '10^11'},
      symbMult,
      {type: 'var', value: 'exp', fontStyle: 'italic'},
      symbBracketLeft,
      {type: 'var', value: '-5330'},
      symbSeg,
      symbBracketLeft,
      {type: 'var', value: '273'},
      symbPlus,
      {type: 'var', value: 't', sub: 'ср', fontStyle: 'italic'},
      symbBracketRight,
      symbBracketRight,
      symbEq,
    ],
    unit: 'Па',
  },
  e_sr_h: {
    name: 'e_sr_h',
    value: [
      {type: 'var', value: 'e', sub: 'ср', fontStyle: 'italic'},
      symbEq,
      symbBracketLeft,
      {type: 'var', value: 'φ', sub: 'ср', fontStyle: 'italic'},
      symbSeg,
      {type: 'symb', value: '100'},
      symbBracketRight,
      symbMult,
      {type: 'var', value: 'E', sub: 'ср', fontStyle: 'italic'},
      symbEq,
    ],
    unit: 'Па',
  },
  V_pr_h: {
    name: 'V_pr_h',
    value: [
      {type: 'var', value: 'V', sub: 'пр', fontStyle: 'italic'},
      symbEq,
      {type: 'var', value: '√'},
      symbBracketLeft,
      {type: 'symb', value: '0.08'},
      {type: 'var', value: 'H', fontStyle: 'italic'},
      symbBracketLeft,
      {type: 'var', value: 't', sub: 'ср', fontStyle: 'italic'},
      symbMinus,
      {type: 'var', value: 't', sub: 'н', fontStyle: 'italic'},
      symbBracketRight,
      symbSeg,
      {type: 'var', value: '∑ξ', fontStyle: 'italic'},
      symbBracketRight,
      symbMinus,
      {type: 'var', value: '7%'},
      symbEq,
    ],
    unit: 'м/с',
  },
  gamma_pr_h: {
    name: 'gamma_pr_h',
    value: [
      {type: 'var', value: 'γ', sub: 'пр', fontStyle: 'italic'},
      symbEq,
      {type: 'symb', value: '3463'},
      symbSeg,
      symbBracketLeft,
      {type: 'var', value: '273'},
      symbPlus,
      {type: 'var', value: 't', sub: 'ср', fontStyle: 'italic'},
      symbBracketRight,
      {type: 'var', value: 'g'},
      symbEq,
    ],
    unit: 'кг/м³',
  },
  W_h: {
    name: 'W_h',
    value: [
      {type: 'var', value: 'W', fontStyle: 'italic'},
      symbEq,
      {type: 'var', value: 'V', sub: 'пр', fontStyle: 'italic'},
      symbMult,
      {type: 'symb', value: '3600'},
      symbMult,
      {type: 'var', value: 'δ', sub: 'пр', fontStyle: 'italic'},
      symbMult,
      {type: 'var', value: 'γ', sub: 'пр', fontStyle: 'italic'},
      symbEq,
    ],
    unit: 'кг/п.м.·час',
  },
  alfa_pr_h: {
    name: 'alfa_pr_h',
    value: [
      {type: 'var', value: 'α', sub: 'пр', fontStyle: 'italic'},
      symbEq,
      {type: 'symb', value: '5,5'},
      symbPlus,
      {type: 'symb', value: '5,7'},
      {type: 'var', value: 'V', sub: 'пр', fontStyle: 'italic'},
      symbPlus,
      {type: 'var', value: 'α', sub: 'л', fontStyle: 'italic'},
      symbEq,
    ],
    unit: 'Вт/м²·ºС',
  },
  alfa_l_h: {
    name: 'alfa_l_h',
    value: [
      {type: 'var', value: 'α', sub: 'л', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [{type: 'symb', value: '1'}],
          [{type: 'symb', value: '1'}, symbSeg, {type: 'var', value: 'с', sub: '1', fontStyle: 'italic'}],
        ],
      },
      symbPlus,
      {
        type: 'fraction',
        value: [
          [{type: 'symb', value: '1'}],
          [{type: 'symb', value: '1'}, symbSeg, {type: 'var', value: 'с', sub: '2', fontStyle: 'italic'}],
        ],
      },
      symbMinus,
      {
        type: 'fraction',
        value: [
          [{type: 'symb', value: '1'}],
          [{type: 'symb', value: '1'}, symbSeg, {type: 'var', value: 'с', fontStyle: 'italic'}],
        ],
      },
      symbEq,
    ],
    unit: 'Вт/м²·ºС',
  },
  R_pr_h: {
    name: 'R_pr_h',
    value: [
      {type: 'var', value: 'R', sub: 'пр', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [[{type: 'symb', value: '1'}], [{type: 'var', value: 'α', sub: 'пр', fontStyle: 'italic'}]],
      },
      symbEq,
    ],
    unit: 'м²·ч·Па/мг',
  },
  k_v_h: {
    name: 'k_v_h',
    value: [
      {type: 'var', value: 'κ', sub: 'в', fontStyle: 'italic'},
      symbEq,
      {type: 'symb', value: '1'},
      symbSeg,
      symbBracketLeft,
      {
        type: 'fraction',
        value: [[{type: 'var', value: 'R', sub: 'пр', fontStyle: 'italic'}], [{type: 'symb', value: '2'}]],
      },
      symbPlus,
      {type: 'var', value: 'R', sub: '1', fontStyle: 'italic'},
      symbPlus,
      {type: 'var', value: 'R', sub: '2', fontStyle: 'italic'},
      symbPlus,
      {type: 'symb', value: '...'},
      symbPlus,
      {type: 'var', value: 'R', sub: 'i', fontStyle: 'italic'},
      symbBracketRight,
      symbEq,
    ],
    unit: 'Вт/м²·°С',
  },
  k_n_h: {
    name: 'k_n_h',
    value: [
      {type: 'var', value: 'κ', sub: 'н', fontStyle: 'italic'},
      symbEq,
      {type: 'symb', value: '1'},
      symbSeg,
      symbBracketLeft,
      {
        type: 'fraction',
        value: [[{type: 'var', value: 'R', sub: 'пр', fontStyle: 'italic'}], [{type: 'symb', value: '2'}]],
      },
      symbPlus,
      {type: 'var', value: 'R', sub: 'пр+1', fontStyle: 'italic'},
      symbPlus,
      {type: 'var', value: 'R', sub: 'пр+2', fontStyle: 'italic'},
      symbPlus,
      {type: 'symb', value: '...'},
      symbPlus,
      {type: 'var', value: 'R', sub: 'пр+i', fontStyle: 'italic'},
      symbBracketRight,
      symbEq,
    ],
    unit: 'Вт/м²·°С',
  },
  t_y_h: {
    name: 't_y_h',
    value: [
      {type: 'var', value: 't', sub: 'y', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [
            symbBracketLeft,
            {type: 'var', value: 'κ', sub: 'в', fontStyle: 'italic'},
            {type: 'var', value: 't', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'κ', sub: 'н', fontStyle: 'italic'},
            {type: 'var', value: 't', sub: 'н', fontStyle: 'italic'},
            symbBracketRight,
            symbPlus,
            symbSquareBracketLeft,
            {type: 'var', value: 't', sub: 'ср', fontStyle: 'italic'},
            symbBracketLeft,
            {type: 'var', value: 'κ', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'κ', sub: 'н', fontStyle: 'italic'},
            symbBracketRight,
            symbMinus,
            symbBracketLeft,
            {type: 'var', value: 'κ', sub: 'в', fontStyle: 'italic'},
            {type: 'var', value: 't', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'κ', sub: 'н', fontStyle: 'italic'},
            {type: 'var', value: 't', sub: 'н', fontStyle: 'italic'},
            symbBracketRight,
            symbMult,
            {type: 'symb', value: 'exp'},
            symbBracketLeft,
            {type: 'symb', value: '-'},
            symbBracketLeft,
            {type: 'var', value: 'C', sub: 'в', fontStyle: 'italic'},
            symbBracketLeft,
            {type: 'var', value: 'κ', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'κ', sub: 'н', fontStyle: 'italic'},
            symbBracketRight,
            {type: 'var', value: 'h', sub: 'y', fontStyle: 'italic'},
            symbSeg,
            {type: 'var', value: 'W', fontStyle: 'italic'},
            {type: 'var', value: 'C', fontStyle: 'italic'},
            symbBracketRight,
            symbBracketRight,
            symbSquareBracketRight,
          ],
          [
            {type: 'var', value: 'κ', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'κ', sub: 'н', fontStyle: 'italic'},
          ],
        ],
      },
      symbEq,
    ],
    unit: '°С',
  },
  E_y_h: {
    name: 'E_y_h',
    value: [
      {type: 'var', value: 'E', sub: 'y', fontStyle: 'italic'},
      symbEq,
      {type: 'symb', value: '1.84'},
      symbMult,
      {type: 'symb', value: '10', sup: '11'},
      symbMult,
      {type: 'symb', value: 'exp'},
      symbBracketLeft,
      symbMinus,
      {
        type: 'fraction',
        value: [
          [{type: 'symb', value: '5330'}],
          [{type: 'symb', value: '273'}, symbPlus, {type: 'var', value: 't', sub: 'y', fontStyle: 'italic'}],
        ],
      },
      symbBracketRight,
      symbEq,
    ],
    unit: 'Па',
  },
  M_v_h: {
    name: 'M_v_h',
    value: [
      {type: 'var', value: 'M', sub: 'в', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [[{type: 'symb', value: '1'}], [{type: 'var', value: '∑R', sub: 'вп', fontStyle: 'italic'}]],
      },
      symbEq,
    ],
    unit: 'мг/м²·ч·Па',
  },
  M_n_h: {
    name: 'M_n_h',
    value: [
      {type: 'var', value: 'M', sub: 'н', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [[{type: 'symb', value: '1'}], [{type: 'var', value: '∑R', sub: 'пн', fontStyle: 'italic'}]],
      },
      symbEq,
    ],
    unit: 'мг/м²·ч·Па',
  },
  B_h: {
    name: 'B_h',
    value: [
      {type: 'var', value: 'B'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [{type: 'symb', value: '1,085'}],
          [
            {type: 'symb', value: '1'},
            symbPlus,
            {type: 'var', value: 't', sub: 'y', fontStyle: 'italic'},
            symbSeg,
            {type: 'symb', value: '273'},
          ],
        ],
      },
      symbEq,
    ],
  },
  e_y_h: {
    name: 'e_y_h',
    value: [
      {type: 'var', value: 'e', sub: 'y', fontStyle: 'italic'},
      symbEq,
      {
        type: 'fraction',
        value: [
          [
            symbBracketLeft,
            {type: 'var', value: 'M', sub: 'в', fontStyle: 'italic'},
            {type: 'var', value: 'e', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'M', sub: 'н', fontStyle: 'italic'},
            {type: 'var', value: 'e', sub: 'н', fontStyle: 'italic'},
            symbBracketRight,
            symbPlus,
            symbSquareBracketLeft,
            {type: 'var', value: 'e', sub: 'ср', fontStyle: 'italic'},
            symbBracketLeft,
            {type: 'var', value: 'M', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'M', sub: 'н', fontStyle: 'italic'},
            symbBracketRight,
            symbMinus,
            symbBracketLeft,
            {type: 'var', value: 'M', sub: 'в', fontStyle: 'italic'},
            {type: 'var', value: 'e', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'M', sub: 'н', fontStyle: 'italic'},
            {type: 'var', value: 'e', sub: 'н', fontStyle: 'italic'},
            symbBracketRight,
            symbSquareBracketRight,
            symbMult,
            {type: 'symb', value: 'exp'},
            symbBracketLeft,
            {type: 'symb', value: '-'},
            symbBracketLeft,
            symbBracketLeft,
            {type: 'var', value: 'M', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'M', sub: 'н', fontStyle: 'italic'},
            symbBracketRight,
            {type: 'var', value: 'h', sub: 'y', fontStyle: 'italic'},
            symbSeg,
            {type: 'var', value: 'W', fontStyle: 'italic'},
            {type: 'var', value: 'B', fontStyle: 'italic'},
            symbBracketRight,
            symbBracketRight,
          ],
          [
            {type: 'var', value: 'M', sub: 'в', fontStyle: 'italic'},
            symbPlus,
            {type: 'var', value: 'M', sub: 'н', fontStyle: 'italic'},
          ],
        ],
      },
      symbEq,
    ],
    unit: 'Па',
  },
};

const calculation1Service = new Calculation1Service();
export default calculation1Service;
